define('my-care-git-ember-portal/index/job-listings/redirect-route/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {
            this.set('thread_id', params.thread_id);
        },
        afterModel: function afterModel() {
            this.transitionTo('index.threads.detail', this.get('thread_id'));
        }
    });
});