define('my-care-git-ember-portal/mixins/seeker_only', ['exports', 'ember'], function (exports, _ember) {
		exports['default'] = _ember['default'].Mixin.create({
				beforeModel: function beforeModel() {
						var self = this;
						self.store.findRecord('person', self.controllerFor('auth').get('currentPersonId')).then(function (person) {
								if (person.get('profileType') === 'Worker') {
										return;
								} else {
										if (person.get('subscriptionType') === 'DSS') {
												self.transitionTo('seekers.findhelp.dss');
										} else if (!person.get('isValidSubscriber') && person.get('subscriptionType') === 'Premium') {
												self.transitionTo('seekers.findhelp.renew');
										}

										return;
								}
						});
				}
		});
});