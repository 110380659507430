define('my-care-git-ember-portal/components/mc-phone-picker/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        _mobilePrefixs: _ember['default'].A([_ember['default'].Object.create({ label: '', value: '' }), _ember['default'].Object.create({ label: '0800', value: '0800' }), _ember['default'].Object.create({ label: '0508', value: '0508' }), _ember['default'].Object.create({ label: '09', value: '09' }), _ember['default'].Object.create({ label: '07', value: '07' }), _ember['default'].Object.create({ label: '06', value: '06' }), _ember['default'].Object.create({ label: "04", value: "04" }), _ember['default'].Object.create({ label: "03", value: "03" }), _ember['default'].Object.create({ label: "020", value: "020" }), _ember['default'].Object.create({ label: "021", value: "021" }), _ember['default'].Object.create({ label: "022", value: "022" }), _ember['default'].Object.create({ label: "027", value: "027" }), _ember['default'].Object.create({ label: "028", value: "028" }), _ember['default'].Object.create({ label: "029", value: "029" })]),
        _mobilePrefix: null,
        prefix: null,
        affix: null,
        didRender: function didRender() {
            var self = this;
            self.set('isCorrectMobileFormat', false);
            var value = self.get('value');
            if (this.isDestroyed) {
                return;
            }
            _ember['default'].RSVP.resolve(this.get("_country")).then(function (country) {
                var isNZ = false;
                if (country) {
                    if (country.get("name").toLowerCase() === 'new zealand') {
                        isNZ = true;
                    }
                    self.set('isNZ', isNZ);
                    if (_ember['default'].isEmpty(value) === false) {
                        var prefixAndaffix = self.get('value').split("-");
                        if (prefixAndaffix.length > 1) {
                            var prefix = self.get('_mobilePrefixs').find(function (p) {
                                return p.get('value') === prefixAndaffix[0];
                            });
                            // prefix does match prefix array
                            if (_ember['default'].isEmpty(prefix)) {

                                self.set('affix', prefixAndaffix[1]);
                            } else {
                                self.set('prefix', prefix);
                                var newAffix = undefined;
                                for (var i = 1; i < prefixAndaffix.length; i++) {
                                    if (i === 1) {
                                        newAffix = prefixAndaffix[i];
                                    } else {
                                        newAffix = newAffix + "-" + prefixAndaffix[i];
                                    }
                                }
                                self.set('affix', newAffix);
                            }
                        } else {
                            self.set('prefix', self.get('_mobilePrefixs').objectAt(0));
                            self.set('affix', value);
                        }
                    } else {
                        self.set('prefix', self.get('_mobilePrefixs').objectAt(0));
                        self.set('affix', "");
                    }
                }
            });
        },

        watchPrefixAndAffix: (function () {
            if (_ember['default'].isEmpty(this.get('prefix.value'))) {
                this.set('value', this.get('affix'));
            } else {

                this.set('value', this.get('prefix.value') + "-" + this.get('affix'));
            }
            this.sendAction('onChangePhoneNumber', this.get('value'));
        }).observes('prefix', 'affix'),

        _isNumeric: function _isNumeric(n) {
            var reg = new RegExp('^[0-9-+]+$');
            return reg.test(n);
        }
    });
});