define('my-care-git-ember-portal/services/account-fee-service', ['exports', 'ember', 'ember-concurrency', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _emberConcurrency, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({
        _store: _ember['default'].inject.service('store'),
        _httpService: _ember['default'].inject.service('http'),

        getAccountFeeType: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findAll('account-fee-type');

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getAccountTypeById: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findRecord('account-type', id);

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getAllAccountFeeByAccountTypeId: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var accountTypeId = _ref.accountTypeId;
            var _ref$reload = _ref.reload;
            var reload = _ref$reload === undefined ? false : _ref$reload;
            var accountFees;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').query('account-fee', { accountTypeId: accountTypeId, isAll: true }, { reload: reload });

                    case 2:
                        accountFees = context$1$0.sent;
                        return context$1$0.abrupt('return', accountFees);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getAccountFeesGroupByFeeTypeByAccountId: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref2) {
            var accountTypeId = _ref2.accountTypeId;
            var reload = _ref2.reload;
            var accountFeeTypes, accountFees, listTypeWithFees;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('getAccountFeeType').perform();

                    case 2:
                        accountFeeTypes = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.get('getAllAccountFeeByAccountTypeId').perform({ accountTypeId: accountTypeId, reload: reload });

                    case 5:
                        accountFees = context$1$0.sent.toArray();

                        accountFees.sort(function (a, b) {
                            return parseInt(a.get('id')) - parseInt(b.get('id'));
                        });

                        listTypeWithFees = [];

                        accountFeeTypes.forEach(function (feeType) {
                            var result = accountFees.filter(function (fee) {
                                return fee.get('accountFeeTypeId') === feeType.get('id');
                            });
                            listTypeWithFees.push({
                                feeType: feeType,
                                accountFees: result
                            });
                        });
                        return context$1$0.abrupt('return', listTypeWithFees);

                    case 10:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        deleteAccountFeeAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref3) {
            var accountTypeId = _ref3.accountTypeId;
            var accountFeeTypeId = _ref3.accountFeeTypeId;
            var url;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        url = _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + ('/api/AccountFees/Delete?accountTypeId=' + accountTypeId + '&accountFeeTypeId=' + accountFeeTypeId);
                        context$1$0.next = 3;
                        return this.get('_httpService').withAuthentication(function (defer) {
                            _ember['default'].$.ajax({
                                url: url,
                                type: 'DELETE',
                                success: function success(data) {
                                    return defer.resolve(data);
                                }
                            });
                        });

                    case 3:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});