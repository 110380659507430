define('my-care-git-ember-portal/models/report-table', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        month: _emberData['default'].attr('string'),
        year: _emberData['default'].attr('string'),
        reportType: _emberData['default'].attr('string'),
        reportRows: _emberData['default'].hasMany('report-row'),
        totalClients: _emberData['default'].attr('string'),
        totalHours: _emberData['default'].attr('string'),
        totalMyCareFees: _emberData['default'].attr('string')
    });
});