define('my-care-git-ember-portal/services/utils/modal-helper', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    _eventbus: _ember['default'].inject.service('event-bus'),

    openModal: function openModal(channelId) {
      var _get;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      (_get = this.get('_eventbus')).publish.apply(_get, ['mc-modal:on:open:' + channelId].concat(args));
    },

    closeModal: function closeModal(channelId) {
      var _get2;

      for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      (_get2 = this.get('_eventbus')).publish.apply(_get2, ['mc-modal:on:close:' + channelId].concat(args));
    },

    onShow: function onShow(channelId, callback) {
      this.get('_eventbus').subscribe('mc-modal:on:show:' + channelId, callback);
    },

    onLoaded: function onLoaded(channelId, callback) {
      this.get('_eventbus').subscribe('mc-modal:on:loaded:' + channelId, callback);
    },

    onShown: function onShown(channelId, callback) {
      this.get('_eventbus').subscribe('mc-modal:on:shown:' + channelId, callback);
    },

    onHide: function onHide(channelId, callback) {
      this.get('_eventbus').subscribe('mc-modal:on:hide:' + channelId, callback);
    },

    onHidden: function onHidden(channelId, callback) {
      this.get('_eventbus').subscribe('mc-modal:on:hidden:' + channelId, callback);
    }
  });
});