define('my-care-git-ember-portal/components/mc-password-strength/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('password', undefined);
            this.initVar('onChange', undefined);
            this.initVar('_passwordRules', []);
            this.initVar('_isPasswordRulesPassed', false);
        },

        willSetupOnce: function willSetupOnce() {
            this._watchPassword();
        },

        _watchIsValid: (function () {
            _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onChange'), this.get('_isPasswordRulesPassed'));
        }).observes('_isPasswordRulesPassed'),

        _watchPassword: (function () {
            var result = this.calculatePasswordStrength(this.get('password'));
            this.set('_passwordRules', result);
            if (!_ember['default'].isEmpty(result)) {
                this.set('_isPasswordRulesPassed', !result.any(function (rule) {
                    return rule.get('isValid') === false;
                }));
            } else {
                this.set('_isPasswordRulesPassed', false);
            }
        }).observes('password'),

        calculatePasswordStrength: function calculatePasswordStrength(password) {
            var result = [_ember['default'].Object.create({
                id: 'atLeastOneLowercase',
                title: 'One lower character',
                isValid: !_ember['default'].isEmpty(password) && password.match(/([a-z])+/g) !== null
            }), _ember['default'].Object.create({
                id: 'atLeastOneUppercase',
                title: 'One uppercase character',
                isValid: !_ember['default'].isEmpty(password) && password.match(/([A-Z])+/g) !== null
            }), _ember['default'].Object.create({
                id: 'atLeastOneNumber',
                title: 'One number',
                isValid: !_ember['default'].isEmpty(password) && password.match(/\d+/g) !== null
            }), _ember['default'].Object.create({
                id: 'length',
                title: '6 to 20 characters long',
                isValid: !_ember['default'].isEmpty(password) && password.length >= 6 && password.length <= 20
            })];
            return _ember['default'].A(result);
        }
    });
});