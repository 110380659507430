define('my-care-git-ember-portal/models/account-error', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        //    person: DS.belongsTo('person',{async: true}),
        //    bankAccounts: DS.hasMany('bank-account',{async: true}),
        //    preferredBankAccount: DS.attr('string'),
        //    accountBalance: DS.attr('number'),
        //    lifetimeEarnings: DS.attr('number'),
        //    accountType: DS.belongsTo('account-type',{async: true}),
        //    accountTypeName: DS.attr('string'),
        //    transactionItems: DS.attr('string'),
        //    creditCards: DS.hasMany('credit-card',{async: true}),
        //    preferredCreditCard: DS.attr('string'),
        //    isGstRegistered: DS.attr('boolean'),
        //    irdNumber: DS.attr('string'),
        //    topupAmount: DS.attr('string'),
        message: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        exception: _emberData['default'].attr('string'),
        account: _emberData['default'].belongsTo('account', { async: true })
    });
});