define('my-care-git-ember-portal/components/mc-user-detail/reset-password/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/password-checker', 'my-care-git-ember-portal/mixins/fedback-handler', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/config/environment', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/mixins/validator'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsPasswordChecker, _myCareGitEmberPortalMixinsFedbackHandler, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalConfigEnvironment, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalMixinsValidator) {

    /**
     * phone-picker component
     */
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsPasswordChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], _myCareGitEmberPortalMixinsValidator['default'], {
        passwordUpdated: false,
        isUpdatingPassword: false,
        div_id: 'reset-password',
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        setupValidations: function setupValidations() {
            this._resetValidation();
            this.addValidation('newPassword', 'newPassword', 'New password', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
        },

        hasPasswordErrors: (function () {
            return this.get('passwordErrors.length') !== 0;
        }).property('passwordErrors.[]'),

        actions: {
            setNewPassword: function setNewPassword() {
                var self = this;
                if (this.get('isPasswordPass') === true) {

                    self.set('isLoading', true);
                    var changePassword = {
                        "changePassword": {
                            "email": self.get('model.email'),
                            "newPassword": self.get('newPassword')
                        }
                    };
                    changePassword = JSON.stringify(changePassword);
                    var defer = this.getDeferWithToken();
                    _ember['default'].$.post(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/logins/ChangePassword', changePassword).then(function (loginResponse) {
                        defer.resolve(loginResponse);
                    }, function (error) {
                        defer.reject(error);
                    });
                    defer.promise.then(function () {
                        toastr.info('Your password is updated.');
                        self.set('passwordUpdated', true);
                        self.set('newPassword', '');
                        self.set('confirmPassword', '');
                        self.set('currentPassword', '');
                        self.set('passwordErrors', []);
                        self.set('isLoading', false);
                    })['catch'](function (error) {
                        if (!_ember['default'].$.isEmptyObject(error)) {
                            self.get('passwordErrors').pushObject("Your current password is not correct.");
                        }
                        self.set('isLoading', false);
                    })['finally'](function () {
                        self.set('isLoading', false);
                    });
                }
            },
            onPasswordChange: function onPasswordChange(isPasswordPass) {
                this.set('isPasswordPass', isPasswordPass);
            },
            togglePassword: function togglePassword() {
                this.toggleProperty('_showPassword');
            }
        }

    });
});