define('my-care-git-ember-portal/models/qualification', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    organisation: _emberData['default'].attr('string'),
    startedOn: _emberData['default'].attr('string'),
    endedOn: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    person: _emberData['default'].belongsTo('person'),
    qualificationType: _emberData['default'].belongsTo('qualification-type')
  });
});