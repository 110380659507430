define('my-care-git-ember-portal/services/env', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({
        get: function get(name) {
            return _myCareGitEmberPortalConfigEnvironment['default'][name];
        },

        getApp: function getApp(name) {
            return _myCareGitEmberPortalConfigEnvironment['default'].APP[name];
        },

        isAndroid: function isAndroid() {
            return _myCareGitEmberPortalConfigEnvironment['default'].APP.ENV_NAME === 'android';
        },

        isIOS: function isIOS() {
            return _myCareGitEmberPortalConfigEnvironment['default'].APP.ENV_NAME === 'ios';
        },

        isMobile: function isMobile() {
            return this.isAndroid() || this.isIOS();
        }
    });
});