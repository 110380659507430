define('my-care-git-ember-portal/index/dashboard/controller', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    updateDashboard: (function () {
      var self = this;
      self.fetchFromServer('/api/Reports/dashboard').then(function (data) {
        // first row
        self.set('activeWorkers', data.activeWorkers);
        self.set('allWorkers', data.allWorkers);
        self.set('publishedProfiles', data.publishedProfiles);
        self.set('clientProfiles', data.clientProfiles);
        self.set('openJobListings', data.openJobListings);
        self.set('hoursAdvertised', data.hoursAdvertised);
        self.set('hoursAdvertisedYear', data.hoursAdvertisedYear);
        // second row
        self.set('contracts', data.contracts);
        self.set('clientDeposits', data.clientDeposits);
        self.set('hoursScheduled', data.hoursScheduled);
        self.set('clientSpend', data.clientSpend);
        self.set('hoursWorked', data.hoursWorked);
        // third row
        self.set('mycareFees', data.mycareFees);
        self.set('averageHourlyFee', data.averageHourlyFee);
        self.set('averageHourlyRate', data.averageHourlyRate);
        self.set('averageCostClient', data.averageCostClient);
        self.set('annualRunRate', data.annualRunRate);
      });
    }).on('init'),

    fetchFromServer: function fetchFromServer(url) {
      var defer = _ember['default'].RSVP.defer();
      _ember['default'].$.ajax({
        type: 'GET',
        url: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + url,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
        },
        success: function success(data) {
          defer.resolve(data);
        },
        error: function error(jqXhr, textStatus) {
          defer.reject(textStatus);
        }
      });
      return defer.promise;
    }

  });
});