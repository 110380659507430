define('my-care-git-ember-portal/index/job-applications/not-suitable/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = this.controllerFor('index.job-applications.index');
            var radioItems = [{ id: 'all', 'name': 'all', isSelected: false }, { id: 'draft', 'name': 'Draft', isSelected: false }, { id: 'index', 'name': 'Screen', isSelected: false }, { id: 'interview', 'name': 'Interview', isSelected: false }, { id: 'hired', 'name': 'Hired', isSelected: false }, { id: 'not-suitable', 'name': 'Not suitable', isSelected: true }];
            controller.set('radioItems', radioItems);
            controller.set('searchString', '');
            controller.set('status', 'not suitable');
            controller.set('isMore', true);
            self.render('index.job-applications.index', { controller: controller });
            controller.getSearchResults(self.get('pageSize'), 0, controller.get('status'), controller.get('searchString'), controller.get('selectedListingType')).then(function (jobApplications) {
                controller.set('_currentAmount', jobApplications.get('length'));
                controller.set('_totalAmount', jobApplications.get('meta.total'));
                controller.set('jobApplications', jobApplications);
            });
        }

    });
});