define("my-care-git-ember-portal/index/board-reports/on-boarding/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 6
          }
        },
        "moduleName": "my-care-git-ember-portal/index/board-reports/on-boarding/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Completed client profiles");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("The sum of Clients profiles completed on the platform.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Completed worker profiles");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("The sum of Workers that have added the information necessary to have their profile published on the platform.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 5, 5);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 5, 5);
        return morphs;
      },
      statements: [["inline", "mc-charts/mc-multi-line-chart", [], ["chartId", "seekerslinechart", "reportDivId", "seekersReport", "reportTypes", ["subexpr", "@mut", [["get", "seekersTypes", ["loc", [null, [6, 111], [6, 123]]], 0, 0, 0, 0]], [], [], 0, 0], "startingTime", ["subexpr", "@mut", [["get", "clientsStartingTime", ["loc", [null, [7, 25], [7, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "_isBeginAtZero", true], ["loc", [null, [6, 12], [8, 14]]], 0, 0], ["inline", "mc-charts/mc-multi-line-chart", [], ["chartId", "completeWorkersChart", "reportDivId", "completeWorkersReport", "reportTypes", ["subexpr", "@mut", [["get", "completeWorkers", ["loc", [null, [15, 123], [15, 138]]], 0, 0, 0, 0]], [], [], 0, 0], "startingTime", ["subexpr", "@mut", [["get", "completeWorkersTime", ["loc", [null, [16, 25], [16, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "_isBeginAtZero", true, "filter", "Completed"], ["loc", [null, [15, 12], [16, 85]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});