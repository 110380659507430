define('my-care-git-ember-portal/index/threads/index/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        errors: [],
        pageSize: 100,
        offset: 0,
        radioItems: [{ id: 'index', 'name': 'All', isSelected: true }, { id: 'not-responded', 'name': 'Not responded', isSelected: false }],
        isMore: true,
        notResponded: false,

        setThreads: (function () {
            var self = this;
            self.set('threads', self.get('content').toArray());
        }).observes('content'),

        actions: {
            searchByType: function searchByType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.transitionToRoute('index.threads.' + type);
                }
            },

            doSearch: function doSearch() {
                var self = this;
                self.set('isLoading', true);
                self.set('offset', 0);
                self.set('isMore', true);
                self.set('threads', []);
                if (_ember['default'].isNone(self.get('searchString'))) {
                    self.getSearchResults(self.get('pageSize'), self.get('offset'), self.get('notResponded')).then(function (thread) {
                        self.set('threads', thread);
                        self.set('isLoading', false);
                    });
                } else {
                    self.getSearchResults(self.get('pageSize'), self.get('offset'), self.get('notResponded'), self.get('searchString')).then(function (thread) {
                        self.set('threads', thread);
                        self.set('isLoading', false);
                    });
                }
            },

            more: function more() {
                var self = this;
                self.set('isLoading', true);
                self.set('offset', self.get('offset') + self.get('threads.length'));
                self.getSearchResults(self.get('pageSize'), self.get('offset'), self.get('notResponded'), self.get('searchString')).then(function (threads) {
                    if (threads.get('length') < self.get('pageSize')) {
                        self.set('isMore', false);
                    }
                    for (var i = 0; i < threads.get('length'); i++) {
                        var thread = threads.objectAt(i);
                        if (!self.get('threads').contains(thread)) {
                            self.get('threads').pushObject(thread);
                        }
                    }
                    self.set('isLoading', false);
                });
            }
        },

        getSearchResults: function getSearchResults(pageSize, offset, notResponded, searchString) {
            var self = this;
            self.set('isLoading', true);
            return self.store.query('thread', { pageSize: pageSize, offset: offset, notResponded: notResponded, s: searchString }).then(function (threads) {
                self.set('isLoading', false);
                return threads;
            });
        }
    });
});