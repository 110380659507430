define('my-care-git-ember-portal/index/job-applications/detail/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        div_id: "job-application",
        messageService: _ember['default'].inject.service('message'),
        threadId: null,
        _pageSize: 20,
        selectedPage: null,
        offSet: 0,
        messages: [],
        showButton: true,
        init: function init() {
            this._super();
            _ember['default'].run.schedule("afterRender", this, function () {
                this.setViewerPermission(this.get('div_id'));
            });
        },

        setup: function setup() {
            this.set('showButton', true);
            this.get('messages').clear();
            this.set('_pageSize', 20);
            this.set('offSet', 0);
            this.set('threadId', this.get('model.thread.id'));
            this.get('initData').perform();
        },
        initData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var messages;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('messageService.getMessageByThreadIdAsync').perform(this.get('threadId'), this.get('_pageSize'), this.get('offSet'), true);

                    case 2:
                        messages = context$1$0.sent;

                        this.set('offSet', this.get("offSet") + messages.get('length'));
                        this.get('messages').pushObjects(messages.toArray());
                        if (messages.get('length') < this.get('_pageSize')) {
                            this.set('showButton', false);
                        }

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            loadMore: function loadMore() {
                this.get('initData').perform();
            },

            save: function save() {
                var self = this;
                if (true) {
                    self.set('isSaving', true);
                    self.store.findRecord('job-application-status-type', self.get('model.jobApplicationStatusId')).then(function (jobApplicationStatus) {
                        self.set('model.jobApplicationStatus', jobApplicationStatus);
                        self.get('model').save().then(function () {
                            self.set('isSaving', false);
                            toastr.info('Job application has been updated.');
                        }, function () {
                            toastr.error('Error saving job application');
                        });
                    });
                }
            },
            back: function back() {
                window.history.go(-1);
            }
        }
    });
});