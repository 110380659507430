define('my-care-git-ember-portal/models/list-job-listing', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        applications: _emberData['default'].attr('string'),
        favoritedByAmount: _emberData['default'].attr('number'),
        title: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        clientId: _emberData['default'].attr('string'),
        clientName: _emberData['default'].attr('string'),
        organiserId: _emberData['default'].attr('string'),
        organiserName: _emberData['default'].attr('string'),
        isPublished: _emberData['default'].attr('boolean'),
        hourlyRateAmount: _emberData['default'].attr('string'),
        hoursPerWeek: _emberData['default'].attr('string'),
        location: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        closesOn: _emberData['default'].attr('string'),
        publishedOn: _emberData['default'].attr('string'),

        isValid: (function () {
            return this.get('closesOn') ? new Date().getTime() < new Date(this.get('closesOn')).getTime() ? true : false : true;
        }).property('closesOn')
    });
});