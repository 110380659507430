define('my-care-git-ember-portal/index/account-error/index/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {
            return this.store.query('accountError', { pagesize: 10, offset: 0, status: 'all' });
        },

        setupController: function setupController(controller, model) {
            controller.set('_accountErrors', model);
            controller.setup();
        }
    });
});