define('my-care-git-ember-portal/models/expense', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        createdOn: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        amount: _emberData['default'].attr('number'),
        quantity: _emberData['default'].attr('number'),
        timesheet: _emberData['default'].belongsTo('timesheet', { async: true }),
        expenseType: _emberData['default'].belongsTo('expense-type', { async: true }),
        createdPerson: _emberData['default'].belongsTo('person', { async: true }),

        fee: (function () {
            return Number(this.get('amount')) * 0.05;
        }).property('amount'),
        total: (function () {
            return Number(this.get('amount')) * 1.05;
        }).property('amount')
    });
});