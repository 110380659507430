define("my-care-git-ember-portal/components/mc-joblisting-editor/local-workers/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-component", "ember-concurrency", "my-care-git-ember-portal/mixins/viewer-premission"], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _emberConcurrency, _myCareGitEmberPortalMixinsViewerPremission) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend(_myCareGitEmberPortalMixinsViewerPremission["default"], {
        sortProps: ['numberOfVerifications:desc', 'loginOn:desc', 'modifiedOn:desc'],
        sortedResultList: _ember["default"].computed.sort('model', 'sortProps'),
        locationService: _ember["default"].inject.service('location-service'),
        searchService: _ember["default"].inject.service('search'),
        div_id: 'worker-round',

        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('mapBound', {});
            this.get('runSearch').perform();
        },

        runSearch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var self, thisLatlng, parameter;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;
                        context$1$0.next = 3;
                        return this.get('locationService.decodeAsync').perform({ location: this.get('jobListing.location') });

                    case 3:
                        thisLatlng = context$1$0.sent;

                        self.set('lat', thisLatlng.lat);
                        self.set('lng', thisLatlng.lng);
                        self.set('mapBound.northLat', thisLatlng.lat);
                        self.set('mapBound.eastLng', thisLatlng.lng);

                        parameter = this.get('searchService').joinBoundsTpSearchParameter(this.get('mapBound'), this.get('location'));
                        context$1$0.next = 11;
                        return this.get('fetchData').perform(parameter);

                    case 11:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        fetchData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(parameter) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('model', []);
                        context$1$0.next = 3;
                        return this.get('searchService').queryProfiles(parameter, true).then(function (serviceProfiles) {
                            _this.set('model', serviceProfiles.toArray());
                            _this.set('isEnableOnCoordinateChangedEvent', true);
                        });

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            search: function search(location, params) {
                this.get('searchService').set('searchParameter', params);
                this.get('runSearch').perform();
            }
        }

        // isDisabledSaveButton: function(){
        //     if(this.get('isFormDirty') === false) {
        //         return true;
        //     }else if(this.get('isSaving') === true){
        //         return true;
        //     }
        //     return false;
        // }.property('isSaving', 'isFormDirty'),
        //
        // titleEquipment: function(){
        //     var totalItems = this.get('_formData.equipment.length');
        //     var count = (totalItems) ? `(${totalItems})`: '';
        //     return `Equipment experience ${count}`;
        // }.property('_formData.equipment.[]'),
        //
        // titleRequirement: function(){
        //     var totalItems = this.get('_formData.requirements.length');
        //     var count = (totalItems) ? `(${totalItems})`: '';
        //     return `My worker must have ${count}`;
        // }.property('_formData.requirements.[]'),
        //
        // titleLanguage: function(){
        //     var totalItems = this.get('_formData.languages.length');
        //     var count = (totalItems) ? `(${totalItems})`: '';
        //     return `Languages ${count}`;
        // }.property('_formData.languages.[]'),
        //
        // didReceiveAttrs(){
        //     if(!this.get('_isInitialised')){
        //         this.setup();
        //     }
        // },
        //
        // setup(){
        //     this.get('_store')
        //         .findAll('equipment')
        //         .then(equipment => {
        //             if(this.isDestroyed){
        //                 return;
        //             }
        //             this.set('_equipmentOptions', equipment);
        //             this.set('_formData.equipment', this.get('jobListing.equipment').toArray());
        //             //always true as there is no valiation required
        //             this.set('isFormValid', true);
        //         });
        //
        //     this.get('_store')
        //         .findAll('requirement')
        //         .then(requirements => {
        //             if(this.isDestroyed){
        //                 return;
        //             }
        //             this.set('_requirementOptions', requirements);
        //             this.set('_formData.requirements', this.get('jobListing.requirements').toArray());
        //         });
        //
        //     this.get('_store')
        //         .findAll('language')
        //         .then(languages => {
        //             if(this.isDestroyed){
        //                 return;
        //             }
        //             this.set('_languageOptions', languages);
        //             this.set('_formData.languages', this.get('jobListing.languages').toArray());
        //         });
        //
        //     Ember.run.later(() => {
        //         if(this.isDestroyed){
        //             return;
        //         }
        //         this.set('_formData.idealWorker', this.get('jobListing.idealWorker'));
        //         this.set('_isInitialised', true);
        //         this.set('isFormDirty', false);
        //     }, 1000);
        // },
        //
        //
        // updateModel(){
        //     var jobListing = this.get('jobListing');
        //     jobListing.set('equipment', this.get('_formData.equipment'));
        //     jobListing.set('languages', this.get('_formData.languages'));
        //     jobListing.set('requirements', this.get('_formData.requirements'));
        //     jobListing.set('idealWorker', this.get('_formData.idealWorker'));
        //     return jobListing;
        // },
        //
        // watchFormDirty: function(){
        //     if(this.get('_isInitialised') === false){
        //         return;
        //     }
        //     Ember.RSVP
        //         .hash({
        //             equipment: this.get('jobListing.equipment'),
        //             languages: this.get('jobListing.languages'),
        //             requirements: this.get('jobListing.requirements'),
        //         })
        //         .then(({equipment, languages, requirements}) => {
        //             if(this.isDestroyed){
        //                 return;
        //             }
        //             var getIds = (list) => (list || Ember.A()).map(it => it.id).sort();
        //             var isDirty =
        //                 this.get('_formData.idealWorker') !== this.get('jobListing.idealWorker') ||
        //                 !_.isEqual(getIds(equipment), getIds(this.get('_formData.equipment'))) ||
        //                 !_.isEqual(getIds(languages), getIds(this.get('_formData.languages'))) ||
        //                 !_.isEqual(getIds(requirements), getIds(this.get('_formData.requirements')));
        //             this.set('isFormDirty', isDirty);
        //         });
        // }.observes('_formData.equipment.[]',
        //     '_formData.languages.[]', '_formData.requirements.[]', '_formData.idealWorker'),
        //
        // Handle502: function(error){
        //     var self = this;
        //     self.set('isSaving',false);
        //     if (error.errors&&error && error.errors[0].status === '0') {
        //         self.get('jobListing').reload();
        //         self.redirectTo('index.job-listings.index');
        //         toastr.error('Please check is job listing saved, and contact Mycare development team.');
        //     }
        // },
        //
        // actions: {
        //     onSelected(category, selectedItems){
        //         this.set(`_formData.${category}`, selectedItems);
        //     },
        //
        //     save(){
        //         var self = this;
        //         this.set('isSaving', true);
        //         this.updateModel()
        //             .save()
        //             .then(jobListing => {
        //                 if(this.isDestroyed){
        //                     return;
        //                 }
        //                 this.set('jobListing', jobListing);
        //                 this.set('isSaving', false);
        //                 this.set('isFormDirty', false);
        //                 this.sendAction('onSaved', jobListing);
        //             }, function(error){
        //                 self.Handle502(error);
        //             });
        //     }
        // }
    });
});