define('my-care-git-ember-portal/index/board-reports/matching/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        listingReportTypes: ['dssJobListings', 'privateJobListings', 'jobListings'],
        listingsStartingTime: moment().add(-12, 'month'),
        agreements: ['dssAgreements', 'privateAgreements', 'agreements'],
        agreementStartingTime: moment().add(-12, 'month'),
        activeAgreements: ['activeDssAgreements', 'activePrivateAgreements', 'activeAgreements'],
        activeAgreementsTime: moment().add(-12, 'month')

    });
});