define("my-care-git-ember-portal/services/data-service/experience-event-template-status", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _store: _ember["default"].inject.service('store'),

        getByTemplateId: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            var reload = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').query('experience-event-template-status', { id: id }, { reload: reload });

                    case 2:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),
        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var template = _ref.template;
            var type = _ref.type;
            var comment = _ref.comment;
            var status;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        status = this.get('_store').createRecord('experience-event-template-status', {
                            experienceEventTemplate: template,
                            experienceEventTemplateStatusType: type,
                            comment: comment
                        });
                        context$1$0.next = 3;
                        return status.save();

                    case 3:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});