define('my-care-git-ember-portal/components/mc-thread-card/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this.get('model.people').then(function (people) {
                var clients = people.filter(function (person) {
                    return person.get('isClient') === true || person.get('isOrganiser') === true;
                });
                _this.set('clients', clients);

                var workers = people.filter(function (person) {
                    return person.get('isWorker') === true && person.get('isClient') !== true;
                });
                _this.set('workers', workers);
            });
        }
    });
});