define('my-care-git-ember-portal/services/location-service', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Service.extend({
        decodeAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var location = _ref.location;
            var defer;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        defer = _ember['default'].RSVP.defer();

                        GMaps.geocode({
                            address: location,
                            callback: function callback(results, status) {
                                if (status === 'OK') {
                                    var latlng = results[0].geometry.location;
                                    defer.resolve({
                                        lat: latlng.lat(),
                                        lng: latlng.lng()
                                    });
                                } else {
                                    defer.reject(status);
                                    throw status;
                                }
                            }
                        });
                        context$1$0.next = 4;
                        return defer.promise;

                    case 4:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});