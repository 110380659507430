define('my-care-git-ember-portal/components/mc-modal-profile-manage-qualification/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/loadable'], function (exports, _ember, _myCareGitEmberPortalMixinsLoadable) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsLoadable['default'], {
        _store: _ember['default'].inject.service('store'),
        currentItem: null,
        isEditMode: false,
        title: null,
        modal: null,
        errors: [],
        startedOn: null,
        endedOn: null,
        qualificationTypes: [],

        init: function init() {
            var self = this;
            self._super();
            self.get('_store').findAll('qualification-type').then(function (types) {
                var tmpType = self.get('_store').createRecord('qualification-type', {
                    title: "Please select one"
                });
                self.set('qualificationTypes', types.toArray().insertAt(0, tmpType));
            });
        },

        setdate: (function () {
            if (!this.get('currentItem.endedOn')) {
                $("#currentPeriod").prop('checked', true);
                _ember['default'].$('#endedOn').addClass('has-disabled');
            } else {
                $("#currentPeriod").prop('checked', false);
                _ember['default'].$('#endedOn').removeClass('has-disabled');
            }
        }).observes('currentItem.endedOn'),

        _toggleEndDate: (function () {
            if (_ember['default'].$('#currentPeriod').is(':checked')) {
                _ember['default'].$('#endedOn').addClass('has-disabled');
                this.set('endedOn', null);
            } else _ember['default'].$('#endedOn').removeClass('has-disabled');
        }).observes('toggleEndDate'),

        actions: {
            save: function save() {
                var self = this;
                var edu = self.get('currentItem');
                if (self.validateForm()) {
                    self.showLoader();
                    if (this.get('isEditMode')) {
                        var editingItem = self.get('editingItem');
                        editingItem.set('title', edu.get('title'));
                        editingItem.set('description', edu.get('description'));
                        editingItem.set('organisation', edu.get('organisation'));
                        editingItem.set('qualificationType', self.get('qualificationType'));
                        editingItem.set('startedOn', self.get('startedOn'));
                        editingItem.set('endedOn', self.get('endedOn'));
                        editingItem.save().then(function () {
                            _ember['default'].$('.modal').modal('hide');
                            toastr.info('Qualification updated successfully.');
                            self.hideLoader();
                        });
                    } else {
                        var qualification = self.get('_store').createRecord('qualification', {
                            title: edu.get('title'),
                            organisation: edu.get('organisation'),
                            description: edu.get('description'),
                            startedOn: self.get('startedOn'),
                            endedOn: self.get('endedOn'),
                            person: self.get('model'),
                            qualificationType: self.get('qualificationType')
                        });
                        qualification.save().then(function (savedEdu) {
                            self.get('model').get('qualifications').pushObject(savedEdu);
                            _ember['default'].$('.modal').modal('hide');
                            toastr.info('Qualification added successfully.');
                            self.hideLoader();
                            self.get('model').save();
                        });
                    }
                }
            },
            onChangeStartedOn: function onChangeStartedOn(date) {
                this.set('startedOn', date);
            },
            onChangeEndedOn: function onChangeEndedOn(date) {
                this.set('endedOn', date);
            }
        },

        validateForm: function validateForm() {
            this.get('errors').clear();
            var isValid = true;
            _ember['default'].$('.has-error').removeClass('has-error');

            if (!this.get('currentItem.title')) {
                isValid = false;
                _ember['default'].$('#title').addClass('has-error');
                this.get('errors').pushObject('Job title is required.');
            }

            if (!this.get('currentItem.organisation')) {
                isValid = false;
                _ember['default'].$('#organisation').addClass('has-error');
                this.get('errors').pushObject('Organisation is required.');
            }

            if (!_ember['default'].$('#currentPeriod').is(':checked') && (moment(this.get('startedOn')).isAfter(this.get('endedOn')) || _ember['default'].isEmpty(this.get('endedOn')))) {
                isValid = false;
                this.get('errors').pushObject('Start date should be before end date.');
            }

            return isValid;
        }
    });
});