define('my-care-git-ember-portal/models/timesheet-note', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        description: _emberData['default'].attr('string'),
        timesheet: _emberData['default'].belongsTo('timesheet', { async: true }),
        person: _emberData['default'].belongsTo('person', { async: true }),
        modifiedOn: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        timesheetNoteCategory: _emberData['default'].belongsTo('timesheet-note-category'),
        createdOnFormattedDate: (function () {
            var sentOn = moment.utc(this.get('createdOn'));
            if (moment.utc().diff(sentOn, 'days') > 7) {
                return sentOn.format('DD MMM YYYY');
            } else {
                return sentOn.fromNow();
            }
        }).property('createdOn')
    });
});