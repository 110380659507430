define('my-care-git-ember-portal/mixins/loadable', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Loadable mixin
   * This mixin is used to store loading state in controller.
   */
  exports['default'] = _ember['default'].Mixin.create({

    isLoading: false,

    /**
     * Set loading state to true
     */
    showLoader: function showLoader() {
      this.set('isLoading', true);
    },

    /**
     * Set loading state to false
     */
    hideLoader: function hideLoader() {
      this.set('isLoading', false);
    }
  });
});