define('my-care-git-ember-portal/index/recommendations/index/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        errors: [],
        searchString: '',
        _pageSize: 10,
        _totalAmount: 0,
        offset: 0,
        isMoreUser: true,

        actions: {
            onClickPage: function onClickPage(page) {
                var self = this;
                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                self.getSearchResults(self.get('_pageSize'), offSet).then(function (recommendations) {
                    var _currentAmount = offSet + recommendations.get('length');
                    self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                    self.set('recommendations', recommendations);
                    self.set('isLoading', false);
                });
            }
        },

        getSearchResults: function getSearchResults(pageSize, offset) {
            var self = this;
            self.set('isLoading', true);
            return self.store.query('recommendation', { pageSize: pageSize, offset: offset }).then(function (recommendations) {
                self.set('isLoading', false);
                return recommendations;
            });
        }
    });
});