define('my-care-git-ember-portal/index/experience/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/mixins/routes/job-listing-radio-button', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalMixinsRoutesJobListingRadioButton, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], _myCareGitEmberPortalMixinsRoutesJobListingRadioButton['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        setupController: function setupController(controller) {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
                return;
            }
            if (_ember['default'].isEmpty(controller.get('_jobListings'))) {
                controller.getSearchResults(controller.get('_pageSize'), 0, '', controller.get('searchString'), controller.get('selectedListingType')).then(function (templates) {
                    controller.set('_templates', templates.sortBy('id').reverse());
                    controller.set('filterResult', templates);
                    controller.set('_currentAmount', templates.get('meta.total') >= controller.get('_pageSize') ? controller.get('_pageSize') : templates.get('meta.total'));
                    controller.set('_totalAmount', templates.get('meta.total'));
                });
            }
        }
    });
});