define('my-care-git-ember-portal/index/job-listings/is-hired/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/mixins/routes/job-listing-radio-button'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalMixinsRoutesJobListingRadioButton) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], _myCareGitEmberPortalMixinsRoutesJobListingRadioButton['default'], {
        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = this.controllerFor('index.job-listings.index');
            var radioItems = this.getJobListingSelect('Is hired');
            controller.set('radioItems', radioItems);
            controller.set('searchString', '');
            controller.set('status', 'is-hired');
            self.render('index.job-listings.index', { controller: controller });
            if (_ember['default'].isEmpty(controller.get('_jobListings'))) {
                controller.getSearchResults(controller.get('_pageSize'), 0, controller.get('status'), controller.get('searchString'), controller.get('selectedListingType')).then(function (jobListings) {
                    controller.set('_jobListings', jobListings);
                    controller.set('filterResult', jobListings);
                    controller.set('_currentAmount', controller.get('_pageSize'));
                    controller.set('_totalAmount', jobListings.get('meta.total'));
                });
            }
        }
    });
});