define("my-care-git-ember-portal/components/mc-table-report-client/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "loading");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "class", "ico");
          dom.setAttribute(el2, "src", "img/loading.gif");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 28
              },
              "end": {
                "line": 22,
                "column": 24
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                            Type\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 24
              },
              "end": {
                "line": 24,
                "column": 24
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            Funder\n                        ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 45,
                    "column": 32
                  },
                  "end": {
                    "line": 48,
                    "column": 32
                  }
                },
                "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                dom.setAttribute(el1, "colspan", "2");
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 32
                  },
                  "end": {
                    "line": 55,
                    "column": 32
                  }
                },
                "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["content", "row.funding", ["loc", [null, [50, 40], [50, 55]]], 0, 0, 0, 0], ["content", "row.organisation", ["loc", [null, [53, 40], [53, 60]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 62,
                      "column": 40
                    },
                    "end": {
                      "line": 64,
                      "column": 40
                    }
                  },
                  "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "dollar-format", [["get", "value", ["loc", [null, [63, 60], [63, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 44], [63, 67]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 40
                    },
                    "end": {
                      "line": 66,
                      "column": 40
                    }
                  },
                  "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "number-round", [["get", "value", ["loc", [null, [65, 59], [65, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 44], [65, 66]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 60,
                    "column": 32
                  },
                  "end": {
                    "line": 68,
                    "column": 32
                  }
                },
                "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["get", "row.isFeeRow", ["loc", [null, [62, 46], [62, 58]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [62, 40], [66, 47]]]]],
              locals: ["value"],
              templates: [child0, child1]
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 36
                  },
                  "end": {
                    "line": 72,
                    "column": 36
                  }
                },
                "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "dollar-format", [["get", "row.total", ["loc", [null, [71, 56], [71, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [71, 40], [71, 67]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 72,
                    "column": 36
                  },
                  "end": {
                    "line": 74,
                    "column": 36
                  }
                },
                "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "number-round", [["get", "row.total", ["loc", [null, [73, 55], [73, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 40], [73, 66]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 24
                },
                "end": {
                  "line": 77,
                  "column": 24
                }
              },
              "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(element0, 5, 5);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "row.isSubRow", ["loc", [null, [45, 38], [45, 50]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [45, 32], [55, 39]]]], ["content", "row.type", ["loc", [null, [57, 36], [57, 48]]], 0, 0, 0, 0], ["block", "each", [["get", "row.getValuesAsArr", ["loc", [null, [60, 40], [60, 58]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [60, 32], [68, 41]]]], ["block", "if", [["get", "row.isFeeRow", ["loc", [null, [70, 42], [70, 54]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [70, 36], [74, 43]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 20
              },
              "end": {
                "line": 78,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "row.hasValue", ["loc", [null, [43, 30], [43, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [43, 24], [77, 31]]]]],
          locals: ["row"],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 24
              },
              "end": {
                "line": 93,
                "column": 24
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "number-round", [["get", "value", ["loc", [null, [91, 47], [91, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [91, 32], [91, 54]]], 0, 0]],
          locals: ["value"],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 104,
                "column": 24
              },
              "end": {
                "line": 108,
                "column": 24
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "number-round", [["get", "value", ["loc", [null, [106, 47], [106, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [106, 32], [106, 54]]], 0, 0]],
          locals: ["value"],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 24
              },
              "end": {
                "line": 123,
                "column": 24
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "dollar-format", [["get", "value", ["loc", [null, [121, 48], [121, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [121, 32], [121, 55]]], 0, 0]],
          locals: ["value"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 0
            },
            "end": {
              "line": 133,
              "column": 0
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "tab-content space-top-3");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "role", "tabpanel");
          dom.setAttribute(el2, "class", "tab-pane active");
          dom.setAttribute(el2, "id", "tab-workers");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "table-responsive table-business");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("table");
          dom.setAttribute(el4, "class", "table table-bordered table-hover dataTable no-footer");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("thead");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tr");
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Funding Source");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Data");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Jan");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Feb");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Mar");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Apr");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("May");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Jun");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Jul");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Aug");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Sep");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Oct");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Nov");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Dec");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("th");
          var el8 = dom.createTextNode("Total");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("tbody");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tr");
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          dom.setAttribute(el7, "colspan", "16");
          dom.setAttribute(el7, "align", "center");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tr");
          dom.setAttribute(el6, "class", "active");
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          dom.setAttribute(el7, "colspan", "2");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          var el8 = dom.createTextNode("\n                            Total Clients/Month\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tr");
          dom.setAttribute(el6, "class", "active");
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          dom.setAttribute(el7, "colspan", "2");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          var el8 = dom.createTextNode("\n                            Total Hours/Month\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tr");
          dom.setAttribute(el6, "class", "active");
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          dom.setAttribute(el7, "colspan", "2");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          var el8 = dom.createTextNode("\n                            Total Mycare Fees/Month\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                        ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("td");
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1, 1, 1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element2, [5]);
          var element4 = dom.childAt(element2, [7]);
          var element5 = dom.childAt(element2, [9]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1, 3]), 0, 0);
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          morphs[2] = dom.createMorphAt(element3, 5, 5);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [7]), 1, 1);
          morphs[4] = dom.createMorphAt(element4, 5, 5);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [7]), 1, 1);
          morphs[6] = dom.createMorphAt(element5, 5, 5);
          morphs[7] = dom.createMorphAt(dom.childAt(element5, [7]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "isClient", ["loc", [null, [20, 34], [20, 42]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [20, 28], [24, 31]]]], ["block", "each", [["get", "currentTable.reportRows", ["loc", [null, [42, 28], [42, 51]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [42, 20], [78, 29]]]], ["block", "each", [["get", "totalClientsVObject.values", ["loc", [null, [89, 32], [89, 58]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [89, 24], [93, 33]]]], ["inline", "number-round", [["get", "totalClientsVObject.total", ["loc", [null, [95, 43], [95, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 28], [95, 70]]], 0, 0], ["block", "each", [["get", "totalHoursObject.values", ["loc", [null, [104, 32], [104, 55]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [104, 24], [108, 33]]]], ["inline", "number-round", [["get", "totalHoursObject.total", ["loc", [null, [110, 43], [110, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [110, 28], [110, 67]]], 0, 0], ["block", "each", [["get", "totalMyCareFeesObject.values", ["loc", [null, [119, 32], [119, 60]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [119, 24], [123, 33]]]], ["inline", "dollar-format", [["get", "totalMyCareFeesObject.total", ["loc", [null, [125, 44], [125, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [125, 28], [125, 73]]], 0, 0]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 133,
            "column": 7
          }
        },
        "moduleName": "my-care-git-ember-portal/components/mc-table-report-client/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "space-3");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "list-inline");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-primary");
        var el5 = dom.createTextNode("Update");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [1, 1]);
        var element7 = dom.childAt(element6, [3, 0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
        morphs[1] = dom.createElementMorph(element7);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "mc-date-month-year-picker", [], ["id", ["subexpr", "@mut", [["get", "reportType", ["loc", [null, [4, 43], [4, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "startingTime", ["loc", [null, [4, 70], [4, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 60], [4, 83]]], 0, 0], "minYear", 2015, "isYearOnly", true, "onChange", ["subexpr", "action", ["setStartTime"], [], ["loc", [null, [4, 124], [4, 147]]], 0, 0]], ["loc", [null, [4, 12], [4, 149]]], 0, 0], ["element", "action", ["getReport"], [], ["loc", [null, [5, 20], [5, 42]]], 0, 0], ["block", "if", [["get", "isLoading", ["loc", [null, [8, 6], [8, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 0], [133, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});