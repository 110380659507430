define('my-care-git-ember-portal/components/mc-user-detail/worker-experience/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        sortProps: ['startedOn:desc'],
        sortedExperiences: _ember['default'].computed.sort('model.experiences', 'sortProps'),
        div_id: "experience",
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },
        actions: {
            addNewExperience: function addNewExperience() {
                var modal = _ember['default'].$('#modal-profile-manage-workexperience');
                modal.modal('hide');
                modal.modal('show');
                this.set('currentItem', _ember['default'].Object.create({}));
                this.set('editingItem', null);
                this.set('isExperienceEditMode', false);
                this.set('experienceTitle', 'Add new experience');
            },
            editExperience: function editExperience(item) {
                var modal = _ember['default'].$('#modal-profile-manage-workexperience');
                modal.modal('hide');
                modal.modal('show');
                this.set('currentItem', _ember['default'].Object.create({
                    title: item.get('title'),
                    organisation: item.get('organisation'),
                    description: item.get('description'),
                    startedOn: item.get('startedOn'),
                    endedOn: item.get('endedOn')
                }));
                this.set('editingItem', item);
                this.set('isExperienceEditMode', true);
                this.set('experienceTitle', 'Edit experience');
            },
            deleteExperience: function deleteExperience(item) {
                bootbox.confirm('Are you sure you want to delete?', function (result) {
                    if (result) {
                        _ember['default'].$('.modal').modal('hide');
                        item.destroyRecord().then(function () {
                            toastr.info(item.get('title') + ' is deleted.');
                        });
                    }
                });
            }
        }
    });
});