define('my-care-git-ember-portal/components/mc-modal-profile-manage-workexperience/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/loadable'], function (exports, _ember, _myCareGitEmberPortalMixinsLoadable) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsLoadable['default'], {
        _store: _ember['default'].inject.service('store'),
        currentItem: null,
        editingItem: null,
        isEditMode: false,
        title: null,
        model: null,
        startedOn: null,
        endedOn: null,
        errors: [],
        tagName: "",

        _init: (function () {
            if (!this.get('currentItem.endedOn')) {
                _ember['default'].$("#expCurrentPeriod").prop('checked', true);
                _ember['default'].$('#expEndedOn').addClass('has-disabled');
            } else {
                _ember['default'].$("#expCurrentPeriod").prop('checked', false);
                _ember['default'].$('#expEndedOn').removeClass('has-disabled');
            }
        }).observes('currentItem.endedOn'),
        _toggleEndDate: (function () {
            if (_ember['default'].$('#expCurrentPeriod').is(':checked')) {
                _ember['default'].$('#expEndedOn').addClass('has-disabled');
                this.set('endedOn', null);
            } else _ember['default'].$('#expEndedOn').removeClass('has-disabled');
        }).observes('expToggleEndDate'),

        actions: {
            save: function save() {
                var self = this;
                var exp = self.get('currentItem');
                if (self.validateForm()) {
                    self.showLoader();
                    if (this.get('isEditMode')) {
                        var editingItem = self.get('editingItem');
                        editingItem.set('title', exp.get('title'));
                        editingItem.set('description', exp.get('description'));
                        editingItem.set('organisation', exp.get('organisation'));
                        editingItem.set('startedOn', self.get('startedOn'));
                        editingItem.set('endedOn', self.get('endedOn'));
                        editingItem.save().then(function (savedExp) {
                            self.get('model.experiences').pushObject(savedExp);
                            _ember['default'].$('.modal').modal('hide');
                            self.hideLoader();
                            toastr.info('Experience updated successfully.');
                            return self.get('model').save();
                        });
                    } else {
                        var record = self.get('_store').createRecord('experience', {
                            title: exp.get('title'),
                            organisation: exp.get('organisation'),
                            description: exp.get('description'),
                            startedOn: self.get('startedOn'),
                            endedOn: self.get('endedOn'),
                            person: self.get('model')
                        });
                        record.save().then(function (savedExp) {
                            self.get('model.experiences').pushObject(savedExp);
                            _ember['default'].$('.modal').modal('hide');
                            self.hideLoader();
                            toastr.info('Experience added successfully.');
                            return self.get('model').save();
                        });
                    }
                }
            },
            onChangeStartedOn: function onChangeStartedOn(date) {
                this.set('startedOn', date);
            },
            onChangeEndedOn: function onChangeEndedOn(date) {
                this.set('endedOn', date);
            }
        },

        validateForm: function validateForm() {

            this.get('errors').removeObjects(this.get('errors'));
            var isValid = true;
            _ember['default'].$('.has-error').removeClass('has-error');
            if (!this.get('currentItem.title')) {
                isValid = false;
                _ember['default'].$('#form-jobTitle').addClass('has-error');
                this.get('errors').pushObject('Job title is required.');
            }

            if (!this.get('currentItem.organisation')) {
                isValid = false;
                _ember['default'].$('#form-organisation').addClass('has-error');
                this.get('errors').pushObject('Organisation is required.');
            }

            if (_ember['default'].isEmpty(this.get('startedOn'))) {
                isValid = false;
                this.get('errors').pushObject('Start date is required');
            }

            if (!_ember['default'].$('#expCurrentPeriod').is(':checked') && (moment(this.get('startedOn')).isAfter(this.get('endedOn')) || _ember['default'].isEmpty(this.get('endedOn')))) {
                isValid = false;
                this.get('errors').pushObject('Start date should be before end date.');
            }

            return isValid;
        }
    });
});