define('my-care-git-ember-portal/components/mc-modal/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    onOpenEventChannel: null,
    channelId: undefined,
    _modalHelper: _ember['default'].inject.service('utils/modal-helper'),

    footer: {
      footer: true
    },
    body: {
      body: true
    },

    _isOpened: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var channelId = this.get('channelId');
      var $modal = this.$('#' + channelId);
      if (!$modal) {
        return;
      }
      $modal.on('loaded.bs.modal', function () {
        _this.get('eventbus').publish('mc-modal:on:loaded:' + channelId);
      });

      $modal.on('show.bs.modal', function () {
        _this.get('eventbus').publish('mc-modal:on:show:' + channelId);
      });

      $modal.on('shown.bs.modal', function () {
        _this.get('eventbus').publish('mc-modal:on:shown:' + channelId);
        if (_this.isDestroyed) {
          return;
        }
        _this.set('_isOpened', true);
      });

      $modal.on('hide.bs.modal', function () {
        _this.get('eventbus').publish('mc-modal:on:hide:' + channelId);
      });

      $modal.on('hidden.bs.modal', function () {
        _this.get('eventbus').publish('mc-modal:on:hidden:' + channelId);
        if (_this.isDestroyed) {
          return;
        }
        _this.set('_isOpened', false);
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      if (_ember['default'].isNone(this.get('channelId'))) {
        console.error('mc-modal: channelId is required');
      }
      this._setupEvents();
    },

    willDestroyElement: function willDestroyElement() {
      this._close();
    },

    _setupEvents: function _setupEvents() {
      var _this2 = this;

      var channelId = this.get('channelId');
      if (!channelId) {
        return;
      }
      this.get('eventbus').subscribe('mc-modal:on:open:' + channelId, function () {
        _this2._open();
      }, false);

      this.get('eventbus').subscribe('mc-modal:on:close:' + channelId, function () {
        _this2._close();
      }, false);
    },

    _open: function _open() {
      if (this.isDestroyed) {
        return;
      }
      if (this.get('_isOpened') === true) {
        return;
      }
      this.set('_isOpened', true);
      var $modal = this.$('#' + this.get('channelId'));
      if (!$modal) {
        return;
      }
      $modal.css('overflow', 'scroll');
      $modal.modal('show');
    },

    _close: function _close() {
      var $modal = this.$('#' + this.get('channelId'));
      if (this.get('_isOpened') === false || !$modal) {
        return;
      }
      this.set('_isOpened', false);
      $modal.modal('hide');
    },

    actions: {
      close: function close() {
        this._close();
      }
    }

  });
});