define('my-care-git-ember-portal/index/funder/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        isMore: true,
        _pageSize: 50,
        transactions: [].toArray(),

        getSearchResults: function getSearchResults(pageSize, offset) {
            var self = this;
            self.set('pageSize', pageSize);
            self.set('offset', offset);
            self.set('isLoading', true);
            return self.store.query('transaction', { accountId: 1, pageSize: pageSize, offset: offset }).then(function (transactions) {
                self.set('isLoading', false);
                return transactions;
            });
        },

        actions: {

            onClickPage: function onClickPage(page) {
                var _this = this;

                var offSet = (page.get('id') - 1) * this.get('_pageSize');

                this.getSearchResults(this.get('_pageSize'), offSet).then(function (transactions) {
                    var _currentAmount = offSet + transactions.get('length');
                    _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                    _this.set('transactions', transactions);
                });
            }
        }
    });
});