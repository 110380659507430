define('my-care-git-ember-portal/utils/mc-modal-component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], {

        _modalHelper: _ember['default'].inject.service('utils/modal-helper'),
        //_componentId is the name of sub class of the component object, make sure use the same name in the controller
        // When extending from this component, use the _modalChannelId, not the channel id pass into that component
        init: function init() {
            this.set('_modalChannelId', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
            this._super.apply(this, arguments);
            _ember['default'].Logger.assert(!_ember['default'].isNone(this.get('_componentId')), '_componentId is required for McModalComponent');
        },

        didInsertElement: function didInsertElement() {
            var _this = this;

            var channelId = this.get('_modalChannelId');
            this.get('_modalHelper').onHide(channelId, function () {
                _this._onBeforeClose();
            });

            this.get('_modalHelper').onHidden(channelId, function () {
                _this._onAfterClosed();
            });
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this._setupEvents();
        },

        _setupEvents: function _setupEvents() {
            var _this2 = this;

            var channelId = this.get('channelId');
            var componentId = this.get('_componentId');

            var openEvent = componentId + ':on:open:' + channelId;
            this.get('eventbus').subscribe(openEvent, function (options) {
                _this2._onBeforeOpen(options);
                _this2._open();
                _this2._onAfterOpened(options);
            }, false);

            this.get('eventbus').subscribe(componentId + ':on:close:' + channelId, function () {
                _this2._close();
            }, false);
        },

        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },

        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },

        _onBeforeOpen: function _onBeforeOpen() {},

        _onAfterOpened: function _onAfterOpened() {},

        _onBeforeClose: function _onBeforeClose() {},

        _onAfterClosed: function _onAfterClosed() {}
    });
});