define('my-care-git-ember-portal/index/work-and-income-user/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        _pageSize: 50,
        _totalAmount: 0,
        _currentAmount: 0,
        _content: [],
        _pageSizes: [_ember['default'].Object.create({ id: '1', name: 25, value: 25 }), _ember['default'].Object.create({ id: '2', name: 50, value: 50 }), _ember['default'].Object.create({ id: '3', name: 100, value: 100 })],
        setup: function setup() {
            this.setTodayPages();
        },

        setTodayPages: function setTodayPages() {
            var pageAmount = this.get('_totalAmount') / this.get('_pageSize');
            var pages = [];
            for (var i = 0; i <= pageAmount; i++) {
                var page = _ember['default'].Object.create({ id: i + 1, value: i, active: false });
                if (i === 0) {
                    page.set("active", true);
                }
                pages.pushObject(page);
            }
            this.set('pages', pages);
            this.set('_currentAmount', this.get('_content.length'));
            if (this.get('pages.length') > 1) {
                this.set('doesShowPage', true);
            }
        },

        getDataFromServer: function getDataFromServer(offSet) {
            var _this = this;

            this.set('isLoading', true);
            return this.store.query('work-and-income', { pageSize: this.get('_pageSize'), offset: offSet }).then(function (notes) {
                var _currentAmount = offSet + notes.get('length');
                _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                _this.set('_totalAmount', notes.get('meta.total'));
                _this.set('_content', notes);
                _this.set('isLoading', false);
            });
        },

        actions: {
            onClickPage: function onClickPage(page) {
                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.getDataFromServer(offSet);
            },

            setPageSize: function setPageSize(pageSize) {
                var _this2 = this;

                this.set('_pageSize', pageSize.get('value'));
                this.getDataFromServer(0);
                _ember['default'].RSVP.resolve(this.getDataFromServer(0)).then(function () {
                    _this2.setTodayPages();
                });
            }
        }

    });
});