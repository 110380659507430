define('my-care-git-ember-portal/index/manage/controller', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
		exports['default'] = _ember['default'].Controller.extend({
				updateProfileStatistics: (function () {
						var self = this;
						self.fetchFromServer('/api/Reports/Statistics').then(function (data) {
								self.set('incompleteProfilesCount', data.statistics.incompleteWorkerCount);
								self.set('incompleteProfiles', data.statistics.incompleteWorkers);
								self.set('premiumSeekersCount', data.statistics.premiumSeekersCount);
								self.set('premiumSeekers', data.statistics.premiumSeekers);
								self.set('stoppedAtCreditCardCount', data.statistics.stoppedAtCreditCardCount);
								self.set('stoppedAtCreditCard', data.statistics.stoppedAtCreditCard);
						});
				}).on('init'),

				fetchFromServer: function fetchFromServer(url) {
						var defer = _ember['default'].RSVP.defer();
						_ember['default'].$.ajax({
								type: 'GET',
								url: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + url,
								dataType: 'json',
								contentType: "application/json; charset=utf-8",
								headers: {
										"Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
								},
								success: function success(data) {
										defer.resolve(data);
								},
								error: function error(jqXhr, textStatus) {
										defer.reject(textStatus);
								}
						});
						return defer.promise;
				}
		});
});