define('my-care-git-ember-portal/fa-code/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            var loginController = this.controllerFor('login');
            if (loginController.get('isAuthenticated') === true) {
                transition.abort();
            }
            var login = loginController.get('login');
            if (!login || _ember['default'].isEmpty(login.email) || _ember['default'].isEmpty(login.password)) {
                this.transitionTo('login');
            }
        },
        setupController: function setupController(controller) {
            controller.set('error', '');
            controller.set('countDown', 20);
            controller.decreaseASecond();
        },
        actions: {
            didTransition: function didTransition() {
                var codeController = this.controllerFor('fa-code');
                codeController.set('code', "");
            }
        }
    });
});