define('my-care-git-ember-portal/mixins/password-checker', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({

        isNewPasswordsMatch: function isNewPasswordsMatch(newPassword, confirmPassword) {
            return newPassword == confirmPassword;
        },
        passwordStrengthTest: function passwordStrengthTest(password) {
            var re = /^(?=.*\d)(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/;
            return re.test(password);
        },
        isPasswordTooShort: function isPasswordTooShort(password) {
            return password.length < 6;
        },
        isPasswordTooLong: function isPasswordTooLong(password) {
            return password.length > 20;
        },
        isPasswordCorrect: function isPasswordCorrect(newPassword, confirmPassword) {
            if (this.isEmptyField(newPassword) || this.isEmptyField(confirmPassword)) {
                return 'Password and confirm password are required.';
            } else {
                if (!this.isNewPasswordsMatch(newPassword, confirmPassword)) {
                    return 'Mismatch password.';
                } else {
                    if (this.isPasswordTooShort(newPassword) || this.isPasswordTooLong(newPassword)) {
                        return 'Password password must be between 6 and 20 characters.';
                    } else {
                        if (!this.passwordStrengthTest(newPassword)) {
                            return "Password must contain at least 1 uppercase character, contain at least 1 number and at least 6 characters.";
                        }
                    }
                }
            }
            return true;
        }

    });
});