define('my-care-git-ember-portal/components/mc-popover-options/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this.set('parentId', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
            this.set('buttonId', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
        },
        didRender: function didRender() {
            _ember['default'].$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
        },

        actions: {
            open: function open() {
                _ember['default'].$('.dropdown.open').removeClass('open');
                _ember['default'].$('#' + this.get('parentId')).addClass('open');
            },

            checkboxChange: function checkboxChange(item) {
                item.toggleProperty('isChecked');
            },

            clearSelectedOptions: function clearSelectedOptions() {

                this.get('items').forEach(function (item) {
                    item.set('isChecked', false);
                });
                if (this.get('selectedValues.length') > 0) {
                    this.set('selectedValues', []);
                }
                _ember['default'].$('.dropdown.open').removeClass('open');
            },
            apply: function apply() {
                var selectedItems = this.get('items').filterBy('isChecked', true);
                if (selectedItems.get('length')) {
                    this.set('selectedValues', selectedItems);
                }
                _ember['default'].$('.dropdown.open').removeClass('open');
            }
        }
    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */