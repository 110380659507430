define('my-care-git-ember-portal/components/mc-table-report/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        isLoading: false,
        _store: _ember['default'].inject.service('store'),
        startingTime: "Jan/2016",
        setData: (function (year, month) {
            var self = this;
            var store = self.get('targetObject.store');
            var thisYear = year ? year : "2016";
            var thisMonth = month ? month : "1";
            this.set('isLoading', true);
            store.query('report-table', { 'reportType': self.get('reportType'), 'year': thisYear, 'month': thisMonth }).then(function (report) {
                self.set('currentTable', report.objectAt(0));
                self.set('startingTime', self.get('thisYear'));
                self.set('isLoading', false);
            });
        }).on('init'),

        actions: {
            getReport: function getReport() {
                var self = this;
                var temp = self.getQuery();
                temp.then(function (report) {
                    self.set('currentTable', report);
                });
            },

            setStartTime: function setStartTime(time) {
                this.set('startingTime', time);
            }
        },
        getQuery: function getQuery() {
            var self = this;
            var month = 1;
            var year = moment(this.get('startingTime')).year();
            var store = self.get('_store');
            self.set('isLoading', true);
            return store.query('report-table', { 'reportType': self.get('reportType'), 'year': year, 'month': month }).then(function (report) {
                self.set('isLoading', false);
                return report.objectAt(0);
            });
        }
    });
});