define('my-care-git-ember-portal/index/job-listings/threads/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        messageSortingDesc: ['message.sentOn:desc'],
        sortedThread: _ember['default'].computed.sort('model', 'messageSortingDesc'),

        actions: {
            viewThreads: function viewThreads(thread) {
                this.transitionToRoute('index.job-listings.messages', thread.get('id'));
            },
            backToUpperLevel: function backToUpperLevel() {
                window.history.back();
            }
        }

    });
});