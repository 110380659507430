define('my-care-git-ember-portal/initializers/global-injector', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'env', 'service:env');
    application.inject('controller', 'env', 'service:env');
    application.inject('model', 'env', 'service:env');
    application.inject('component', 'env', 'service:env');
    application.inject('mixin', 'env', 'service:env');

    application.inject('route', 'eventbus', 'service:event-bus');
    application.inject('controller', 'eventbus', 'service:event-bus');
    application.inject('model', 'eventbus', 'service:event-bus');
    application.inject('component', 'eventbus', 'service:event-bus');
    application.inject('mixin', 'eventbus', 'service:event-bus');
  }

  exports['default'] = {
    name: 'global-injector',
    initialize: initialize
  };
});