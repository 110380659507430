define('my-care-git-ember-portal/models/job', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        title: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        image: _emberData['default'].belongsTo('file', { async: true }),
        createdOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        personJobs: _emberData['default'].hasMany('person-job', { async: true }),
        tasks: _emberData['default'].hasMany('job-task', { async: true }),
        notes: _emberData['default'].hasMany('note', { async: true }),
        events: _emberData['default'].hasMany('event', { async: true }),
        isArchived: _emberData['default'].attr('boolean'),
        hasValidSubscription: _emberData['default'].attr('boolean'),
        owners: _emberData['default'].attr()
    });
});