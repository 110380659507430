define('my-care-git-ember-portal/index/recommendations/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        beforeModel: function beforeModel() {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
            }
        },
        setupController: function setupController(controller) {
            controller.getSearchResults(10, 0).then(function (recommendations) {
                controller.set('recommendations', recommendations);
                controller.set('_currentAmount', recommendations.get('length') > controller.get('_pageSize') ? controller.get('_pageSize') : recommendations.get('length'));
                controller.set('_totalAmount', recommendations.get('meta.total'));
            });
        }
    });
});