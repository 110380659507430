define('my-care-git-ember-portal/index/users/threads/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return this.store.findRecord('person', params.user_id);
        },
        setupController: function setupController(controller, model) {
            controller.set("model", model);
            controller.set("isDisplayMessages", false);
            controller.set('isMore', true);
            controller.set("pageSize", 10);
            controller.set("offset", 0);
            controller.get('threads').clear();
            controller.getThreads();
        }
    });
});