define("my-care-git-ember-portal/services/image-data-uploader", ["exports", "ember", "my-care-git-ember-portal/config/environment", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _httpService: _ember["default"].inject.service('http'),

        uploadData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(data) {
            var imageUpload, json;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        imageUpload = {
                            imageUpload: {
                                data: data,
                                fileName: 'fileName.png'
                            }
                        };
                        json = JSON.stringify(imageUpload);
                        context$1$0.next = 4;
                        return this.get('_httpService').withAuthentication(function (defer) {
                            var url = _myCareGitEmberPortalConfigEnvironment["default"].APP.API_HOST + '/api/imageUploader';
                            _ember["default"].$.post(url, json).then(function (response) {
                                if (_this.isDestroyed) {
                                    return;
                                }
                                defer.resolve(response);
                            }, function (error) {
                                defer.reject(error);
                            });

                            defer.promise.then(function (loginResponse) {
                                if (_this.isDestroyed) {
                                    return;
                                }
                                return loginResponse.media.url;
                            })["catch"](function () {});
                        });

                    case 4:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 5:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});