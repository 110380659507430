define('my-care-git-ember-portal/index/account-error/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        _accountErrors: [],
        status: '',
        _pageSize: 10,
        _currentAmount: 0,
        _totalAmount: 0,
        _accountErrorService: _ember['default'].inject.service('account-error-service'),
        setup: function setup() {
            this.set('_totalAmount', this.get('_accountErrors.meta.total'));
            this.set('_currentAmount', this.get('_accountErrors.length'));
        },

        actions: {
            deleteItem: function deleteItem(item) {
                var _this = this;

                bootbox.confirm('Are you sure to remove error:' + item.get('id') + '?', function (result) {
                    if (result) {
                        _this.get('_accountErrors').removeObject(item);
                        _this.get('_accountErrorService.removeAccountError').perform(item);
                    }
                });
            },

            onClickPage: function onClickPage(page) {
                var _this2 = this;

                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.set('isLoading', true);
                this.get('_accountErrorService.getSearchResults').perform(this.get('_pageSize'), offSet, this.get('status'), '').then(function (accountErrors) {
                    var _currentAmount = offSet + accountErrors.get('length');
                    _this2.set('_currentAmount', _currentAmount >= _this2.get('_totalAmount') ? _this2.get('_totalAmount') : _currentAmount);
                    _this2.set('_accountErrors', accountErrors);
                    _this2.set('isLoading', false);
                });
            }
        }
    });
});