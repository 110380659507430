define('my-care-git-ember-portal/index/users/search/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {

        searchString: '',
        queryParams: {
            searchString: {
                refreshModel: true
            }
        },

        model: function model(params) {
            this.set('searchString', params.searchString);
            return params;
        },

        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = this.controllerFor('index.users.index');
            self.render('index.users.index', {
                controller: controller
            });
            var radioItems = [{ id: 'worker', 'name': 'Worker', isSelected: false }, { id: 'seeker', 'name': 'Organiser', isSelected: false }, { id: 'client', 'name': 'Client', isSelected: false }, { id: 'business', 'name': 'Business', isSelected: false }, { id: 'archived', 'name': 'Archived', isSelected: false }, { id: 'all', 'name': 'All', isSelected: true }];
            controller.set('radioItems', radioItems);
            controller.set('searchString', this.get('searchString'));
            controller.getSearchResults(controller.get('_pageSize'), 0, this.get('searchString'), 'all').then(function (people) {
                controller.set('_people', people);
                controller.set('filterResult', people);
                controller.set('_currentAmount', people.get('length') > controller.get('_pageSize') ? controller.get('_pageSize') : people.get('length'));
                controller.set('_totalAmount', people.get('meta.total'));
            });
        }
    });
});