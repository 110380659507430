define('my-care-git-ember-portal/adapters/application', ['exports', 'ember-data', 'my-care-git-ember-portal/config/environment'], function (exports, _emberData, _myCareGitEmberPortalConfigEnvironment) {

    //Set default adapter for Ember Data
    //Configure here to set Authorization header for all ajax requests issued by Ember (ember-data).
    //Please note that the following configuration is only relevant if ajax is made by Ember. If you are using
    //bare $.ajax, you need to set jQuery ajax setting separately.
    exports['default'] = _emberData['default'].RESTAdapter.extend({
        host: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST,
        headers: (function () {
            return {
                "Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
            };
        }).property().volatile(),

        /**
        * Namespace for the ember app.
        * For example, http://www.example.com/api <= only url after api(namespace) will be routed to ember router.
        */
        namespace: 'api',

        shouldReloadAll: function shouldReloadAll() {
            return false;
        },

        shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
            return false;
        }
    });
});
/**
 * Created by tinhtooaung on 5/06/15.
 */