define('my-care-git-ember-portal/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _myCareGitEmberPortalConfigEnvironment) {

    var App;

    _ember['default'].MODEL_FACTORY_INJECTIONS = true;

    App = _ember['default'].Application.extend({
        modulePrefix: _myCareGitEmberPortalConfigEnvironment['default'].modulePrefix,
        Resolver: _emberResolver['default'],
        currentPath: ''
    });

    (0, _emberLoadInitializers['default'])(App, _myCareGitEmberPortalConfigEnvironment['default'].modulePrefix);

    //Ember.Handlebars.registerHelper('compare', function (a, b, options) {
    //    if (a === undefined || b === undefined) {
    //        return;
    //    }
    //    return Ember.Handlebars.bind.call(options.contexts[0], a, options, true, function (result) {
    //        return result === b;
    //    });
    //});

    //Raygun.init('JVbUw+5FvfcCe6AzoJIQ3g==').attach();
    //
    //Ember.onerror = function (error) {
    //    Raygun.send(error);
    //};
    //
    //Ember.RSVP.on('error', function(error) {
    //    Raygun.send(error);
    //});
    //
    //Ember.Logger.error = function (message, cause, stack) {
    //    // If you want to send to Raygun in addition to console logging:
    //    Raygun.send(new Error(message), null, { cause: cause, stack: stack });
    //};

    exports['default'] = App;
});