define('my-care-git-ember-portal/components/mc-availability-picker/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _ember['default'].Component.extend({
        modelWithAvailabilities: null,
        onSaved: null,
        onSave: null,
        isValid: false,
        isDelegateSave: false,
        isReadOnly: false,

        _store: _ember['default'].inject.service('store'),
        _formData: {
            isSelectAll: false
        },
        _isSaving: false,

        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('modelWithAvailabilities')) {
                this._setupFormData(this.get('modelWithAvailabilities'));
                this.set('when', this.get('modelWithAvailabilities.when'));
            }
        },

        _watchSelectAll: (function () {
            if (this.get('_formData.isSelectAll') === true) {
                this.get('_formData.availabilities').forEach(function (group) {
                    group.get('items').forEach(function (item) {
                        return item.set('isChecked', true);
                    });
                });
            } else {
                this._setupFormData(this.get('modelWithAvailabilities'), false);
            }
        }).observes('_formData.isSelectAll'),

        _setupFormData: function _setupFormData(modelWithAvailabilities) {
            var _this = this;

            var shouldReload = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];
            //jshint ignore:line
            this.set('_formData.when', modelWithAvailabilities.get('when'));
            this.get('_store').findAll('availability', { reload: shouldReload }).then(function (types) {
                var existingAvailabilities = modelWithAvailabilities.get('availabilities') || [];
                var availabilities = types.map(function (item) {
                    var isChecked = !_ember['default'].isNone(existingAvailabilities.find(function (i) {
                        return i.get('id') === item.get('id');
                    }));
                    return _ember['default'].Object.create({
                        record: item,
                        isChecked: isChecked
                    });
                });
                var groupByTime = _.groupBy(availabilities.toArray(), function (item) {
                    return item.get('record.time');
                });
                var groupByTimeWithName = Object.keys(groupByTime).map(function (key) {
                    return _ember['default'].Object.create({
                        name: key,
                        items: _.sortBy(groupByTime[key], function (item) {
                            return item.get('record.dayId');
                        })
                    });
                });
                _this.set('_formData.availabilities', _.sortBy(groupByTimeWithName, function (group) {
                    return group.get('items').objectAt(0).get('record.timeId');
                }));
            });
        },

        _setModelData: function _setModelData() {
            var allItems = _ember['default'].A();
            this.get('_formData.availabilities').forEach(function (group) {
                allItems.pushObjects(group.get('items'));
            });
            this.set('modelWithAvailabilities.availabilities', allItems.filter(function (item) {
                return item.get('isChecked') === true;
            }).map(function (item) {
                return item.get('record');
            }));
            // this.set('modelWithAvailabilities.when', this.get('when'));
        },

        watchWhen: (function () {
            this.set('modelWithAvailabilities.when', this.get('_formData.when'));
        }).observes('_formData.when'),

        actions: {
            onSelectedEvent: function onSelectedEvent(item, event) {
                item.set('isChecked', event.target.checked);
                this._setModelData();
            },

            save: function save() {
                var _this2 = this;

                this.set('_isSaving', true);
                this._setModelData();
                this.get('modelWithAvailabilities').save().then(function () {
                    _this2.set('_isSaving', false);
                    _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(_this2.get('onSaved'));
                });
            }
        }
    });
});