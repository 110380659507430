define('my-care-git-ember-portal/index/agreement/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        beforeModel: function beforeModel() {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
            }
        },

        model: function model() {
            var controller = this.controllerFor('index.agreement.index');
            if (_ember['default'].isEmpty(controller.get('contracts'))) {
                return this.store.query('Contract', { pagesize: 10, offset: 0, status: 'all' });
            }
        },

        setupController: function setupController(controller, model) {
            if (_ember['default'].isEmpty(model) === false) {
                controller.set('contracts', model);
                controller.setup();
            }
        }
    });
});