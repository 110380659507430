define('my-care-git-ember-portal/controllers/auth', ['exports', 'ember', 'my-care-git-ember-portal/config/environment', 'my-care-git-ember-portal/mixins/loadable'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment, _myCareGitEmberPortalMixinsLoadable) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsLoadable['default'], {
        //login object used to login
        login: {
            email: '',
            password: ''
        },
        isRedirecting: false,
        isLoggedIn: false,
        errors: [],
        loginAttempts: '',
        currentPerson: null,

        /**
         * Check is user is authenticated.
         * Return true if user is authenticated, otherwise false.
         */
        isAuthenticated: (function () {
            if (this.get('isLoggedIn')) {
                return true;
            } else if (localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY) !== null) {
                return true;
            } else {
                return false;
            }
        }).property('isLoggedIn').volatile(),

        /**
         * Get logged in user Id
         */
        loggedInUserId: (function () {
            return localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_USER_KEY);
        }).property(),

        profileId: (function () {
            return localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_PROFILE_ID);
        }).property().volatile(),

        /**
         * Get currently selected person Id
         */
        currentPersonId: (function () {
            var profileId = this.get('profileId');
            if (profileId) {
                return profileId;
            }
            return localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_USER_KEY);
        }).property().volatile(),

        /**
         * Get currently selected person Id
         */
        getCurrentPerson: (function () {
            var self = this;
            var currentPerson = self.get('currentPerson');
            if (currentPerson) {
                return currentPerson;
            }
            return self.store.findRecord('person', self.get('currentPersonId')).then(function (currentPerson) {
                self.set('currentPerson', currentPerson);
                return currentPerson;
            }, function () {
                self.transitionToRoute('login');
            });
        }).property().volatile(),

        actions: {
            /**
             * Login function that handle action callback
             */
            login: function login(_login) {
                this.set('login', _login);
            },
            /**
             * Logout function. This will clear the session cookies but will not logout on the server.
             */
            logout: function logout() {
                this.invalidate();
            }
        },

        /**
         * clear all login data from browse
         */
        invalidate: function invalidate() {
            localStorage.removeItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY);
            localStorage.removeItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_USER_KEY);
            this.set('login', {});
            this.set('isLoggedIn', false);
            this.transitionToRoute('login');
        },

        getcurrentPersonObject: function getcurrentPersonObject() {
            return this.store.findRecord('person', this.get('profileId')).then(function (person) {
                return person;
            });
        }
    });
});