define('my-care-git-ember-portal/components/mc-birthday-picker/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/utils/common', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('maxYear', 120);
            this.initVar('minYear', 0);
            this.initVar('initialValue', undefined);
            this.initVar('error', undefined);
            this.initVar('onSelected', undefined);
            this.initVar('isDisabed', undefined);

            this.initVar('_yearOptions', undefined);
            this.initVar('_monthOptions', undefined);
            this.initVar('_dateOptions', undefined);
            this.initVar('_selectedMonthOptions', undefined);
            this.initVar('_selectedYearOptions', undefined);
            this.initVar('_selectedDateOptions', undefined);

            this.initVar('_selectedDateValue', undefined);
            this.initVar('_selectedMonthValue', undefined);
            this.initVar('_selectedYearValue', undefined);
            this.initVar('isLoaded', false);
            this._setupDateOptions(31);
            this._setupMonthOptions();
            this._setupYearOptions();
        },

        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('isLoaded') === false) {
                this._setupInitialValue();
            }
        },

        _setupDateOptions: function _setupDateOptions(range) {
            var dateOptions = _myCareGitEmberPortalUtilsCommon['default'].createSelectOptions({
                models: _.map(_.range(1, range + 1), function (item, index) {
                    return _ember['default'].Object.create({ id: index, value: item });
                }),
                labelKey: 'value',
                modelName: 'date',
                prompt: 'Date',
                isIncludeNew: false
            });
            this.set('_dateOptions', dateOptions);
        },

        _setupMonthOptions: function _setupMonthOptions() {
            var monthOptions = _myCareGitEmberPortalUtilsCommon['default'].createSelectOptions({
                models: _.map(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], function (item, index) {
                    return _ember['default'].Object.create({ id: index, value: item });
                }),
                labelKey: 'value',
                modelName: 'month',
                prompt: 'Month',
                isIncludeNew: false
            });
            this.initVar('_monthOptions', monthOptions);
        },

        _setupYearOptions: function _setupYearOptions() {
            var currentYear = moment().year() - this.get('minYear');
            var yearOptions = _myCareGitEmberPortalUtilsCommon['default'].createSelectOptions({
                models: _.map(_.range(this.get('maxYear')), function (value, index) {
                    return _ember['default'].Object.create({ id: index, value: currentYear - value });
                }),
                labelKey: 'value',
                modelName: 'year',
                prompt: 'Year',
                isIncludeNew: false
            });
            this.initVar('_yearOptions', yearOptions);
        },

        _setupInitialValue: function _setupInitialValue() {

            var momentInitialDate = this.get('initialValue');

            if (_ember['default'].isEmpty(momentInitialDate) || typeof momentInitialDate !== 'string') {
                return;
            }
            if (typeof momentInitialDate === 'string') {
                momentInitialDate = moment(this.get('initialValue'));
            }

            var day = momentInitialDate.date();
            var month = momentInitialDate.month();
            var year = momentInitialDate.year();

            this.set('_selectedDayOption', this.get('_dateOptions').find(function (opt) {
                return opt.get('record.value') === day;
            }));
            this.set('_selectedMonthOption', this.get('_monthOptions').find(function (opt) {
                return opt.get('record.id') === month;
            }));
            this.set('_selectedYearOption', this.get('_yearOptions').find(function (opt) {
                return opt.get('record.value') === year;
            }));

            this.set('_selectedDayValue', this.get('_selectedDayOption.record.value'));
            this.set('_selectedMonthValue', this.get('_selectedMonthOption.record.id'));
            this.set('_selectedYearValue', this.get('_selectedYearOption.record.value'));
        },

        _watchSelectedValues: (function () {
            if (this.isDestroyed) {
                return;
            }
            var day = this.get('_selectedDayValue');
            var year = this.get('_selectedYearValue');
            var month = this.get('_selectedMonthValue');

            if (!_ember['default'].isNone(day) && !_ember['default'].isNone(year) && !_ember['default'].isNone(month)) {
                if (day === -1 || month === -1 || year === -1) {
                    _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onSelected'), null);
                } else {
                    var date = moment(moment().date(day).month(month).year(year).format('YYYY-MM-DD'));
                    this.set('isLoaded', true);
                    _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onSelected'), date);
                }
            } else {
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onSelected'), null);
            }
        }).observes('_selectedDayValue', '_selectedMonthValue', '_selectedYearValue'),

        _updateDateOptionsBaseOnMonth: function _updateDateOptionsBaseOnMonth() {
            var isLeapYear = this.isLeapYear(this.get('_selectedYearValue'));
            switch (this.get('_selectedMonthValue')) {
                case 8:
                case 3:
                case 5:
                case 10:
                    this._setupDateOptions(30);
                    break;
                case 1:
                    this._setupDateOptions(isLeapYear ? 29 : 28);
                    break;
                default:
                    this._setupDateOptions(31);
                    break;
            }
        },

        actions: {
            onDaySelected: function onDaySelected(value) {
                this.set('_selectedDayValue', value.get('record.value'));
            },

            onMonthSelected: function onMonthSelected(value) {
                this.set('_selectedMonthValue', value.get('record.id'));
                this._updateDateOptionsBaseOnMonth(value.get('record.id'));
            },

            onYearSelected: function onYearSelected(value) {
                this.set('_selectedYearValue', value.get('record.value'));
                this._updateDateOptionsBaseOnMonth();
            }
        },

        isLeapYear: function isLeapYear(year) {
            return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
        }
    });
});