define('my-care-git-ember-portal/index/users/detail/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/mixins/fedback-handler', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalMixinsFedbackHandler, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {

        listingController: _ember['default'].inject.controller('index/job-listings/new'),
        isLoadMobile: false, // flag to update component
        _clientAccounts: [],
        _workerAccount: {},
        recommendationPageSize: 10,
        recommendationOffset: 0,
        /**
         * url for the avatar. This is passed to uploader to newly uploaded url as well.
         */
        currentAvatarUrl: '',
        avatarUrl: '',
        /**
         * Update image thumbnail after uploaded
         */

        isClientOrganiser: (function () {
            return this.get('model.isClient') && this.get('model.isOrganiser');
        }).property('model.isClient', 'model.isOrganiser'),

        isWorkerOrganiser: (function () {
            return this.get('model.isWorker') && this.get('model.isOrganiser');
        }).property('model.isWorker', 'model.isOrganiser'),

        setup: function setup() {
            var self = this;
            self.set('isLoadMobile', !self.get('isLoadMobile'));
            self.set('_workerAccount', {});
            self.getrecommendations();
            self.emptyErrorMessages();
            self._getClientAccounts();
            self._getWorkerAccount();
            self.get('_getContractsAndListings').perform();
        },

        _getContractsAndListings: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var clientJoblistings, clientAgreements, relationships, relatedPersons, joblistingsArray, agreementsArray;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!this.get('model.isOrganiser')) {
                            context$1$0.next = 19;
                            break;
                        }

                        clientJoblistings = [];
                        clientAgreements = [];
                        context$1$0.next = 5;
                        return this.get('model.relationships');

                    case 5:
                        relationships = context$1$0.sent;
                        context$1$0.next = 8;
                        return _ember['default'].RSVP.all(relationships.map(function (rel) {
                            return rel.get('relatedPerson');
                        }));

                    case 8:
                        relatedPersons = context$1$0.sent;
                        context$1$0.next = 11;
                        return _ember['default'].RSVP.all(relatedPersons.map(function (p) {
                            return p.get('jobListings');
                        }));

                    case 11:
                        joblistingsArray = context$1$0.sent;
                        context$1$0.next = 14;
                        return _ember['default'].RSVP.all(relatedPersons.map(function (p) {
                            return p.get('clientContracts');
                        }));

                    case 14:
                        agreementsArray = context$1$0.sent;

                        joblistingsArray.forEach(function (listings) {
                            clientJoblistings = clientJoblistings.concat(listings.toArray());
                        });
                        agreementsArray.forEach(function (agreements) {
                            clientAgreements = clientAgreements.concat(agreements.toArray());
                        });

                        this.set('clientJoblistings', clientJoblistings);
                        this.set('clientAgreements', clientAgreements);

                    case 19:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getrecommendations: function getrecommendations() {
            var self = this;
            self.store.query('recommendation', { personId: self.get('model.id'), pageSize: self.get('recommendationPageSize'),
                offset: self.get('recommendationOffset') }).then(function (recommendations) {
                self.set('recommendations', recommendations);
            });
        },

        _getClientAccounts: function _getClientAccounts() {
            var _this = this;

            this.get('model.accounts').then(function (accounts) {
                var clientAccounts = accounts.filter(function (account) {
                    return account.get('accountType.name').toLowerCase() !== 'worker';
                });
                _this.set('_clientAccounts', clientAccounts);
            });
        },

        _getWorkerAccount: function _getWorkerAccount() {
            var _this2 = this;

            this.get('model.accounts').then(function (accounts) {
                var workerAccounts = accounts.filter(function (account) {
                    return account.get('accountType.name').toLowerCase() === 'worker';
                });
                if (workerAccounts.get('length') === 1) {
                    _this2.set('_workerAccount', workerAccounts.objectAt(0));
                }
            });
        },

        watchClientAccount: (function () {
            this._getWorkerAccount();
        }).observes('model'),

        actions: {

            iEmptyErrorMessages: function iEmptyErrorMessages() {
                this.emptyErrorMessages();
            },

            iAddErrorMessage: function iAddErrorMessage(error) {
                this.addErrorMessage(error);
            },

            newJobListing: function newJobListing() {
                var self = this;
                var joblisting = self.store.createRecord('job-listing', {
                    person: self.get('model'),
                    availabilities: []
                });
                self.get('listingController').set('joblisting', joblisting);
                self.transitionToRoute('index.job-listings.new');
            },

            transitionToSearch: function transitionToSearch() {
                var location = this.get('model.location');
                if (this.get("model.isClient") === true) {
                    window.open(window.location.protocol + '//' + window.location.host + '/#/search/profiles?location=' + location);
                } else if (this.get("model.isWorker") === true) {
                    window.open(window.location.protocol + '//' + window.location.host + '/#/search/jobs?location=' + location);
                }
            },

            back: function back() {
                window.history.go(-1);
            },

            redirectTo: function redirectTo(route) {
                this.transitionToRoute(route);
            }

        }

    });
});