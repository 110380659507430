define('my-care-git-ember-portal/components/mc-account-type/edit-account-type-modal/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-modal-component', 'my-care-git-ember-portal/config/environment', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/utils/common', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcModalComponent, _myCareGitEmberPortalConfigEnvironment, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalUtilsCommon, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcModalComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsDefer['default'], {
        _store: _ember['default'].inject.service('store'),
        _modalHelper: _ember['default'].inject.service('utils/modal-helper'),
        formData: {},
        init: function init() {
            this.initVar('_componentId', 'mc-update-account-type-modal');
            this._super.apply(this, arguments);

            this.initVar('isNew', false);
            this.initVar('formData', {});
        },

        willSetupOnce: function willSetupOnce() {
            this._super.apply(this, arguments);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var accountType = _ref.accountType;

            this.set('accountType', accountType);
            this.set('formData.amount', accountType.get('BookingThreshold'));
            this.addValidation('formData.amount', 'amount', 'Cap', [_myCareGitEmberPortalUtilsValidators['default'].isRequired, this.validateRate, this.validateInt]);

            this.set('isShowConfirmationForm', false);
            this.set('isSaving', false);
        },

        validateRate: function validateRate() {
            var isValid = this.get('formData.amount') >= 0 && this.get('formData.amount') <= 100000;
            return {
                result: isValid,
                message: isValid ? null : 'Fee cap amount must be below or equal to 100000'
            };
        },

        validateInt: function validateInt() {

            var isValid = parseInt(this.get('formData.amount')) == this.get('formData.amount') ? true : Number.isInteger(this.get('formData.amount'));
            return {
                result: isValid,
                message: isValid ? null : 'Fee Cap must be a whole number. Decimal places are not allowed'
            };
        },

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (this._validate()) {
                            context$1$0.next = 2;
                            break;
                        }

                        return context$1$0.abrupt('return', false);

                    case 2:
                        context$1$0.prev = 2;

                        this.set('accountType.BookingThreshold', this.get('formData.amount'));
                        context$1$0.next = 6;
                        return this.get('accountType').save();

                    case 6:
                        this._close();
                        this.sendAction('_onDone');
                        context$1$0.next = 13;
                        break;

                    case 10:
                        context$1$0.prev = 10;
                        context$1$0.t0 = context$1$0['catch'](2);

                        this.set('apiError', "New account fee must start in future and can only have one future account fee");

                    case 13:
                        ;

                    case 14:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[2, 10]]);
        }))

    });
});