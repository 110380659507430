define('my-care-git-ember-portal/index/timesheet-incident/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        _pageSize: 10,
        _totalAmount: 0,
        _currentAmount: 0,
        _notes: [],
        setup: function setup() {
            this.set('_currentAmount', this.get('_notes.length'));
        },

        actions: {
            onClickPage: function onClickPage(page) {
                var _this = this;

                this.set('isLoading', true);
                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.store.query('timesheet-note', { pageSize: this.get('_pageSize'), offset: offSet }).then(function (notes) {
                    var _currentAmount = offSet + notes.get('length');
                    _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                    _this.set('_notes', notes);
                    _this.set('isLoading', false);
                });
            }
        }

    });
});