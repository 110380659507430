define('my-care-git-ember-portal/components/mc-responsive-timeline/timeline-li/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * phone-picker component
     */
    exports['default'] = _ember['default'].Component.extend({
        /**
         * Setup component at startup.
         */
        tagName: '',
        init: function init() {
            this._super();
            if (this.get('index') % 2 === 0) {
                this.set('isLeft', true);
            }
            if (this.get('model.activityType') == 'hires') {
                this.set('iconBackground', 'success');
                this.set('typeIcon', '<i class="fa fa-check"></i>');
            } else if (this.get('model.activityType') == 'jobApplications') {
                this.set('iconBackground', 'warning');
                this.set('typeIcon', '<i class="fa fa-file-o"></i>');
            } else if (this.get('model.activityType') == 'jobListings') {
                this.set('iconBackground', 'danger');
                this.set('typeIcon', '<i class="fa fa-list-alt"></i>');
            } else if (this.get('model.activityType') == 'organiser') {
                this.set('iconBackground', 'organiser');
                this.set('typeIcon', '<i class="fa fa-users"></i>');
            } else if (this.get('model.activityType') == 'worker') {
                this.set('iconBackground', 'worker');
                this.set('typeIcon', '<i class="fa fa-user"></i>');
            } else if (this.get('model.activityType') == 'messages') {
                this.set('iconBackground', '');
                this.set('typeIcon', '<i class="fa fa-commenting"></i>');
            } else if (this.get('model.activityType') == 'contracts') {
                this.set('iconBackground', 'success');
                this.set('typeIcon', '<i class="fa fa-check"></i>');
            }
        }
    });
});