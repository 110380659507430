define('my-care-git-ember-portal/index/agreement/detail/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {
            var self = this;
            return _ember['default'].RSVP.hash({
                contract: self.store.findRecord('contract', params.agreement_id),
                timesheets: self.store.query('timesheet', { contractId: params.agreement_id })
            }).then(function (result) {
                return result;
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('contract', model.contract);
            controller.setup();
        }
    });
});