define("my-care-git-ember-portal/components/mc-custom-activity-editor/component", ["exports", "ember", "my-care-git-ember-portal/mixins/validator", "my-care-git-ember-portal/utils/Validators", "my-care-git-ember-portal/utils/function-helpers", "my-care-git-ember-portal/utils/mc-component"], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsFunctionHelpers, _myCareGitEmberPortalUtilsMcComponent) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend(_myCareGitEmberPortalMixinsValidator["default"], {
        _store: _ember["default"].inject.service('store'),

        init: function init() {
            this._super.apply(this, arguments);

            //public attributes
            this.initVar('personId', undefined);
            this.initVar('value', []);
            this.initVar('onAdded', undefined);

            //private properties
            this.initVar('_isLoading', false);
            this.initVar('_isInitialised', false);
            this.initVar('_formData', {
                customActivity: null
            });
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this._setupValidations();
        },

        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.customActivity', 'customActivity', 'Activity', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.set('_isInitialised', true);
        },

        _resetForm: function _resetForm() {
            this.set('_formData.customActivity', null);
        },

        actions: {
            addActivity: function addActivity() {
                var _this = this;

                if (!this._validate()) {
                    return;
                }
                this.set('_isLoading', true);
                var store = this.get('_store');
                var activityTypes = store.peekAll('activity-type');
                var myActivityTypes = activityTypes.filter(function (act) {
                    return act.get('activityGroup') === 1 && act.get('createdPerson.id') == _this.get('personId');
                }).sortBy('sortOrder').reverse();
                if (this.isDestroyed) {
                    return;
                }
                store.findRecord('person', this.get('personId')).then(function (person) {
                    if (_this.isDestroyed) {
                        return;
                    }
                    var newActivityType = store.createRecord('activity-type', {
                        name: _this.get('_formData.customActivity'),
                        createdPerson: person,
                        isArchived: false,
                        activityGroup: 1,
                        sortOrder: _ember["default"].isEmpty(myActivityTypes) ? 600 : activityTypes.objectAt(0).get('sortOrder') + 1
                    });
                    return newActivityType.save();
                }).then(function (newActivityType) {
                    if (_this.isDestroyed) {
                        return;
                    }
                    if (_ember["default"].isNone(_this.get('value'))) {
                        _this.set('value', []);
                    }
                    _this.get('value').pushObject(newActivityType);
                    _this._resetForm();
                    _this._setupValidations();
                    _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(_this.get('onAdded'), newActivityType);
                    _this.set('_isLoading', false);
                });
            },

            removeActivity: function removeActivity(activity) {
                this.get('value').removeObject(activity);
            }
        }
    });
});