define('my-care-git-ember-portal/models/list-person', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        isPublished: _emberData['default'].attr('boolean'),
        isDssUser: _emberData['default'].attr('boolean'),
        isDssOrganiser: _emberData['default'].attr('boolean'),
        isValidUser: _emberData['default'].attr('boolean'),
        isCircle: _emberData['default'].attr('boolean'),
        isClient: _emberData['default'].attr('boolean'),
        isOrganiser: _emberData['default'].attr('boolean'),
        isWorker: _emberData['default'].attr('boolean'),
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        isEmailVerified: _emberData['default'].attr('boolean'),
        phoneNumber: _emberData['default'].attr('string'),
        isPhoneNumberVerified: _emberData['default'].attr('boolean'),
        numberOfVerifications: _emberData['default'].attr('number'),
        avatarUrl: _emberData['default'].attr('string'),
        dateOfBirth: _emberData['default'].attr('string'),
        lastLoginOn: _emberData['default'].attr('string'),
        numberOfListings: _emberData['default'].attr('number'),
        address: _emberData['default'].attr('string'),
        isAddressVerified: _emberData['default'].attr('boolean'),
        location: _emberData['default'].attr('string'),
        relationships: _emberData['default'].hasMany('related-person', { async: true, inverse: null })
    });
});