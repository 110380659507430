define('my-care-git-ember-portal/router', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _myCareGitEmberPortalConfigEnvironment['default'].locationType
    });

    Router.map(function () {
        this.route("pagenotfound", { path: "*path" });
        this.route('index', { path: '/' }, function () {
            this.route('account-balance', { path: '/account-balance' }, function () {
                this.route('index', { path: '/' });
                this.route('pending', { path: '/pending' });
            }), this.route('account-types', { path: 'account-types' }, function () {}), this.route('account-fees', { path: 'account-fees/:account_type_id' }, function () {}), this.route('users', { path: '/users' }, function () {
                this.route('index', { path: '/' });
                this.route('all', { path: '/all' });
                this.route('business', { path: '/business' });
                this.route('new-business-user', { path: '/new-business-user' });
                this.route('seeker', { path: '/seeker' });
                this.route('worker', { path: '/worker' });
                this.route('client', { path: '/client' });
                this.route('archived', { path: '/archived' });
                this.route('search', { path: '/search' });
                this.route('detail', { path: '/:user_id' }, function () {
                    this.route('index', { path: '/' });
                    this.route('basic-info', { path: '/basic-info' });
                    this.route('address', { path: '/address' });
                    this.route('job-listings', { path: '/job-listings' });
                    this.route('invited-people', { path: '/invited-people' });
                    this.route('client-agreement', { path: '/client-agreement' });
                    this.route('relationship', { path: '/relationship' });
                    this.route('related-organiser', { path: '/related-organiser' });
                    this.route('related-client', { path: '/related-client' });
                    this.route('reset-password', { path: '/reset-password' });
                    this.route('files', { path: '/files' });
                    this.route('client-accounts', { path: '/client-accounts' });
                    this.route('worker-account', { path: '/worker-account' });
                    this.route('worker-experience', { path: '/worker-experience' });
                    this.route('worker-qualification', { path: '/worker-qualification' });
                    this.route('moj', { path: '/moj' });
                    this.route('worker-opportunity', { path: '/worker-opportunity' });
                    this.route('worker-agreement', { path: '/worker-agreement' });
                    this.route('worker-review', { path: '/worker-review' });
                    this.route('listing-around', { path: '/listing-around' });
                    this.route('account-detail', { path: '/account-detail/:account_id' });
                    this.route('client-report', { path: '/account-detail/:account_id/client-report' });
                    this.route('worker-report', { path: '/account-detail/:account_id/worker-report' });
                });
                this.route('threads', { path: '/:user_id/threads' }, function () {
                    this.route('messages', { path: '/:thread_id' });
                });
            });
            this.route('dashboard', { path: '/dashboard' });
            this.route('job-listings', { path: '/job-listings' }, function () {
                this.route('index', { path: '/' });
                this.route('open', { path: '/open' });
                this.route('new', { path: '/new' });
                this.route('draft', { path: '/draft' });
                this.route('closed', { path: '/closed' });
                this.route('not-hired', { path: '/not-hired' });
                this.route('is-hired', { path: '/is-hired' });
                this.route('detail-redirect', { path: '/:job_listing_id' });
                this.route('no-applicants', { path: '/no-applicants' });
                this.route('detail', { path: '/detail/:job_listing_id' }, function () {
                    this.route('index', { path: '' });
                    this.route('applicants', { path: '/applicants' });
                    this.route('when', { path: '/when' });
                    this.route('helprequired', { path: '/helprequired' });
                    this.route('idealworker', { path: '/idealworker' });
                    this.route('favouritedby', { path: '/favouritedby' });
                    this.route('local-workers', { path: '/local-workers' });
                });
                this.route('threads', { path: '/threads' });
                this.route('messages', { path: '/thread/:thread_id' });
                this.route('redirect-route', { path: '/:listing_id/applicants/:applicant_id/thread/:thread_id' });
            });
            this.route('search', { path: '/search' }, function () {
                this.route('index', { path: '/' });
                this.route('profiles', { path: '/profiles' });
                this.route('jobs', { path: '/jobs' });
            });
            this.route('funder', { path: '/funder' }, function () {
                this.route('index', { path: '/' });
                this.route('transactions', { path: '/transactions' });
                this.route('payments', { path: '/payments' });
            });
            this.route('agreement', { path: '/agreement' }, function () {
                this.route('index', { path: '/' });
                this.route('detail', { path: '/:agreement_id' });
            });
            this.route('account-error', { path: '/account-error' }, function () {
                this.route('index', { path: '/' });
                this.route('detail', { path: '/:error_id' });
            });
            this.route('market-dynamics', { path: '/market-dynamics' });
            this.route('reports', { path: '/reports' });
            this.route('business-kpi', { path: '/business-kpi' });
            this.route('dss-reports', { path: '/dss-reports' });
            this.route('covid-vaccination-settings', { path: '/covid-vaccination-settings' });
            this.route('manage', { path: '/manage' });
            this.route('business', { path: '/business' });
            this.route('browse', { path: '/browse' }, function () {
                this.route('profile', { path: '/profile' }, function () {
                    this.route('index', { path: '/:profile_id' });
                });
                this.route('job', { path: '/job' }, function () {
                    this.route('index', { path: '/:job_id' });
                });
            });
            this.route('registrations', { path: '/registrations' }, function () {
                this.route('detail', { path: '/:pps_id' });
                this.route('new', { path: '/new' });
                this.route('work-and-income', { path: '/work-and-income' });
                this.route('dss', { path: '/dss' });
                this.route('all', { path: '/all' });
            });
            this.route('job-applications', { path: '/job-applications' }, function () {
                this.route('index', { path: '/' });
                this.route('all', { path: '/all' });
                this.route('draft', { path: '/draft' });
                this.route('interview', { path: '/interview' });
                this.route('hired', { path: '/hired' });
                this.route('not-suitable', { path: '/not-suitable' });
                this.route('detail', { path: '/:app_id' });
            });

            this.route('visits', { path: '/visits' }, function () {
                this.route('index', { path: '/' });
                this.route('detail', { path: '/:visit_id' });
            });

            this.route('weekly-visits-report', { path: '/weekly-visits-report' }, function () {
                this.route('seeker-report', { path: '/seeker' });
                this.route('worker-report', { path: '/worker' });
            });

            this.route('recommendations', { path: '/reviews' }, function () {
                this.route('index', { path: '/' });
                this.route('detail', { path: '/:pps_id' });
            });
            this.route('threads', { path: '/threads' }, function () {
                this.route('index', { path: '/' });
                this.route('not-responded', { path: '/not-responded' });
                this.route('detail', { path: '/:thread_id' });
            });
            this.route('unsubmitted-timesheet', { path: '/unsubmitted-timesheet' }, function () {
                this.route('index', { path: '/' });
            });
            this.route('board-reports', { path: '/board-reports' }, function () {
                this.route('on-boarding', { path: '/on-boarding' });
                this.route('matching', { path: '/matching' });
                this.route('working', { path: '/working' });
                this.route('paying', { path: '/paying' });
            });
            this.route('timesheet-incident', { path: '/timesheet-incident' });
            this.route('work-and-income-user', { path: '/work-and-income-user' });
            this.route('invoicable-accounts', { path: '/invoicable-accounts' });
            this.route('client-report', { path: '/client-report' });
            this.route('weekly-visits-report-detail', { path: '/weekly-visits-report-detail' });
            this.route('experience', { path: '/experience' }, function () {
                this.route('index', { path: '/' });
                this.route('detail', { path: '/:id' }, function () {
                    this.route('index', { path: '/' });
                    this.route('address', { path: '/address' });
                    this.route('description', { path: '/description' });
                    this.route('events', { path: '/events' });
                    this.route('images', { path: '/images' });
                    this.route('statuses', { path: '/statuses' });
                });
            });
            this.route('experience-event', { path: '/experience-event' }, function () {
                this.route('index', { path: '/:id' });
            });
        });
        this.route('login', { path: '/login' });
        this.route('fa-code', { path: '/fa-code' });
    });

    exports['default'] = Router;
});