define('my-care-git-ember-portal/components/mc-image-cropper/step-two/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers) {
  exports['default'] = _ember['default'].Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
      // this.set('onCompleted', undefined);
      // this.set('onChangeStep', undefined);
      // this.set('file', undefined);
      this.set('cropBoxData', { width: 150, height: 150 });
      // this.set('ratio', undefined);
    },

    //   didReceiveAttrs(){
    //       // this.set('onCompleted', undefined);
    //       // this.set('onChangeStep', undefined);
    //       // this.set('file', undefined);
    //       this.set('cropBoxData', {width: 150, height: 150});
    //       // this.set('ratio', undefined);
    // },

    didInsertElement: function didInsertElement() {
      this._setup();
    },

    _setup: function _setup() {
      var _this = this;

      var $container = this.$('.crop-space');
      var $image = $container.find('img');
      var cropBoxData = this.get('cropBoxData');

      // set center position
      var left = $container.width() / 2 - cropBoxData.width / 2;
      var top = $container.height() / 2 - cropBoxData.height / 2;
      _ember['default'].set(cropBoxData, 'top', top);
      _ember['default'].set(cropBoxData, 'left', left);

      //init cropper
      var ratio = this.get('ratio') || 1;
      $($image[0]).on('ready', function () {
        _this.cropper.setCropBoxData(cropBoxData);
        // make background transparent
        _this.$('.cropper-container').css('backgroundColor', 'transparent');
        _this.$('.cropper-wrap-box').css('backgroundColor', 'transparent');
        _this.$('.cropper-drag-box').css('backgroundColor', 'transparent');
      });

      this.cropper = new Cropper($image[0], {
        aspectRatio: ratio,
        responsive: true,
        viewMode: 2,
        strict: false,
        guides: false,
        highlight: false,
        dragCrop: false,
        zoomable: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        background: false,
        restore: true
      });
    },

    actions: {
      rotateRight: function rotateRight() {
        if (this.cropper) {
          this.cropper.rotate(90);
        }
      },

      crop: function crop() {
        var canvas = this.cropper.getCroppedCanvas(this.get('cropBoxData'));
        var imageData = canvas.toDataURL();
        _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onCompleted'), imageData);
      },

      back: function back() {
        _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onChangeStep'), 1);
      }
    }
  });
});