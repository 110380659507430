define('my-care-git-ember-portal/components/mc-image-cropper/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-modal-component', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsMcModalComponent, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _myCareGitEmberPortalUtilsMcModalComponent['default'].extend({

        _modalHelper: _ember['default'].inject.service('utils/modal-helper'),

        init: function init() {
            this.set('cropBoxData', { width: 300, height: 300 });
            this.set('ratio', 1);
            this.set('_componentId', 'mc-image-cropper');
            this.set('_selectedFileData', undefined);
            this.set('_cropFileDataUrl', undefined);
            this.set('_isUploadCompleted', false);
            this.set('_stepRegistry', {
                one: true,
                two: false,
                three: false
            });
            this._super.apply(this, arguments);
        },

        _toStepOne: function _toStepOne() {
            this.set('_stepRegistry', {
                one: true,
                two: false,
                three: false
            });
        },

        _toStepTwo: function _toStepTwo() {
            this.set('_stepRegistry', {
                one: false,
                two: true,
                three: false
            });
        },

        _toStepThree: function _toStepThree() {
            this.set('_stepRegistry', {
                one: false,
                two: false,
                three: true
            });
        },

        willSetupOnce: function willSetupOnce() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('_modalHelper').onHide(this.get('_modalChannelId'), function () {
                _this._toStepOne();
                if (_this.get('_isUploadCompleted') === false) {
                    _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(_this.get('onCancelled'));
                }
            });
        },

        actions: {
            onSetupOneCompleted: function onSetupOneCompleted(file) {
                this.set('_selectedFileData', file);
                this._toStepTwo();
            },

            onSetupTwoCompleted: function onSetupTwoCompleted(fileUrl) {
                this.set('_cropFileDataUrl', fileUrl);
                this._toStepThree();
            },

            onSetupThreeCompleted: function onSetupThreeCompleted(fileUrl) {
                this.set('_isUploadCompleted', true);
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onCompleted'), fileUrl);
                this._close();
            },

            onChangeStep: function onChangeStep(stepNumber) {
                if (stepNumber === 1) {
                    this._toStepOne();
                } else if (stepNumber === 2) {
                    this._toStepTwo();
                } else if (stepNumber === 3) {
                    this._toStepThree();
                } else {
                    console.error('unkown mc-image-cropper step: ' + stepNumber);
                }
            },

            cancel: function cancel() {
                this._close();
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onCancelled'));
            }
        }
    });
});