define('my-care-git-ember-portal/index/users/client/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = this.controllerFor('index.users.index');
            var radioItems = [{ id: 'worker', 'name': 'Worker', isSelected: false }, { id: 'seeker', 'name': 'Organiser', isSelected: false }, { id: 'client', 'name': 'Client', isSelected: true }, { id: 'business', 'name': 'Business', isSelected: false }, { id: 'archived', 'name': 'Archived', isSelected: false }, { id: 'all', 'name': 'All', isSelected: false }];
            controller.set('radioItems', radioItems);
            controller.reset();
            controller.set('isShowClientType', true);

            self.render('index.users.index', {
                controller: controller
            });
            controller.set('searchString', '');
            if (_ember['default'].isEmpty(controller.get('_people'))) {
                controller.getSearchResults(controller.get('_pageSize'), 0, '', 'owner').then(function (people) {
                    controller.set('_people', people);
                    controller.set('filterResult', people);
                    controller.set('_currentAmount', people.get('length') > controller.get('_pageSize') ? controller.get('_pageSize') : people.get('length'));
                    controller.set('_totalAmount', people.get('meta.total'));
                    if (_ember['default'].isEmpty(controller.get('searchString')) === false) {
                        controller.doFilter();
                    }
                });
            }
        }
    });
});