define('my-care-git-ember-portal/models/vaccination-status', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        //id: DS.attr('number'),
        personId: _emberData['default'].attr('number'),
        personName: _emberData['default'].attr('string'),
        covidVaccinationTypeId: _emberData['default'].attr('number'),
        covidVaccinationType: _emberData['default'].attr('string'),
        covidVaccinationDoseId: _emberData['default'].attr('number'),
        covidVaccinationDoseName: _emberData['default'].attr('string'),
        vaccinationDate: _emberData['default'].attr('string'),
        vaccinationExpiryDate: _emberData['default'].attr('string'),
        nextVaccinationDate: _emberData['default'].attr('string'),
        isCertificateVerified: _emberData['default'].attr('boolean'),
        certificateVerifiedById: _emberData['default'].attr('string'),
        certificateVerifiedBy: _emberData['default'].attr('string'),
        certificateVerifiedDate: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        certificateFiles: _emberData['default'].attr()
    });
});