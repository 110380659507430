define('my-care-git-ember-portal/components/mc-responsive-timeline/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        _store: _ember['default'].inject.service('store'),
        timelineSorting: ['activityOn:desc'],
        sortedTimelines: _ember['default'].computed.sort('model', 'timelineSorting'),
        reorderTimelines: [],
        isAutoLoad: false,
        tagName: '',

        autoLoadFunction: function autoLoadFunction() {
            var self = this;
            if (self.get('isAutoLoad') === true) {
                _ember['default'].run.later(function () {
                    self.loadData();
                    self.autoLoadFunction();
                }, 10000);
            }
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this.loadData();
        },

        loadData: function loadData() {
            var self = this;
            this.get('_store').findAll('activity', { reload: true }).then(function (activities) {
                self.set('model', activities);
            });
        },

        watchIsAutoLoad: (function () {
            var self = this;
            self.autoLoadFunction();
        }).observes('isAutoLoad'),

        actions: {
            switchAutoLoad: function switchAutoLoad() {
                this.set('isAutoLoad', !this.get('isAutoLoad'));
            }
        }

    });
});