define('my-care-git-ember-portal/components/mc-account-type/row/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _emberConcurrency) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        typeStyle: '',
        _store: _ember['default'].inject.service('store'),
        _routingService: _ember['default'].inject.service('routing'),
        accountFees: [],

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.get('LoadAccountTypeRates').perform();
        },

        LoadAccountTypeRates: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var accountFees;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').query('account-fee', { accountTypeId: this.get('accountType.id') });

                    case 2:
                        accountFees = context$1$0.sent;

                        this.set('accountFees', accountFees);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            showEditAccountTypeModal: function showEditAccountTypeModal(accountType) {
                this.get('eventbus').publish('mc-update-account-type-modal:on:open:' + this.get('_channelId'), { accountType: accountType });
            }
        }
    });
});