define('my-care-git-ember-portal/services/profile-service', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Service.extend({
        _store: _ember['default'].inject.service('store'),
        /**
         * Get person by given id
         * @param {string} personId id of the person
         * @return {Promise} person with given id
         */
        getPersonByIdAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var personId = _ref.personId;
            var _ref$reload = _ref.reload;
            var reload = _ref$reload === undefined ? false : _ref$reload;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findRecord('person', personId, { reload: reload });

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});