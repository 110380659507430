define('my-care-git-ember-portal/index/experience/detail/route', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Route.extend({

        statusService: _ember['default'].inject.service('data-service/experience-event-template-status'),
        model: function model(params) {
            return this.get('getModel').perform(params.id);
        },

        getModel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(templateId) {
            var statuses, template;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('statusService.getByTemplateId').perform(templateId);

                    case 2:
                        statuses = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.store.findRecord('experience-event-template', templateId, { reload: true });

                    case 5:
                        template = context$1$0.sent;
                        return context$1$0.abrupt('return', _ember['default'].Object.create({ template: template, statuses: statuses.toArray() }));

                    case 7:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setup();
        }
    });
});