define('my-care-git-ember-portal/helpers/time-difference', ['exports', 'ember'], function (exports, _ember) {

  function timeDifference(data) {
    var endDate = data[0],
        startDate = data[1];

    if (_ember['default'].isBlank(endDate) || _ember['default'].isBlank(startDate)) {
      return '';
    }

    var duration = moment.duration(moment(endDate).diff(moment(startDate)));
    var minutes = duration.asMinutes();
    var hours = minutes / 60;

    if (hours === 1) {
      return hours + ' hr';
    }
    return hours + ' hrs';
  }

  exports.timeDifference = timeDifference;
  exports['default'] = _ember['default'].Helper.helper(timeDifference);
});