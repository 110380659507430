define("my-care-git-ember-portal/components/mc-search-bar/distance/component", ["exports", "ember", "my-care-git-ember-portal/utils/common", "my-care-git-ember-portal/utils/mc-component", "my-care-git-ember-portal/utils/function-helpers"], function (exports, _ember, _myCareGitEmberPortalUtilsCommon, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('parentId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
            this.initVar('buttonId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
            this.initVar('isVolunteer', false);
            this.initVar('min', 10);
            this.initVar('step', 10);
            this.initVar('max', 40);
        },

        buttonSelectionText: (function () {
            // var self = this;
            if (_ember["default"].isEmpty(this.get('selectValue'))) {
                return '';
            } else {
                return "(" + this.get('selectValue') + " kilometers)";
            }
        }).property('selectValue'),

        didRender: function didRender() {
            _ember["default"].$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
            this.set('isComponentReady', true);
        },

        actions: {
            open: function open() {
                _ember["default"].$('.dropdown.open').removeClass('open');
                _ember["default"].$('#' + this.get('parentId')).addClass('open');
            },

            updateValue: function updateValue(value) {
                if (this.get('isComponentReady') === true) {
                    this.set('selectValue', parseInt(value));
                }
            },

            applyValue: function applyValue() {
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onSetDistance'), this.get('selectValue'));
                this.set('isUpdated', true);
                _ember["default"].$('.dropdown.open').removeClass('open');
            },

            cancelValue: function cancelValue() {
                this.set('selectValue', null);
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onSetDistance'), this.get('selectValue'));
                this.set('isUpdated', false);
                _ember["default"].$('.dropdown.open').removeClass('open');
            }

        }
    });
});