define('my-care-git-ember-portal/index/funder/transactions/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {

        model: function model() {
            var controller = this.controllerFor('index.funder.transactions');
            if (_ember['default'].isEmpty(controller.get('transactions'))) {
                return this.store.query('transaction', { accountId: 1, pageSize: 50, offset: 0 });
            }
        },
        setupController: function setupController(controller, model) {
            if (_ember['default'].isEmpty(model) === false) {
                controller.set('transactions', model);
                controller.set("_currentAmount", model.get('length'));
                controller.set("_totalAmount", model.get('meta.total'));
            }
        }
    });
});