define('my-care-git-ember-portal/index/users/detail/worker-report/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        sortProps: ['actualStartsOn:desc'],
        sortedTimesheets: _ember['default'].computed.sort('timesheets', 'sortProps'),
        totalPayment: 0,
        selectedFromDate: '',
        selectedToDate: '',
        httpService: _ember['default'].inject.service('http'),
        isWorker: true,
        timesheets: [],
        isLoading: false,
        hasDatePickerError: false,

        isMobile: (function () {
            return this.get('env').isMobile();
        }).property().volatile(),

        addWorkerTax: (function () {
            this.get('timesheets').forEach(function (timesheet) {
                timesheet.set('totalWorkerBeforeTax', timesheet.get('totalCostWorker') + timesheet.get('workerTax'));
            });
        }).observes('timesheets.length'),

        setup: function setup() {
            var self = this;
            self._initStartTimeAndEndTime();
            self.set('startDate', moment(self.get('selectedFromDate'), 'DD/MM/YYYY'));
            self.set('endDate', moment(self.get('selectedToDate'), 'DD/MM/YYYY'));

            self.store.query('person', {
                status: 'contracted',
                isWorker: self.get('isWorker'),
                id: self.get('model.person.id')
            }, { reload: true }).then(function (clients) {
                if (self.isDestroyed) {
                    return;
                }
                self.set('people', clients);
            });
        },

        downloadCsv: function downloadCsv(from, to, clientId, contractorId) {
            var self = this;
            var apiUrl = this.get('env').getApp('API_HOST');
            var url = apiUrl + '/api/timesheets?accountId=' + this.get("model.id") + '&contractorPersonId=' + contractorId + '&clientPersonId=' + clientId + '&endsOn=' + to + '&startsOn=' + from + '&isClientAccount=false&generateCsv=true';
            var filename = 'mycare-report-' + moment().format('DD-MM-YYYY') + '.csv';
            this.get('httpService').withAuthentication(function () {
                _ember['default'].$.ajax({
                    url: url,
                    success: function success(data) {
                        if (this.isDestroyed) {
                            return;
                        }
                        var file = new File([data], filename, {
                            lastModified: new Date(0), // optional - default = now
                            type: "application/csv" // optional - default = ''
                        });
                        var fr = new FileReader();
                        fr.readAsText(file);
                        self.set('fileUrl', URL.createObjectURL(file));
                        self.set('fileName', filename);
                    }
                });
            });
        },

        actions: {
            print: function print() {
                window.print();
            },

            openDetail: function openDetail(timesheetId) {
                this.set('openTimesheetId', timesheetId);
                if (_ember['default'].$('.' + timesheetId).hasClass('in')) {
                    if (timesheetId === this.get('openTimesheetId')) {
                        this.set('openTimesheetId', null);
                    }
                    return;
                }
                _ember['default'].$('.transaction-table .collapse').each(function () {
                    var $this = $(this);
                    if ($this.attr('id') !== String(timesheetId)) {
                        $this.removeClass('in');
                    }
                });
                return false;
            }
        },

        watchSelectedChanges: (function () {
            var self = this;
            this.set('hasDatePickerError', false);
            if (_ember['default'].isEmpty(self.get('selectedFromDate')) === false && _ember['default'].isEmpty(self.get('selectedToDate')) === false && self.get('isLoading') === false) {
                self.set('startDate', moment(self.get('selectedFromDate'), 'DD/MM/YYYY'));
                self.set('endDate', moment(self.get('selectedToDate'), 'DD/MM/YYYY'));
                if (moment(this.get('endDate')).add(-1, 'day').isBefore(this.get('startDate'))) {
                    this.set('hasDatePickerError', true);
                    return;
                }
                self.getReports(self.get('startDate').startOf('day'), moment(self.get('selectedToDate'), 'DD/MM/YYYY').endOf('day'), self.get('selectedPerson'));
            }
        }).observes('selectedFromDate', 'selectedToDate', 'selectedPerson'),

        getReports: function getReports(startsOn, endsOn, selectedPerson) {
            var self = this;
            var clientPersonId = null;
            if (_ember['default'].isEmpty(selectedPerson) === false) {
                clientPersonId = selectedPerson.get('id');
            }
            self.set('isLoading', true);
            self.get('timesheets').clear();

            var queryObject = {
                accountId: self.get('model.id'),
                clientPersonId: clientPersonId,
                contractorPersonId: self.get('model.person.id'),
                startsOn: startsOn.utc().toISOString(),
                endsOn: endsOn.utc().toISOString(),
                isClientAccount: false
            };
            if (_ember['default'].isEmpty(selectedPerson)) {
                delete queryObject['clientPersonId'];
            }

            self.store.query('timesheet', queryObject, { reload: true }).then(function (timesheets) {
                if (self.isDestroyed) {
                    return;
                }
                if (_ember['default'].isEmpty(timesheets) === false) {
                    self.set('thisReport', self.store.peekRecord('account-report', 1));
                    self.get('timesheets').pushObjects(timesheets.toArray());
                    if (selectedPerson) {
                        self.downloadCsv(startsOn.utc().toISOString(), endsOn.utc().toISOString(), clientPersonId, self.get('model.person.id'));
                    } else {
                        self.downloadCsv(startsOn.utc().toISOString(), endsOn.utc().toISOString(), null, self.get('model.person.id'));
                    }
                }
                self.set('isLoading', false);
            });
        },

        setTotalPayment: function setTotalPayment(timesheets) {
            var self = this;
            self.set('totalPayment', 0);
            timesheets.forEach(function (timesheet) {
                self.set('totalPayment', self.get('totalPayment') + timesheet.get('estimateWorkerNetPay'));
            });
        },

        _initStartTimeAndEndTime: function _initStartTimeAndEndTime() {
            var self = this;
            //        if (Ember.isEmpty(self.get('selectedFromDate')) && Ember.isEmpty(self.get('selectedFromDate'))) {
            self.set('selectedFromDate', moment().startOf('month').format('DD/MM/YYYY'));
            self.set('selectedToDate', moment().endOf('month').format('DD/MM/YYYY'));
            //        }
        }
    });
});