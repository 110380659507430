define('my-care-git-ember-portal/components/mc-user-detail/moj/trust/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({
        tagName: '',
        isLoading: false,
        _store: _ember['default'].inject.service('store'),
        init: function init() {
            //public attributes
            this._super.apply(this, arguments);
        },

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.set('isLoading', true);
            self.set('doseType', '0');
            self.get('_store').findAll('covid-vaccination-dose-type').then(function (vaccinationDoses) {
                self.set('vaccinationDoses', vaccinationDoses);

                self.set('vaccinationState', null);
                self.get('_store').findRecord('covid-vaccination', self.get("model.id")).then(function (vaccinationState) {
                    self.set('doseType', vaccinationState.get('covidVaccinationDoseId').toString());
                    self.set('vaccinationState', vaccinationState);
                    self.set('isLoading', false);
                });
            });
        },

        saveTrust: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(trust) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return trust.save();

                    case 2:
                        toastr.info('Vetting status is saved');

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveVaccinationStatus: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var self, covidVaccinations;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;

                        self.set('isSaving', true);
                        covidVaccinations = this.get('_store').createRecord('covid-vaccination', {
                            personId: this.get("model.id"),
                            covidVaccinationDoseId: parseInt(this.get('doseType'))
                        });

                        if (this.get('vaccinationState')) {
                            this.get('vaccinationState').covidVaccinationDoseId = this.get('doseType');
                        }
                        context$1$0.next = 6;
                        return covidVaccinations.save().then(function (vaccinationState) {
                            self.set('isSaving', false);
                            self.set('vaccinationState', vaccinationState);
                            toastr.info('Vaccination status is saved');
                        }, function (error) {
                            self.set('isSaving', false);
                            toastr.info('Vaccination status not saved');
                            self._addValidationError('api', 'Vaccination status already updated');
                        });

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});