define('my-care-git-ember-portal/components/mc-star-rating/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/guid-creater', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalMixinsGuidCreater, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsGuidCreater['default'], {
        tagName: '',

        /**
         * Initialise component at startup
         */
        init: function init() {
            this._super();
            var self = this;
            self.set('selectComponentId', self.getGuid());
        },
        didRender: function didRender() {
            var _this = this;

            var ratingComponent = _ember['default'].$('#' + this.get('selectComponentId'));
            var isFullStar = this.get('isFullStar') ? this.get('isFullStar') : true;
            var isReadOnly = this.get('isReadOnly') ? this.get('isReadOnly') : false;
            ratingComponent.rateYo({
                fullStar: isFullStar,
                readOnly: isReadOnly
            }).on("rateyo.set", function (e, data) {
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(_this.get('onSetRate'), data.rating);
            });
            /* set the option `multiColor` to show Multi Color Rating */
            // ratingComponent.rateYo("rating", this.get('rating')? this.get('rating'): 0);
            ratingComponent.rateYo("option", "multiColor", true);
        },

        watchRating: (function () {
            var ratingComponent = _ember['default'].$('#' + this.get('selectComponentId'));
            ratingComponent.rateYo("rating", this.get('rating') ? this.get('rating') : 0);
        }).observes('rating')
    });
});