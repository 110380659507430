define('my-care-git-ember-portal/models/work-and-income', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        trustType: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        dateOfBirth: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        lastLoginOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        jobApplications: _emberData['default'].attr('number'),
        hoursWorked: _emberData['default'].attr('number'),
        overEightWeeks: (function () {
            var lastLogin = moment.utc(this.get('lastLoginOn'));
            if (moment.utc().diff(lastLogin, 'days') > 8) {
                return lastLogin.format('DD MMM YYYY');
            } else {
                return lastLogin.fromNow();
            }
        }).property('lastLoginOn'),

        getTrustType: (function () {
            // var lastLogin = moment.utc(this.get('lastLoginOn'));
            if (this.get('trustType') === 'Not started') {
                return 'None';
            } else {
                return this.get('trustType');
            }
        }).property('trustType')
    });
});