define('my-care-git-ember-portal/components/mc-joblisting-editor/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent) {
  exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.initVar('jobListing', null);
      this.initVar('person', null);
      this.initVar('onFinished', null);
      this.initVar('isPaymentRequired', false);
      this.initVar('requiredPaymentType', null);
      this.initVar('isShowBasicForm', true);
      this.initVar('formBasicErrors', {});
      this.initVar('formServicesErrors', {});
      this.initVar('formDetailError', {});
      this.initVar('_currentTab', '#basic');
    },

    didInsertElement: function didInsertElement() {
      var self = this;
      this.$('a[data-toggle="tab"]').click(function () {
        self.switchTab($(this).attr('href'));
        return true;
      });
      this.switchTab('#basic');
    },

    isDssPaymentRequired: (function () {
      return this.get('requiredPaymentType') === 'dss';
    }).property('requiredPaymentType'),

    isPrivatePaymentRequired: (function () {
      return this.get('requiredPaymentType') === 'private';
    }).property('requiredPaymentType'),

    isPublishingEnabled: (function () {
      return this.get('isAllFormsValid') === true && this.get('isPaymentRequired') === false;
    }).property('isAllFormsValid', 'isPaymentRequired'),

    errorCounts: (function () {
      return _ember['default'].A([this.get('isBasicFormValid'), this.get('isServicesFormValid'), this.get('isAvailabilitiesFormValid'), this.get('isDetailsFormValid')]).filter(function (item) {
        return item === false;
      }).get('length');
    }).property('isBasicFormValid', 'isServicesFormValid', 'isAvailabilitiesFormValid', 'isDetailsFormValid', 'isPaymentRequired'),

    isReady: (function () {
      return this.get('isAllFormsValid') !== undefined && this.get('isPublishingEnabled') !== undefined;
    }).property('isAllFormsValid', 'isPublishingEnabled'),

    isAllFormsValid: (function () {
      return this.get('isBasicFormValid') && this.get('isServicesFormValid') && this.get('isDetailsFormValid') && this.get('isAvailabilitiesFormValid');
    }).property('isBasicFormValid', 'isServicesFormValid', 'isDetailsFormValid', 'isAvailabilitiesFormValid'),

    showNoti: function showNoti() {
      var _this = this;

      if (this.isDestroyed) {
        return;
      }
      this.set('isSaved', true);
      _ember['default'].run.later(function () {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('isSaved', false);
      }, 3000);
    },

    switchTab: function switchTab(tabId) {
      this.set('_currentTab', tabId);
      return false;
    },

    actions: {
      onJobListingStatusUpdated: function onJobListingStatusUpdated() {
        if (this.isDestroyed) {
          return;
        }
        this.showNoti();
        this.set('reloadBasicForm', new Date().getTime());
      },

      onBasicTabSavedEvent: function onBasicTabSavedEvent() {
        if (this.isDestroyed) {
          return;
        }
        this.showNoti();
        this.set('onJoblistingEdited', new Date().getTime());
      },

      onServicesTabSavedEvent: function onServicesTabSavedEvent() {
        if (this.isDestroyed) {
          return;
        }
        this.showNoti();
      },

      onDetailsTabSavedEvent: function onDetailsTabSavedEvent() {
        if (this.isDestroyed) {
          return;
        }
        this.showNoti();
      },

      onAvailabilityTabSavedEvent: function onAvailabilityTabSavedEvent() {
        if (this.isDestroyed) {
          return;
        }
        this.showNoti();
      }
    }
  });
});