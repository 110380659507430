define('my-care-git-ember-portal/models/contract', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        account: _emberData['default'].belongsTo('account', { async: true }),
        timesheets: _emberData['default'].hasMany('timesheet', { async: true }),
        clientPerson: _emberData['default'].belongsTo('person', { async: true, inverse: 'clientContracts' }),
        contractorPerson: _emberData['default'].belongsTo('person', { async: true, inverse: 'contracts' }),
        contractorSignedOn: _emberData['default'].attr('string'),
        contractName: _emberData['default'].attr('string'),
        clientSignedOn: _emberData['default'].attr('string'),
        contractStartsOn: _emberData['default'].attr('string'),
        contractEndsOn: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        createdPerson: _emberData['default'].belongsTo('person', { async: true, inverse: null }),
        hourlyRate: _emberData['default'].attr('number'),
        nightlyRate: _emberData['default'].attr('number'),
        contractDetail: _emberData['default'].belongsTo('contract-detail', { async: true }),
        thread: _emberData['default'].belongsTo('thread', { async: true }),

        isNewNightlyRate: (function () {
            var self = this;
            return Ember.isEmpty(self.get('newNightlyRate.content')) === false;
        }).property('newNightlyRate'),

        isNewHourlyRate: (function () {
            var self = this;
            return Ember.isEmpty(self.get('newHourlyRate.content')) === false;
        }).property('newHourlyRate'),

        isNewRate: (function () {
            return this.get('isNewNightlyRate') || this.get('isNewHourlyRate');
        }).property('isNewNightlyRate', 'isNewHourlyRate'),

        isNewContact: (function () {
            var self = this;
            return Ember.isEmpty(self.get('contractorSignedOn'));
        }).property('contractorSignedOn', 'contractEndsOn'),

        getContractStatus: (function () {
            if (!Ember.isEmpty(this.get('contractorSignedOn')) && !Ember.isEmpty(this.get('contractEndsOn')) && moment().diff(this.get('contractEndsOn')) > 0) {
                return 'danger';
            } else if (!Ember.isEmpty(this.get('contractorSignedOn'))) {
                return 'success';
            } else {
                return 'warning';
            }
        }).property('contractorSignedOn', 'contractEndsOn')

    });
});