define('my-care-git-ember-portal/services/search', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        _store: _ember['default'].inject.service('store'),
        searchParameter: {},

        joinBoundsTpSearchParameter: function joinBoundsTpSearchParameter(bounds, location, distance) {
            var self = this;
            var searchParameter = self.get('searchParameter');

            if (bounds.eastLng) {
                searchParameter.eastLng = bounds.eastLng;
            }

            if (bounds.southLat) {
                searchParameter.southLat = bounds.southLat;
            }

            if (bounds.westLng) {
                searchParameter.westLng = bounds.westLng;
            }

            if (bounds.northLat) {
                searchParameter.northLat = bounds.northLat;
            }

            if (location) {
                searchParameter.location = location;
            }

            if (distance) {
                searchParameter.distance = distance;
            }

            return searchParameter;
        },

        queryProfiles: function queryProfiles(searchParameter) {
            var reload = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

            var self = this;
            return self.get('_store').query('search-profile', searchParameter, reload);
        },

        queryJobs: function queryJobs(searchParameter) {
            var reload = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

            var self = this;

            return self.get('_store').query('search-job-listing', searchParameter, reload);
        }

    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */