define('my-care-git-ember-portal/components/mc-joblisting-editor/required/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        _store: _ember['default'].inject.service('store'),
        div_id: 'joblisting-required',

        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('onSaved', null);
            this.initVar('_equipmentOptions', []);
            this.initVar('_requirementOptions', []);
            this.initVar('_languageOptions', []);
            this.initVar('isSaving', false);
            this.initVar('isFormValid', undefined);
            this.initVar('_isInitialised', false);
            this.initVar('_formData', {
                idealWorker: null,
                equipment: [],
                requirements: [],
                languages: []
            });
        },

        isDisabledSaveButton: (function () {
            if (this.get('isFormDirty') === false) {
                return true;
            } else if (this.get('isSaving') === true) {
                return true;
            }
            return false;
        }).property('isSaving', 'isFormDirty'),

        titleEquipment: (function () {
            var totalItems = this.get('_formData.equipment.length');
            var count = totalItems ? '(' + totalItems + ')' : '';
            return 'Equipment experience ' + count;
        }).property('_formData.equipment.[]'),

        titleRequirement: (function () {
            var totalItems = this.get('_formData.requirements.length');
            var count = totalItems ? '(' + totalItems + ')' : '';
            return 'My worker must have ' + count;
        }).property('_formData.requirements.[]'),

        titleLanguage: (function () {
            var totalItems = this.get('_formData.languages.length');
            var count = totalItems ? '(' + totalItems + ')' : '';
            return 'Languages ' + count;
        }).property('_formData.languages.[]'),

        didReceiveAttrs: function didReceiveAttrs() {
            if (!this.get('_isInitialised')) {
                this.setup();
            }
        },

        setup: function setup() {
            var _this = this;

            this.get('_store').findAll('equipment').then(function (equipment) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_equipmentOptions', equipment);
                _this.set('_formData.equipment', _this.get('jobListing.equipment').toArray());
                //always true as there is no valiation required
                _this.set('isFormValid', true);
            });

            this.get('_store').findAll('requirement').then(function (requirements) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_requirementOptions', requirements);
                _this.set('_formData.requirements', _this.get('jobListing.requirements').toArray());
            });

            this.get('_store').findAll('language').then(function (languages) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_languageOptions', languages);
                _this.set('_formData.languages', _this.get('jobListing.languages').toArray());
            });

            _ember['default'].run.later(function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_formData.idealWorker', _this.get('jobListing.idealWorker'));
                _this.set('_isInitialised', true);
                _this.set('isFormDirty', false);
            }, 1000);
        },

        updateModel: function updateModel() {
            var jobListing = this.get('jobListing');
            jobListing.set('equipment', this.get('_formData.equipment'));
            jobListing.set('languages', this.get('_formData.languages'));
            jobListing.set('requirements', this.get('_formData.requirements'));
            jobListing.set('idealWorker', this.get('_formData.idealWorker'));
            return jobListing;
        },

        watchFormDirty: (function () {
            var _this2 = this;

            if (this.get('_isInitialised') === false) {
                return;
            }
            _ember['default'].RSVP.hash({
                equipment: this.get('jobListing.equipment'),
                languages: this.get('jobListing.languages'),
                requirements: this.get('jobListing.requirements')
            }).then(function (_ref) {
                var equipment = _ref.equipment;
                var languages = _ref.languages;
                var requirements = _ref.requirements;

                if (_this2.isDestroyed) {
                    return;
                }
                var getIds = function getIds(list) {
                    return (list || _ember['default'].A()).map(function (it) {
                        return it.id;
                    }).sort();
                };
                var isDirty = _this2.get('_formData.idealWorker') !== _this2.get('jobListing.idealWorker') || !_.isEqual(getIds(equipment), getIds(_this2.get('_formData.equipment'))) || !_.isEqual(getIds(languages), getIds(_this2.get('_formData.languages'))) || !_.isEqual(getIds(requirements), getIds(_this2.get('_formData.requirements')));
                _this2.set('isFormDirty', isDirty);
            });
        }).observes('_formData.equipment.[]', '_formData.languages.[]', '_formData.requirements.[]', '_formData.idealWorker'),

        Handle502: function Handle502(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('jobListing').reload();
                self.redirectTo('index.job-listings.index');
                toastr.error('Please check is job listing saved, and contact Mycare development team.');
            }
        },

        actions: {
            onSelected: function onSelected(category, selectedItems) {
                this.set('_formData.' + category, selectedItems);
            },

            save: function save() {
                var _this3 = this;

                var self = this;
                this.set('isSaving', true);
                this.updateModel().save().then(function (jobListing) {
                    if (_this3.isDestroyed) {
                        return;
                    }
                    _this3.set('jobListing', jobListing);
                    _this3.set('isSaving', false);
                    _this3.set('isFormDirty', false);
                    _this3.sendAction('onSaved', jobListing);
                }, function (error) {
                    self.Handle502(error);
                });
            }
        }
    });
});