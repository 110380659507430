define('my-care-git-ember-portal/components/mc-multi-select/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        componentList: [],
        isLoadedData: false,
        didInsertElement: function didInsertElement() {
            this.set('isEnableUpdate', false);
        },

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            if (_ember['default'].isEmpty(self.get('items')) === false && self.get('isLoadedData') === false) {
                self.set('componentList', self.get('items'));
                self.get('componentList').forEach(function (item) {
                    item.set('isChecked', false);
                });

                if (self.get('selectedValues.length') > 0) {
                    self.get('selectedValues').forEach(function (selectedItem) {
                        self.get('componentList').forEach(function (item) {
                            if (item.get('id') === selectedItem.get('id')) {
                                item.set('isChecked', true);
                            }
                        });
                    });
                }
                self.set("isLoadedData", true);
            }
        },

        reloadMultiSelect: (function () {
            var self = this;
            if (!_ember['default'].isEmpty(self.get('items'))) {
                self.set('componentList', self.get('items'));
                if (self.get('selectedValues.length') > 0) {
                    self.get('selectedValues').forEach(function (selectedItem) {
                        self.get('componentList').forEach(function (item) {
                            if (item.get('id') === selectedItem.get('id')) {
                                item.set('isChecked', true);
                            } else {
                                item.set('isChecked', false);
                            }
                        });
                    });
                } else {
                    self.get('componentList').forEach(function (item) {
                        item.set('isChecked', false);
                    });
                }
            }
        }).observes('items'),

        reloadSelectedValues: function reloadSelectedValues() {
            var self = this;
            self.set('isEnableUpdate', false);
            self.get('componentList').forEach(function (item) {
                item.set('isChecked', false);
            });
            if (_ember['default'].isEmpty(self.get('selectedValues')) === false) {
                self.get('selectedValues').forEach(function (selectedItem) {
                    self.get('componentList').forEach(function (item) {
                        if (!_ember['default'].isNone(selectedItem) && item.get('id') === selectedItem.get('id')) {
                            item.set('isChecked', true);
                        }
                    });
                });
            }
            self.set('isEnableUpdate', true);
        },

        update: (function () {
            var self = this;
            if (self.get('isEnableUpdate') === true) {
                self.get('selectedValues').clear();
                var list = self.get('componentList').filterBy('isChecked', true);
                if (list.get('length') > 0) {
                    this.set('hasError', false);
                }
                self.get('selectedValues').pushObjects(list);
            }
        }).observes('componentList.@each.isChecked'),

        actions: {
            closeModal: function closeModal() {
                var self = this;
                _ember['default'].$('#' + self.get('modalId')).modal('hide');
            },

            toggleSelectionModal: function toggleSelectionModal() {
                var self = this;
                _ember['default'].$('#' + self.get('modalId')).modal('show');
                self.reloadSelectedValues();
            },

            removeItem: function removeItem(id) {
                var self = this;
                self.get('componentList').forEach(function (item) {
                    if (item.get('id') === id) {
                        item.set('isChecked', !item.get('isChecked'));
                        self.get('selectedValues').removeObject(item);
                    }
                });
            }
        }
    });
});