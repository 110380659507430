define('my-care-git-ember-portal/index/account-fees/route', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  exports['default'] = _ember['default'].Route.extend({
    _accountFeeService: _ember['default'].inject.service('account-fee-service'),

    model: function model(params) {
      return this.get('modelAsync').perform(params.account_type_id);
    },

    modelAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(accountTypeId) {
      var accountType, accountFeeGroups;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('_accountFeeService.getAccountTypeById').perform(accountTypeId);

          case 2:
            accountType = context$1$0.sent;
            context$1$0.next = 5;
            return this.get('_accountFeeService.getAccountFeesGroupByFeeTypeByAccountId').perform({ accountTypeId: accountTypeId, reload: true });

          case 5:
            accountFeeGroups = context$1$0.sent;
            return context$1$0.abrupt('return', { accountType: accountType, accountFeeGroups: accountFeeGroups });

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),
    actions: {
      refreshCurrentRoute: function refreshCurrentRoute() {

        this.refresh();
      }
    }
  });
});