define('my-care-git-ember-portal/index/job-listings/detail/helprequired/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            save: function save() {
                toastr.info('Job listing updated.');
            },
            redirectTo: function redirectTo(route) {
                this.transitionToRoute(route);
            }
        }

    });
});