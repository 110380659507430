define('my-care-git-ember-portal/fa-code/controller', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        loginController: _ember['default'].inject.controller('login'),
        authController: _ember['default'].inject.controller('auth'),
        serviceService: _ember['default'].inject.service('login'),
        countDown: 60,
        isButtonDisabled: true,
        isEnableWatch: false,
        decreaseASecond: function decreaseASecond() {
            var self = this;
            if (self.get('countDown') > 1) {
                self.set('countDown', self.get('countDown') - 1);
                _ember['default'].run.later(function () {
                    self.decreaseASecond();
                }, 1000);
            } else {
                this.set('isButtonDisabled', false);
            }
        },

        actions: {
            loginWithCode: function loginWithCode() {
                var self = this;
                self.set('error', '');
                if (self.validationForm()) {
                    self.set('isLoading', true);
                    self.get('loginController.login');
                    var loginData = this.get('loginController.login');
                    loginData.username = loginData.email;
                    loginData.grant_type = 'password';
                    loginData.code = this.get('code');
                    self.get('serviceService').doAuthenticate(loginData).then(function () {
                        self.set('authController.isLoggedIn', true);
                        // Transition to previous page or homepage
                        self.set('isLoading', false);
                        var previousTransition = localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.REDIRECT_URL);
                        if (previousTransition) {
                            self.transitionToRoute(previousTransition);
                            localStorage.removeItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.REDIRECT_URL);
                        } else {
                            self.transitionToRoute('index.dashboard');
                        }
                    })['catch'](function () {
                        self.set('isLoading', false);
                        self.set('error', 'The code is incorrect.');
                    });
                }
            },

            reSendCode: function reSendCode() {
                var self = this;
                var login = this.get('loginController.login');
                login.username = login.email;
                login.grant_type = 'password';
                delete login['code'];
                self.get('serviceService').doAuthenticate(login).then(function () {})['catch'](function () {
                    self.set('isLoading', false);
                    self.set('countDown', 60);
                    self.set('isButtonDisabled', true);
                    self.decreaseASecond();
                });
            }
        },

        validationForm: function validationForm() {
            var self = this;
            var bool = true;
            self.set('error', '');
            if (_ember['default'].isEmpty(self.get('code'))) {
                self.set('error', 'Code is required.');
                bool = false;
            } else if (self.get('code.length') !== 6) {
                self.set('error', 'Code must be 6 digits.');
                bool = false;
            }
            return bool;
        }
    });
});