define('my-care-git-ember-portal/models/account-fee', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        rate: _emberData['default'].attr('number'),
        startsOn: _emberData['default'].attr('string'),
        accountFeeTypeId: _emberData['default'].attr('string'),
        accountFeeTypeName: _emberData['default'].attr('string'),
        accountTypeId: _emberData['default'].attr('string'),
        accountTypeName: _emberData['default'].attr('string'),
        endsOn: _emberData['default'].attr('string'),
        accountType: _emberData['default'].belongsTo('accountType', { async: true }),
        createdOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        accountFeeType: _emberData['default'].belongsTo('accountFeeType', { async: true }),
        rateInNumber: (function () {
            return parseFloat(this.get('rate') * 100).toFixed(2);
        }).property('rate'),

        startOfStartsOn: (function () {
            return moment.utc(this.get('startsOn')).startOf('day').toISOString();
        }).property('startsOn'),

        startOfEndsOn: (function () {
            return moment.utc(this.get('endsOn')).startOf('day').toISOString();
        }).property('endsOn')

    });
});