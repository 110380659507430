define('my-care-git-ember-portal/models/job-listing', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        person: _emberData['default'].belongsTo('person', { async: true, inverse: 'jobListings' }),
        organiser: _emberData['default'].belongsTo('person', { async: true }),
        isPublished: _emberData['default'].attr('boolean'),
        title: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        idealWorker: _emberData['default'].attr('string'),
        isShareable: _emberData['default'].attr('boolean'),
        isRequiredMoj: _emberData['default'].attr('boolean'),
        helpRequired: _emberData['default'].attr('string'),
        when: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        images: _emberData['default'].hasMany('file', { async: true }),
        hourlyRate: _emberData['default'].belongsTo('hourly-rate', { async: true }),
        isHourlyRate: _emberData['default'].attr('boolean'),
        hourlyRateAmount: _emberData['default'].attr('number'),
        hoursPerWeek: _emberData['default'].belongsTo('hours-per-week', { async: true }),
        location: _emberData['default'].attr('string'),
        latitude: _emberData['default'].attr('number'),
        longitude: _emberData['default'].attr('number'),
        startsOn: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        closesOn: _emberData['default'].attr('string'),
        endsOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        availabilities: _emberData['default'].hasMany('availability', { async: true }),
        languages: _emberData['default'].hasMany('language', { async: true }),
        requirements: _emberData['default'].hasMany('requirement', { async: true }),
        services: _emberData['default'].hasMany('service', { async: true }),
        applications: _emberData['default'].hasMany('job-application', { async: true }),
        equipment: _emberData['default'].hasMany('equipment', { async: true }),
        employmentTypes: _emberData['default'].hasMany('employment-type', { async: true }),
        interests: _emberData['default'].attr('string'),
        relatedOrganisers: _emberData['default'].attr('string'),
        jobType: _emberData['default'].attr('string'),
        activityTypes: _emberData['default'].hasMany('activity-type', { async: true }),
        favouritedBy: _emberData['default'].hasMany('person', { async: true }),

        isPersonListing: (function () {
            return this.get('jobType').toLowerCase() == 'person';
        }).property('jobType'),

        getHourlyRates: (function () {
            return [this.get('hourlyRate')];
        }).property('hourlyRate'),
        //job
        getStartsOn: (function () {
            return this.get('startsOn') ? moment(this.get('startsOn')).format('DD/M/YYYY') : "";
        }).property('startsOn'),
        //job
        getEndsOn: (function () {
            return this.get('endsOn') ? moment(this.get('endsOn')).format('DD/M/YYYY') : "";
        }).property('endsOn'),
        //application
        getCreatedOn: (function () {
            return this.get('createdOn') ? moment(this.get('createdOn')).format('DD/M/YYYY') : "";
        }).property('createdOn'),
        //application
        getClosedOn: (function () {
            return this.get('closesOn') ? moment(this.get('closesOn')).format('DD/M/YYYY') : "";
        }).property('closesOn'),

        getHourlyRateAmount: (function () {
            return this.get('hourlyRateAmount') > 0 ? '$' + this.get('hourlyRateAmount') : 'Volunteer';
        }).property('hourlyRateAmount')
    });
});