define('my-care-git-ember-portal/index/visits/detail/route', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(param) {
            // to trigger index error method, in case to protect tokenKey is not correct
            return this.get('getModel').perform(param.visit_id);
            // return this.store.findRecord('timesheet',param.visit_id);
        },
        getModel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(timesheetId) {
            var timesheet;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.store.findRecord('timesheet', timesheetId, { reload: true });

                    case 2:
                        timesheet = context$1$0.sent;
                        context$1$0.next = 5;
                        return timesheet.get('contract');

                    case 5:
                        return context$1$0.abrupt('return', timesheet);

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        setupController: function setupController(controller, model) {

            controller.set('timesheet', model);
            controller.set('contract', model.get('contract'));
        }
    });
});