define('my-care-git-ember-portal/components/mc-date-month-year-picker/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/guid-creater'], function (exports, _ember, _myCareGitEmberPortalMixinsGuidCreater) {
    /**
     * date month-year-picker component
     */
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsGuidCreater['default'], {
        value: '',
        /**
         * Setup component at startup.
         */
        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            _ember['default'].run.scheduleOnce('afterRender', self, self.setup);
        },

        setup: function setup() {
            var self = this;
            var input = _ember['default'].$('#' + self.get('id') + ' input');
            var da = new Date();
            var currentYear = da.getFullYear();
            input.combodate({
                minYear: self.get('minYear') ? self.get('minYear') : 1925,
                maxYear: currentYear,
                yearDescending: true,
                firstItem: 'name'
            });
            if (self.get('value')) {
                input.combodate('setValue', moment(new Date(self.get('value'))).format('MMM/YYYY'));
                if (self.get('isYearOnly') === true) {
                    input.combodate('setValue', moment(new Date(self.get('value'))).format('YYYY'));
                }
            }

            input.change(function () {
                var dateVal = input.combodate('getValue');
                if (_ember['default'].isBlank(dateVal)) {
                    self.set('value', null);
                    self.sendAction('onChange', null);
                } else {
                    input.find('select.month').removeClass('has-error');
                    input.find('select.year').removeClass('has-error');
                    self.set('value', moment.utc(input.combodate('getValue'), 'MMM/YYYY').toISOString());
                    if (self.get('onChange')) {
                        self.sendAction('onChange', self.get('value'));
                    }
                }
            });

            _ember['default'].$('#' + self.get('id')).find('select.month').attr('id', 'month-' + self.get('id'));
            _ember['default'].$('#' + self.get('id')).find('select.year').attr('id', 'year-' + self.get('id'));
        },

        actions: {
            setDate: function setDate() {
                this.sendAction('onUpdate', this.get('value'));
            }
        }
    });
});