define('my-care-git-ember-portal/index/search/jobs/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/scroll_top'], function (exports, _ember, _myCareGitEmberPortalMixinsScroll_top) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsScroll_top['default'], {
        queryParams: {
            location: {
                refreshModel: true
            }
        },
        model: function model(params) {
            var self = this;
            if (params.location !== null) {
                this.set('searchLocation', params.location);
            }
            return self.store.peekAll('search-job-listing');
        },

        setupController: function setupController(controller, model) {
            _ember['default'].$('#body').removeClass('home');
            controller.set('isSearchTypeCarers', false);
            controller.set('isSearchTypeJobs', true);
            controller.set('model', model);
            controller.setup();
        },

        actions: {
            willTransition: function willTransition() {
                var controller = this.controllerFor('index.search.profiles');
                controller.get('model').clear();
            }
        }

    });
});