define('my-care-git-ember-portal/index/threads/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        setupController: function setupController(controller) {
            var radioItems = [{ id: 'index', 'name': 'All', isSelected: true }, { id: 'not-responded', 'name': 'Not responded', isSelected: false }];
            controller.set('radioItems', radioItems);
            controller.set('notResponded', false);
            if (_ember['default'].isEmpty(controller.get('content'))) {
                controller.getSearchResults(this.get('pageSize'), 0, false).then(function (threads) {
                    controller.set('content', threads);
                });
            }
        }
    });
});