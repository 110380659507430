define("my-care-git-ember-portal/index/account-types/controller", ["exports", "ember", "my-care-git-ember-portal/utils/common"], function (exports, _ember, _myCareGitEmberPortalUtilsCommon) {
    exports["default"] = _ember["default"].Controller.extend({

        _channelId: _myCareGitEmberPortalUtilsCommon["default"].createGuid(),

        updateDashboard: function updateDashboard() {},

        actions: {
            onFinish: function onFinish() {}
        }
    });
});