define('my-care-git-ember-portal/helpers/subscription-type', ['exports', 'ember'], function (exports, _ember) {
   exports['default'] = _ember['default'].Helper.helper(function (params) {
      var type = params[0];
      if (type !== undefined && type !== null) {
         if (type == 'Premium') {
            return 'Private';
         }
         return type;
      } else {
         return type;
      }
   });
});