define("my-care-git-ember-portal/services/smart-cropper", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({

        cropImage: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(file, option, callback) {
            var reader;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        reader = new FileReader();

                        reader.onload = function (evt) {
                            var img = new Image();
                            img.onload = function () {
                                _this.get('run').perform(img, option).then(function (imageObj) {
                                    callback(imageObj);
                                });
                            };
                            img.src = evt.target.result;
                        };
                        reader.readAsDataURL(file);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        run: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(image, option) {
            var result, crop, canvas, ctx, d;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return smartcrop.crop(image, option);

                    case 2:
                        result = context$1$0.sent;
                        crop = result.topCrop, canvas = _ember["default"].$('<canvas>')[0], ctx = canvas.getContext('2d');

                        canvas.width = option.width;
                        canvas.height = option.height;
                        d = ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);
                        return context$1$0.abrupt("return", canvas);

                    case 8:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});