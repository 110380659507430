define('my-care-git-ember-portal/index/job-applications/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        errors: [],
        jobApplications: [],
        isMore: true,
        isHired: false,
        selectedListingType: 'all',
        _offSet: 0,
        _currentAmount: 0,
        _totalAmount: 0,
        _pageSize: 10,
        listingTypes: [{ id: 'all', 'name': 'All', isSelected: true }, { id: 'premium', 'name': 'Private', isSelected: false }, { id: 'dss', 'name': 'Dss', isSelected: false }],

        actions: {
            searchByType: function searchByType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.set('_pageSize', 10);
                    self.transitionToRoute('index.job-applications.' + type);
                }
            },

            onClickPage: function onClickPage(page) {
                var self = this;
                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                self.getSearchResults(self.get('_pageSize'), offSet, self.get('status'), self.get('searchString'), self.get('selectedListingType')).then(function (jobApplications) {
                    var _currentAmount = offSet + jobApplications.get('length');
                    self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                    self.set('jobApplications', jobApplications);
                    self.set('isLoading', false);
                });
            },

            doSearch: function doSearch() {
                var self = this;
                self.set('isLoading', true);
                self.set('_offSet', 0);
                var searchString = "";
                if (_ember['default'].isNone(self.get('searchString')) === false) {
                    searchString = self.get('searchString');
                }
                self.getSearchResults(self.get('_pageSize'), self.get('_offSet'), self.get('status'), searchString, self.get('selectedListingType')).then(function (jobApplications) {
                    self.set('jobApplications', jobApplications);
                    var _currentAmount = jobApplications.get('length');
                    self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                    self.set('_totalAmount', jobApplications.get('meta.total'));
                    self.set('isLoading', false);
                });
            },
            /* jshint expr: true */
            setListingType: function setListingType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.set('isMore', true);
                    self.set('selectedListingType', type);
                    self.set('_offSet', 0), self.getSearchResults(self.get('_pageSize'), 0, self.get('status'), self.get('searchString'), self.get('selectedListingType')).then(function (jobApplications) {
                        self.set('jobApplications', jobApplications);
                        self.set('_currentAmount', jobApplications.get('length'));
                        self.set('_totalAmount', jobApplications.get('meta.total'));
                        self.set('isLoading', false);
                    });
                }
            }
        },

        getSearchResults: function getSearchResults(pageSize, offset, status, searchString, subscriptionType) {
            var self = this;
            self.set('isLoading', true);
            return self.store.query('job-application', {
                pageSize: pageSize,
                offset: offset,
                status: status,
                s: searchString,
                subscriptionType: subscriptionType
            }).then(function (jobApplications) {
                self.set('isLoading', false);
                return jobApplications;
            });
        }
    });
});