define('my-care-git-ember-portal/components/mc-visit-table-needs-approval/row/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        didRender: function didRender() {
            if (_ember['default'].isEmpty(this.get('model.contract.id'))) {
                this.get('model').reload();
            }
        }

    });
});