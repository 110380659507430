define('my-care-git-ember-portal/models/trust', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    description: _emberData['default'].attr('string'),
    person: _emberData['default'].belongsTo('person', { async: true }),
    trustType: _emberData['default'].attr('number'),
    statusType: _emberData['default'].attr('number'),
    createdOn: _emberData['default'].attr('string'),

    isPoliceTrust: (function () {
      return this.get('trustType') === 1;
    }).property('trustType'),

    isMojTrust: (function () {
      return this.get('trustType') === 2;
    }).property('trustType'),

    isCompleted: (function () {
      if (this.get('statusType') == 1) {
        return false;
      } else if (this.get('statusType') == 2) {
        return true;
      }
    }).property('statusType'),

    setIsCompleted: function setIsCompleted() {
      if (this.get('statusType') == 1) {
        this.set('statusType', 2);
      } else if (this.get('statusType') == 2) {
        this.set('statusType', 1);
      }
    }
  });
});