define('my-care-git-ember-portal/index/job-listings/detail/applicants/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        div_id: 'jobListingApplications',
        init: function init() {
            var self = this;
            this._super();
            _ember['default'].run.schedule("afterRender", this, function () {
                self.setViewerPermission(self.get('div_id'));
            });
        },

        actions: {
            transitionToLocation: function transitionToLocation(jobListingId, applicantId) {
                this.transitionToRoute('index.job-listings.threads', { queryParams: { jobListingId: jobListingId, applicantId: applicantId } });
            }
        }
    });
});