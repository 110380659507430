define("my-care-git-ember-portal/components/mc-image-data-cropper/step-three/component", ["exports", "ember", "my-care-git-ember-portal/utils/function-helpers", "my-care-git-ember-portal/utils/mc-component"], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _myCareGitEmberPortalUtilsMcComponent) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('onEnter', undefined);
            this.initVar('onChangeStep', undefined);
            this.initVar('file', undefined);
            this.initVar('_isLoading', false);
        },

        willSetupOnce: function willSetupOnce() {

            _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onEnter'));
        },

        actions: {
            uploadImage: function uploadImage() {
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onCompleted'), this.get('file'));
            },

            back: function back() {
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onChangeStep'), 2);
            }
        }
    });
});