define('my-care-git-ember-portal/components/mc-user-detail/address/component', ['exports', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/mixins/form-checker'], function (exports, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalMixinsFormChecker) {
    exports['default'] = Ember.Component.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        div_id: "address",
        _store: Ember.inject.service('store'),
        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
            self.get('_store').findAll('country').then(function (countries) {
                self.set('countries', countries);
            });
        },
        actions: {

            toggleAddressStatus: function toggleAddressStatus() {
                //isValidAddress
                if (this.get('model.firstAddress.isValidAddress')) {
                    this.set('model.firstAddress.addressStatus', 0);
                } else {
                    this.set('model.firstAddress.addressStatus', 3);
                }
            },

            saveAddress: function saveAddress() {
                var self = this;
                if (this.addressCheck()) {
                    self.set('isAddressSaving', true);
                    self.get('model.firstAddress').save().then(function () {
                        self.set('isAddressSaving', false);
                        toastr.info('Address has been updated.');
                    }, function (error) {
                        self.save502ErrorHandle(error);
                    });
                }
            }

        },

        save502ErrorHandle: function save502ErrorHandle(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('model').reload();
                toastr.error('Please reload this page, check whether the data is saved, and contact Mycare development team.');
            }
        },

        addressCheck: function addressCheck() {
            var self = this;
            var isValid = true;
            if (Ember.isEmpty(self.get('model.firstAddress.number'))) {
                Ember.$('#number').addClass('has-error');
                isValid = false;
            } else if (self.get('model.firstAddress.number').length > 10) {
                Ember.$('#number').addClass('has-error');
                isValid = false;
            }

            if (Ember.isEmpty(self.get('model.firstAddress.street'))) {
                Ember.$('#street').addClass('has-error');
                isValid = false;
            }

            if (Ember.isEmpty(self.get('model.firstAddress.suburb'))) {
                Ember.$('#suburb').addClass('has-error');
                isValid = false;
            }

            if (Ember.isEmpty(self.get('model.firstAddress.city'))) {
                Ember.$('#city').addClass('has-error');
                isValid = false;
            }

            if (Ember.isEmpty(self.get('model.firstAddress.postcode'))) {
                Ember.$('#postcode').addClass('has-error');
                isValid = false;
            } else if (self.get('model.firstAddress.postcode.length') < 4 || self.get('model.firstAddress.postcode.length') > 10) {
                Ember.$('#postcode').addClass('has-error');
                isValid = false;
            }

            if (!isValid) {
                window.scrollTo(0, 0);
            } else {
                Ember.$('.has-error').removeClass('has-error');
            }

            return isValid;
        }
    });
});