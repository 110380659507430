define('my-care-git-ember-portal/index/registrations/all/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        beforeModel: function beforeModel() {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
            }
        },

        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = this.controllerFor('index.registrations.index');
            var radioItems = [{ id: 'dss', 'name': 'Dss', isSelected: false }, { id: 'work-and-income', 'name': 'Work and Income', isSelected: false }, { id: 'all', 'name': 'All', isSelected: true }];
            controller.set('radioItems', radioItems);
            controller.set('searchString', '');
            controller.set('type', 'all');

            controller.getSearchResults(controller.get('_pageSize'), 0, controller.get('searchString'), controller.get('type')).then(function (response) {
                controller.set('_totalAmount', response.get('meta.total'));
                controller.set('_currentAmount', controller.get('_pageSize') >= response.get('meta.total') ? response.get('meta.total') : controller.get('_pageSize'));
                controller.set('registrations', response);
            });

            self.render('index.registrations.index', { controller: controller });
        }
    });
});