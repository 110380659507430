define('my-care-git-ember-portal/models/person', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        isPublished: _emberData['default'].attr('boolean'),
        accounts: _emberData['default'].hasMany('account', { async: true }),
        availability: _emberData['default'].attr('string'),
        title: _emberData['default'].attr('string'),
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        avatar: _emberData['default'].belongsTo('image', { async: true }),
        hasAvatar: _emberData['default'].attr('boolean'),
        hasBankAccount: _emberData['default'].attr('boolean'),
        hasCreditCard: _emberData['default'].attr('boolean'),
        numberOfVerifications: _emberData['default'].attr('number'),
        profileType: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        organisationName: _emberData['default'].attr('string'),
        dateOfBirth: _emberData['default'].attr('string'),
        gender: _emberData['default'].attr('string'),
        uniqueId: _emberData['default'].attr('string'),
        interests: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        phoneNumber: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        location: _emberData['default'].attr('string'),
        latitude: _emberData['default'].attr('string'),
        longitude: _emberData['default'].attr('string'),
        hourlyRates: _emberData['default'].hasMany('hourly-rate', { async: true }),
        login: _emberData['default'].attr('number'),
        lastLoginOn: _emberData['default'].attr('string'),
        numberOfHires: _emberData['default'].attr('number'),
        images: _emberData['default'].hasMany('image', { async: true }),
        recommendations: _emberData['default'].hasMany('recommendation', { async: true, inverse: 'person' }),
        experiences: _emberData['default'].hasMany('experience', { async: true }),
        qualifications: _emberData['default'].hasMany('qualification', { async: true }),
        employmentTypes: _emberData['default'].hasMany('employment-type', { async: true }),
        languages: _emberData['default'].hasMany('language', { async: true }),
        requirements: _emberData['default'].hasMany('requirement', { async: true }),
        services: _emberData['default'].hasMany('service', { async: true }),
        relationships: _emberData['default'].hasMany('relationship', { async: true, inverse: 'person' }),
        relatedWith: _emberData['default'].hasMany('relationship', { async: true, inverse: 'relatedPerson' }),
        jobApplications: _emberData['default'].hasMany('job-application', { async: true }),
        jobListings: _emberData['default'].hasMany('job-listing', { async: true, inverse: 'person' }),
        threads: _emberData['default'].hasMany('thread', { async: true }),
        equipment: _emberData['default'].hasMany('equipment', { async: true }),
        ethnicities: _emberData['default'].hasMany('ethnicity', { async: true }),
        supportTypes: _emberData['default'].hasMany('support-type', { async: true }),
        files: _emberData['default'].hasMany('file', { async: true }),
        isCircle: _emberData['default'].attr('boolean'),
        isClient: _emberData['default'].attr('boolean'),
        isDisabled: _emberData['default'].attr('boolean'),
        isDssUser: _emberData['default'].attr('boolean'),
        isValidSubscriber: _emberData['default'].attr('boolean'),
        isDssOrganiser: _emberData['default'].attr('boolean'),
        isOrganiser: _emberData['default'].attr('boolean'),
        isWorker: _emberData['default'].attr('boolean'),
        isValidUser: _emberData['default'].attr('boolean'),
        isInterviewed: _emberData['default'].attr('boolean'),
        hasIRD: _emberData['default'].attr('boolean'),
        isShareable: _emberData['default'].attr('boolean'),
        addresses: _emberData['default'].hasMany('address', { async: true }),
        clientContracts: _emberData['default'].hasMany('contract', { async: true, inverse: 'clientPerson' }),
        contracts: _emberData['default'].hasMany('contract', { async: true, inverse: 'contractorPerson' }),
        invitedPeople: _emberData['default'].hasMany('person', { async: true, inverse: null }),
        firstAddress: Ember.computed.alias('addresses.firstObject'),
        isPhoneNumberVerified: _emberData['default'].attr('boolean'),
        isEmailVerified: _emberData['default'].attr('boolean'),
        isAddressVerified: _emberData['default'].attr('boolean'),
        isGovernmentPay: _emberData['default'].attr('boolean'),
        isPrivatePay: _emberData['default'].attr('boolean'),
        trusts: _emberData['default'].hasMany('trust', { async: true }),
        respondRatio: _emberData['default'].attr('number'),
        registrations: _emberData['default'].hasMany('registration', { async: true }),
        availabilities: _emberData['default'].hasMany('availability', { async: true }),
        accountType: _emberData['default'].belongsTo('account-type', { async: true }),
        password: _emberData['default'].attr('string'),
        getFirstName: (function () {
            if (Ember.isEmpty(this.get('firstName'))) {
                return "";
            } else {
                return this.get('firstName');
            }
        }).property('firstName'),
        getLastName: (function () {
            if (Ember.isEmpty(this.get('lastName'))) {
                return "";
            } else {
                return this.get('lastName');
            }
        }).property('lastName'),
        getEmail: (function () {
            if (Ember.isEmpty(this.get('email'))) {
                return "";
            } else {
                return this.get('email');
            }
        }).property('email'),

        getJobListings: (function () {
            return this.get('jobListings').filter(function (j) {
                return j.id != undefined;
            });
        }).property('jobListings'),

        getFullName: (function () {
            return this.get('getFirstName') + " " + this.get('getLastName');
        }).property('firstName', 'lastName'),

        hasJobListings: (function () {
            return this.get('jobListings.length') > 0;
        }).property('jobListings'),

        isSystemUser: (function () {
            return !Ember.isNone(this.get('email'));
        }).property('email')
    });
});