define('my-care-git-ember-portal/components/mc-joblisting-card/favourite/component', ['exports', 'ember', 'mycare-ember/utils/mc-component'], function (exports, _ember, _mycareEmberUtilsMcComponent) {
  exports['default'] = _mycareEmberUtilsMcComponent['default'].extend({
    _store: _ember['default'].inject.service('store'),

    init: function init() {
      this.set('jobListing', undefined);
      this.set('onClick', undefined);
      this.set('favouritePersonId', undefined);
      this.set('_isFavourite', false);
      this.set('_isApplied', false);
      this.set('_applicationStatus', undefined);
      this.set('_jobApplication', undefined);
      this._super.apply(this, arguments);
    },

    _willSetupOnce: function _willSetupOnce() {
      var _this = this;

      this.get('_store').findRecord('person', this.get('favouritePersonId')).then(function (person) {
        _ember['default'].RSVP.resolve(person.get('jobApplications')).then(function (jobApplications) {
          var jobListingApplication = jobApplications.find(function (jl) {
            return jl.get('id') === _this.get('jobListing.id');
          });
          if (jobListingApplication) {
            _this.set('_isApplied', false);
            return;
          }
          _this.set('_jobApplication', jobListingApplication);
          _this.set('_applicationStatus', jobListingApplication.get('jobApplicationStatus.name'));
        });
      });
    },

    actions: {
      viewApplication: function viewApplication() {
        var app = this.get('_jobApplication');
        if (this.get('_applicationStatus') === 'draft') {
          this.get('eventbus').publish('global:on:changeRoute', 'browse.jobs.detail.draft', app.get('id'));
        } else if (this.get('_applicationStatus') === 'submitted') {
          this.get('eventbus').publish('global:on:changeRoute', 'browse.jobs.detail.submitted', app.get('id'));
        } else {
          this.get('eventbus').publish('global:on:changeRoute', 'browse.jobs.detail.apply', app.get('id'));
        }
      }
    }
  });
});