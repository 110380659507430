define('my-care-git-ember-portal/components/mc-accordian/group/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({
        classNames: ['panel', 'panel-default'],

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('title', null);
            this.initVar('hasError', false);
            this.initVar('isOpenedInitially', false);
            this.initVar('id', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
        },

        actions: {
            onClick: function onClick() {
                var _this = this;

                _ember['default'].run.later(function () {
                    var firstErrorElem = _this.$('.panel-heading')[0];
                    firstErrorElem.scrollIntoView(true);
                }, 100);
            }
        }
    });
});