define('my-care-git-ember-portal/components/mc-account-update/refund-modal/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-modal-component', 'my-care-git-ember-portal/config/environment', 'my-care-git-ember-portal/mixins/defer'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcModalComponent, _myCareGitEmberPortalConfigEnvironment, _myCareGitEmberPortalMixinsDefer) {
    exports['default'] = _myCareGitEmberPortalUtilsMcModalComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsDefer['default'], {

        _modalHelper: _ember['default'].inject.service('utils/modal-helper'),

        init: function init() {
            this.initVar('_componentId', 'mc-refund-modal');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
        },

        willSetupOnce: function willSetupOnce() {
            this._super.apply(this, arguments);
        },
        _onBeforeOpen: function _onBeforeOpen() {
            this.set('amount', '');
            this.set('isRefundToMycare', '');
            this.addValidation('amount', 'amount', 'Amount', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
            this.addValidation('isRefundToMycare', 'isRefundToMycare', '"Is refund to mycare"', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
            this.set('isShowConfirmationForm', false);
            this.set('isSaving', false);
        },

        actions: {
            showConfirmForm: function showConfirmForm() {
                if (this._validate()) {
                    this.set('isShowConfirmationForm', true);
                }
            },

            save: function save() {
                var _this = this;

                var defer = this.getDeferWithToken();
                var data = JSON.stringify({
                    "transaction": {
                        "account": this.get('_account.id'),
                        "amount": this.get('amount'),
                        "isRefundToMycare": this.get('isRefundToMycare')
                    }
                });
                this.set('isSaving', true);
                _ember['default'].$.post(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/Accounts/Refund', data).then(function (loginResponse) {
                    defer.resolve(loginResponse);
                }, function (error) {
                    defer.reject(error);
                });
                defer.promise.then(function (loginResponse) {
                    _this.sendAction('_onDone');
                    _this._close();
                    _this.set('isSaving', false);
                }, function (error) {
                    toastr.error('No permission.');
                    _this.set('isSaving', false);
                });
            }

        }
    });
});