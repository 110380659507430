define('my-care-git-ember-portal/index/account-types/route', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
     exports['default'] = _ember['default'].Route.extend({

          model: function model() {

               return this.get('modelAsync').perform();
          },

          modelAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
               var accountTypes;
               return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                    while (1) switch (context$1$0.prev = context$1$0.next) {
                         case 0:
                              context$1$0.next = 2;
                              return this.store.findAll('account-type', { reload: true });

                         case 2:
                              accountTypes = context$1$0.sent;
                              return context$1$0.abrupt('return', {
                                   accountTypes: accountTypes
                              });

                         case 4:
                         case 'end':
                              return context$1$0.stop();
                    }
               }, callee$0$0, this);
          }))
     });
});

/*  const accounttype= yield this.store.query('account-type',{id: 1});
  console.log('accounttype',accounttype);*/