define('my-care-git-ember-portal/index/account-fees/controller', ['exports', 'ember', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _ember['default'].Controller.extend({

        _channelId: _myCareGitEmberPortalUtilsCommon['default'].createGuid(),

        updateDashboard: function updateDashboard() {},

        actions: {
            showEditAccountFeeModal: function showEditAccountFeeModal() {
                this.get('eventbus').publish('mc-update-account-fee-modal:on:open:' + this.get('_channelId'));
            },

            onFinish: function onFinish() {
                this.send('refreshCurrentRoute');
            }
        }
    });
});