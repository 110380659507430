define("my-care-git-ember-portal/index/users/detail/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 17
            },
            "end": {
              "line": 3,
              "column": 46
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Worker ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 53
            },
            "end": {
              "line": 3,
              "column": 82
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Client ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 89
            },
            "end": {
              "line": 3,
              "column": 124
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Organiser ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 19,
                "column": 8
              }
            },
            "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn");
            var el3 = dom.createTextNode("New Job Listing");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn");
            var el3 = dom.createTextNode("Search Workers");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var element3 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createElementMorph(element3);
            return morphs;
          },
          statements: [["element", "action", ["newJobListing"], [], ["loc", [null, [14, 32], [14, 58]]], 0, 0], ["element", "action", ["transitionToSearch"], [], ["loc", [null, [17, 32], [17, 63]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 8
              },
              "end": {
                "line": 24,
                "column": 8
              }
            },
            "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn");
            var el3 = dom.createTextNode("Search Jobs");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["transitionToSearch"], [], ["loc", [null, [22, 32], [22, 63]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 12
                },
                "end": {
                  "line": 27,
                  "column": 78
                }
              },
              "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Manage Threads");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 8
              },
              "end": {
                "line": 29,
                "column": 8
              }
            },
            "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["index.users.threads", ["get", "model", ["loc", [null, [27, 45], [27, 50]]], 0, 0, 0, 0]], ["class", "btn"], 0, null, ["loc", [null, [27, 12], [27, 90]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "space-3 list-inline");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "btn");
          var el4 = dom.createTextNode("Back");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [1, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element5);
          morphs[1] = dom.createMorphAt(element4, 3, 3);
          morphs[2] = dom.createMorphAt(element4, 4, 4);
          morphs[3] = dom.createMorphAt(element4, 5, 5);
          return morphs;
        },
        statements: [["element", "action", ["back"], [], ["loc", [null, [10, 32], [10, 49]]], 0, 0], ["block", "if", [["get", "model.isClient", ["loc", [null, [12, 14], [12, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 8], [19, 15]]]], ["block", "if", [["get", "model.isWorker", ["loc", [null, [20, 14], [20, 28]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [20, 8], [24, 15]]]], ["block", "if", [["get", "model.isSystemUser", ["loc", [null, [25, 14], [25, 32]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [25, 8], [29, 15]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 8
                },
                "end": {
                  "line": 37,
                  "column": 8
                }
              },
              "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "error", ["loc", [null, [36, 11], [36, 20]]], 0, 0, 0, 0]],
            locals: ["error"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "space-3 alert alert-full-width alert-danger");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "errors", ["loc", [null, [35, 16], [35, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [35, 8], [37, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 4
            },
            "end": {
              "line": 40,
              "column": 4
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "hasErrors", ["loc", [null, [33, 10], [33, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 4], [39, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 12
            },
            "end": {
              "line": 48,
              "column": 12
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          var el2 = dom.createTextNode("\n                Personal Details\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "bind-attr", [], ["href", "view.href"], ["loc", [null, [45, 15], [45, 45]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 12
            },
            "end": {
              "line": 52,
              "column": 12
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "mc-user-detail/nav/client-organiser", [], ["_clientAccounts", ["subexpr", "readonly", [["get", "_clientAccounts", ["loc", [null, [51, 76], [51, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 66], [51, 92]]], 0, 0], "_workerAccount", ["subexpr", "readonly", [["get", "_workerAccount", ["loc", [null, [51, 118], [51, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 108], [51, 133]]], 0, 0], "model", ["subexpr", "readonly", [["get", "model", ["loc", [null, [51, 150], [51, 155]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 140], [51, 156]]], 0, 0]], ["loc", [null, [51, 12], [51, 158]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 12
              },
              "end": {
                "line": 54,
                "column": 12
              }
            },
            "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "mc-user-detail/nav/worker-organiser", [], ["jobListings", ["subexpr", "readonly", [["get", "clientJoblistings", ["loc", [null, [53, 72], [53, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 62], [53, 90]]], 0, 0], "model", ["subexpr", "readonly", [["get", "model", ["loc", [null, [53, 107], [53, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 97], [53, 113]]], 0, 0]], ["loc", [null, [53, 12], [53, 115]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 12
                },
                "end": {
                  "line": 56,
                  "column": 12
                }
              },
              "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mc-user-detail/nav/organiser", [], ["jobListings", ["subexpr", "readonly", [["get", "clientJoblistings", ["loc", [null, [55, 65], [55, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 55], [55, 83]]], 0, 0], "model", ["subexpr", "readonly", [["get", "model", ["loc", [null, [55, 100], [55, 105]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 90], [55, 106]]], 0, 0]], ["loc", [null, [55, 12], [55, 108]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 12
                  },
                  "end": {
                    "line": 58,
                    "column": 12
                  }
                },
                "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mc-user-detail/nav/client", [], ["_clientAccounts", ["subexpr", "readonly", [["get", "_clientAccounts", ["loc", [null, [57, 66], [57, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 56], [57, 82]]], 0, 0], "model", ["subexpr", "readonly", [["get", "model", ["loc", [null, [57, 99], [57, 104]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 89], [57, 105]]], 0, 0]], ["loc", [null, [57, 12], [57, 107]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 12
                    },
                    "end": {
                      "line": 60,
                      "column": 12
                    }
                  },
                  "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mc-user-detail/nav/worker", [], ["_workerAccount", ["subexpr", "readonly", [["get", "_workerAccount", ["loc", [null, [59, 65], [59, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 55], [59, 80]]], 0, 0], "model", ["subexpr", "readonly", [["get", "model", ["loc", [null, [59, 97], [59, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 87], [59, 103]]], 0, 0]], ["loc", [null, [59, 12], [59, 105]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.7.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 60,
                        "column": 12
                      },
                      "end": {
                        "line": 62,
                        "column": 12
                      }
                    },
                    "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "mc-user-detail/nav/systemUser", ["loc", [null, [61, 12], [61, 45]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.7.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 60,
                      "column": 12
                    },
                    "end": {
                      "line": 62,
                      "column": 12
                    }
                  },
                  "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "model.isSystemUser", ["loc", [null, [60, 22], [60, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [60, 12], [62, 12]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 58,
                    "column": 12
                  },
                  "end": {
                    "line": 62,
                    "column": 12
                  }
                },
                "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "model.isWorker", ["loc", [null, [58, 22], [58, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [58, 12], [62, 12]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 12
                },
                "end": {
                  "line": 62,
                  "column": 12
                }
              },
              "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "model.isClient", ["loc", [null, [56, 22], [56, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [56, 12], [62, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 12
              },
              "end": {
                "line": 62,
                "column": 12
              }
            },
            "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.isOrganiser", ["loc", [null, [54, 22], [54, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [54, 12], [62, 12]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 12
            },
            "end": {
              "line": 62,
              "column": 12
            }
          },
          "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "isWorkerOrganiser", ["loc", [null, [52, 22], [52, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [52, 12], [62, 12]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "my-care-git-ember-portal/index/users/detail/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "main-title");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-fluid");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Edit ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("profile");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-fluid");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "nav nav-tabs");
        dom.setAttribute(el3, "role", "tablist");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        dom.setAttribute(el3, "class", "content user");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "container");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "role", "tabpanel");
        dom.setAttribute(el5, "class", "tab-pane fade in");
        dom.setAttribute(el5, "style", "padding-top:20px;");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0, 1, 1]);
        var element7 = dom.childAt(fragment, [2]);
        var element8 = dom.childAt(element7, [4]);
        var element9 = dom.childAt(element8, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 2, 2);
        morphs[2] = dom.createMorphAt(element6, 3, 3);
        morphs[3] = dom.createMorphAt(element7, 1, 1);
        morphs[4] = dom.createMorphAt(element7, 2, 2);
        morphs[5] = dom.createMorphAt(element9, 1, 1);
        morphs[6] = dom.createMorphAt(element9, 3, 3);
        morphs[7] = dom.createMorphAt(dom.childAt(element8, [3, 1, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.isWorker", ["loc", [null, [3, 23], [3, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 17], [3, 53]]]], ["block", "if", [["get", "model.isClient", ["loc", [null, [3, 59], [3, 73]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [3, 53], [3, 89]]]], ["block", "if", [["get", "model.isOrganiser", ["loc", [null, [3, 95], [3, 112]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [3, 89], [3, 131]]]], ["block", "unless", [["get", "isViewer", ["loc", [null, [7, 14], [7, 22]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [7, 4], [31, 15]]]], ["block", "if", [["get", "model.isSystemUser", ["loc", [null, [32, 10], [32, 28]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [32, 4], [40, 11]]]], ["block", "link-to", ["index.users.detail.basic-info"], ["tagName", "li", "href", false], 5, null, ["loc", [null, [44, 12], [48, 24]]]], ["block", "if", [["get", "isClientOrganiser", ["loc", [null, [50, 18], [50, 35]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [50, 12], [62, 19]]]], ["content", "outlet", ["loc", [null, [67, 20], [67, 30]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});