define('my-care-git-ember-portal/components/mc-table-report-client/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        _store: _ember['default'].inject.service('store'),
        isLoading: false,
        startingTime: "Jan/2018",
        totalClientsVObject: {},
        totalHoursObject: {},
        totalMyCareFeesObject: {},
        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            var store = self.get('targetObject.store');
            var thisYear = "2018";
            var thisMonth = "1";
            this.set('isLoading', true);
            store.queryRecord('report-table', { 'reportType': self.get('reportType'), 'year': thisYear, 'month': thisMonth }).then(function (report) {
                self.set('currentTable', report);
                self._setTotalValues(report);
                var reportRows = report.get('reportRows');
                self.setReportRows(reportRows);
                self.set('startingTime', self.get('thisYear'));
                self.set('isLoading', false);
            });
        },

        actions: {
            getReport: function getReport() {
                var self = this;
                var temp = self.getQuery();
                temp.then(function (report) {
                    self.set('currentTable', report);
                    self._setTotalValues(report);
                    var reportRows = report.get('reportRows');
                    self.setReportRows(reportRows);
                });
            },

            setStartTime: function setStartTime(time) {
                this.set('startingTime', time);
            }
        },

        getQuery: function getQuery() {
            var self = this;
            var month = 1;
            var year = moment(this.get('startingTime')).year();
            var store = self.get('_store');
            self.set('isLoading', true);
            return store.queryRecord('report-table', { 'reportType': self.get('reportType'), 'year': year, 'month': month }).then(function (report) {
                self.set('isLoading', false);
                return report;
            });
        },

        _setTotalValues: function _setTotalValues(report) {
            this.set('totalClientsVObject', this._getTotalObject(report.get('totalClients')));
            this.set('totalHoursObject', this._getTotalObject(report.get('totalHours')));
            this.set('totalMyCareFeesObject', this._getTotalObject(report.get('totalMyCareFees')));
        },

        _getTotalObject: function _getTotalObject(valueArray) {
            var arr = valueArray.split(",").map(Number);
            var total = 0;
            arr.forEach(function (value) {
                total += value;
            });
            return { values: arr, total: total };
        },

        setReportRows: function setReportRows(reportRows) {
            var totalHoursRows = reportRows.filter(function (row) {
                return row.get('type').toLowerCase() !== 'number of clients';
            });

            var feeRows = reportRows.filter(function (row) {
                return row.get('type').toLowerCase().indexOf('fee') != -1;
            });

            feeRows.forEach(function (row) {
                row.set('isFeeRow', true);
            });

            totalHoursRows.forEach(function (row) {
                row.set('funding', '');
                row.set('organisation', '');
                row.set('isSubRow', true);
            });
        }
    });
});