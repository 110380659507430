define('my-care-git-ember-portal/mixins/routes/viewer_not_allow', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({
        auth: _ember['default'].computed.alias('controllers.auth'),
        loginServer: _ember['default'].inject.service('login'),
        isViewer: function isViewer() {
            if (this.get('loginServer.isReportViewer') === true) {
                this.get('auth').invalidate();
            }
        }

    });
});