define('my-care-git-ember-portal/services/routing', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        eventbus: _ember['default'].inject.service('event-bus'),
        router: _ember['default'].inject.service('-routing'),

        init: function init() {
            this.get('eventbus').subscribe('global:on:changeRoute', this._onChangeRoute);
        },

        _onChangeRoute: function _onChangeRoute(routeName) {
            var object = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
            var options = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

            if (object) {
                if (options) {
                    this.get('router').transitionTo(routeName, object, options);
                } else {
                    this.get('router').transitionTo(routeName, object);
                }
            } else {
                if (options) {
                    this.get('router').transitionTo(routeName, options);
                } else {
                    this.get('router').transitionTo(routeName);
                }
            }
        }
    });
});