define('my-care-git-ember-portal/index/work-and-income-user/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.query('work-and-income', { pageSize: 50, offset: 0 });
        },

        setupController: function setupController(controller, model) {
            controller.set('_content', model);
            controller.set('_totalAmount', model.get('meta.total'));
            controller.setup();
        }
    });
});