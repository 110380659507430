define("my-care-git-ember-portal/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 20
            },
            "end": {
              "line": 23,
              "column": 20
            }
          },
          "moduleName": "my-care-git-ember-portal/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-dashboard fa-fw");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Dashboard\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 20
              },
              "end": {
                "line": 32,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-user fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Users\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 20
              },
              "end": {
                "line": 39,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-credit-card fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Registrations\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 20
              },
              "end": {
                "line": 46,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-commenting fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Reviews\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 20
              },
              "end": {
                "line": 53,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-search fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Search\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 20
              },
              "end": {
                "line": 60,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-briefcase fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Listings\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 20
              },
              "end": {
                "line": 76,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-briefcase fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Account Service Fees\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 20
              },
              "end": {
                "line": 83,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-money fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Account balance\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 88,
                "column": 20
              },
              "end": {
                "line": 90,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-check fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Job opportunities\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 20
              },
              "end": {
                "line": 97,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-gavel fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Agreements\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child9 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 20
              },
              "end": {
                "line": 104,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-bar-chart fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Weekly visits report\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 16
            },
            "end": {
              "line": 108,
              "column": 16
            }
          },
          "moduleName": "my-care-git-ember-portal/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1, 1]);
          var element11 = dom.childAt(fragment, [3, 1]);
          var element12 = dom.childAt(fragment, [5, 1]);
          var element13 = dom.childAt(fragment, [7, 1]);
          var element14 = dom.childAt(fragment, [9, 1]);
          var element15 = dom.childAt(fragment, [12, 1]);
          var element16 = dom.childAt(fragment, [14, 1]);
          var element17 = dom.childAt(fragment, [16, 1]);
          var element18 = dom.childAt(fragment, [18, 1]);
          var element19 = dom.childAt(fragment, [20, 1]);
          var morphs = new Array(20);
          morphs[0] = dom.createElementMorph(element10);
          morphs[1] = dom.createMorphAt(element10, 1, 1);
          morphs[2] = dom.createElementMorph(element11);
          morphs[3] = dom.createMorphAt(element11, 1, 1);
          morphs[4] = dom.createElementMorph(element12);
          morphs[5] = dom.createMorphAt(element12, 1, 1);
          morphs[6] = dom.createElementMorph(element13);
          morphs[7] = dom.createMorphAt(element13, 1, 1);
          morphs[8] = dom.createElementMorph(element14);
          morphs[9] = dom.createMorphAt(element14, 1, 1);
          morphs[10] = dom.createElementMorph(element15);
          morphs[11] = dom.createMorphAt(element15, 1, 1);
          morphs[12] = dom.createElementMorph(element16);
          morphs[13] = dom.createMorphAt(element16, 1, 1);
          morphs[14] = dom.createElementMorph(element17);
          morphs[15] = dom.createMorphAt(element17, 1, 1);
          morphs[16] = dom.createElementMorph(element18);
          morphs[17] = dom.createMorphAt(element18, 1, 1);
          morphs[18] = dom.createElementMorph(element19);
          morphs[19] = dom.createMorphAt(element19, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["toggleOverlay"], [], ["loc", [null, [29, 26], [29, 52]]], 0, 0], ["block", "link-to", ["index.users"], [], 0, null, ["loc", [null, [30, 20], [32, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [36, 26], [36, 52]]], 0, 0], ["block", "link-to", ["index.registrations"], [], 1, null, ["loc", [null, [37, 20], [39, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [43, 26], [43, 52]]], 0, 0], ["block", "link-to", ["index.recommendations"], [], 2, null, ["loc", [null, [44, 20], [46, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [50, 26], [50, 52]]], 0, 0], ["block", "link-to", ["index.search"], [], 3, null, ["loc", [null, [51, 20], [53, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [57, 26], [57, 52]]], 0, 0], ["block", "link-to", ["index.job-listings"], [], 4, null, ["loc", [null, [58, 20], [60, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [73, 22], [73, 48]]], 0, 0], ["block", "link-to", ["index.account-types"], [], 5, null, ["loc", [null, [74, 20], [76, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [80, 26], [80, 52]]], 0, 0], ["block", "link-to", ["index.account-balance.index"], [], 6, null, ["loc", [null, [81, 20], [83, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [87, 26], [87, 52]]], 0, 0], ["block", "link-to", ["index.job-applications"], [], 7, null, ["loc", [null, [88, 20], [90, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [94, 26], [94, 52]]], 0, 0], ["block", "link-to", ["index.agreement"], [], 8, null, ["loc", [null, [95, 20], [97, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [101, 26], [101, 52]]], 0, 0], ["block", "link-to", ["index.weekly-visits-report.seeker-report"], [], 9, null, ["loc", [null, [102, 20], [104, 32]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 114,
                "column": 20
              },
              "end": {
                "line": 116,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-times fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Visits pending\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 121,
                "column": 20
              },
              "end": {
                "line": 123,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-wheelchair fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Visit incident\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 128,
                "column": 20
              },
              "end": {
                "line": 130,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-exclamation-circle fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Account errors\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 135,
                "column": 20
              },
              "end": {
                "line": 137,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-money fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Funder account\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 111,
              "column": 16
            },
            "end": {
              "line": 140,
              "column": 16
            }
          },
          "moduleName": "my-care-git-ember-portal/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 1]);
          var element7 = dom.childAt(fragment, [3, 1]);
          var element8 = dom.childAt(fragment, [5, 1]);
          var element9 = dom.childAt(fragment, [7, 1]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6, 1, 1);
          morphs[2] = dom.createElementMorph(element7);
          morphs[3] = dom.createMorphAt(element7, 1, 1);
          morphs[4] = dom.createElementMorph(element8);
          morphs[5] = dom.createMorphAt(element8, 1, 1);
          morphs[6] = dom.createElementMorph(element9);
          morphs[7] = dom.createMorphAt(element9, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["toggleOverlay"], [], ["loc", [null, [113, 26], [113, 52]]], 0, 0], ["block", "link-to", ["index.unsubmitted-timesheet"], [], 0, null, ["loc", [null, [114, 20], [116, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [120, 26], [120, 52]]], 0, 0], ["block", "link-to", ["index.timesheet-incident"], [], 1, null, ["loc", [null, [121, 20], [123, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [127, 26], [127, 52]]], 0, 0], ["block", "link-to", ["index.account-error"], [], 2, null, ["loc", [null, [128, 20], [130, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [134, 26], [134, 52]]], 0, 0], ["block", "link-to", ["index.funder"], [], 3, null, ["loc", [null, [135, 20], [137, 32]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 143,
              "column": 20
            },
            "end": {
              "line": 145,
              "column": 20
            }
          },
          "moduleName": "my-care-git-ember-portal/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-line-chart fa-fw");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Board reports\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 151,
                "column": 20
              },
              "end": {
                "line": 153,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-info-circle fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Market dynamics\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 20
              },
              "end": {
                "line": 160,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-line-chart fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Business KPI\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 165,
                "column": 20
              },
              "end": {
                "line": 167,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-bar-chart fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Reports\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 172,
                "column": 20
              },
              "end": {
                "line": 174,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-table fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Client reports\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 179,
                "column": 20
              },
              "end": {
                "line": 181,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-table fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Matching service reports\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 186,
                "column": 20
              },
              "end": {
                "line": 188,
                "column": 20
              }
            },
            "moduleName": "my-care-git-ember-portal/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-shield fa-fw");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Covid vaccination settings\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 148,
              "column": 16
            },
            "end": {
              "line": 191,
              "column": 16
            }
          },
          "moduleName": "my-care-git-ember-portal/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(fragment, [3, 1]);
          var element2 = dom.childAt(fragment, [5, 1]);
          var element3 = dom.childAt(fragment, [7, 1]);
          var element4 = dom.childAt(fragment, [9, 1]);
          var element5 = dom.childAt(fragment, [11, 1]);
          var morphs = new Array(12);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createMorphAt(element1, 1, 1);
          morphs[4] = dom.createElementMorph(element2);
          morphs[5] = dom.createMorphAt(element2, 1, 1);
          morphs[6] = dom.createElementMorph(element3);
          morphs[7] = dom.createMorphAt(element3, 1, 1);
          morphs[8] = dom.createElementMorph(element4);
          morphs[9] = dom.createMorphAt(element4, 1, 1);
          morphs[10] = dom.createElementMorph(element5);
          morphs[11] = dom.createMorphAt(element5, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["toggleOverlay"], [], ["loc", [null, [150, 26], [150, 52]]], 0, 0], ["block", "link-to", ["index.market-dynamics"], [], 0, null, ["loc", [null, [151, 20], [153, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [157, 26], [157, 52]]], 0, 0], ["block", "link-to", ["index.business-kpi"], [], 1, null, ["loc", [null, [158, 20], [160, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [164, 26], [164, 52]]], 0, 0], ["block", "link-to", ["index.reports"], [], 2, null, ["loc", [null, [165, 20], [167, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [171, 26], [171, 52]]], 0, 0], ["block", "link-to", ["index.client-report"], [], 3, null, ["loc", [null, [172, 20], [174, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [178, 26], [178, 52]]], 0, 0], ["block", "link-to", ["index.dss-reports"], [], 4, null, ["loc", [null, [179, 20], [181, 32]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [185, 26], [185, 52]]], 0, 0], ["block", "link-to", ["index.covid-vaccination-settings"], [], 5, null, ["loc", [null, [186, 20], [188, 32]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 209,
            "column": 0
          }
        },
        "moduleName": "my-care-git-ember-portal/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1, "class", "navbar navbar-fixed-top");
        dom.setAttribute(el1, "role", "navigation");
        dom.setAttribute(el1, "style", "margin-bottom: 0");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navbar-header");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "navbar-brand");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "img/mycare-logo-w.svg");
        dom.setAttribute(el4, "alt", "Mycare Logo");
        dom.setAttribute(el4, "id", "logo");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "btn-bar");
        dom.setAttribute(el3, "id", "toggle");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bar1");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bar2");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bar3");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "overlay");
        dom.setAttribute(el1, "id", "overlay");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("nav");
        dom.setAttribute(el2, "class", "overlay-menu");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "clearfix");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("hr");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-6");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "small");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-xs-6 text-right");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "btn btn-primary");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa fa-sign-out fa-fw");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" Logout");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [0, 1, 3]);
        var element21 = dom.childAt(fragment, [2, 1]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element22, [1]);
        var element24 = dom.childAt(element23, [1, 1]);
        var element25 = dom.childAt(element22, [3]);
        var element26 = dom.childAt(element25, [3, 1]);
        var element27 = dom.childAt(element21, [5]);
        var element28 = dom.childAt(element27, [3, 1]);
        var morphs = new Array(12);
        morphs[0] = dom.createElementMorph(element20);
        morphs[1] = dom.createElementMorph(element24);
        morphs[2] = dom.createMorphAt(element24, 1, 1);
        morphs[3] = dom.createMorphAt(element23, 3, 3);
        morphs[4] = dom.createMorphAt(element25, 1, 1);
        morphs[5] = dom.createElementMorph(element26);
        morphs[6] = dom.createMorphAt(element26, 1, 1);
        morphs[7] = dom.createMorphAt(element25, 5, 5);
        morphs[8] = dom.createMorphAt(dom.childAt(element27, [1, 1]), 0, 0);
        morphs[9] = dom.createElementMorph(element28);
        morphs[10] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[11] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        return morphs;
      },
      statements: [["element", "action", ["toggleOverlay"], [], ["loc", [null, [7, 41], [7, 67]]], 0, 0], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [20, 26], [20, 52]]], 0, 0], ["block", "link-to", ["index.dashboard"], [], 0, null, ["loc", [null, [21, 20], [23, 32]]]], ["block", "if", [["get", "isAdministrator", ["loc", [null, [27, 22], [27, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [27, 16], [108, 23]]]], ["block", "if", [["get", "isAdministrator", ["loc", [null, [111, 22], [111, 37]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [111, 16], [140, 23]]]], ["element", "action", ["toggleOverlay"], [], ["loc", [null, [142, 26], [142, 52]]], 0, 0], ["block", "link-to", ["index.board-reports.on-boarding"], [], 3, null, ["loc", [null, [143, 20], [145, 32]]]], ["block", "if", [["get", "isAdministrator", ["loc", [null, [148, 22], [148, 37]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [148, 16], [191, 23]]]], ["content", "appVersion", ["loc", [null, [197, 35], [197, 49]]], 0, 0, 0, 0], ["element", "action", ["logout"], [], ["loc", [null, [200, 19], [200, 38]]], 0, 0], ["content", "outlet", ["loc", [null, [207, 0], [207, 10]]], 0, 0, 0, 0], ["content", "mc-contract/modal", ["loc", [null, [208, 0], [208, 21]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});