define("my-care-git-ember-portal/mixins/get-date-of-week", ["exports", "ember"], function (exports, _ember) {

  /**
   * Loadable mixin
   * This mixin is used to store loading state in controller.
   */
  exports["default"] = _ember["default"].Mixin.create({
    getMonday: function getMonday(d) {
      if (d) {
        d = new Date(d);
      } else {
        d = new Date();
      }
      var day = d.getDay(),
          diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is monday
      var dd = new Date(d.setDate(diff));
      return new Date(dd);
    },

    getSunday: function getSunday(d) {
      if (d) {
        d = new Date(d);
      } else {
        d = new Date();
      }
      var day = d.getDay(),
          diff = d.getDate() + 7 - day + (day === 0 ? -7 : 0); // adjust when day is sunday
      var dd = new Date(d.setDate(diff));
      return moment(new Date(dd)).endOf('day').toDate();
    }
  });
});