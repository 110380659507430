define('my-care-git-ember-portal/helpers/seperate-upper-case', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (params) {
        var word = params[0];
        var i = 0;
        var character = '';
        var returnWord = '';
        for (var i = 0; i < word.length; i++) {
            character = word.charAt(i);
            if (character == character.toUpperCase() && i !== 0) {
                returnWord = returnWord + ' ' + character;
            } else {
                returnWord = returnWord + character;
            }
        }
        return returnWord;
    });
});