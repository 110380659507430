define('my-care-git-ember-portal/models/address', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        number: _emberData['default'].attr('string'),
        street: _emberData['default'].attr('string'),
        suburb: _emberData['default'].attr('string'),
        city: _emberData['default'].attr('string'),
        postcode: _emberData['default'].attr('string'),
        addressStatus: _emberData['default'].attr('string'),
        country: _emberData['default'].belongsTo('country', { async: true }),
        person: _emberData['default'].belongsTo('person'),
        verificationCode: _emberData['default'].attr('string'),
        isInvalidAddress: (function () {
            return this.get('addressStatus') == 1;
        }).property('addressStatus'),
        isPending: (function () {
            return this.get('addressStatus') == 2;
        }).property('addressStatus'),
        isValidAddress: (function () {
            return this.get('addressStatus') == 3;
        }).property('addressStatus'),
        isNotAddressExist: (function () {
            return this.get('addressStatus') === null || this.get('addressStatus') === undefined;
        }).property('addressStatus'),
        getStatusLabel: (function () {
            if (this.get('addressStatus') == 1) {
                return "";
            } else if (this.get('addressStatus') == 2) {
                return "Verify";
            } else if (this.get('addressStatus') == 3) {
                return "";
            } else {
                return "";
            }
        }).property('addressStatus'),
        getAddressString: (function () {
            return this.get('number') + ',' + this.get('street') + ',' + this.get('suburb');
        }).property('number', 'street', 'suburb'),
        getLocation: (function () {
            if (this.get('location')) {
                return this.get('location');
            } else {
                return 'loading...';
            }
        }).property('location'),

        setLocation: (function () {
            var self = this;
            this.get('country').then(function (country) {
                self.set('location', self.get('city') + ', ' + country.get("name"));
            });
        }).observes('country')

    });
});