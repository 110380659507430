define('my-care-git-ember-portal/mixins/guid-creater', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Loadable mixin
   * This mixin is used to store loading state in controller.
   */
  exports['default'] = _ember['default'].Mixin.create({

    getGuid: function getGuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
  });
});