define('my-care-git-ember-portal/index/board-reports/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        fetchDataService: _ember['default'].inject.service('fetch-data'),
        hiresReportTypes: ['dssHires', 'privateHires', 'hires'],
        hiresStartingTime: moment().add(-12, 'month'),
        completeWorkers: ['completeWorkers'],
        completeWorkersTime: moment().add(-12, 'month'),
        listingReportTypes: ['dssJobListings', 'privateJobListings', 'jobListings'],
        listingsStartingTime: moment().add(-12, 'month'),
        seekersTypes: ['dssClients', 'privateClients', 'clients'],
        clientsStartingTime: moment().add(-12, 'month'),
        agreements: ['dssAgreements', 'privateAgreements', 'agreements'],
        agreementStartingTime: moment().add(-12, 'month'),
        activeAgreements: ['activeDssAgreements', 'activePrivateAgreements', 'activeAgreements'],
        activeAgreementsTime: moment().add(-12, 'month'),
        visits: ['dssApprovedVisits', 'privateApprovedVisits', 'approvedVisits'],
        visitApprovedStartingTime: moment().add(-12, 'month'),
        activePlatformClients: ['activeDssPlatformClients', 'activePrivatePlatformClients', 'activePlatformClients'],
        activePlatformClientsTime: moment().add(-12, 'month'),
        workers: ['activePlatformWorkers'],
        workersStartingTime: moment().add(-12, 'month'),
        approvedVisits: ['dssApprovedVisits', 'privateApprovedVisits', 'approvedVisits'],
        approvedVisitsTime: moment().add(-12, 'month'),
        platformServiceRevenues: ['platformServiceDssRevenues', 'platformServicePrivateRevenues', 'platformServiceRevenues'],
        platformServiceRevenuesTime: moment().add(-12, 'month'),

        averageClientHourlyCosts: ['averageDssClientHourlyCost', 'averagePrivateClientHourlyCost', 'averageClientHourlyCost'],
        averageClientHourlyCostsTime: moment().add(-12, 'month'),

        averageMycareHourlyFees: ['averageDssMycareHourlyFee', 'averagePrivateMycareHourlyFee', 'averageMycareHourlyFee'],
        averageMycareHourlyFeesTime: moment().add(-12, 'month'),

        averageWorkerIncome: ['averageWorkerIncome'],
        averageWorkerIncomeTime: moment().add(-12, 'month'),

        averageWorkerNegotiatedRate: ['averageWorkerNegotiatedRate'],
        averageWorkerNegotiatedRateTime: moment().add(-12, 'month'),

        agreementsStartingTime: moment().add(-12, 'month'),
        updateDashboard: function updateDashboard() {
            var self = this;
            self.get('fetchDataService').fetchFromServer('/api/Reports/dashboard').then(function (data) {
                // first row //consumer
                self.set('publishedProfiles', data.publishedProfiles);
                self.set('clientProfiles', data.clientProfiles);
                self.set('openJobListings', data.openJobListings);
                self.set('hoursAdvertised', data.hoursAdvertised);
                self.set('hoursAdvertisedYear', data.hoursAdvertisedYear);
                // second row
                self.set('consumerContracts', data.consumerContracts - 5);
                self.set('consumerDeposits', data.consumerDeposits);
                self.set('consumerHoursScheduled', data.consumerHoursScheduled);
                self.set('consumerSpend', data.consumerSpend);
                self.set('consumerHoursWorked', data.consumerHoursWorked);
                // third row
                self.set('consumerMycareFees', data.consumerMycareFees);
                self.set('consumerHourlyFee', data.consumerHourlyFee);
                self.set('consumerHourlyRate', (data.consumerCostClient - data.consumerHourlyFee) / 0.95);
                self.set('consumerCostClient', data.consumerCostClient);
                self.set('consumerRunRate', data.consumerRunRate);
            });
            // api/Reports/Dashboard (publishedProfiles)
        }
    });
});