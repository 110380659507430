define('my-care-git-ember-portal/utils/Validators', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {
    isRequired: function isRequired(name, value) {
      var sub = !_ember['default'].isNone(value) && !_ember['default'].isNone(value.trim) ? value.trim() : value;
      var isValid = !_ember['default'].isEmpty(sub) && !_ember['default'].isNone(sub);
      return {
        result: isValid,
        message: isValid ? null : name + ' is required'
      };
    },

    isNonNegative: function isNonNegative(name, value) {
      var sub = !_ember['default'].isNone(value) && !_ember['default'].isNone(value.trim) ? value.trim() : value;
      var isValid = sub >= 0;
      return {
        result: isValid,
        message: isValid ? null : name + ' should be greater than 0'
      };
    },

    noSpecialCharacters: function noSpecialCharacters(name, value) {
      return {
        result: !/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g.test(value),
        message: name + ' should contain only letters.'
      };
    },

    isPhoneNumber: function isPhoneNumber(name, value) {
      return {
        result: !_ember['default'].isEmpty(value) && !_ember['default'].isEmpty(value.split('-')[1]) && value.split('-')[1].length >= 4 && value.split('-')[1].length <= 13 && /^(?!0+$)[a-zA-Z0-9]+$/.test(value.split('-')[1]),
        message: name + ' is required and must be between 4 and 13 digits.'
      };
    },

    consent: function consent(name, value) {
      return {
        result: value === true,
        message: name + ' is required'
      };
    },

    isEmail: function isEmail(name, value) {
      var sub = !_ember['default'].isNone(value) && !_ember['default'].isNone(value.trim) ? value.trim() : value;
      var emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var isValid = !_ember['default'].isNone(sub) && !_ember['default'].isNone(sub.match(emailRegx));
      return {
        result: isValid,
        message: isValid ? null : name + ' must be valid email address'
      };
    },

    isEqual: function isEqual(_ref) {
      var name1 = _ref.name1;
      var value1 = _ref.value1;
      var name2 = _ref.name2;
      var value2 = _ref.value2;

      var sub1 = !_ember['default'].isNone(value1) && !_ember['default'].isNone(value1.trim) ? value1.trim() : value1;
      var sub2 = !_ember['default'].isNone(value2) && !_ember['default'].isNone(value2.trim) ? value2.trim() : value2;
      var isValid = _ember['default'].isEqual(sub1, sub2);
      return {
        result: isValid,
        message: isValid ? null : name1 + ' must be the same as ' + name2
      };
    }
  };
});