define('my-care-git-ember-portal/index/agreement/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        errors: [],
        contracts: [],
        isMore: true,
        status: '',
        _pageSize: 10,
        _currentAmount: 0,
        _totalAmount: 0,
        confirmSearchString: '',
        setup: function setup() {
            this.set('_currentAmount', this.get('contracts.length'));
            this.set('_totalAmount', this.get('contracts.meta.total'));
        },
        actions: {
            doSearch: function doSearch() {
                var self = this;
                self.set('isLoading', true);
                self.set('offset', 0);
                this.set('selectedPage', '');
                this.set('_currentAmount', self.get('_pageSize'));
                if (_ember['default'].isEmpty(self.get('searchString'))) {
                    self.getSearchResults(self.get('pageSize'), self.get('offset'), self.get('status')).then(function (agreements) {
                        var _currentAmount = agreements.get('length');
                        self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                        self.set('contracts', agreements.toArray());
                        self.set('_totalAmount', agreements.get('meta.total'));
                        self.set('isLoading', false);
                    });
                } else {
                    self.set('confirmSearchString', self.get('searchString'));
                    self.getSearchResults(self.get('pageSize'), self.get('offset'), self.get('status'), self.get('searchString')).then(function (agreements) {
                        self.set('_currentAmount', agreements.get('length'));
                        self.set('_totalAmount', agreements.get('meta.total'));
                        self.set('contracts', agreements.toArray());
                        self.set('isLoading', false);
                    });
                }
            },

            onClickPage: function onClickPage(page) {
                var _this = this;

                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.set('selectedPage', page);
                this.getSearchResults(this.get('_pageSize'), offSet, this.get('status'), this.get('confirmSearchString')).then(function (contracts) {
                    var _currentAmount = offSet + contracts.get('length');
                    _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                    _this.set('contracts', contracts);
                });
            }
        },
        getSearchResults: function getSearchResults(pagesize, offset, status, searchString) {
            var self = this;
            self.set('isLoading', true);
            return self.store.query('Contract', { pagesize: pagesize, offset: offset, status: status, s: searchString }).then(function (contract) {
                self.set('isLoading', false);
                return contract;
            }, function (error) {
                console.log(error);
                self.set('isLoading', false);
                toastr.error('An error has occurred');
            });
        }
    });
});