define('my-care-git-ember-portal/helpers/percentage-format', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (params) {
        var number = parseFloat(params[0]);
        if (_ember['default'].isEmpty(number)) {
            return '0%';
        } else {
            var percent = parseFloat(number * 100).toFixed(2) + "%";
            return percent;
        }
    });
});