define('my-care-git-ember-portal/components/mc-radio-picker/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * phone-picker component
     */
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            clickOption: function clickOption(item) {
                this.get('radioItems').forEach(function (item) {
                    _ember['default'].set(item, "isSelected", false);
                });
                _ember['default'].set(item, "isSelected", true);
                this.get('action')(item.id);
            }
        }
    });
});