define('my-care-git-ember-portal/index/job-listings/detail-redirect/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return this.store.findRecord('job-listing', params.job_listing_id, { reload: true });
        },

        afterModel: function afterModel(model) {
            this.transitionTo('index.job-listings.detail', model);
        }
    });
});