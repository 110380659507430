define('my-care-git-ember-portal/models/registration', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        dateOfBirth: _emberData['default'].attr('string'),
        gender: _emberData['default'].attr('string'),
        address: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        phoneNumber: _emberData['default'].attr('string'),
        uniqueId: _emberData['default'].attr('string'),
        relationship: _emberData['default'].attr('string'),
        referrer: _emberData['default'].attr('string'),
        organisation: _emberData['default'].belongsTo('organisation', { async: true }),
        fundingHost: _emberData['default'].belongsTo('funding-host', { async: true }),
        benefitAmount: _emberData['default'].attr('number'),
        atHome: _emberData['default'].attr('boolean'),
        hasChildren: _emberData['default'].attr('boolean'),
        hasPartner: _emberData['default'].attr('boolean'),
        isUsed: _emberData['default'].attr('boolean'),
        code: _emberData['default'].attr('string'),
        benefitTypes: _emberData['default'].hasMany('benefit-type', { async: true }),
        ethnicities: _emberData['default'].hasMany('ethnicity', { async: true }),
        disabilities: _emberData['default'].hasMany('disability', { async: true }),
        fundingTypes: _emberData['default'].hasMany('funding-type', { async: true }),
        registrationType: _emberData['default'].belongsTo('registration-type', { async: true }),
        person: _emberData['default'].attr('string'),
        contactPerson: _emberData['default'].attr('string'),
        businessName: _emberData['default'].attr('string'),
        costCentre: _emberData['default'].attr('string'),

        getFirstName: (function () {
            if (Ember.isNone(this.get('firstName'))) {
                return "";
            } else {
                return this.get('firstName');
            }
        }).property('firstName'),

        getLastName: (function () {
            if (Ember.isNone(this.get('lastName'))) {
                return "";
            } else {
                return this.get('lastName');
            }
        }).property('lastName'),
        getEmail: (function () {
            if (Ember.isNone(this.get('email'))) {
                return "";
            } else {
                return this.get('email');
            }
        }).property('email'),

        getRegistrationType: (function () {
            if (this.get('registrationType.name') === 'WI') {
                return 'Work and Income';
            } else {
                return this.get('registrationType.name');
            }
        }).property('registrationType')
    });
});