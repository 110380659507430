define("my-care-git-ember-portal/components/mc-weekly-picker/component", ["exports", "ember", "my-care-git-ember-portal/mixins/get-date-of-week", "my-care-git-ember-portal/utils/common"], function (exports, _ember, _myCareGitEmberPortalMixinsGetDateOfWeek, _myCareGitEmberPortalUtilsCommon) {
    exports["default"] = _ember["default"].Component.extend(_myCareGitEmberPortalMixinsGetDateOfWeek["default"], {
        tagName: '',
        stringOfWeek: 'This week',
        intOfCurrentWeek: 0,
        date: '',
        setup: (function () {
            var self = this;
            self.set('componentId', _myCareGitEmberPortalUtilsCommon["default"].createGuid() + 'DatePicker');
            _ember["default"].run.scheduleOnce('afterRender', this, function () {
                if (_ember["default"].isEmpty(self.get('selectedDate'))) {
                    self.setDate();
                } else {
                    self.setStartAndEndOfWeek(moment(self.get('selectedDate')).utc());
                    self.set('selectedDate', self.get('selectedDate'));
                }
            });
        }).on('init'),

        setStartAndEndOfWeek: function setStartAndEndOfWeek(date) {
            var dateOfToday = new Date();
            if (date) {
                dateOfToday = new Date(date);
            }
            var dateOfMonday = this.getMonday(dateOfToday);
            var dateOfSunday = this.getSunday(dateOfToday);
            this.set('selectedMonday', dateOfMonday);
            this.set('selectedSunday', dateOfSunday);

            this.sendAction('onSelectDate', { startDate: this.get('selectedMonday'), endDate: this.get('selectedSunday') });
            this.setDateDifference(dateOfMonday);
        },

        setDateDifference: function setDateDifference(date) {
            var now = moment.utc(this.getMonday(new Date())); //todays date
            var duration = moment.duration(now.diff(date));
            var days = duration.asDays();
            var dayOfDiff = Math.round(days / 7);
            if (dayOfDiff === 0) {
                this.set('intOfCurrentWeek', dayOfDiff);
            } else {
                this.set('intOfCurrentWeek', -dayOfDiff);
            }
            this.watchIntOfCurrentWeek();
        },

        watchDate: (function () {
            var selectedDate = this.get('date');
            this.setDate(selectedDate);
        }).observes('date'),

        isAbleToAddTimeSheet: (function () {
            var dateOfToday = new Date();
            var dateOfMonday = this.getMonday(dateOfToday);
            return !!(this.get('isScheduleView') === true && this.get('isSeeker') === true && moment.utc(this.get('selectedSunday')).isAfter(dateOfMonday));
        }).property('selectedSunday', 'isScheduleView'),

        watchIntOfCurrentWeek: function watchIntOfCurrentWeek() {
            if (this.get('intOfCurrentWeek') === 0) {
                this.set('stringOfWeek', 'Current week');
            } else if (this.get('intOfCurrentWeek') === 1) {
                this.set('stringOfWeek', 'Next week');
            } else if (this.get('intOfCurrentWeek') > 1) {
                this.set('stringOfWeek', 'In ' + this.get('intOfCurrentWeek') + ' weeks');
            } else if (this.get('intOfCurrentWeek') === -1) {
                this.set('stringOfWeek', 'Last week');
            } else if (this.get('intOfCurrentWeek') < -1) {
                this.set('stringOfWeek', Math.abs(this.get('intOfCurrentWeek')) + ' weeks ago');
            }
        },

        setDate: function setDate(date) {
            var self = this;
            var selectedDate = date ? moment(date, 'DD/MM/YYYY').startOf('day').utc() : moment().startOf('day').utc();

            self.setStartAndEndOfWeek(selectedDate);
            var formattedDate = selectedDate.toISOString();
            self.set('selectedDate', formattedDate);
        },

        actions: {
            getPreviousWeek: function getPreviousWeek() {
                var selectedDate = new Date(this.get('selectedMonday'));
                var diff = selectedDate.getDate() - 6;
                var dd = new Date(selectedDate.setDate(diff));
                var selectedDateString = moment.utc(dd).format('DD/MM/YYYY').toString();
                _ember["default"].Application.selectedDate = selectedDateString;
                this.setDate(selectedDateString);
            },

            getNextWeek: function getNextWeek() {
                var selectedDate = new Date(this.get('selectedMonday'));
                var diff = selectedDate.getDate() + 8;
                var dd = new Date(selectedDate.setDate(diff));
                var selectedDateString = moment.utc(dd).format('DD/MM/YYYY').toString();
                _ember["default"].Application.selectedDate = selectedDateString;
                this.setDate(selectedDateString);
            }
        }

    });
});