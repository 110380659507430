define('my-care-git-ember-portal/services/account-error-service', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Service.extend({
        _store: _ember['default'].inject.service('store'),
        getSearchResults: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(pagesize, offset, status, searchString) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').query('accountError', { pagesize: pagesize, offset: offset, status: status, searchString: searchString });

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),
        removeAccountError: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(accountError) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        accountError.deleteRecord();
                        return context$1$0.abrupt('return', accountError.save());

                    case 2:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});