define('my-care-git-ember-portal/index/registrations/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        beforeModel: function beforeModel() {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
                return;
            } else {
                this.transitionTo('index.registrations.all');
            }
        }

    });
});