define('my-care-git-ember-portal/index/threads/not-responded/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = self.controllerFor('index.threads.index');
            var radioItems = [{ id: 'index', 'name': 'All', isSelected: false }, { id: 'not-responded', 'name': 'Not responded', isSelected: true }];
            controller.set('radioItems', radioItems);
            controller.set('notResponded', true);
            self.render('index.threads.index', { controller: controller });
            if (_ember['default'].isEmpty(controller.get('content'))) {
                controller.getSearchResults(50, 0, true).then(function (threads) {
                    controller.set('content', threads);
                });
            }
        }
    });
});