define('my-care-git-ember-portal/index/reports/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        fetchDataService: _ember['default'].inject.service('fetch-data'),
        workersReportTypes: ['completeWorkers', 'workers'],
        clientsReportTypes: ['dssClients', 'privateClients', 'clients'],
        jobListingsReportTypes: ['dssJobListings', 'privateJobListings', 'jobListings'],
        matchesReportTypes: ['dssMatches', 'privateMatches', 'matches'],
        matchTypesReportTypes: ['applications', 'opportunities', 'matches'],
        hiresReportTypes: ['dssHires', 'privateHires', 'hires'],
        hireTypesReportTypes: ['applicationHires', 'opportunityHires', 'hires'],
        agreementsReportTypes: ['dssAgreements', 'privateAgreements', 'agreements'],
        createdVisitsReportTypes: ['dssCreatedVisits', 'privateCreatedVisits', 'createdVisits'],
        scheduledVisitsReportTypes: ['dssScheduledVisits', 'privateScheduledVisits', 'scheduledVisits'],
        approvedVisitsReportTypes: ['dssApprovedVisits', 'privateApprovedVisits', 'approvedVisits'],

        workersStartingTime: moment().add(-11, 'month'),
        clientsStartingTime: moment().add(-11, 'month'),
        listingsStartingTime: moment().add(-11, 'month'),
        matchesStartingTime: moment().add(-11, 'month'),
        matchTypesStartingTime: moment().add(-11, 'month'),

        hiresStartingTime: moment().add(-11, 'month'),
        hiresTypesStartingTime: moment().add(-11, 'month'),
        agreementsStartingTime: moment().add(-11, 'month'),
        createdVisitsStartingTime: moment().add(-11, 'month'),
        approvedVisitsStartingTime: moment().add(-11, 'month'),

        scheduledVisitsStartingTime: moment().add(-10, 'month'),

        updateDashboard: function updateDashboard() {
            var self = this;
            self.get('fetchDataService').fetchFromServer('/api/Reports/dashboard').then(function (data) {
                // first row //consumer
                self.set('publishedProfiles', data.publishedProfiles);
                self.set('clientProfiles', data.clientProfiles);
                self.set('openJobListings', data.openJobListings);
                self.set('hoursAdvertised', data.hoursAdvertised);
                self.set('hoursAdvertisedYear', data.hoursAdvertisedYear);
                // second row
                self.set('consumerContracts', data.consumerContracts - 5);
                self.set('consumerDeposits', data.consumerDeposits);
                self.set('consumerHoursScheduled', data.consumerHoursScheduled);
                self.set('consumerSpend', data.consumerSpend);
                self.set('consumerHoursWorked', data.consumerHoursWorked);
                // third row
                self.set('consumerMycareFees', data.consumerMycareFees);
                self.set('consumerHourlyFee', data.consumerHourlyFee);
                self.set('consumerHourlyRate', (data.consumerCostClient - data.consumerHourlyFee) / 0.95);
                self.set('consumerCostClient', data.consumerCostClient);
                self.set('consumerRunRate', data.consumerRunRate);
            });
        },

        actions: {
            setAllDates: function setAllDates(date) {
                this.set('workersStartingTime', date);
                this.set('clientsStartingTime', date);
                this.set('listingsStartingTime', date);
                this.set('matchesStartingTime', date);
                this.set('matchTypesStartingTime', date);

                this.set('hiresStartingTime', date);
                this.set('hiresTypesStartingTime', date);
                this.set('agreementsStartingTime', date);
                this.set('createdVisitsStartingTime', date);
                this.set('scheduledVisitsStartingTime', date);
                this.set('approvedVisitsStartingTime', date);
            }
        }
    });
});