define('my-care-git-ember-portal/components/mc-button-with-search-filter-dropdown/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers', 'my-care-git-ember-portal/utils/mc-component'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _myCareGitEmberPortalUtilsMcComponent) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_value', undefined);
            this.initVar('_listOptions', []);
            this.initVar('options', []);
            this.initVar('filteredOptions', []);
            this.initVar('isShow', false);
            this.initVar('isLoaded', false);
        },

        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('isLoaded') === false) {
                this._setupOptions();
            }
        },

        _setupOptions: function _setupOptions() {
            var self = this;
            self.get('options').clear();
            self.get('filteredOptions').clear();
            self.get('_listOptions').forEach(function (option, index) {
                var option = _ember['default'].Object.create({ id: index, value: option, isShow: false });
                self.get('options').pushObject(option);
                self.get('filteredOptions').pushObject(option);
            });
            this.set('isLoaded', true);
        },

        watchRegistrationType: (function () {
            var _this = this;

            var filter = this.get('_value').toUpperCase();
            this.get('filteredOptions').clear();
            this.get('options').forEach(function (option) {
                if (option.get('value').toUpperCase().indexOf(filter) > -1) {
                    _this.get('filteredOptions').pushObject(option);
                }
            });
            _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('setItem'), this.get('_value'));
            this.set('isShow', true);
        }).observes('_value'),

        actions: {
            toggleShowDropdown: function toggleShowDropdown() {
                if (this.get('isShow') === true) {
                    this._setupOptions();
                }
                this.set('isShow', !this.get('isShow'));
            },

            enter: function enter() {
                this.set('isShow', false);
            },

            setSelectValue: function setSelectValue(value) {
                this.set('_value', value);
                this.set('isShow', false);
            }

        }
    });
});