define('my-care-git-ember-portal/index/browse/profile/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/scroll_top'], function (exports, _ember, _myCareGitEmberPortalMixinsScroll_top) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsScroll_top['default'], {
        model: function model(params) {
            var result = _ember['default'].Object.create({});
            return this.store.findRecord('person', params.profile_id).then(function (person) {
                result.set('person', person);
                return person;
            }).then(function (person) {
                return person.get('trusts').then(function (trusts) {
                    result.set('trusts', trusts);
                    return result;
                });
            });
        },
        setupController: function setupController(controller, model) {

            var trust = model.get('trusts').objectAt(model.get('trusts.length') - 1);
            controller.set('isNoMinistryCheck', trust.get('statusType') === 1);
            controller.set('isMinistryCheckComplete', trust.get('statusType') === 2);
            controller.set('isMinistryCheckPending', trust.get('statusType') === 4);
            controller.set('isMinistryCheckConviction', trust.get('statusType') === 5);

            controller.set('model', model.get('person'));
        }
    });
});