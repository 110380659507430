define('my-care-git-ember-portal/components/mc-date-picker-with-toggle-button/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * date-picker component
     * Options: <br>
     *   selection: bind variable to update when value is selected.
     */
    exports['default'] = _ember['default'].Component.extend({
        /**
         * Setup component on startup
         */
        setup: (function () {
            var self = this;

            //Initialise date picker
            var input = this.$('#' + this.get('componentId')).datepicker({
                autoclose: true,
                //Do not change this date format if you can. If you have to do, please check all date related code with momentjs.
                format: 'dd/mm/yyyy',
                todayHighlight: true
            });

            //Call back when date selection is changed
            input.on("changeDate", function (event) {
                //update the property bind with 'selection'
                self.set("selection", event.format());
            });
            this.set('tempSelection', this.get('selection'));

            this._super();
            _ember['default'].run.scheduleOnce('afterRender', this, this.afterRenderEvent);
        }).on('didInsertElement'),

        afterRenderEvent: function afterRenderEvent() {
            _ember['default'].$('#' + this.get('componentId')).datepicker('setDate', this.get('selection'));
            if (!this.get('selection')) {
                this.set('isEnable', true);
            }
        },

        /**
         * Reset date picker with selected values when selection was maded.
         */
        reset: (function () {
            this.$('.cp-datePicker').datepicker('setDate', this.get('selection'));
        }).observes('selection'),

        startdate: (function () {
            if (this.get('isEnable')) {
                this.set('selection', '');
            } else {
                this.set('selection', this.get('tempSelection'));
            }
        }).observes('isEnable')
    });
});