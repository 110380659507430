define('my-care-git-ember-portal/components/mc-task-button/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('task', undefined);
            this.initVar('params', undefined);
            this.initVar('text', undefined);
            this.initVar('isDisabled', false);

            _ember['default'].Logger.assert(!_ember['default'].isNone(this.get('task')));
        }
    });
});