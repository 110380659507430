define('my-care-git-ember-portal/models/job-application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        person: _emberData['default'].belongsTo('person', { async: true }),
        jobApplicationStatus: _emberData['default'].belongsTo('job-application-status-type', { async: true }),
        jobListing: _emberData['default'].belongsTo('job-listing', { async: true }),
        createdOn: _emberData['default'].attr('string'),
        appliedOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        body: _emberData['default'].attr('string'),
        files: _emberData['default'].hasMany('file', { async: true }),
        thread: _emberData['default'].belongsTo('thread', { async: true }),
        createMessage: _emberData['default'].attr('boolean'),
        isOpportunity: _emberData['default'].attr('boolean'),
        isHired: (function () {
            return this.get('jobApplicationStatus.id') == 5;
        }).property('jobApplicationStatus'),
        jobApplicationStatusId: (function () {
            return this.get('jobApplicationStatus.id');
        }).property('jobApplicationStatus')
    });
});