define('my-care-git-ember-portal/components/mc-sortby-button/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        // customIcon is an attribute to create font awesome icon
        tagName: '',
        isReserve: true,
        actions: {
            doAction: function doAction() {
                this.sendAction('action', this.get('param'));
            }
        }
    });
});