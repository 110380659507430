define("my-care-git-ember-portal/components/mc-experience/gallery/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-component", "my-care-git-ember-portal/utils/common", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon, _emberConcurrency) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({
        _imageService: _ember["default"].inject.service('image'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_avatarUploaderChannelId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
            this.initVar('formData', {
                uploadedFiles: _ember["default"].A()
            });
            this.initVar('_errors', []);
            this.initVar('_fileBlocks', _ember["default"].A([]));
            this.initVar('_uploadFiles', null);
            this.initVar('onCompleted', null);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._loadDataAsync();
        },

        _loadDataAsync: function _loadDataAsync() {
            var _this = this;

            this.get('_fileBlocks').forEach(function (block) {
                _this.get('formData.uploadedFiles').pushObject(block.get('file'));
            });
        },

        actions: {
            onCompleted: function onCompleted(_fileBlock) {
                var _this2 = this;

                this.get('_imageService.saveAsync').perform(_fileBlock.get('file')).then(function (savedFile) {
                    var index = _fileBlock.get('id');
                    if (!_ember["default"].isEmpty(_this2.get('formData.uploadedFiles').objectAt(index))) {
                        _this2.get('formData.uploadedFiles').removeAt(index);
                        _this2.get('formData.uploadedFiles').insertAt(index, savedFile);
                    } else {
                        _this2.get('formData.uploadedFiles').pushObject(savedFile);
                    }
                    var filteredFile = _this2.get('formData.uploadedFiles').filter(function (file) {
                        return !_ember["default"].isEmpty(file);
                    });
                    _this2.sendAction('onCompleted', filteredFile.toArray());
                });
            },

            onDeleted: function onDeleted(file) {
                this.get('formData.uploadedFiles').removeObject(file);
            },

            onSelectedFile: function onSelectedFile(files, resetInput) {
                var _uploadFiles = _ember["default"].A();
                for (var i = 0; i < files.length; i++) {
                    _uploadFiles.pushObject(files[i]);
                }
                this.get('_uploadFiles').pushObjects(_uploadFiles);
                resetInput();
            }
        }

    });
});