define('my-care-git-ember-portal/components/mc-user-detail/file-uploader/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        div_id: 'files',
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },
        actions: {
            deleteFile: function deleteFile(file) {
                bootbox.confirm('Are you sure want to delete?', function (result) {
                    if (result) {
                        file.deleteRecord();
                        file.save().then(function () {
                            toastr.info(file.get('fileName') + ' has been deleted');
                        }, function () {
                            toastr.info('There was an error deleting file, please try again');
                        });
                    }
                });
            }

        }
    });
});