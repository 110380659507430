define('my-care-git-ember-portal/components/mc-caregiver-card/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        isHero: (function () {
            var self = this;
            return parseInt(self.get('model.numberOfHires')) > 0 && self.get('model.numberOfVerifications') === 4 && parseInt(self.get('model.respondRatio')) === 100;
        }).property('model.numberOfHires', 'model.numberOfVerifications', 'model.respondRatio'),

        isVerified: (function () {
            var self = this;
            return self.get('model.numberOfVerifications') === 4;
        }).property('model.numberOfVerifications'),

        messageRatioComplete: (function () {
            var self = this;
            return self.get('model.respondRatio') === 100;
        }).property('model.respondRatio'),

        actions: {}

    });
});