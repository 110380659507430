define('my-care-git-ember-portal/mixins/message-sending', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        sendMessage: function sendMessage(title, body, people, sender, afterSuccess, fnialFunction, errorfunction, isMatch) {
            var self = this;
            var thread = self.store.createRecord('thread', {
                title: title,
                isMatch: isMatch,
                people: people
            });
            return thread.save().then(function (thread) {
                return self.store.createRecord('message', {
                    thread: thread,
                    text: body,
                    sentOn: moment.utc(new Date()).toISOString(),
                    isSentByMe: true,
                    sender: sender
                }).save().then(function (message) {
                    thread.set("message", message);
                    thread.get("messages").pushObject(message);
                    afterSuccess();
                }, function () {
                    errorfunction();
                })['finally'](function () {
                    fnialFunction();
                });
            });
        }
    });
});