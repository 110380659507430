define('my-care-git-ember-portal/mixins/modal-job-apply-mixin', ['exports', 'ember', 'my-care-git-ember-portal/mixins/file-uploader-board', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalMixinsFileUploaderBoard, _myCareGitEmberPortalConfigEnvironment) {
    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create(_myCareGitEmberPortalMixinsFileUploaderBoard['default'], {

        needs: ['application', 'auth'],
        auth: _ember['default'].computed.alias('controllers.auth'),
        person: _ember['default'].computed.alias('controllers.application.model.person'),

        /**
         * File upload url for file
         */
        fileUploadUrl: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/FileUpload',

        /**
         * Url return from server after uploaded to <code>fileUploadUrl</code>
         */
        uploadedFileUrls: [],

        fileTypeAllowList: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'gif'],

        /**
         * Maximum number of file allowance
         */
        maximumFileUpload: 3,

        /**
         * Currently selected file item
         */
        currentFileItem: null,

        /**
         * Check if AddFileButton should be disable in according to maximimFileUpload
         */
        disableAddFileBtn: (function () {
            var numOfFiles = this.get('uploadedFileUrls.length') + this.get('parentObj.files.length');
            return numOfFiles >= this.get('maximumFileUpload');
        }).property('uploadedFileUrls.[]', 'maximumFileUpload', 'parentObj.files.[]'),

        setBody: (function () {
            var self = this;
            if (!self.get('body')) {
                this.store.findRecord('person', this.get('auth.currentPersonId')).then(function (currentUser) {
                    self.get('model.person').then(function (listingOwner) {
                        self.set('body', 'Hi ' + listingOwner.get('firstName') + ', My name is ' + currentUser.get('firstName') + ',' + ' I would like to apply for the job you listed ' + self.get('model.title') + '.' + ' For more information please view my profile. Regards, ' + currentUser.get('firstName'));
                    });
                });
            }
        }).observes('model.person'),

        actions: {
            /**
             * Use this method for link instead of link-to helper.
             * link-to helper does not hide boostrap modal and the black overlay will keep showing.
             */
            afterApplyContinueSearch: function afterApplyContinueSearch() {
                _ember['default'].$('.modal').modal('hide');
                this.transitionToRoute('browse.jobs');
            },

            /**
             * Use this method for link instead of link-to helper.
             * link-to helper does not hide boostrap modal and the black overlay will keep showing.
             */
            afterApplyCheckMessage: function afterApplyCheckMessage() {
                _ember['default'].$('.modal').modal('hide');
                this.transitionToRoute('threads');
            },

            /**
             * Add file to person
             */
            addFile: function addFile() {
                var self = this;
                if (!self.get('disableAddFileBtn')) {
                    self.get('uploadedFileUrls').pushObject(_ember['default'].Object.create({
                        id: self.get('uploadedFileUrls.length') + 1,
                        url: '',
                        fileName: '',
                        uploadedByPerson: null
                    }));
                }
            },

            /**
             * Delete file from person
             * @param file to delete
             */
            deleteFile: function deleteFile(file) {
                if (file instanceof this.store.modelFor('file')) {
                    this.get('parentObj.files').removeObject(file);
                } else {
                    this.get('uploadedFileUrls').removeObject(file);
                }
            },

            /**
             * Open file picker
             * @param file file that is curretly editing
             */
            openFilePicker: function openFilePicker(file) {
                if (!this.get('disableAddFileBtn')) {
                    this.set('currentFileItem', file);
                    _ember['default'].$('#modal-file-picker').modal('hide');
                    _ember['default'].$('#modal-file-picker').modal('show');
                }
            },

            /**
             * Choose file
             * @param file file to be chosen
             */
            chooseFile: function chooseFile(file) {
                this.get('parentObj.files').removeObject(file);
                this.get('parentObj.files').pushObject(file);
                _ember['default'].$('#modal-file-picker').modal('hide');
            },

            saveDraft: function saveDraft(isDraft) {
                var self = this;
                if (isDraft) {
                    self.set('isSavingApplicationDraft', true);
                } else {
                    self.set('isSavingApplication', true);
                }
                var app = self.get('application');
                var appType = this.store.findRecord('job-application-status-type', isDraft ? 1 : 2);
                appType.then(function (appType) {
                    app.set('body', self.get('body'));
                    app.set('jobApplicationStatus', appType);
                    app.save().then(function () {
                        _ember['default'].$('.modal').modal('hide');
                        self.checkIfAlreadyApplied();
                        if (isDraft) {
                            self.set('isSavingApplicationDraft', false);
                            toastr.info('Draft is saved.');
                        } else {
                            self.set('isSavingApplication', false);
                            toastr.info('Application is submitted.');
                        }
                    });
                });
            },
            /**
             * Apply job.
             * @param isDraft true if the job application should be saved as Draft. Otherwise false.
             */
            makeApplication: function makeApplication(isDraft) {

                if (!this.get('auth.isAuthenticated')) {
                    this.transitionToRoute('login');
                    return;
                }
                var self = this;
                if (isDraft) {
                    self.set('isSavingApplicationDraft', true);
                } else {
                    self.set('isSavingApplication', true);
                }
                var currentPersonId = this.get('controllers.auth.currentPersonId');
                var app = self.store.createRecord('job-application');
                var applyingPerson = null;

                self.saveFiles(app).then(function () {
                    return self.store.findRecord('person', currentPersonId);
                }).then(function (person) {
                    applyingPerson = person;
                    return self.store.findRecord('job-application-status-type', isDraft ? 1 : 2);
                }).then(function (applicationStatus) {
                    self.get('parentObj.files').forEach(function (item) {
                        app.get('files').pushObject(item);
                    });
                    app.set('jobApplicationStatus', applicationStatus);
                    app.set('appliedOn', moment.utc(new Date()).toISOString());
                    app.set('modifiedOn', moment.utc(new Date()).toISOString());
                    app.set('person', applyingPerson);
                    app.set('jobListing', self.get('model'));

                    app.set('body', self.get('body'));
                    return app.save();
                }).then(function (jobApplication) {
                    applyingPerson.get('jobApplications').pushObject(jobApplication);
                    return applyingPerson.save();
                }).then(function () {
                    self.hideLoader();
                    self.set('body', '');
                    _ember['default'].$('.modal').modal('hide');
                    if (isDraft) {
                        self.set('isSavingApplicationDraft', false);
                        _ember['default'].$('#modal-afterjob-apply-draft').modal('show');
                    } else {
                        self.set('isSavingApplication', false);
                        _ember['default'].$('#modal-afterjob-apply').modal('show');
                    }
                });
            }
        },

        /**
         * save files
         * @param app job application model
         * @returns {*}
         */
        saveFiles: function saveFiles(app) {
            var self = this;
            var defer = _ember['default'].RSVP.defer();
            var uploadedFileUrls = self.get('uploadedFileUrls');
            if (uploadedFileUrls.length > 0) {
                _.each(uploadedFileUrls, function (fileItem, index) {
                    self.store.createRecord('file', {
                        fileName: fileItem.get('fileName'),
                        url: fileItem.get('url'),
                        uploadedOn: moment.utc(new Date()).toISOString(),
                        uploadedByPerson: app.get('person')
                    }).save()
                    //								.then(function (file) {
                    //										self.get('person.files').pushObject(file);
                    //										self.get('person').save();
                    //										return file;
                    //								})
                    .then(function (file) {
                        app.get('files').pushObject(file);
                        if (uploadedFileUrls.length - 1 == index) {
                            self.get('uploadedFileUrls').clear();
                            defer.resolve(app);
                        }
                        return file;
                    })['catch'](function (error) {
                        defer.reject(error);
                    });
                });
            } else {
                defer.resolve(app);
            }
            return defer.promise;
        }
    });
});