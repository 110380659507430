define('my-care-git-ember-portal/components/mc-user-table/row/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        isExpend: false,
        tagName: "",

        relatedPeople: (function () {
            var self = this;
            return self.get('model.relationships').filter(function (item) {
                return item.get('id') !== self.get('model.id');
            });
        }).property('model.relationships.[]'),

        hasRelatedPeople: (function () {
            return this.get('model.relationships.length') > 0 ? true : false;
        }).property('model.relationships'),

        actions: {
            toggleExpend: function toggleExpend() {
                if (this.get('hasRelatedPeople') > 0) {
                    this.set('isExpend', !this.get('isExpend'));
                }
            }
        }
    });
});