define("my-care-git-ember-portal/components/mc-worker-review/component", ["exports", "ember", "my-care-git-ember-portal/mixins/validator", "my-care-git-ember-portal/utils/Validators", "my-care-git-ember-portal/utils/mc-modal-component", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcModalComponent, _emberConcurrency) {
    exports["default"] = _myCareGitEmberPortalUtilsMcModalComponent["default"].extend(_myCareGitEmberPortalMixinsValidator["default"], {

        _modalHelper: _ember["default"].inject.service('utils/modal-helper'),
        profileService: _ember["default"].inject.service('profile-service'),
        reviewService: _ember["default"].inject.service('review-service'),
        init: function init() {
            this.initVar('organisers', null);
            this.initVar('_componentId', 'mc-worker-review');
            this.initVar('hasSelected', false);
            this.initVar('formData', {
                professional: null,
                supportive: null,
                trusted: null,
                friendly: null,
                like: null,
                reviewedBy: null,
                publicMessage: null,
                person: null
            });
            this._super.apply(this, arguments);
        },

        _onBeforeOpen: function _onBeforeOpen() {
            this.set('formData', {
                professional: null,
                supportive: null,
                trusted: null,
                friendly: null,
                like: null,
                reviewedBy: null,
                publicMessage: null,
                person: null
            });
            this.get('setOrganisers').perform();
            this._setupValidations();
        },

        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('formData.professional', 'professional', 'Professional', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.supportive', 'supportive', 'Supportive', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.trusted', 'trusted', 'Trusted', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.friendly', 'friendly', 'Friendly', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.like', 'like', 'Like', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
        },

        setOrganisers: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var relatedWiths, organisers;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('model.relatedWith');

                    case 2:
                        relatedWiths = context$1$0.sent;
                        organisers = [];

                        relatedWiths.forEach(function (relationship) {
                            var person = {
                                id: relationship.get('person.id'),
                                name: relationship.get('person.firstName')
                            };
                            organisers.push(person);
                        });
                        this.set('organisers', organisers);
                        if (this.get('organisers').length === 1) {
                            this.set('formData.reviewedBy', relatedWiths.objectAt(0).get('person'));
                            this.set('hasSelected', true);
                        }

                    case 7:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            close: function close() {
                this._close();
            },

            setOrganiser: function setOrganiser(person) {
                this.get('getProfileAsync').perform(person.id);
            },

            sendReviewClick: function sendReviewClick() {
                if (!this._validate()) {
                    return;
                }
                this.get('saveReviewAsync').perform().then(function () {
                    toastr.info('Review saved.');
                });
            },
            setProfessional: function setProfessional(rate) {
                this.set('formData.professional', rate);
            },
            setSupportive: function setSupportive(rate) {
                this.set('formData.supportive', rate);
            },
            setTrusted: function setTrusted(rate) {
                this.set('formData.trusted', rate);
            },
            setFriendly: function setFriendly(rate) {
                this.set('formData.friendly', rate);
            },
            setNotLike: function setNotLike() {
                this.set('formData.like', false);
            },
            setLike: function setLike() {
                this.set('formData.like', true);
            }
        },

        saveReviewAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('formData.person', this.get('agreement.contractorPerson'));
                        this.set('formData.description', this.get('agreement.publicMessage'));
                        context$1$0.next = 4;
                        return this.get('reviewService.postAsync').perform({ formData: this.get('formData') });

                    case 4:
                        this._close();

                    case 5:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getProfileAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            var person;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('profileService').getPersonByIdAsync(id);

                    case 2:
                        person = context$1$0.sent;

                        this.set('formData.reviewedBy', person);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});