define('my-care-git-ember-portal/models/relationship', ['exports', 'ember-data'], function (exports, _emberData) {
		exports['default'] = _emberData['default'].Model.extend({
				person: _emberData['default'].belongsTo('person', { async: true }),
				relatedPerson: _emberData['default'].belongsTo('person', { async: true }),
				role: _emberData['default'].belongsTo('role', { async: true }),
				getRole: (function () {
						return this.get('role').then(function (role) {
								return role;
						});
				}).property('role'),
				isWorker: (function () {
						return this.get('role.name') === 'Worker';
				}).property('role'),
				isOrganiser: (function () {
						return this.get('role.name') === 'Organiser';
				}).property('role')
		});
});