define('my-care-git-ember-portal/index/experience/detail/events/controller', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend({

        formData: _ember['default'].Object.create({}),
        setup: function setup() {
            this.set('formData.events', this.get('model.experienceEvents'));
        },

        actions: {
            redirectTo: function redirectTo(route) {
                this.transitionToRoute(route);
            },
            onSelectedOption: function onSelectedOption() {},

            onSelectedPreparationTime: function onSelectedPreparationTime() {},

            onCompleted: function onCompleted() {}
        }

    });
});