define('my-care-git-ember-portal/index/users/detail/client-accounts/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/mixins/fedback-handler', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalMixinsFedbackHandler, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {

        _clientAccounts: [],

        setup: function setup() {
            var self = this;
            self._getClientAccounts();
        },

        _getClientAccounts: function _getClientAccounts() {
            var _this = this;

            this.get('model.accounts').then(function (accounts) {
                var clientAccounts = accounts.filter(function (account) {
                    return account.get('accountType.name').toLowerCase() !== 'worker';
                });
                _this.set('_clientAccounts', clientAccounts);
            });
        },

        watchClientAccount: (function () {
            this._getClientAccounts();
        }).observes('model')

    });
});