define('my-care-git-ember-portal/index/weekly-visits-report/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        beforeModel: function beforeModel() {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
            } else {
                this.transitionTo('index.weekly-visits-report.seeker-report');
            }
        }

    });
});