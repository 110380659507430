define('my-care-git-ember-portal/index/registrations/new/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        setup: function setup() {
            this.set('registration.registrationType', this.get('registrationTypes').objectAt(0));
        },
        isWorkAndIncoming: (function () {
            return this.get('registration.registrationType.name') == 'WI';
        }).property('registration.registrationType'),

        isDSS: (function () {
            return this.get('registration.registrationType.name') === 'DSS';
        }).property('registration.registrationType'),

        isNDHB: (function () {
            return this.get('registration.registrationType.name') === 'NDHB';
        }).property('registration.registrationType'),

        isMetlifecare: (function () {
            return this.get('registration.registrationType.name') === 'Metlifecare';
        }).property('registration.registrationType'),

        actions: {
            setRegistrationType: function setRegistrationType(type) {
                this.set('registration.registrationType', type);
            },
            redirectTo: function redirectTo(route) {
                this.transitionToRoute(route);
            }
        }
    });
});