define('my-care-git-ember-portal/components/mc-charts/mc-single-report-line-chart/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/lineCharts/single-line-chart-data', 'my-care-git-ember-portal/mixins/lineCharts/baseLineChart'], function (exports, _ember, _myCareGitEmberPortalMixinsLineChartsSingleLineChartData, _myCareGitEmberPortalMixinsLineChartsBaseLineChart) {
    // ****chart.js component****
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsLineChartsBaseLineChart['default'], _myCareGitEmberPortalMixinsLineChartsSingleLineChartData['default'], {
        isLoading: false,
        isShowRawData: false,
        setData: (function () {
            var self = this;
            self.set('chartParentId', self.get('chartId') + '_parent');
            if (self.drawDataChart) {
                if (_ember['default'].isEmpty(self.get('localData'))) {
                    self.drawDataChart(self.get('isShowRawData'));
                } else {
                    _ember['default'].run.later(function () {
                        self.drawChart(self.get('localData'), self.get('isShowRawData'));
                    }, 500);
                }
            }
        }).on('init'),

        watchDate: (function () {
            var self = this;
            if (_ember['default'].isEmpty(self.get('startingTime')) === false) {
                self.drawDataChart(self.get('isShowRawData'));
            }
        }).observes('startingTime'),

        actions: {
            getReport: function getReport() {
                var self = this;
                if (self.drawDataChart && _ember['default'].isEmpty(self.get('startingTime')) === false) {
                    self.drawDataChart(self.get('isShowRawData'));
                }
            }
        }
    });
});