define('my-care-git-ember-portal/components/mc-joblisting-editor/availabilities/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        _store: _ember['default'].inject.service('store'),
        div_id: 'availability',
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('formData', _ember['default'].Object.create({
                when: null,
                availabilities: []
            }));
            this.initVar('personId', null);
            this.initVar('isFormValid', undefined);
            this.initVar('_isInitialised', false);
            this.initVar('_activityGroups', []);
            this.setup();
        },

        _watchFormValid: (function () {
            return this.set('isFormValid', this.get('isValidationsPassed'));
        }).observes('isValidationsPassed', 'isFormDirty', 'validations'),

        isDisabledSaveButton: (function () {
            if (this.get('isFormDirty') === false) {
                return true;
            } else if (this.get('isSaving') === true) {
                return true;
            }
            return false;
        }).property('isSaving', 'isFormDirty'),

        setup: function setup() {
            var _this = this;

            this.set('formData.availabilities', this.get('jobListing.availabilities'));
            this.set('formData.when', this.get('jobListing.when'));
            _ember['default'].run.later(function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this._setupValidation();
                _this._validateAsync({ keepDirty: false });
                _this.set('_isInitialised', true);
                _this.set('isFormDirty', false);
            }, 1000);
        },

        _setupValidation: function _setupValidation() {
            this._resetValidation();
            this.addValidation('formData.availabilities.[]', 'error', 'Availability', [this._validateAvailability]);
            this.addValidation('formData.when', 'error', 'when', [this._validateAvailability]);
        },

        _validateAvailability: function _validateAvailability(name) {
            var isValid = _myCareGitEmberPortalUtilsValidators['default'].isRequired(name, this.get('formData.availabilities')).result;
            return {
                result: isValid || _ember['default'].isEmpty(this.get('formData.when')) === false,
                message: 'Availabilities is requied'
            };
        },

        updateModel: function updateModel() {
            var jobListing = this.get('jobListing');
            jobListing.set('availabilities', this.get('formData.availabilities').slice());
            jobListing.set('when', this.get('formData.when'));
            return jobListing;
        },

        watchFormDirty: (function () {
            var _this2 = this;

            var checkExistingAvailabilities = this.get('jobListing.availabilities').any(function (item) {
                return _ember['default'].isNone(_this2.get('formData.availabilities').find(function (jItem) {
                    return jItem.get('id') === item.get('id');
                }));
            });
            var isDirty = this.get('formData.when') !== this.get('jobListing.when') || this.get('formData.availabilities.length') !== this.get('jobListing.availabilities.length') || checkExistingAvailabilities;
            this.set('isFormDirty', isDirty);
        }).observes('formData.availabilities', 'formData.when'),

        handle502: function handle502(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('jobListing').reload();
                self.redirectTo('index.job-listings.index');
                toastr.error('Please check is job listing saved, and contact Mycare development team.');
            }
        },

        actions: {
            save: function save() {
                var _this3 = this;

                var self = this;
                this._validateAsync({ keepDirty: false }).then(function (isValid) {
                    if (_this3.isDestroyed) {
                        return;
                    }
                    if (isValid) {
                        _this3.set('isSaving', true);
                        _this3.updateModel().save().then(function (jobListing) {
                            if (_this3.isDestroyed) {
                                return;
                            }
                            _this3.set('jobListing', jobListing);
                            _this3.set('isSaving', false);
                            _this3.sendAction('onSaved', jobListing);
                            _this3.set('isFormDirty', false);
                        }, function (error) {
                            self.handle502(error);
                        });
                    }
                });
            }
        }
    });
});