define('my-care-git-ember-portal/mixins/search_settings_mixin', ['exports', 'ember'], function (exports, _ember) {
  /**
   * Loadable mixin
   * This mixin is used to store loading state in controller.
   */
  exports['default'] = _ember['default'].Mixin.create({
    searchString: '',
    pageSize: 10,
    offset: 0
  });
});