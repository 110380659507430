define("my-care-git-ember-portal/components/mc-image-data-cropper/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-modal-component", "my-care-git-ember-portal/utils/function-helpers", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalUtilsMcModalComponent, _myCareGitEmberPortalUtilsFunctionHelpers, _emberConcurrency) {
    exports["default"] = _myCareGitEmberPortalUtilsMcModalComponent["default"].extend({

        _modalHelper: _ember["default"].inject.service('utils/modal-helper'),

        init: function init() {
            var _this = this;

            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-image-cropper');
            this._super.apply(this, arguments);

            this.initVar('description', undefined);
            this.initVar('onCompleted', undefined);
            this.initVar('onCancelled', undefined);
            this.initVar('cropBoxData', { width: 300, height: 300 });
            this.initVar('ratio', 1);
            this.initVar('_selectedFileData', undefined);
            this.initVar('_cropFileDataUrl', undefined);
            this.initVar('_isUploadCompleted', false);
            this.initVar('_stepRegistry', {
                two: false,
                three: false
            });

            this.get('_modalHelper').onHide(this.get('_modalChannelId'), function () {
                if (_this.get('_isUploadCompleted') === false) {
                    _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(_this.get('onCancelled'));
                }
            });
        },

        _onBeforeOpen: function _onBeforeOpen() {
            if (!_ember["default"].isEmpty(this.get('_imageData'))) {
                this.set('currentStep', 2);
                this.get('loadImage').perform();
            }
        },

        loadImage: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var url;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return (0, _emberConcurrency.timeout)(1000);

                    case 2:
                        context$1$0.next = 4;
                        return _ember["default"].RSVP.resolve(this.get('_imageData'));

                    case 4:
                        url = context$1$0.sent;

                        this.set('_selectedFileData', url);
                        this._toStepTwo();

                    case 7:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        _toStepTwo: function _toStepTwo() {
            this.set('_stepRegistry', {
                two: true,
                three: false
            });
        },

        _toStepThree: function _toStepThree() {
            this.set('_stepRegistry', {
                two: false,
                three: true
            });
        },

        _isShowBackButton: (function () {
            if (this.get('isShowBackButton') || this.get('_stepRegistry.one') === false) {
                return true;
            }
            return false;
        }).property('_stepRegistry', 'isShowBackButton'),

        actions: {

            onSetupTwoEnter: function onSetupTwoEnter() {
                this.set('currentStep', 2);
            },

            onSetupThreeEnter: function onSetupThreeEnter() {
                this.set('currentStep', 3);
            },

            onSetupTwoCompleted: function onSetupTwoCompleted(fileUrl) {
                this.set('_cropFileDataUrl', fileUrl);
                this._toStepThree();
            },

            onSetupThreeCompleted: function onSetupThreeCompleted(fileUrl) {
                this.set('_isUploadCompleted', true);
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onCompleted'), fileUrl);
                this._close();
            },

            onChangeStep: function onChangeStep(stepNumber) {
                if (stepNumber === 2) {
                    this._toStepTwo();
                } else if (stepNumber === 3) {
                    this._toStepThree();
                } else if (stepNumber === 1) {
                    this._close();
                } else {
                    console.error("unkown mc-image-cropper step: " + stepNumber);
                }
            },

            cancel: function cancel() {
                var currentStep = this.get('currentStep');
                if (currentStep === 1) {
                    this._close();
                    _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onCancelled'));
                } else {
                    this.send('onChangeStep', currentStep - 1);
                }
            }
        }
    });
});