define('my-care-git-ember-portal/models/account-type', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        name: _emberData['default'].attr('string'),
        isPrivatePayEnabled: _emberData['default'].attr('boolean'),
        minimumAccountBalance: _emberData['default'].attr('number'),
        clientServiceFee: _emberData['default'].attr('number'),
        workerServiceFee: _emberData['default'].attr('number'),
        gstRate: _emberData['default'].attr('number'),
        taxRate: _emberData['default'].attr('number'),
        BookingThreshold: _emberData['default'].attr('number'),

        getServiceFee: (function () {
            if (Ember.isEmpty(this.get('clientServiceFee'))) {
                return 0;
            }
            return this.get('clientServiceFee');
        }).property('clientServiceFee'),

        hasServiceFee: (function () {
            return Ember.isEmpty(this.get('clientServiceFee')) === false;
        }).property('clientServiceFee')
    });
});