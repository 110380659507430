define('my-care-git-ember-portal/components/mc-joblisting-editor/local-workers/search-bar/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _ember['default'].Component.extend({
        _store: _ember['default'].inject.service('store'),
        isSearchTypeJobs: false,
        isSearchTypeCarers: false,
        doesShowSelectTypeOption: true,
        isVolunteer: false,
        isLiveSearch: false,
        isAdvanceSearch: true,
        selectedServices: [],
        selectedLanguages: [],
        selectedRequirements: [],
        selectedEquipment: [],
        selectedEmploymentTypes: [],
        selectMin: 16,
        selectMax: 40,
        //mobile attributes
        mobileMin: 16,
        mobileMax: 40,
        min: 16,
        max: 40,
        isMobileVolunteer: false,

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            var self = this;
            _ember['default'].RSVP.hash({
                services: self.get('_store').findAll('service'),
                languages: self.get('_store').findAll('language'),
                requirements: self.get('_store').findAll('requirement'),
                employmentTypes: self.get('_store').findAll('employmentType'),
                equipment: self.get('_store').findAll('equipment')
            }).then(function (_ref) {
                var services = _ref.services;
                var languages = _ref.languages;
                var requirements = _ref.requirements;
                var employmentTypes = _ref.employmentTypes;
                var equipment = _ref.equipment;

                self.set('services', services);
                self.set('languages', languages);
                self.set('requirements', requirements);
                self.set('employmentTypes', employmentTypes);
                self.set('equipment', equipment);
                _this.set('isComponentReady', true);
            });
        },

        isShowJobSearchLabel: (function () {
            if (!this.get('doesShowSelectTypeOption') && this.get('isSearchTypeJobs')) {
                return true;
            }
        }).property('isSearchTypeJobs', 'doesShowSelectTypeOption'),

        isShowProfileSearchLabel: (function () {
            if (!this.get('doesShowSelectTypeOption') && this.get('isSearchTypeCarers')) {
                return true;
            }
        }).property('isSearchTypeCarers', 'doesShowSelectTypeOption'),

        actions: {
            toggleLiveSearch: function toggleLiveSearch() {
                this.set('isLiveSearch', !this.get('isLiveSearch'));
            },
            moreFilters: function moreFilters() {
                var self = this;
                self.set('showSummary', false);
                _ember['default'].$('#search-filters').collapse('show');
            },

            updateFilters: function updateFilters() {
                var self = this;
                _ember['default'].$('#search-filters').collapse('hide');
                self.set('showSummary', true);
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('search'));
            },

            closeFilters: function closeFilters() {
                var self = this;
                _ember['default'].$('#search-filters').collapse('hide');
                self.set('showSummary', true);
            },

            removeFilter: function removeFilter(type, item) {
                var self = this;
                self.get(type).removeObject(item);
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('search'));
            },

            removeKeyword: function removeKeyword() {
                var self = this;
                self.set('keyword', '');
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('search'));
            },

            clearFilter: function clearFilter() {
                var self = this;
                self.get('selectedServices').clear();
                self.get('selectedLanguages').clear();
                self.get('selectedRequirements').clear();
                self.get('selectedEquipment').clear();
                self.get('selectedEmploymentTypes').clear();
            },
            setRate: function setRate(rateObj) {
                this.set('selectMax', rateObj.max);
                this.set('selectMin', rateObj.min);
                this.set('isVolunteer', rateObj.isVolunteer);
            },
            setDistance: function setDistance(distanceVal) {
                this.set('selectedDistance', parseInt(distanceVal));
            },
            /**
             * Change current search type
             * @param type jobs or carers
             */

            //mobile actions, only for mobile view
            onUpdateMin: function onUpdateMin(min) {
                if (this.get('isComponentReady') === true) {
                    this.set('mobileMin', min);
                }
            },
            onUpdateMax: function onUpdateMax(max) {
                if (this.get('isComponentReady') === true) {
                    this.set('mobileMax', max);
                }
            },
            toggleMobileVolunteer: function toggleMobileVolunteer() {
                this.toggleProperty('isMobileVolunteer');
            },

            onSelected: function onSelected(actionP, items) {
                if (actionP === 'services') {
                    this.set('mobileServices', items);
                }
                if (actionP === 'equipment') {
                    this.set('mobileEquipment', items);
                }
                if (actionP === 'requirements') {
                    this.set('mobileRequirements', items);
                }
                if (actionP === 'languages') {
                    this.set('mobileLanguages', items);
                }
            },

            setMobileChanges: function setMobileChanges() {
                this.processSearch(this.get('mobileLanguages'), this.get('mobileRequirements'), this.get('mobileEquipment'), [], this.get('mobileServices'), [], this.get('isMobileVolunteer'), this.get('mobileMin'), this.get('mobileMax'));
            }
            //end of mobile actions
        },

        watchSearchParameter: (function () {
            this.processSearch(this.get('selectedLanguages'), this.get('selectedRequirements'), this.get('selectedEquipment'), this.get('selectedEmploymentTypes'), this.get('selectedServices'), this.get('scoringServices'), this.get('isVolunteer'), this.get('selectMin'), this.get('selectMax'), this.get('selectedDistance'));
        }).observes('location', 'keyword', 'selectedLanguages.[]', 'selectedRequirements.[]', 'selectedEquipment.[]', 'selectedEmploymentTypes.[]', 'selectedServices.[]', 'isVolunteer', 'selectMin', 'selectMax', 'selectedDistance', 'isLiveSearch'),

        processSearch: function processSearch(languages, requirements, equipment, employmentTypes, services, scoringServices, isVolunteer, lowerPrice, upperPrice, distance) {
            var self = this;
            var searchParameter = {};
            if (self.get('keyword')) {
                searchParameter.keyword = self.get('keyword');
            }

            if (self.arrToStr(languages)) {
                searchParameter.languages = self.arrToStr(languages);
            }

            if (self.arrToStr(requirements)) {
                searchParameter.requirements = self.arrToStr(requirements);
            }

            if (self.arrToStr(equipment)) {
                searchParameter.equipment = self.arrToStr(equipment);
            }

            if (self.arrToStr(employmentTypes)) {
                searchParameter.employmentTypes = self.arrToStr(employmentTypes);
            }
            if (self.arrToStr(services)) {
                searchParameter.services = self.arrToStr(services);
            }

            if (self.arrToStr(scoringServices)) {
                searchParameter.scoringServices = self.arrToStr(scoringServices);
            }
            if (isVolunteer == true) {
                searchParameter.isVolunteer = true;
            } else {
                searchParameter.isVolunteer = false;
                searchParameter.lowerPrice = lowerPrice;
                searchParameter.upperPrice = upperPrice;
            }
            searchParameter.distance = distance;

            searchParameter.isLive = this.get('isLiveSearch');

            self.set('searchParameter', searchParameter);
            _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('search'), self.get('location'), self.get('searchParameter'));
        },

        arrToStr: function arrToStr(arr) {
            var str = '';
            if (!_ember['default'].isEmpty(arr)) {
                for (var i = 0; i < arr.get('length'); i++) {
                    str += arr.objectAt(i).get('name');
                    if (i !== arr.get('length') - 1) {
                        str += '|';
                    }
                }
            }
            return str;
        },

        validateForm: function validateForm() {
            var self = this;
            self.set('displaySearchLocationError', false);
            if (!self.get('isSearchTypeJobs') && !self.get('isSearchTypeCarers')) {
                self.set('displaySearchTypeError', true);
                return false;
            }

            if (self.get('location') === undefined || self.get('location') === null) {
                self.set('displaySearchLocationError', true);
                return false;
            }
            return true;
        }
    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */