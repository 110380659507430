define('my-care-git-ember-portal/components/mc-user-detail/worker-review/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        div_id: 'reviews',
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.get('model.recommendations', { reload: true });
        },
        recommendations: (function () {
            return this.get('model.recommendations').toArray().reverse();
        }).property()
    });
});