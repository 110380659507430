define('my-care-git-ember-portal/components/mc-image-cropper/step-three/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers) {
  exports['default'] = _ember['default'].Component.extend({

    _httpService: _ember['default'].inject.service('http'),

    init: function init() {
      this._super.apply(this, arguments);
      // this.set('onChangeStep', undefined);
      // this.set('file', undefined);
      this.set('_isLoading', false);
    },

    actions: {
      uploadImage: function uploadImage() {
        var _this = this;

        var self = this;
        this.set('_isLoading', true);
        var data = this.get('file').replace(/^data:image\/png;base64,/, '');
        var imageUpload = {
          imageUpload: {
            data: data,
            fileName: 'fileName.png'
          }
        };
        var json = JSON.stringify(imageUpload);

        this.get('_httpService').withAuthentication(function (defer) {
          var url = _this.get('env').getApp('API_HOST') + '/api/imageUploader';
          _ember['default'].$.post(url, json).then(function (response) {
            defer.resolve(response);
          }, function (error) {
            defer.reject(error);
          });

          defer.promise.then(function (loginResponse) {
            _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(_this.get('onCompleted'), loginResponse.media.url);
            self.set('_isLoading', false);
          })['catch'](function () {
            self.set('_isLoading', false);
          });
        });
      },

      back: function back() {
        _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onChangeStep'), 2);
      }
    }
  });
});