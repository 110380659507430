define('my-care-git-ember-portal/utils/mc-component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsFunctionHelpers) {
    var _slice = Array.prototype.slice;
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsValidator['default'], {

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_isInitialised', false);
            this.initVar('_isInitialising', false);
        },

        initVar: function initVar(name, defaultValue) {
            if (_ember['default'].isNone(this.get(name))) this.set(name, defaultValue);
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            if (this.get('_isInitialised') === false && this.get('_isInitialising') === false) {
                this.set('_isInitialising', true);
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnoreWithContext.apply(_myCareGitEmberPortalUtilsFunctionHelpers['default'], [this.willSetupOnce, this].concat(_slice.call(arguments)));
                this.toggleProperty('_isInitialised');
            }
        },

        willSetupOnce: function willSetupOnce() {}
    });
});