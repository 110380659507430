define('my-care-git-ember-portal/index/visits/index/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            // to trigger index error method, in case to protect tokenKey is not correct
            return this.store.query('timesheet', { statuses: 'all' });
        },
        setupController: function setupController(controller, model) {
            controller.set('timesheets', model);
        }
    });
});