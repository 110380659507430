define('my-care-git-ember-portal/mixins/file-uploader-board', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create({
        fileUploadUrl: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/FileUpload',
        store: _ember['default'].inject.service(),
        filesUploadErrors: [],

        hasUploadErrors: (function () {
            return this.get('filesUploadErrors.length') > 0;
        }).property('filesUploadErrors.[]'),

        uploadcomplete: (function () {
            var percent = this.get('percent') + '%';
            return new _ember['default'].Handlebars.SafeString("width: " + percent);
        }).property('percent'),

        isUploading: (function () {

            if (this.get('percent') > 0) {
                return true;
            }
            return false;
        }).property('percent'),

        getUploadPercent: (function () {
            return parseInt(this.get('percent')) + '%';
        }).property('percent'),

        actions: {
            upload: function upload(obj) {
                var self = this;
                self.send('setSuccessMessage', '');
                var file = self.get('store').createRecord('file', {
                    url: obj.url,
                    fileName: obj.fileName,
                    uploadedOn: moment.utc(new Date()).toISOString(),
                    person: self.get('model.id')
                });
                file.save().then(function (file) {
                    self.send('toggleIsAdd');
                    toastr.info('Your file ' + obj.fileName + ' has successfully uploaded.');
                    self.get('model.files').pushObject(file);
                });
            },

            checkFile: function checkFile(text) {
                this.get('filesUploadErrors').pushObject(text);
            },

            emptyErrors: function emptyErrors() {
                this.set('filesUploadErrors', []);
            },

            toggleIsAdd: function toggleIsAdd() {
                this.set('percent', 0);
                this.set('filesUploadErrors', []);
                this.set('isAdd', !this.get('isAdd'));
            },

            setSuccessMessage: function setSuccessMessage(message) {
                this.set('filesUploadErrors', []);
                this.set("successMessage", message);
            }
        }
    });
});