define("my-care-git-ember-portal/components/mc-activities-picker/component", ["exports", "ember", "my-care-git-ember-portal/utils/function-helpers", "my-care-git-ember-portal/utils/mc-component"], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _myCareGitEmberPortalUtilsMcComponent) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({
        _store: _ember["default"].inject.service('store'),

        init: function init() {
            //public attributes
            this._super.apply(this, arguments);
            this.initVar('disableCoachText', false);
            this.initVar('personId', undefined);
            this.initVar('onChanged', undefined);
            this.initVar('error', undefined);
            this.initVar('initialValues', undefined);
            this.initVar('blacklist', []);

            //private properties
            this.initVar('_formData', {
                activityTypes: []
            });
            this.initVar('_activityGroups', undefined);
            this.initVar('_customGroupId', 1);
        },

        willSetupOnce: function willSetupOnce() {
            if (this.get('personId')) {
                this._setupFormData();
                if (this.get('initialValues')) {
                    this.set('_formData', {
                        activityTypes: this.get('initialValues')
                    });
                }
            }
        },

        _setupFormData: function _setupFormData() {
            var _this = this;

            var initialValues = this.get('initialValues');
            var blacklist = this.get('blacklist');
            var hasInitialValue = function hasInitialValue(item) {
                return !_ember["default"].isNone(initialValues.find(function (initItem) {
                    return initItem.get('id') === item.get('id');
                }));
            };
            var isBlacklisted = function isBlacklisted(item) {
                return !_ember["default"].isNone(blacklist.find(function (initItem) {
                    return initItem.get('id') === item.get('id');
                }));
            };

            this.get('_store').query('activity-group', { personId: this.get('personId') }, { reload: true }).then(function (groups) {
                if (_this.isDestroyed) {
                    return;
                }
                var activityGroups = _ember["default"].Object.create({});
                groups.forEach(function (groupModel) {
                    var activityTypes = groupModel.get('activityTypes').filter(function (act) {
                        return isBlacklisted(act) === false;
                    }).sortBy('sortOrder');
                    var newGroup = _ember["default"].Object.create({
                        record: groupModel,
                        activityTypes: activityTypes,
                        selectedItems: activityTypes.filter(function (item) {
                            return hasInitialValue(item);
                        }),
                        isCustom: groupModel.get('id') === '1'
                    });
                    var totalItems = newGroup.get('selectedItems.length');
                    var counter = totalItems > 0 ? "(" + totalItems + ")" : '';
                    newGroup.set('name', groupModel.get('name') + " " + counter);
                    activityGroups.set(groupModel.get('id'), newGroup);
                });
                _this.set('_originalGroups', groups);
                _this.set('_activityGroups', activityGroups);
                _this.set('_activityGroupsOrder', groups.sortBy('sortOrder').map(function (item) {
                    return item.get('id');
                }));
            });
        },

        _updateActivities: function _updateActivities(groupId, newActivities) {

            var allSelectedActivityTypes = [];
            this.get('_originalGroups').forEach(function (AG) {
                var selectedAT = AG.get('activityTypes').filter(function (at) {
                    return at.get('isChecked') == true;
                });
                allSelectedActivityTypes.pushObjects(selectedAT);
            });
            this.set('_formData.activityTypes', allSelectedActivityTypes);
            var group = this.get("_activityGroups." + groupId);
            var activityIds = group.get('activityTypes').map(function (act) {
                return act.get('id');
            });
            var existing = allSelectedActivityTypes;
            newActivities.forEach(function (activity) {
                if (!existing.includes(activity)) {
                    existing.pushObject(activity);
                }
            });

            var activitiesNotInNew = existing.filter(function (item) {
                return newActivities.includes(item) === false;
            });
            var activitiesNotInNewWithinGroup = activitiesNotInNew.filter(function (item) {
                return activityIds.includes(item.get('id'));
            });
            existing.removeObjects(activitiesNotInNewWithinGroup);
            this.set('_formData.activityTypes', existing);
            var totalItems = newActivities.get('length');
            var counter = totalItems > 0 ? "(" + totalItems + ")" : '';
            group.set('name', group.get('record.name') + " " + counter);
        },

        actions: {
            onSelected: function onSelected(groupId, selectedItems) {
                this._updateActivities(groupId, selectedItems);
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onChanged'), this.get('_formData.activityTypes').toArray());
            },

            onNewActivityTypeAddedEvent: function onNewActivityTypeAddedEvent(newActivity) {
                newActivity.toggleProperty('isChecked');
                var customGroupId = this.get('_customGroupId');
                var group = this.get('_activityGroups').get(customGroupId.toString());
                group.get('activityTypes').pushObject(newActivity);
                group.get('selectedItems').pushObject(newActivity);
                this._setupFormData();
                this._updateActivities(customGroupId, [newActivity]);
                this.send('onSelected', customGroupId, group.get('selectedItems'));
            }
        }
    });
});