define('my-care-git-ember-portal/components/mc-joblisting-editor/details/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        _store: _ember['default'].inject.service('store'),
        div_id: 'joblisting-details',

        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('formData', _ember['default'].Object.create({
                helpRequired: null
            }));
            this.initVar('personId', null);
            this.initVar('isFormValid', undefined);
            this.initVar('_activityGroups', []);
            this.initVar('_isFormDirty', false);
        },

        _watchFormValid: (function () {
            return this.set('isFormValid', this.get('isValidationsPassed'));
        }).observes('isValidationsPassed', '_isFormDirty', 'validations'),

        isDisabledSaveButton: (function () {
            if (this.get('_isFormDirty') === false) {
                return true;
            } else if (this.get('isSaving') === true) {
                return true;
            }
            return false;
        }).property('isSaving', '_isFormDirty'),

        willSetupOnce: function willSetupOnce() {
            this.setup();
        },

        setup: function setup() {
            var _this = this;

            this.set('formData.activityTypes', this.get('jobListing.activityTypes').toArray());
            this.set('formData.helpRequired', this.get('jobListing.helpRequired'));
            _ember['default'].run.later(function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this._setupValidation();
                _this._validateAsync({ keepDirty: true });
                _this.set('_isFormDirty', false);
            }, 1000);
        },

        _setupValidation: function _setupValidation() {
            this._resetValidation();
            this.addValidation('formData.activityTypes.[]', 'activityTypes', 'At least one activity', [this._validateActivityTypes]);
            this.addValidation('formData.helpRequired', 'activityTypes', 'At least one activity', [this._validateActivityTypes]);
        },

        _validateActivityTypes: function _validateActivityTypes(name) {
            var isValid = _myCareGitEmberPortalUtilsValidators['default'].isRequired(name, this.get('formData.activityTypes')).result;
            return {
                result: isValid || _ember['default'].isEmpty(this.get('formData.helpRequired')) === false,
                message: 'Availabilities is required'
            };
        },

        updateModel: function updateModel() {
            var jobListing = this.get('jobListing');
            jobListing.set('activityTypes', this.get('formData.activityTypes').slice());
            jobListing.set('helpRequired', this.get('formData.helpRequired'));
            return jobListing;
        },

        watchFormDirty: (function () {
            var _this2 = this;

            var checkExistingActivityTypes = this.get('jobListing.activityTypes').any(function (item) {
                return _ember['default'].isNone(_this2.get('formData.activityTypes').find(function (jItem) {
                    return jItem.get('id') === item.get('id');
                }));
            });
            var isDirty = this.get('formData.helpRequired') !== this.get('jobListing.helpRequired') || this.get('formData.activityTypes.length') !== this.get('jobListing.activityTypes.length') || checkExistingActivityTypes;
            this.set('_isFormDirty', isDirty);
        }).observes('formData.activityTypes.length', 'formData.helpRequired'),

        handle502: function handle502(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('jobListing').reload();
                self.redirectTo('index.job-listings.index');
                toastr.error('Please check is job listing saved, and contact Mycare development team.');
            }
        },

        actions: {
            onSelectedActivities: function onSelectedActivities(activities) {
                this.set('formData.activityTypes', activities);
            },

            save: function save() {
                var _this3 = this;

                var self = this;
                this._validateAsync({ keepDirty: false }).then(function (isValid) {
                    if (_this3.isDestroyed) {
                        return;
                    }
                    if (isValid) {
                        _this3.set('isSaving', true);
                        _this3.updateModel().save().then(function (jobListing) {
                            if (_this3.isDestroyed) {
                                return;
                            }
                            _this3.set('jobListing', jobListing);
                            _this3.set('isSaving', false);
                            _this3.set('_isFormDirty', false);
                            _this3.sendAction('onSaved', jobListing);
                        }, function (error) {
                            self.handle502(error);
                        });
                    }
                });
            }
        }
    });
});