define('my-care-git-ember-portal/components/mc-user-detail/basic-information/component', ['exports', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/mixins/form-checker', 'ember-concurrency'], function (exports, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalMixinsFormChecker, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: "",
        div_id: 'personal-details',
        reviewService: Ember.inject.service('review-service'),
        review: {},

        init: function init() {
            //public attributes
            this._super.apply(this, arguments);
            this.initVar('_store', Ember.inject.service('store'));
            this.initVar('_routingService', Ember.inject.service('routing'));
            this.initVar('formData', {});
        },

        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        personUrl: (function () {
            return this.get('env').getApp('APP_HOST') + "/browse/profiles/" + this.get('model.id');
        }).property('model.id'),

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.set('formData.isShareable', this.get('model.isShareable'));
            self.set('formData.isDisabled', this.get('model.isDisabled'));
            var promiseHash = {
                services: self.get('_store').findAll('service'),
                languages: self.get('_store').findAll('language'),
                requirements: self.get('_store').findAll('requirement'),
                employmentTypes: self.get('_store').findAll('employmentType'),
                equipment: self.get('_store').findAll('equipment'),
                hourlyRates: self.get('_store').findAll('hourlyRate'),
                supportTypes: self.get('_store').findAll('supportType'),
                accountTypes: self.get('_store').findAll('accountType'),
                ethnicities: self.get('_store').findAll('ethnicity')

            };
            Ember.RSVP.hash(promiseHash).then(function (_ref) {
                var services = _ref.services;
                var languages = _ref.languages;
                var requirements = _ref.requirements;
                var employmentTypes = _ref.employmentTypes;
                var equipment = _ref.equipment;
                var hourlyRates = _ref.hourlyRates;
                var supportTypes = _ref.supportTypes;
                var accountTypes = _ref.accountTypes;
                var ethnicities = _ref.ethnicities;

                self.set('services', services);
                self.set('languages', languages);
                self.set('requirements', requirements);
                self.set('employmentTypes', employmentTypes);
                self.set('equipment', equipment);
                self.set('hourlyRates', hourlyRates);
                self.set('supportTypes', supportTypes);
                self.set('accountTypes', accountTypes);
                self.set('ethnicities', ethnicities);
            });
            this.get('getReview').perform();
        },

        getReview: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var review;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!this.get('model.id')) {
                            context$1$0.next = 5;
                            break;
                        }

                        context$1$0.next = 3;
                        return this.get('reviewService.getReviewByIdAsync').perform({ id: this.get('model.id') });

                    case 3:
                        review = context$1$0.sent;

                        this.set('review', review);

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            save: function save() {
                var self = this;
                if (self.personalDetailCheck()) {
                    self.set('isSaving', true);
                    self.set('model.isShareable', this.get('formData.isShareable'));
                    self.set('model.isDisabled', this.get('formData.isDisabled'));
                    var defer = Ember.RSVP.defer();
                    if (self.get('model.location')) {
                        GMaps.geocode({
                            address: self.get('model.location'),
                            callback: function callback(results, status) {
                                if (status === 'OK') {
                                    var latlng = results[0].geometry.location;
                                    defer.resolve({
                                        lat: latlng.lat(),
                                        lng: latlng.lng()
                                    });
                                } else {
                                    defer.reject(status);
                                }
                            }
                        });
                        defer.promise.then(function (latlng) {
                            self.set('model.latitude', latlng.lat);
                            self.set('model.longitude', latlng.lng);
                            self.modelSave();
                        });
                    } else {
                        self.modelSave();
                    }
                }
            },

            remove: function remove() {
                var self = this;
                self.set('isDeleting', true);
                self.get('model').deleteRecord();
                self.get('model').save().then(function () {
                    Ember.$('#modal-remove-yes-no').modal('hide');
                    toastr.info('User deleted');
                    self.set('isDeleting', false);
                    self.get('eventbus').publish('global:on:changeRoute', 'index.users.index');
                    // self.get('_routingService')._onChangeRoute('index.users.index');
                }, function (error) {
                    console.log('error', error);
                });
            },

            showRemoveModal: function showRemoveModal() {
                Ember.$('#modal-remove-yes-no').modal();
            },

            uploadAvatar: function uploadAvatar() {
                this.get('eventbus').publish('mc-image-cropper:on:open:' + this.get('_uploaderChannelId'));
                Ember.$('#' + this.get('_uploaderChannelId')).modal('show');
            },

            updatePhoneNumber: function updatePhoneNumber(phoneNumber) {
                this.set('model.phoneNumber', phoneNumber);
            },

            onUploadCompleted: function onUploadCompleted(fileUrl) {
                var self = this;

                var image = self.get('_store').createRecord('image', {
                    url: fileUrl,
                    uploadedOn: moment.utc(new Date()).toISOString()
                });
                Ember.RSVP.resolve(image.save()).then(function (avatar) {
                    self.set('model.avatar', avatar);
                    self.modelSave();
                });
            },

            onChange: function onChange(date) {
                if (!Ember.isEmpty(date)) {
                    this.set('dateOfBirth', date.toISOString());
                }
            }
        },

        modelSave: function modelSave() {
            var self = this;
            self.set('model.dateOfBirth', self.get('dateOfBirth'));
            if (self.get('currentAvatarUrl') !== self.get('model.avatar.url')) {
                self.get('_store').createRecord('image', {
                    url: self.get('model.avatar.url'),
                    uploadedOn: moment.utc(new Date()).toISOString()
                }).save().then(function (avatar) {
                    self.get('model').set('avatar', avatar);
                    self.get('model').save().then(function () {
                        self.set('isSaving', false);
                        self.set('isLoadMobile', !self.get('isLoadMobile'));
                        toastr.info('Profile has been updated.');
                    }, function (error) {
                        self.userProfile502Handle(error);
                    });
                }, function (error) {
                    if (error.errors && error && error.errors[0].status === '0') {
                        self.get('model').save().then(function () {
                            self.set('isSaving', false);
                            self.set('isLoadMobile', !self.get('isLoadMobile'));
                            toastr.info('Profile has been updated.');
                        }, function (error) {
                            self.userProfile502Handle(error);
                        });
                    }
                });
            } else {
                self.get('model').save().then(function () {
                    self.set('isSaving', false);
                    self.set('isLoadMobile', !self.get('isLoadMobile'));
                    toastr.info('Profile has been updated.');
                }, function (error) {
                    self.userProfile502Handle(error);
                });
            }
        },

        userProfile502Handle: function userProfile502Handle(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('model').reload();
                self.get('eventbus').publish('global:on:changeRoute', 'index.users.index');
                // self.get('_routingService')._onChangeRoute('index.users.index');
                toastr.error('Please check is user profile saved, and contact Mycare development team.');
            }
        },

        personalDetailCheck: function personalDetailCheck() {
            Ember.$('.has-error').removeClass('has-error');
            var isValid = true;
            if (Ember.isEmpty(this.get('model.firstName')) || this.get('model.firstName') === '') {
                Ember.$('#firstName').addClass('has-error');
                isValid = false;
            }

            if (Ember.isEmpty(this.get('model.lastName')) || this.get('model.lastName') === '') {
                Ember.$('#lastName').addClass('has-error');
                isValid = false;
            }
            if (this.get('model.isSystemUser') === true) {
                if (!this.isEmail(this.get('model.email'))) {
                    Ember.$('#email').addClass('has-error');
                    isValid = false;
                }
                if (Ember.isEmpty(this.get('model.phoneNumber'))) {
                    Ember.$('#mobile-number').addClass('has-error');
                    isValid = false;
                }
            }

            return isValid;
        }
    });
});