define("my-care-git-ember-portal/index/job-listings/detail/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "my-care-git-ember-portal/index/job-listings/detail/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "tab-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "role", "tabpanel");
        dom.setAttribute(el2, "class", "tab-pane fade in active");
        dom.setAttribute(el2, "id", "job-listing-details");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "mc-joblisting-editor/basic", [], ["jobListing", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 44], [3, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "isFormValid", ["subexpr", "@mut", [["get", "isBasicFormValid", ["loc", [null, [4, 45], [4, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "errors", ["subexpr", "@mut", [["get", "formBasicErrors", ["loc", [null, [5, 40], [5, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "reload", ["subexpr", "@mut", [["get", "reloadBasicForm", ["loc", [null, [6, 40], [6, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "personId", ["subexpr", "@mut", [["get", "model.person.id", ["loc", [null, [7, 42], [7, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "onSaved", "save", "redirectTo", ["subexpr", "action", ["redirectTo"], [], ["loc", [null, [8, 61], [8, 82]]], 0, 0]], ["loc", [null, [3, 4], [8, 84]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});