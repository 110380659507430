define('my-care-git-ember-portal/mixins/form-checker', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({
        isEmptyField: function isEmptyField(field) {
            if (!field) {
                return true;
            }
            return false;
        },
        isEmail: function isEmail(email) {
            var sub = !_ember['default'].isNone(email) && !_ember['default'].isNone(email.trim) ? email.trim() : email;
            var emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !_ember['default'].isNone(sub) && !_ember['default'].isNone(sub.match(emailRegx));
        },
        isNameCorrect: function isNameCorrect(name) {
            var re = /^[A-z]+$/;
            return re.test(name);
        }

    });
});