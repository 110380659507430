define('my-care-git-ember-portal/index/users/detail/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {
            return this.store.findRecord('person', params.user_id, { reload: true });
        },

        setupController: function setupController(controller, model) {
            var self = this;
            _ember['default'].$('#personalDetails').collapse('show');
            self.store.query('subscription', { personId: model.get('id'), getAll: true }).then(function (subscriptions) {
                controller.set('model', model);
                controller.set('subscriptions', subscriptions);
                controller.setup();
                if (_ember['default'].isEmpty(model.get('firstAddress'))) {
                    var firstAddress = self.store.createRecord('address', {
                        addressStatus: 1,
                        person: model
                    });
                    model.get('addresses').pushObject(firstAddress);
                }
            });
        }
    });
});