define('my-care-git-ember-portal/models/subscription', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        price: _emberData['default'].attr('string'),
        startsOn: _emberData['default'].attr('string'),
        expiresOn: _emberData['default'].attr('string'),
        isCancelled: _emberData['default'].attr('boolean'),
        //    person: DS.belongsTo('person'),
        subscriptionType: _emberData['default'].belongsTo('subscription-type', { async: true })

    });
});
//    isAbleToDelete: function(){
//          if(Ember.isNone(this.get('expiresOn'))&&)
//    }.property('expiresOn','isCancelled')