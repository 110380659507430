define('my-care-git-ember-portal/index/job-applications/detail/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return this.store.findRecord('job-application', params.app_id, { reload: true });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setup();
        }
    });
});