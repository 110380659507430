define("my-care-git-ember-portal/models/search-profile", ["exports", "ember-data"], function (exports, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        rawId: _emberData["default"].attr("string"),
        avatar: _emberData["default"].attr("string"),
        firstName: _emberData["default"].attr("string"),
        description: _emberData["default"].attr("string"),
        hourlyRates: _emberData["default"].attr(),
        joinedOn: _emberData["default"].attr("string"),
        profilePicture: _emberData["default"].attr("string"),
        location: _emberData["default"].attr("string"),
        latitude: _emberData["default"].attr("string"),
        longitude: _emberData["default"].attr("string"),
        services: _emberData["default"].attr(),
        numberOfVerifications: _emberData["default"].attr("number"),
        numberOfHires: _emberData["default"].attr("number"),
        respondRatio: _emberData["default"].attr("number"),
        loginOn: _emberData["default"].attr('string'),
        modifiedOn: _emberData["default"].attr('string')
    });
});