define('my-care-git-ember-portal/index/weekly-visits-report/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        selectedDate: "",
        periodStartsOn: moment().toISOString(),
        periodEndsOn: moment.utc().toISOString(),
        seekerReportCtrl: _ember['default'].inject.controller('index.weekly-visits-report.seeker-report'),
        workerReportCtrl: _ember['default'].inject.controller('index.weekly-visits-report.worker-report'),
        watchDatePicker: function watchDatePicker() {
            var self = this;
            var startsOn = this.get('periodStartsOn');
            var endsOn = this.get('periodEndsOn');
            if (_ember['default'].isEmpty(startsOn) === false && _ember['default'].isEmpty(endsOn) === false) {
                self.set('isLoading', true);
                var periodStartsOn = moment(startsOn).startOf('day').toISOString();
                var periodEndsOn = moment(endsOn).endOf('day').toISOString();
                var timesheetQuery = this.store.query('timesheet-summary', {
                    periodStartsOn: periodStartsOn,
                    periodEndsOn: periodEndsOn,
                    personType: "seeker"
                });
                _ember['default'].RSVP.resolve(timesheetQuery).then(function (response) {
                    self.set('isLoading', false);
                    self.clientTimesheetSetup(response, periodStartsOn, periodEndsOn);
                    self.setTotals(response);
                });

                var workerTimesheetQuery = this.store.query('timesheet-summary', {
                    periodStartsOn: periodStartsOn,
                    periodEndsOn: periodEndsOn,
                    personType: "worker"
                });
                _ember['default'].RSVP.resolve(workerTimesheetQuery).then(function (response) {
                    self.set('isLoading', false);
                    self.workerTimesheetSetup(response, periodStartsOn, periodEndsOn);
                });
            }
        },

        clientTimesheetSetup: function clientTimesheetSetup(timesheets, periodStartsOn, periodEndsOn) {

            this.set('clientTimesheetSummaries', timesheets);
            this.set('seekerReportCtrl.timesheetSummaries', timesheets);
            this.set('seekerReportCtrl.startsOn', periodStartsOn);
            this.set('seekerReportCtrl.endsOn', periodEndsOn);
        },

        workerTimesheetSetup: function workerTimesheetSetup(timesheets, periodStartsOn, periodEndsOn) {
            this.set('workerTimesheetSummaries', timesheets);
            this.set('workerReportCtrl.timesheetSummaries', timesheets);
            this.set('workerReportCtrl.startsOn', periodStartsOn);
            this.set('workerReportCtrl.endsOn', periodEndsOn);
        },

        setTotals: function setTotals(timesheetsSummaries) {
            var cost = 0;
            var hours = 0;
            var overNightHours = 0;
            var totalVisits = 0;
            timesheetsSummaries.forEach(function (summary) {
                cost += parseFloat(summary.get('cost'));
                hours += parseFloat(summary.get('hours'));
                overNightHours += parseFloat(summary.get('overNighthours'));
                totalVisits += parseFloat(summary.get('numberOfVisits'));
            });
            this.set('totalCost', cost);
            this.set('totalHours', hours);
            this.set('totalOverNightHours', overNightHours);
            this.set("totalVisits", totalVisits);
        },

        actions: {
            onSelected: function onSelected(_ref) {
                var startDate = _ref.startDate;
                var endDate = _ref.endDate;

                this.set('periodStartsOn', startDate);
                this.set('periodEndsOn', endDate);
                this.watchDatePicker();
            }
        }
    });
});