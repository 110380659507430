define('my-care-git-ember-portal/models/thread', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        title: _emberData['default'].attr('string'),
        isMatch: _emberData['default'].attr('boolean'),
        people: _emberData['default'].hasMany('person', { async: true }),
        messages: _emberData['default'].hasMany('message', { async: true, inverse: 'thread' }),
        messageBody: _emberData['default'].attr('string'),
        messageDate: _emberData['default'].attr('string'),
        message: _emberData['default'].belongsTo('message', { async: true }),
        unReadMessages: _emberData['default'].hasMany('message', { async: true }),
        jobApplication: _emberData['default'].belongsTo('job-application', { async: true }),
        getNames: (function () {
            var names = "";
            var i = 0;
            var people = this.get('people');
            people.forEach(function (person) {
                names += person.get('firstName');
                if (i < people.get('.length') - 1) {
                    names += ", ";
                    i++;
                }
            });
            return names;
        }).property('people.@each.firstName')

    });
});