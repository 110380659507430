define('my-care-git-ember-portal/models/experience-event', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        attendees: _emberData['default'].hasMany('person', { async: true }),
        title: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        startsOn: _emberData['default'].attr('string'),
        endsOn: _emberData['default'].attr('string'),
        maximumNumberOfPeople: _emberData['default'].attr('number'),
        minimumNumberOfPeople: _emberData['default'].attr('number'),
        experienceEventTemplate: _emberData['default'].belongsTo('experience-event-template', { async: true }),
        date: (function () {
            return moment(this.get('startsOn')).startOf('day').toISOString();
        }).property('startsOn')
    });
});