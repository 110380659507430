define('my-care-git-ember-portal/mixins/unauthenticated_route', ['exports', 'ember'], function (exports, _ember) {

    //Mixin for route that does not require authentication
    exports['default'] = _ember['default'].Mixin.create({
        redirectRouteName: 'index',
        redirect: function redirect() {
            if (this.controllerFor('auth').get('isAuthenticated')) {
                this.transitionTo(this.get('redirectRouteName'));
            }
        },
        beforeModel: function beforeModel() {
            _ember['default'].$('.modal').modal('hide');
            this.redirect();
        }
    });
});
/**
 * Created by tinhtooaung on 5/06/15.
 */