define("my-care-git-ember-portal/services/address", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _store: _ember["default"].inject.service('store'),

        getAddressById: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findRecord('address', id);

                    case 2:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var address = _ref.address;
            var formData = _ref.formData;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        address.set('number', formData.number);
                        address.set('street', formData.street);
                        address.set('city', formData.city);
                        address.set('suburb', formData.suburb);
                        address.set('postcode', formData.postcode);
                        context$1$0.next = 7;
                        return address.save();

                    case 7:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 8:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});