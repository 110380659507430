define("my-care-git-ember-portal/services/data-service/account-error-service", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _store: _ember["default"].inject.service('store'),

        getSearchResults: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(pageSize, offset, status, searchString) {
            var self;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;
                        context$1$0.next = 3;
                        return self.store.query('accountError', { pagesize: pageSize, offset: offset, status: status, s: searchString }).then(function (accountErrors) {
                            return accountErrors;
                        }, function (error) {
                            console.log(error);
                            toastr.error('An error has occurred');
                        });

                    case 3:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        removeAccountError: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(accountError) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        accountError.deleteRecord();
                        accountError.save();

                    case 2:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});