define('my-care-git-ember-portal/index/job-listings/new/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            var self = this;
            var controller = this.controllerFor('index.job-listings.new');
            if (_ember['default'].isEmpty(controller.get('joblisting'))) {
                if (history.length > 2) {
                    history.go(-1);
                } else {
                    self.transitionTo('index.users');
                }
            }
        },
        actions: {
            willTransition: function willTransition() {
                var controller = this.controllerFor('index.job-listings.new');
                controller.set('joblisting', null);
            }
        }

    });
});