define("my-care-git-ember-portal/mixins/error-handler", ["exports", "ember"], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports["default"] = _ember["default"].Mixin.create({
        errorHandle: function errorHandle(error) {
            if (error.status == 401) {
                localStorage.removeItem("profileId");
                localStorage.removeItem("profileName");
                localStorage.removeItem("profileType");
                localStorage.removeItem("tokenKey");
                localStorage.removeItem("tokenKey");
                this.transitionTo('login');
                window.location.reload(true);
            }
        }

    });
});