define('my-care-git-ember-portal/components/mc-boolean-button/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * phone-picker component
     */
    exports['default'] = _ember['default'].Component.extend({
        /**
         * Setup component at startup.
         */
        defaultClass: 'btn btn-block alert-danger',

        init: function init() {
            this._super();
            if (this.get('customFalseClass')) {
                this.set('defaultClass', this.get('customFalseClass'));
            }
        },

        actions: {
            click: function click() {
                if (this.get('action')) {
                    this.get('action')();
                } else {
                    this.set('bool', !this.get('bool'));
                }
            }
        }
    });
});