define('my-care-git-ember-portal/index/experience/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/mixins/routes/job-listing-radio-button'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalMixinsRoutesJobListingRadioButton) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], _myCareGitEmberPortalMixinsRoutesJobListingRadioButton['default'], {
        errors: [],
        isMore: true,
        status: '',
        selectedListingType: 'all',
        _pageSize: 50,
        listingTypes: [{ id: 'all', 'name': 'All', isSelected: true }, { id: 'premium', 'name': 'Private', isSelected: false }, { id: 'dss', 'name': 'Dss', isSelected: false }],

        filter: (function () {
            var searchString = this.get('searchString');
            var content = this.get('_templates');
            var filterResult = content.filter(function (item) {
                if (item.get('title').toLowerCase().indexOf(searchString.toLowerCase()) != -1 || item.get('description').toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    return true;
                }
            });
            this.set('filterResult', filterResult);
        }).observes('searchString'),

        actions: {

            onClickPage: function onClickPage(page) {
                var self = this;
                self.set('isLoading', true);
                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.set('selectedPage', page);
                self.getSearchResults(self.get('_pageSize'), offSet, self.get('status'), self.get('searchString'), self.get('selectedListingType')).then(function (jobListings) {
                    var _currentAmount = offSet + jobListings.get('length');
                    self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                    self.set('_jobListings', jobListings);
                    self.set('filterResult', jobListings);
                    self.set('isLoading', false);
                });
            },

            searchByType: function searchByType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.set('_jobListings', []);
                    this.set('selectedPage', '');
                    self.transitionToRoute('index.job-listings.' + type);
                }
            },

            doSearch: function doSearch() {
                var self = this;
                self.set('isLoading', true);
                self.set('offset', 0);
                self.set('isMore', true);
                self.set('selectedPage', '');
                if (_ember['default'].isNone(self.get('searchString'))) {
                    self.getSearchResults(self.get('_pageSize'), self.get('offset'), self.get('status'), '', self.get('selectedListingType')).then(function (jobListings) {

                        var _currentAmount = jobListings.get('length');
                        self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                        self.set('_totalAmount', jobListings.get('meta.total'));
                        self.set('_jobListings', jobListings);
                        self.set('filterResult', jobListings);
                        self.set('isLoading', false);
                    });
                } else {
                    self.getSearchResults(self.get('_pageSize'), self.get('offset'), self.get('status'), self.get('searchString'), self.get('selectedListingType')).then(function (jobListings) {
                        var _currentAmount = jobListings.get('length');
                        self.set('_currentAmount', _currentAmount >= self.get('_totalAmount') ? self.get('_totalAmount') : _currentAmount);
                        self.set('_totalAmount', jobListings.get('meta.total'));
                        self.set('_jobListings', jobListings);
                        self.set('filterResult', jobListings);
                        self.set('isLoading', false);
                    });
                }
            },

            setListingType: function setListingType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.set('isMore', true);
                    self.set('selectedListingType', type);
                    self.getSearchResults(self.get('_pageSize'), 0, self.get('status'), self.get('searchString'), self.get('selectedListingType')).then(function (jobListings) {
                        self.set('_currentSize', jobListings.get('length'));
                        self.set('_totalAmount', jobListings.get('meta.total'));
                        self.set('_jobListings', jobListings);
                        self.set('filterResult', jobListings);
                        self.set('isLoading', false);
                    });
                }
            }

        },
        getSearchResults: function getSearchResults(pagesize, offset, status, searchString) {
            var self = this;
            self.set('isLoading', true);
            return self.store.query('list-experience-event-template', {
                pagesize: pagesize,
                offset: offset,
                status: status,
                s: searchString
            }).then(function (templates) {
                self.set('isLoading', false);
                return templates;
            }, function (error) {
                console.log(error);
                self.set('isLoading', false);
                toastr.error('An error has occurred');
            });
        }
    });
});