define('my-care-git-ember-portal/components/mc-multi-person-avatar/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Multiple person avatar display component
     */
    exports['default'] = _ember['default'].Component.extend({

        limitedPeople: (function () {
            return this.get('people').slice(0, 2);
        }).property('people')
    });
});