define("my-care-git-ember-portal/components/mc-multiple-select-dropdown/component", ["exports", "ember", "my-care-git-ember-portal/utils/common", "my-care-git-ember-portal/utils/mc-component"], function (exports, _ember, _myCareGitEmberPortalUtilsCommon, _myCareGitEmberPortalUtilsMcComponent) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('componentList', []);
            this.initVar('selectedItems', []);
            this.initVar('initialValues', []);
            this.initVar('showSelection', true);
            this.initVar('options', []);
            this.initVar('isEditMode', false);
            this.initVar('onChanged', undefined);
            this.initVar('_modalChannelId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
        },

        didInsertElement: _ember["default"].on('didInsertElement', function () {
            var _this = this;

            this.$('select').multipleSelect({
                selectAll: false,
                placeholder: this.get('placeholder'),
                onClick: function onClick(view) {
                    var options = _this.get('options');
                    var opt = options.filter(function (option) {
                        return String(option.get(_this.get('valuePath'))) === String(view.value);
                    }).objectAt(0);
                    if (view.checked) {
                        _this.get('selectedItems').pushObject(opt);
                    } else {
                        _this.get('selectedItems').removeObject(opt);
                    }
                    _this.sendAction('onSelected', _this.get('selectedItems'));
                }
            });

            this._setInitData();
        }),

        _setInitData: function _setInitData() {
            var _this2 = this;

            var indexes = [];
            this.get('options').forEach(function (option, index) {
                _this2.get('initData').forEach(function (initData) {
                    if (initData === option) {
                        _this2.get('selectedItems').pushObject(option);
                        indexes.push(index + 1);
                    }
                });
            });
            this.$("select").multipleSelect("setSelects", indexes);
        },

        _options: _ember["default"].computed('options.[]', function () {
            var _this3 = this;

            var options = this.get('options');
            var result = _ember["default"].A(options).map(function (opt) {
                if (typeof opt === 'string' || typeof opt === 'number') {
                    var _Ember$Object$create;

                    return _ember["default"].Object.create((_Ember$Object$create = {}, _defineProperty(_Ember$Object$create, _this3.get('valuePath'), opt), _defineProperty(_Ember$Object$create, _this3.get('labelPath'), opt), _Ember$Object$create));
                }
                return opt;
            });
            return this._addPromptOrReturn(result);
        }),

        _addPromptOrReturn: function _addPromptOrReturn(items) {
            var prompt = this.get('prompt');
            if (prompt) {
                var _Ember$Object$create2;

                return [_ember["default"].Object.create((_Ember$Object$create2 = {}, _defineProperty(_Ember$Object$create2, this.get('valuePath'), null), _defineProperty(_Ember$Object$create2, this.get('labelPath'), typeof prompt === 'string' ? prompt : 'Please select item'), _Ember$Object$create2))].concat(_toConsumableArray(items));
            }
            return items;
        }

    });
});