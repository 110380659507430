define("my-care-git-ember-portal/components/mc-user-detail/listing-around/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-component", "ember-concurrency", "my-care-git-ember-portal/mixins/viewer-premission"], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _emberConcurrency, _myCareGitEmberPortalMixinsViewerPremission) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend(_myCareGitEmberPortalMixinsViewerPremission["default"], {
        tagName: '',
        sortProps: ['numberOfVerifications:desc', 'loginOn:desc', 'modifiedOn:desc'],
        sortedResultList: _ember["default"].computed.sort('results', 'sortProps'),
        locationService: _ember["default"].inject.service('location-service'),
        searchService: _ember["default"].inject.service('search'),
        div_id: 'local-listings',

        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('mapBound', {});
            this.get('runSearch').perform();
        },

        runSearch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var self, thisLatlng, parameter;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;
                        context$1$0.next = 3;
                        return this.get('locationService.decodeAsync').perform({ location: this.get('profile.location') });

                    case 3:
                        thisLatlng = context$1$0.sent;

                        self.set('lat', thisLatlng.lat);
                        self.set('lng', thisLatlng.lng);
                        self.set('mapBound.northLat', thisLatlng.lat);
                        self.set('mapBound.eastLng', thisLatlng.lng);

                        parameter = this.get('searchService').joinBoundsTpSearchParameter(this.get('mapBound'), this.get('location'));
                        context$1$0.next = 11;
                        return this.get('fetchData').perform(parameter);

                    case 11:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        fetchData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(parameter) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('results', []);
                        context$1$0.next = 3;
                        return this.get('searchService').queryJobs(parameter, true).then(function (serviceProfiles) {
                            _this.set('results', serviceProfiles.toArray());
                            _this.set('isEnableOnCoordinateChangedEvent', true);
                        });

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            search: function search(location, params) {
                this.get('searchService').set('searchParameter', params);
                this.get('runSearch').perform();
            }
        }

    });
});