define('my-care-git-ember-portal/helpers/number-format', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (params) {
        var number = params[0];
        if (_ember['default'].isEmpty(number)) {
            return '0.00';
        } else {
            var fixedTwoDigital = parseFloat(number).toFixed(2),
                twoPartOfNumber = fixedTwoDigital.split('.'),
                numberBeforeDoc = twoPartOfNumber[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (twoPartOfNumber[1] === '00') {
                return numberBeforeDoc;
            }
            return numberBeforeDoc + '.' + twoPartOfNumber[1];
        }
    });
});