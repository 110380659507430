define("my-care-git-ember-portal/helpers/short-string", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Helper.helper(function (params) {
        var description = params[0];
        var strLength = params[1];
        if (description !== undefined && description !== null) {
            if (description.length > strLength) {
                description = description.substring(0, strLength - 1) + "... ";
            }
            return description;
        } else {
            return description;
        }
    });
});