define('my-care-git-ember-portal/components/mc-charts/mc-multi-line-chart/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/lineCharts/baseLineChart'], function (exports, _ember, _myCareGitEmberPortalMixinsLineChartsBaseLineChart) {
    // ****chart.js component****
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsLineChartsBaseLineChart['default'], {
        isLoading: false,
        _isBeginAtZero: false,
        startingTime: null,
        reportTypes: [],
        isDollarFormat: false,
        chartType: null,
        filter: null,

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.set('chartParentId', self.get('chartId') + '_parent');
            self.set('pickerStartingTime', self.get('startingTime'));
            self.set('localTime', self.get('startingTime'));
            self.drawDataChart();
        },

        actions: {
            onUpdate: function onUpdate(date) {
                this.set('localTime', date);
                this.drawDataChart();
            }
        },

        drawDataChart: function drawDataChart() {
            var _this = this;

            var self = this;
            self.set('isLoading', true);
            var reportTypes = self.get('reportTypes');
            var dataArray = [];
            var promiseList = [];
            self.setMonthAndYear();

            reportTypes.forEach(function (reportType) {
                promiseList.push(self.getDataQuery(reportType));
            });
            _ember['default'].RSVP.all(promiseList).then(function (responses) {
                responses.forEach(function (response) {
                    var rawData = response.objectAt(0);
                    dataArray.push({ label: rawData.get('label'), data: rawData.get('values').split(','), total: rawData.get('total'),
                        last30days: rawData.get('last30days'), offset: rawData.get('offset'), type: self.get('chartType') ? self.get('chartType') : 'bar'
                    });
                });

                if (_this.isDestroyed) {
                    return;
                }
                if (!self.get('chartType')) {
                    dataArray = self.getProcessedChartData(dataArray);
                }
                var lastObject = responses.objectAt(responses.length - 1).objectAt(0);
                self.set('total', lastObject.get('total'));
                self.set('last30days', lastObject.get('last30days'));

                self.buildChart(dataArray);
                self.set('isLoading', false);
            });
        },

        getProcessedChartData: function getProcessedChartData(dataArray) {
            var self = this;
            var byOffset = dataArray.slice(0);
            var monthlyData = byOffset.filterBy('label', this.get('filter') ? this.get('filter') : 'Total');
            monthlyData[0].type = "line";
            // self.set('total', monthlyData[0].total);
            // self.set('last30days', monthlyData[0].last30days);
            return byOffset;
        }

    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */