define('my-care-git-ember-portal/components/mc-account-fee/edit-account-fee-modal/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-modal-component', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/utils/common', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcModalComponent, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalUtilsCommon, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcModalComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsDefer['default'], {
        _store: _ember['default'].inject.service('store'),
        _modalHelper: _ember['default'].inject.service('utils/modal-helper'),
        _datepickerComponentId: _myCareGitEmberPortalUtilsCommon['default'].createGuid(),
        _startDate: moment().add(1, 'days').format('DD/MM/YYYY'),
        formData: {},
        init: function init() {
            this.initVar('_componentId', 'mc-update-account-fee-modal');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('isNew', false);
        },

        willSetupOnce: function willSetupOnce() {
            this._super.apply(this, arguments);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var accountFeeDto = _ref.accountFeeDto;
            var isNew = _ref.isNew;

            this.set('formData', {
                date: undefined,
                amount: undefined
            });
            this.set('apiError', undefined);

            this.set('accountFeeDto', accountFeeDto);
            this.set('isNew', isNew);
            if (accountFeeDto && !isNew) {
                this.set("formData.amount", accountFeeDto.get('rate') * 100);
                this.set("formData.date", moment(accountFeeDto.get('startsOn')).format('DD/MM/YYYY'));
            }
            this.addValidation('formData.amount', 'amount', 'Amount', [_myCareGitEmberPortalUtilsValidators['default'].isRequired, this.isPositive, this.checkDecimal, this.validateRate]);
            this.addValidation('formData.date', 'date', 'Date', [_myCareGitEmberPortalUtilsValidators['default'].isRequired, this.isFutureDate]);

            this.set('isShowConfirmationForm', false);
            this.set('isSaving', false);
        },

        validateRate: function validateRate() {

            var isValid = this.get('formData.amount') >= 0 && this.get('formData.amount') <= 99;
            return {
                result: isValid,
                message: isValid ? null : 'Rate must be between 0 and 99'
            };
        },

        isPositive: function isPositive() {

            var isValid = this.get('formData.amount') >= 0;
            return {
                result: isValid,
                message: isValid ? null : 'Rate cannot be negative'
            };
        },

        checkDecimal: function checkDecimal() {
            var regex = /^\d+(\.\d{0,2})?$/g;
            var isValid = regex.test(this.get('formData.amount'));
            return {
                result: isValid,
                message: isValid ? null : 'Only two decimal places are allowed'
            };
        },

        isFutureDate: function isFutureDate() {
            var isValid = moment(this.get('formData.date'), 'DD/MM/YYYY').isAfter(moment().endOf('day'));
            return {
                result: isValid,
                message: isValid ? null : 'Valid From must be a date in the future'
            };
        },

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var rate, formattedStartsOn, startsOn, endsOn, accountFee;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (this._validate()) {
                            context$1$0.next = 2;
                            break;
                        }

                        return context$1$0.abrupt('return', false);

                    case 2:
                        this._resetErrors();
                        this.set('apiError', undefined);
                        rate = parseFloat(this.get('formData.amount') / 100);
                        formattedStartsOn = moment(this.get('formData.date'), "DD/MM/YYYY").startOf('day').utc();
                        startsOn = formattedStartsOn.toISOString();
                        endsOn = formattedStartsOn.add(100, 'year').toISOString();
                        context$1$0.prev = 8;

                        if (!this.get('isNew')) {
                            context$1$0.next = 15;
                            break;
                        }

                        accountFee = this.get('_store').createRecord('accountFee', {
                            startsOn: startsOn,
                            endsOn: endsOn,
                            accountFeeTypeId: this.get('accountFeeDto.accountFeeTypeId'),
                            rate: rate,
                            accountTypeId: this.get('accountFeeDto.accountTypeId')
                        });
                        context$1$0.next = 13;
                        return accountFee.save();

                    case 13:
                        context$1$0.next = 20;
                        break;

                    case 15:
                        this.set('accountFeeDto.startsOn', startsOn);
                        this.set('accountFeeDto.endsOn', endsOn);
                        this.set('accountFeeDto.rate', rate);
                        context$1$0.next = 20;
                        return this.get('accountFeeDto').save();

                    case 20:
                        this._close();
                        this.sendAction('_onDone');
                        context$1$0.next = 27;
                        break;

                    case 24:
                        context$1$0.prev = 24;
                        context$1$0.t0 = context$1$0['catch'](8);

                        this.set('apiError', "New account fee must start in future and can only have one future account fee");

                    case 27:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[8, 24]]);
        }))

    });
});