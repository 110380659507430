define('my-care-git-ember-portal/index/recommendations/detail/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        div_id: 'recommendations',
        init: function init() {
            var self = this;
            this._super();
            _ember['default'].run.schedule("afterRender", this, function () {
                self.setViewerPermission(self.get('div_id'));
            });
        },
        actions: {
            save: function save() {
                var self = this;
                if (true) {
                    self.set('isSaving', true);
                    self.get('model').save().then(function () {
                        self.set('isSaving', false);
                        toastr.info('Review has been updated.');
                    }, function () {
                        toastr.error('Error saving review');
                    });
                }
            },
            deleteReview: function deleteReview() {
                var self = this;
                bootbox.confirm('Are you sure you want to delete?', function (result) {
                    if (result) {
                        self.get('model').deleteRecord();
                        self.get('model').save().then(function () {
                            self.transitionToRoute('index.recommendations');
                        }, function () {
                            toastr.error('An error has occurred');
                        });
                    }
                });
            },
            back: function back() {
                window.history.go(-1);
            }
        }
    });
});