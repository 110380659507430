define('my-care-git-ember-portal/models/timesheet-summary', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        agreementId: _emberData['default'].attr('number'),
        clientFirstName: _emberData['default'].attr('string'),
        clientLastName: _emberData['default'].attr('string'),
        clientPersonId: _emberData['default'].attr('number'),
        cost: _emberData['default'].attr('number'),
        hours: _emberData['default'].attr('number'),
        overNights: _emberData['default'].attr('number'),
        overNighthours: _emberData['default'].attr('number'),
        numberOfVisits: _emberData['default'].attr('number'),
        organiserFirstName: _emberData['default'].attr('string'),
        organiserLastName: _emberData['default'].attr('string'),
        organiserPersonId: _emberData['default'].attr('number'),
        workerFirstName: _emberData['default'].attr('string'),
        workerLastName: _emberData['default'].attr('string'),
        workerPersonId: _emberData['default'].attr('number')
    });
});