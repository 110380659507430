define('my-care-git-ember-portal/components/mc-registration-form/work-and-income/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/fedback-handler', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsFedbackHandler, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {

        _store: _ember['default'].inject.service('store'),

        div_id: 'dss-form',
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.emptyErrorMessages();
            _ember['default'].RSVP.hash({
                organisations: self.get('_store').findAll('organisation', { reload: true }),
                benefits: self.get('_store').findAll('benefit-type', { reload: true })
            }).then(function (_ref) {
                var organisations = _ref.organisations;
                var benefits = _ref.benefits;

                self.set('benefitTypes', benefits);
                var filterResult = organisations.filter(function (item) {
                    return item.get('registrationType.name') === 'WI';
                });
                if (filterResult.get('length') === 1) {
                    self.set('organisation', filterResult.objectAt(0));
                }
                self.set('organisations', filterResult);
            });
        },

        selectedOrganisations: (function () {
            var self = this;
            return [self.get('model.organisation')];
        }).property('model.id'),

        isWorkingIncoming: (function () {
            return this.get('model.registrationType.name') === 'WI';
        }).property().volatile(),

        isDSS: (function () {
            return this.get('model.registrationType.name') === 'DSS';
        }).property().volatile(),

        actions: {
            setOrganisation: function setOrganisation(type) {
                this.set('model.organisation', type);
            },
            save: function save() {
                var self = this;
                self.set('model.organisation', self.get('organisation'));
                if (self.personalDetailCheck()) {
                    self.set('isSaving', true);
                    self.get('model').save().then(function () {
                        self.set('isSaving', false);
                        self.redirectTo('index.registrations');
                        toastr.info('Registration saved.');
                    }, function (error) {
                        self.handle502(error);
                    });
                }
            },
            deleteSubscriber: function deleteSubscriber() {
                var self = this;
                bootbox.confirm('Are you sure you want to delete?', function (result) {
                    if (result) {
                        self.set('isDeleting', true);
                        self.get('model').deleteRecord();
                        self.get('model').save().then(function () {
                            self.set('isDeleting', false);
                            self.redirectTo('index.registrations');
                        });
                    }
                });
            },
            back: function back() {
                this.get('model').rollbackAttributes();
                window.history.go(-1);
            }
        },

        handle502: function handle502(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('model').reload();
                self.redirectTo('index.registrations');
                toastr.error('Please reload this page, check whether the data is saved, and contact Mycare development team.');
            }
        },

        personalDetailCheck: function personalDetailCheck() {
            //Clear out all previous errors
            this.emptyErrorMessages();
            _ember['default'].$('.has-error').removeClass('has-error');
            var isValid = true;
            if (_ember['default'].isEmpty(this.get('model.firstName'))) {
                this.addErrorMessage('First name is required.');
                _ember['default'].$('#firstName').addClass('has-error');
                isValid = false;
            }
            if (_ember['default'].isEmpty(this.get('model.lastName'))) {
                this.addErrorMessage('Last name is required.');
                _ember['default'].$('#lastName').addClass('has-error');
                isValid = false;
            }

            if (_ember['default'].isEmpty(this.get('model.organisation'))) {
                this.get('errors').pushObject('Organisation is required.');
                _ember['default'].$('#organisationType').find('input').addClass('has-error');
                isValid = false;
            }

            return isValid;
        }

    });
});