define('my-care-git-ember-portal/components/mc-user-detail/moj/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        div_id: "ministry-of-justice",

        init: function init() {
            //public attributes
            this._super.apply(this, arguments);
            this.initVar('sortedTrusts', []);
        },

        didRender: function didRender() {
            this.setViewerPermission(this.get('div_id'));
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this.get('setShowPoliceCheckOrMoj').perform();
        },

        setShowPoliceCheckOrMoj: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var trusts, sortedTrusts;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('model.trusts');

                    case 2:
                        trusts = context$1$0.sent.toArray();
                        sortedTrusts = _.sortBy(trusts, function (trust) {
                            return moment(trust.get('createdOn'));
                        }).reverse();

                        this.set('sortedTrusts', sortedTrusts);

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});