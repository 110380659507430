define('my-care-git-ember-portal/index/users/detail/account-detail/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/mixins/creditcard-add-process', 'my-care-git-ember-portal/utils/common', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalMixinsCreditcardAddProcess, _myCareGitEmberPortalUtilsCommon, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsSearch_settings_mixin['default'], _myCareGitEmberPortalMixinsCreditcardAddProcess['default'], {
        isMore: true,
        account_id: 0,
        transactions: [].toArray(),
        _channelId: _myCareGitEmberPortalUtilsCommon['default'].createGuid(),
        loginServer: _ember['default'].inject.service('login'),

        isFinanceAdmin: (function () {
            return this.get('loginServer.isFinanceAdmin');
        }).property('loginServer.isFinanceAdmin'),

        isIFClient: (function () {
            return this.get('model.accountTypeName') == 'IF' || this.get('model.accountTypeName') == 'IF Family Carers' ? true : false;
        }).property().volatile(),

        isEnableAddcreditCard: (function () {
            return !this.get('model.person.isWorker') || this.get('model.person.isClient');
        }).property().volatile(),

        getSearchResults: function getSearchResults(pageSize, offset) {
            var self = this;
            self.set('pageSize', pageSize);
            self.set('offset', offset);
            self.set('isLoading', true);
            return self.store.query('transaction', { accountId: self.get('account_id'), pageSize: pageSize, offset: offset }, { reload: true }).then(function (transactions) {
                self.set('isLoading', false);
                return transactions;
            });
        },

        watchCreditCard: function watchCreditCard() {
            this.setFilteredCreditCards();
        },

        setPreferredCreditCards: (function () {
            var self = this;
            self.set('isLoadingCreditCards', true);
            self.get('model.creditCards').then(function (creditCards) {
                creditCards.forEach(function (creditCard) {
                    creditCard.set('isPreferredCreditCard', false);
                    if (self.get('model.preferredCreditCard') === creditCard.get('id')) {
                        creditCard.set('isPreferredCreditCard', true);
                    }
                });
            });
        }).observes('model.preferredCreditCard', 'model.creditCards.[]'),

        setFilteredCreditCards: (function () {
            var self = this;
            self.set('isLoadingCreditCards', true);
            self.get('model.creditCards').then(function (creditCards) {
                var filteredCreditCards = creditCards.filter(function (creditCard) {
                    return !_ember['default'].isEmpty(creditCard.get('cardNumber'));
                });
                self.set('isLoadingCreditCards', false);
                var sortedCreditCards = filteredCreditCards.sortBy('isPreferredCreditCard').toArray().reverse();
                self.set('filteredCreditCards', sortedCreditCards);
            });
        }).observes('model.creditCards.@each.isPreferredCreditCard'),

        executeCreditCard: function executeCreditCard() {
            var self = this;
            self.set('creditCardError', '');
            var afterFunction = function afterFunction(creditCard) {
                self.set('model.preferredCreditCard', creditCard.get('id'));
                self.get('model').save().then(function () {
                    self.watchCreditCard();
                    _ember['default'].$('#modal-credit-card-add').modal('hide');
                });
            };
            var errorHandle = function errorHandle() {};
            self.addCreditCardProcess(self.get('model'), afterFunction, errorHandle);
        },

        removeCardAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(creditCard) {
            var account;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        account = this.get('model');

                        if (!(creditCard.get('id') === account.get('preferredCreditCard'))) {
                            context$1$0.next = 5;
                            break;
                        }

                        account.set('preferredCreditCard', 0);
                        context$1$0.next = 5;
                        return account.save();

                    case 5:
                        creditCard.deleteRecord();
                        context$1$0.next = 8;
                        return creditCard.save();

                    case 8:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),
        actions: {
            more: function more() {
                var self = this;
                self.set('offset', self.get('transactions.length'));
                self.set('isLoading', true);
                self.getSearchResults(self.get('pageSize'), self.get('offset')).then(function (transactions) {
                    var isMore = false;
                    for (var i = 0; i < transactions.get('length'); i++) {
                        var transaction = transactions.objectAt(i);
                        if (!self.get('transactions').contains(transaction)) {
                            isMore = true;
                            self.get('transactions').pushObject(transaction);
                        }
                    }
                    self.set('isMore', isMore);
                    self.set('isLoading', false);
                });
            },

            showUpdateBalanceModal: function showUpdateBalanceModal() {
                this.get('eventbus').publish('mc-update-account-balance-modal:on:open:' + this.get('_channelId'));
            },

            showIFGlobalFloatModal: function showIFGlobalFloatModal() {
                var account = this.get('model');
                this.get('eventbus').publish('mc-if-global-float-modal:on:open:' + this.get('_channelId'), { account: account });
            },

            showRepaymentModal: function showRepaymentModal() {
                this.get('eventbus').publish('mc-update-repayment-modal:on:open:' + this.get('_channelId'));
            },

            showRefundModal: function showRefundModal() {
                this.get('eventbus').publish('mc-refund-modal:on:open:' + this.get('_channelId'));
            },

            onFinishUpdateBalance: function onFinishUpdateBalance() {
                var _this = this;

                this.getSearchResults(50, 0).then(function (transactions) {
                    _this.get('transactions').clear();
                    _this.get('transactions').pushObjects(transactions.toArray());
                });
            },

            removeCreditCard: function removeCreditCard(creditCard) {
                this.get('removeCardAsync').perform(creditCard);
            },

            setDefault: function setDefault(creditCard) {
                var self = this;
                var account = self.get('model');
                account.get('creditCards').forEach(function (creditCard) {
                    creditCard.set('isPreferredCreditCard', false);
                });
                creditCard.set('isPreferredCreditCard', true);
                account.set('preferredCreditCard', creditCard.get('id'));
                account.save();
            },

            showCreditCardModal: function showCreditCardModal() {
                this.executeCreditCard();
            }
        }
    });
});