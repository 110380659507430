define("my-care-git-ember-portal/index/experience/detail/index/controller", ["exports", "ember", "ember-concurrency", "my-care-git-ember-portal/utils/Validators", "my-care-git-ember-portal/mixins/validator"], function (exports, _ember, _emberConcurrency, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalMixinsValidator) {
    exports["default"] = _ember["default"].Controller.extend(_myCareGitEmberPortalMixinsValidator["default"], {

        isLoadingCategory: true,
        templateService: _ember["default"].inject.service('experience-event-template'),
        cancellationService: _ember["default"].inject.service('data-service/experience-event-template-cancelation-type'),
        categories: [],
        initData: {},
        preparationTimes: [_ember["default"].Object.create({ id: '1', name: '1' }), _ember["default"].Object.create({ id: '2', name: '2' }), _ember["default"].Object.create({ id: '3', name: '3' }), _ember["default"].Object.create({ id: '4', name: '4' })],

        maximumNumberOptions: [_ember["default"].Object.create({ id: '1', name: '1' }), _ember["default"].Object.create({ id: '2', name: '2' }), _ember["default"].Object.create({ id: '3', name: '3' }), _ember["default"].Object.create({ id: '4', name: '4' }), _ember["default"].Object.create({ id: '5', name: '5' }), _ember["default"].Object.create({ id: '6', name: '6' }), _ember["default"].Object.create({ id: '7', name: '7' }), _ember["default"].Object.create({ id: '8', name: '8' }), _ember["default"].Object.create({ id: '9', name: '9' }), _ember["default"].Object.create({ id: '10', name: '10' })],

        formData: _ember["default"].Object.create({}),
        setup: function setup() {
            var _this = this;

            this.set('initData.preparationTime', this.get('preparationTimes').filter(function (time) {
                return time.id == _this.get('model.preparationTime');
            }).objectAt(0));
            this.set('initData.maximumNumberOfPeople', this.get('maximumNumberOptions').filter(function (time) {
                return time.id == _this.get('model.maximumNumberOfPeople');
            }).objectAt(0));
            this.set('initData.categories', this.get('model.categories'));

            var perparationTime = this.get('preparationTimes').filter(function (time) {
                return time.id == _this.get('model.preparationTime');
            }).objectAt(0);
            this.set('formData.preparationTime', perparationTime ? perparationTime.get('id') : null);
            this.set('formData.maximumNumberOfPeople', this.get('model.maximumNumberOfPeople') ? this.get('maximumNumberOptions').filter(function (time) {
                return time.id == _this.get('model.maximumNumberOfPeople');
            })[0].get('id') : this.get('maximumNumberOptions').objectAt(0));
            this.set('formData.title', this.get('model.title'));
            this.set('formData.description', this.get('model.description'));
            this.set('formData.categories', this.get('model.categories'));
            this.set('formData.pricePerPerson', this.get('model.pricePerPerson'));
            this.set('formData.selectedProvides', this.get('model.experienceEventProvides'));

            this.set('formData.isPublished', this.get('model.isPublished'));
            this.set('formData.aggreedWithMyCareTermsAndConditions', this.get('model.aggreedWithMyCareTermsAndConditions'));
            this.set('formData.isAnyItemProvideOnTheEvent', this.get('model.isAnyItemProvideOnTheEvent'));
            this.set('formData.isExperienceIncludeAlcohol', this.get('model.isExperienceIncludeAlcohol'));
            this.set('formData.readMyCareTermsAndConditions', this.get('model.readMyCareTermsAndConditions'));
            this.set('formData.cancellationId', this.get('model.cancelationType.id'));

            this.get('loadData').perform();
            this._setupValidations();
        },

        loadData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var items, categories, cancellationTypes;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('isLoadingCategory', true);
                        this.store.findAll('experience-event-provide-item');
                        context$1$0.next = 4;
                        return this.store.findAll('experience-event-provide-item');

                    case 4:
                        items = context$1$0.sent;

                        this.set('items', items.toArray());
                        context$1$0.next = 8;
                        return this.store.findAll('experience-event-category');

                    case 8:
                        categories = context$1$0.sent;
                        context$1$0.next = 11;
                        return this.get('cancellationService.findAll').perform();

                    case 11:
                        cancellationTypes = context$1$0.sent;
                        context$1$0.next = 14;
                        return (0, _emberConcurrency.timeout)(500);

                    case 14:
                        cancellationTypes = cancellationTypes.sortBy('id');
                        this.set('cancellationTypes', cancellationTypes);
                        this.set('categories', categories.toArray());

                        this.set('isLoadingCategory', false);

                    case 18:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        _setupValidations: function _setupValidations() {
            this.addValidation('formData.title', 'title', 'Title', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.description', 'description', 'Description', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.preparationTime', 'preparationTime', 'Preparation time', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.maximumNumberOfPeople', 'maximumNumberOfPeople', 'Maximum number of people', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.cancellationId', 'cancellation', 'Cancellation', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
        },

        actions: {
            save: function save() {
                if (this._validate()) {
                    this.get('templateService.saveBasicAsync').perform({ template: this.get('model'), formData: this.get('formData') }).then(function () {
                        toastr.info('Experience updated.');
                    });
                }
            },
            redirectTo: function redirectTo(route) {
                this.transitionToRoute(route);
            },
            onSelectedOption: function onSelectedOption(items) {
                this.set('formData.categories', items);
            },

            onSelectedPreparationTime: function onSelectedPreparationTime(item) {
                this.set('formData.preparationTime', item.get('id'));
            },
            onSelectedMaximumPeople: function onSelectedMaximumPeople(item) {
                this.set('formData.maximumNumberOfPeople', item.get('id'));
            },

            onCreateProvide: function onCreateProvide(item) {}
        }

    });
});