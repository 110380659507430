define('my-care-git-ember-portal/index/business-kpi/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        fetchDataService: _ember['default'].inject.service('fetch-data'),

        updateDashboard: function updateDashboard() {
            var self = this;
            self.get('fetchDataService').fetchFromServer('/api/Reports/dashboard').then(function (data) {
                // first row //consumer
                self.set('publishedProfiles', data.publishedProfiles);
                self.set('clientProfiles', data.clientProfiles);
                self.set('openJobListings', data.openJobListings);
                self.set('hoursAdvertised', data.hoursAdvertised);
                self.set('hoursAdvertisedYear', data.hoursAdvertisedYear);
                // second row
                self.set('consumerContracts', data.consumerContracts - 5);
                self.set('consumerDeposits', data.consumerDeposits);
                self.set('consumerHoursScheduled', data.consumerHoursScheduled);
                self.set('consumerSpend', data.consumerSpend);
                self.set('consumerHoursWorked', data.consumerHoursWorked);
                // third row
                self.set('consumerMycareFees', data.consumerMycareFees);
                self.set('consumerHourlyFee', data.consumerHourlyFee);
                self.set('consumerHourlyRate', data.consumerCostClient - data.consumerHourlyFee);
                self.set('consumerCostClient', data.consumerCostClient);
                self.set('consumerRunRate', data.consumerRunRate);
            });
        }
    });
});