define('my-care-git-ember-portal/index/job-listings/threads/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        queryParams: {
            jobListingId: {
                refreshModel: true
            },
            applicantId: {
                refreshModel: true
            }
        },
        model: function model(params) {
            return this.store.query('thread', { jobListing: params.jobListingId, applicant: params.applicantId });
        }

    });
});