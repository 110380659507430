define('my-care-git-ember-portal/mixins/fedback-handler', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({
        errors: [],
        addErrorMessage: function addErrorMessage(message) {
            this.get('errors').pushObject(message);
        },
        emptyErrorMessages: function emptyErrorMessages() {
            this.get('errors').clear();
        },
        hasErrors: (function () {
            return this.get('errors.length') !== 0;
        }).property('errors.[]')
    });
});