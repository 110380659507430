define('my-care-git-ember-portal/index/users/index/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            var _this = this;

            var controller = this.controllerFor('index.users.index');
            controller.set('_people', []);
            controller.set('type', 'all');
            controller.set('selectedPage', '');
            controller.set('pageSize', 10);
            controller.set('isWorker', false);
            controller.set('searchString', '');
            controller.getSearchResults().then(function (people) {
                controller.set('_people', people);
                controller.set('filterResult', people);
                _this.transitionTo('index.users.all');
            });
        }
    });
});