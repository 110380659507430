define('my-care-git-ember-portal/components/mc-user-detail/client-agreement/component', ['exports', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/utils/common'], function (exports, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        div_id: 'client-agreements',
        _reviewChannelId: null,
        init: function init() {
            this.set('_reviewChannelId', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
            this._super.apply(this, arguments);
        },
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        actions: {
            onReview: function onReview(agreement) {
                this.set('selectedAgreement', agreement);
                this.get('eventbus').publish('mc-worker-review:on:open:' + this.get('_reviewChannelId'));
            }

        }
    });
});