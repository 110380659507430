define('my-care-git-ember-portal/models/list-experience-event-template', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        title: _emberData['default'].attr('string'),
        createdPerson: _emberData['default'].belongsTo('person', { async: true }),
        description: _emberData['default'].attr('string'),
        describeYourSelf: _emberData['default'].attr('string'),
        whatWeDoDescription: _emberData['default'].attr('string'),
        whereWeGoDescription: _emberData['default'].attr('string'),
        directions: _emberData['default'].attr('string'),
        meetingLocation: _emberData['default'].belongsTo('address', { async: true }),
        latitude: _emberData['default'].attr('number'),
        longitude: _emberData['default'].attr('number'),
        guestRequirementDescription: _emberData['default'].attr('string'),
        guestArrangeDescription: _emberData['default'].attr('string'),
        note: _emberData['default'].attr('string'),
        aggreedWithMyCareTermsAndConditions: _emberData['default'].attr('boolean'),
        isAnyItemProvideOnTheEvent: _emberData['default'].attr('boolean'),
        isExperienceIncludeAlcohol: _emberData['default'].attr('boolean'),
        readMyCareTermsAndConditions: _emberData['default'].attr('boolean'),
        preparationTime: _emberData['default'].attr('number', { defaultValue: 0 }),
        maximumNumberOfPeople: _emberData['default'].attr('number', { defaultValue: 0 }),
        images: _emberData['default'].hasMany('image', { async: true }),
        categories: _emberData['default'].hasMany('experience-event-category', { async: true }),
        experienceEvents: _emberData['default'].hasMany('experience-event', { async: true }),
        pricePerPerson: _emberData['default'].attr('number', { defaultValue: 0 }),
        isPublished: _emberData['default'].attr('boolean'),
        publishedOn: _emberData['default'].attr('string'),
        experienceEventProvides: _emberData['default'].hasMany('experience-event-provide', { async: true }),
        originalImage: _emberData['default'].belongsTo('image', { async: true }),
        coverImage: _emberData['default'].belongsTo('image', { async: true }),
        thumbImage: _emberData['default'].belongsTo('image', { async: true }),
        // reviews: DS.hasMany('review', {async: true}),
        createdOn: _emberData['default'].attr('string'),
        modifiedOn: _emberData['default'].attr('string'),
        cancellation: _emberData['default'].attr('boolean')

    });
});