define('my-care-git-ember-portal/components/mc-charts/mc-bar-chart/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        isLoading: false,
        Months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],

        setData: (function () {
            var self = this;
            self._super();
            var store = self.get('targetObject.store');
            var momentObj = moment(new Date());
            momentObj.add(-11, 'month');
            var thisMonth = momentObj.format('M');
            var thisYear = momentObj.format('YYYY');
            this.set('isLoading', true);
            store.query('report', { 'reportType': self.get('reportType'), 'year': thisYear, 'month': thisMonth }).then(function (report) {
                self.set('m', report.objectAt(0));
                self.set('startingTime', self.get('m.year') + "-" + self.get('m.month'));
                self.setCanvas();
                self.setMax();
                var data = self.get('m.values').split(",").map(Number);
                self.set("total", self.get('m.total'));
                self.set("last30days", self.get('m.last30days'));
                self.drawChart(data);
                self.set('isLoading', false);
            });
        }).on('init'),

        setMax: function setMax() {
            var data = this.get('m.values').split(",").map(Number);
            this.set('datamax', d3.max(data));
        },
        setCanvas: function setCanvas() {
            this.set('canvas', d3.select('#' + this.get('chartId')).append("svg").attr('width', 550).attr('height', 560).append("g").attr('transform', 'translate(' + 40 + ',' + 10 + ')'));
        },
        drawChart: function drawChart(data) {
            var width = 500;
            var height = 500;
            var margin = 40;
            var self = this;
            var max = this.get('datamax') > d3.max(data) ? this.get('datamax') : d3.max(data);

            var y_position = d3.scale.linear().domain([0, max]).range([height, 0]);

            var widthScale = d3.scale.linear().domain([0, max]).range([0, width]);

            var heightScale = d3.scale.ordinal().domain(d3.range(data.length + 1)).rangeRoundBands([margin, width], 0.05);

            var colorScale = d3.scale.linear().domain([0, max]).range(['rgb(0, 155, 222)', 'rgb(129, 54, 139)']);

            var yScale = d3.scale.linear().domain([0, max]).range([width, 0]);

            var xScale = d3.scale.ordinal().domain(this.getXScaleMarks(this.get('m.month'), this.get('m.year'))).rangeRoundBands([margin, width], 1);

            var canvas = this.get('canvas');

            var bars = canvas.selectAll('rect').data(data);

            bars.enter().append('rect');

            bars.attr('width', function () {
                return width / 15;
            }).attr('height', function (d) {
                return widthScale(d);
            }).attr('x', function (d, i) {
                return heightScale(i) - 20;
            }).attr('y', function (d) {
                return y_position(d);
            }).attr('fill', function (d) {
                return colorScale(d);
            }).on("click", function (d, i) {
                self.redirect(d, i);
            });
            bars.exit().remove();

            canvas.selectAll("text").remove();
            var text = canvas.selectAll("text").data(data, function (d) {
                return d;
            });
            text.enter().append("text").text(function (d) {
                return d;
            });

            text.attr("text-anchor", "middle").attr("x", function (d, i) {
                return heightScale(i) + heightScale.rangeBand() / 2 - margin / 2;
            }).attr("y", function (d) {
                return yScale(d) + 20;
            }).attr("font-family", "sans-serif").attr("font-size", "11px").attr("fill", "white");
            this.addYScale(yScale);
            this.addXScale(xScale);
        },

        addXScale: function addXScale(xScale) {
            var x_axis = d3.svg.axis().scale(xScale);
            var canvas = this.get('canvas');
            canvas.selectAll("g.x.axis").remove();
            canvas.append("g").attr("class", "x axis").attr("transform", "translate(-40,500)").call(x_axis).selectAll("text").style("text-anchor", "end").attr("transform", "rotate(-65)");
        },
        addYScale: function addYScale(yScale) {

            var y_axis = d3.svg.axis().orient("left").scale(yScale);

            var canvas = this.get('canvas');
            canvas.selectAll("g.y.axis").remove();
            canvas.append("g").attr("class", "y axis").attr("transform", "translate(" + '0' + ",0)").call(y_axis);
        },

        getXScaleMarks: function getXScaleMarks(startingMonth, startingYear) {
            var xScaleMarks = [];
            for (var i = 0; i < 12; i++) {
                if (startingMonth > 12) {
                    startingMonth = startingMonth - 12;
                    startingYear = parseInt(startingYear) + 1;
                }

                var date = new Date(startingYear + "-" + startingMonth);
                var formatted = moment(date).format('MMM/YYYY');
                xScaleMarks.push(formatted);
                startingMonth++;
            }
            return xScaleMarks;
        },

        redirect: function redirect() {
            //        var self = this;
            //        var temp= self.getQuery();
            //        temp.then(function(report){
            //            self.set('m',report.objectAt(0));
            //            self.set('startingTime',self.get('m.year')+"-"+self.get('m.month'));
            //            var data = self.get('m.values').split(",").map(Number);
            //            data[i]=9;
            //            self.drawChart(data);
            //        })
        },

        actions: {
            getReport: function getReport() {
                var self = this;
                var temp = self.getQuery();
                temp.then(function (report) {
                    self.set('m', report.objectAt(0));
                    self.set('startingTime', self.get('m.year') + "-" + self.get('m.month'));
                    self.set("total", self.get('m.total'));
                    self.set("last30days", self.get('m.last30days'));
                    var data = self.get('m.values').split(",").map(Number);
                    self.drawChart(data);
                });
            }
        },
        getQuery: function getQuery() {
            var self = this;
            var dateTimeString = _ember['default'].$('#' + this.get('reportType')).find('input').combodate('getValue');
            var dateTimeArray = dateTimeString.split("/");
            var month = self.get('Months').indexOf(dateTimeArray[0]) + 1;
            var year = dateTimeArray[1];
            var store = self.get('targetObject.store');
            if (month > 0) {
                self.set('isLoading', true);
                return store.query('report', {
                    'reportType': self.get('reportType'),
                    'year': year,
                    'month': month
                }).then(function (report) {
                    self.set('isLoading', false);
                    return report;
                });
            }
        }
    });
});