define('my-care-git-ember-portal/mixins/carer_only', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Loadable mixin
   * This mixin is used to store loading state in controller.
   */
  exports['default'] = _ember['default'].Mixin.create({

    beforeModel: function beforeModel() {
      this.checkProfileType();
    },

    afterModel: function afterModel() {
      this.checkProfileType();
    },

    checkProfileType: function checkProfileType() {
      var profileType = localStorage.getItem("profileType");
      if (profileType == 'seeker') {
        location.href = "/";
      }
    }
  });
});