define('my-care-git-ember-portal/components/mc-image-cropper/step-one/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      _onCompleted: function _onCompleted(file) {
        this.onCompleted(file);
      }
    }

  });
});