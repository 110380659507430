define("my-care-git-ember-portal/mixins/code-email-reciever", ["exports", "ember"], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports["default"] = _ember["default"].Mixin.create({

        processRecieveData: function processRecieveData(transition, controllerName) {
            var email = transition.queryParams.email;
            var code = transition.queryParams.code;
            var resetPasswordObject = {
                "email": email, "code": code
            };
            if (code && email) {
                var controller = this.controllerFor(controllerName);
                controller.send('setAccountInfo', resetPasswordObject);
            }
        }

    });
});