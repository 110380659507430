define('my-care-git-ember-portal/components/mc-custom-activity-picker/component', ['exports', 'my-care-git-ember-portal/components/mc-multi-select/component'], function (exports, _myCareGitEmberPortalComponentsMcMultiSelectComponent) {
  exports['default'] = _myCareGitEmberPortalComponentsMcMultiSelectComponent['default'].extend({

    actions: {
      onNewActivityTypeAddedEvent: function onNewActivityTypeAddedEvent(activity) {
        this.get('options').pushObject(activity);
        this.get('selectedItems').pushObject(activity);
        this.reloadSelectedValues();
      }
    }
  });
});