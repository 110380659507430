define('my-care-git-ember-portal/mixins/routes/job-listing-radio-button', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({
        radioItems: [{ id: 'index', 'name': 'All', isSelected: false }, { id: 'open', 'name': 'Open', isSelected: false }, { id: 'draft', 'name': 'Draft', isSelected: false }, { id: 'closed', 'name': 'Closed', isSelected: false }, { id: 'not-hired', 'name': 'Not hired', isSelected: false }, { id: 'is-hired', 'name': 'Is hired', isSelected: false }, { id: 'no-applicants', 'name': 'No applicants', isSelected: false }],

        getJobListingSelect: function getJobListingSelect(string) {
            this.get('radioItems').forEach(function (radioItem) {
                _ember['default'].set(radioItem, "isSelected", false);
            });
            for (var i = 0; i < this.get('radioItems.length'); i++) {
                var radioItem = this.get('radioItems').objectAt(i);
                if (radioItem.name === string) {
                    _ember['default'].set(radioItem, "isSelected", true);
                    break;
                }
            }
            return this.get('radioItems');
        }

    });
});