define('my-care-git-ember-portal/components/mc-drop-down/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * date month-year-picker component
     */
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        /**
         * Setup component at startup.
         */
        _init: (function () {}).on('didInsertElement'),
        actions: {
            setSelectedItem: function setSelectedItem(objectId) {
                var object = this.get('items').findBy('id', objectId);
                this.sendAction('setItem', object);
            }
        }
    });
});