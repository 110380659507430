define("my-care-git-ember-portal/index/board-reports/working/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "my-care-git-ember-portal/index/board-reports/working/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Active platform service clients");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("The sum of Clients that have visits scheduled for the last 30 days or some future time.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Active platform service workers");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("The sum of workers that have visits scheduled for the last 30 days or some future time.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-6");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Visit hours approved");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("The sum of 'Visit Hours' approved in the period analysed.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 5, 5);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 5, 5);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2, 1, 1]), 5, 5);
        return morphs;
      },
      statements: [["inline", "mc-charts/mc-multi-line-chart", [], ["chartId", "clientschart", "reportDivId", "clientsReport", "reportTypes", ["subexpr", "@mut", [["get", "activePlatformClients", ["loc", [null, [6, 107], [6, 128]]], 0, 0, 0, 0]], [], [], 0, 0], "startingTime", ["subexpr", "@mut", [["get", "activePlatformClientsTime", ["loc", [null, [7, 26], [7, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "_isBeginAtZero", true], ["loc", [null, [6, 12], [7, 74]]], 0, 0], ["inline", "mc-charts/mc-multi-line-chart", [], ["chartId", "workerschart", "reportDivId", "workersReport", "reportTypes", ["subexpr", "@mut", [["get", "workers", ["loc", [null, [14, 107], [14, 114]]], 0, 0, 0, 0]], [], [], 0, 0], "startingTime", ["subexpr", "@mut", [["get", "workersStartingTime", ["loc", [null, [15, 25], [15, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "_isBeginAtZero", true], ["loc", [null, [14, 12], [15, 66]]], 0, 0], ["inline", "mc-charts/mc-multi-line-chart", [], ["chartId", "approvedVisitschart", "reportDivId", "approvedVisitsReport", "reportTypes", ["subexpr", "@mut", [["get", "approvedVisits", ["loc", [null, [24, 121], [24, 135]]], 0, 0, 0, 0]], [], [], 0, 0], "startingTime", ["subexpr", "@mut", [["get", "approvedVisitsTime", ["loc", [null, [25, 26], [25, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "_isBeginAtZero", true], ["loc", [null, [24, 12], [25, 66]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});