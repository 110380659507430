define('my-care-git-ember-portal/services/http', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    withAuthentication: function withAuthentication(work) {
      var access_token = localStorage.getItem('tokenKey');
      _ember['default'].$.ajaxPrefilter(function (options) {
        options.headers = {
          Authorization: 'bearer ' + access_token,
          "content-Type": "application/json"
        };
      });
      var defer = _ember['default'].RSVP.defer();
      work.call(null, defer);
      return defer.promise;
    },

    withoutAuthentication: function withoutAuthentication(work) {
      _ember['default'].$.ajaxPrefilter(function (options) {
        options.headers = {
          "content-Type": "application/json"
        };
      });
      var defer = _ember['default'].RSVP.defer();
      work.call(null, defer);
      return defer.promise;
    },

    withStandard: function withStandard(work) {
      _ember['default'].$.ajaxPrefilter(function (options) {
        options.headers = {};
      });
      var defer = _ember['default'].RSVP.defer();
      work.call(null, defer);
      return defer.promise;
    }
  });
});