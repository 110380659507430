define("my-care-git-ember-portal/index/experience/detail/address/controller", ["exports", "ember", "ember-concurrency", "my-care-git-ember-portal/utils/Validators", "my-care-git-ember-portal/mixins/validator"], function (exports, _ember, _emberConcurrency, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalMixinsValidator) {
    exports["default"] = _ember["default"].Controller.extend(_myCareGitEmberPortalMixinsValidator["default"], {

        isLoadingCategory: true,
        categories: [],
        formData: _ember["default"].Object.create({}),
        addressService: _ember["default"].inject.service('address'),
        locationService: _ember["default"].inject.service('location-service'),
        templateService: _ember["default"].inject.service('experience-event-template'),

        setup: function setup() {
            var _this = this;

            _ember["default"].RSVP.resolve(this.get('model.meetingLocation')).then(function (address) {
                _this.set('formData.number', _this.get('model.meetingLocation.number'));
                _this.set('formData.street', _this.get('model.meetingLocation.street'));
                _this.set('formData.city', _this.get('model.meetingLocation.city'));
                _this.set('formData.postcode', _this.get('model.meetingLocation.postcode'));
                _this.set('formData.directions', _this.get('model.directions'));
                _this.set('address', address);
            });
            this._setupValidations();
        },

        _setupValidations: function _setupValidations() {
            this.addValidation('formData.number', 'number', 'Number', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.street', 'street', 'Street', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.city', 'city', 'City', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.postcode', 'postcode', 'Post code', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
            this.addValidation('formData.directions', 'directions', 'Directions', [_myCareGitEmberPortalUtilsValidators["default"].isRequired]);
        },

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var latlng;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!this._validate()) {
                            context$1$0.next = 8;
                            break;
                        }

                        context$1$0.next = 3;
                        return this.get('addressService.saveAsync').perform({
                            address: this.get('address'),
                            formData: this.get('formData')
                        });

                    case 3:
                        context$1$0.next = 5;
                        return this.get('locationService.decodeAsync').perform({ location: this.get('formData.city') });

                    case 5:
                        latlng = context$1$0.sent;

                        this.get('templateService.saveAddressAsync').perform({
                            template: this.get('model'),
                            formData: this.get('formData'),
                            latlng: latlng
                        });
                        toastr.info('Experience updated.');

                    case 8:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});