define('my-care-git-ember-portal/index/browse/profile/index/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        sortProps: ['startedOn:desc'],
        sortedQualifications: _ember['default'].computed.sort('model.qualifications', 'sortProps'),
        sortedExperiences: _ember['default'].computed.sort('model.experiences', 'sortProps'),
        recommendationSortProps: ['createdOn:desc'],
        sortedRecommendations: _ember['default'].computed.sort('model.recommendations', 'recommendationSortProps'),

        setup: function setup() {
            var self = this;
            self.set('professionalRating', null);
            self.set('supportiveRating', null);
            self.set('trustedRating', null);
            self.set('friendlyRating', null);
            self.set('isNotLike', false);
            self.set('isLike', false);
            self.set('publicMessage', null);
            self.set('privateMessage', null);
            self.setReview();
            self.setIsHired();
        },

        setIsMinistryCheck: (function () {
            var self = this;
            self.get('model.trusts').then(function (trusts) {
                var ministryCheck = trusts.objectAt(0);
                self.set('isMinistryCheck', false);
                if (ministryCheck && ministryCheck.get('statusType') == 2) {
                    self.set('isMinistryCheck', true);
                }
            });
        }).observes('model.trusts'),

        setReview: function setReview() {
            var self = this;
            self.get('model.review', { reload: true }).then(function (review) {
                self.set('averageProfessional', null);
                self.set('averageTrusted', null);
                self.set('averageSupportive', null);
                self.set('averageFriendly', null);
                self.set('sumTotal', null);
                _ember['default'].run.later(function () {
                    self.set('total', review.get('getTotal'));
                    self.set('averageProfessional', review.get('getProfessional'));
                    self.set('averageTrusted', review.get('getTrusted'));
                    self.set('averageSupportive', review.get('getSupportive'));
                    self.set('averageFriendly', review.get('getFriendly'));
                    self.set('sumTotal', (self.get('averageProfessional') + review.get('getTrusted') + review.get('getSupportive') + review.get('getFriendly')) / 4);
                }, 200);
            });
        },

        setIsHired: function setIsHired() {
            var self = this;
            self.set('isHiredWorker', false);
            self.store.query('person', { status: 'contractedhired', isWorker: false, id: self.get('auth.profileId') }).then(function (hiredWorkers) {
                for (var i = 0; i < hiredWorkers.get('length'); i++) {
                    var worker = hiredWorkers.objectAt(i);
                    if (worker.get('id') === self.get('model.id')) {
                        self.set('isHiredWorker', true);
                        break;
                    }
                }
            });
        },

        messageRatioComplete: (function () {
            var self = this;
            return self.get('model.respondRatio') === 100;
        }).property('model.respondRatio'),

        isVerifiedId: (function () {
            var self = this;
            return self.get('model.isEmailVerified') && self.get('model.isPhoneNumberVerified') && self.get('model.isAddressVerified') && self.get('isMinistryCheckComplete');
        }).property('model.id', 'isMinistryCheckComplete'),

        isMyCareHero: (function () {
            var self = this;
            return self.get('model.respondRatio') === 100 && self.get('isVerifiedId') && self.get('model.numberOfHires') > 0;
        }).property('model.id', 'isVerifiedId', 'model.numberOfHires')

    });
});