define("my-care-git-ember-portal/components/mc-experience/gallery/mc-task-item/component", ["exports", "ember", "my-care-git-ember-portal/mixins/validator", "my-care-git-ember-portal/utils/mc-component", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsMcComponent, _emberConcurrency) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend(_myCareGitEmberPortalMixinsValidator["default"], {
        fileUploadServer: _ember["default"].inject.service('file-upload'),
        init: function init() {
            this.initVar('isCheckResult', false);
            this._super.apply(this, arguments);
        },

        actions: {
            onOpenFileInput: function onOpenFileInput() {
                this.$('input').click();
            },

            onSelectedFile: function onSelectedFile(files, resetInput) {
                var _this = this;

                var _thisFile = files[0];
                var checkResult = this.get('fileUploadServer').fileCheck(_thisFile, true);
                var checkFilesize = this.get('fileUploadServer').isFileTooLarge(_thisFile.size);
                this.set('isCheckResult', false);
                if (checkResult !== true) {
                    this.set('imageUploadMessage', checkResult);
                    this.set('isCheckResult', true);
                    return;
                }

                if (checkFilesize !== true) {
                    this.set('imageUploadMessage', checkFilesize);
                    this.set('isCheckResult', true);
                    return;
                }

                _ember["default"].RSVP.resolve(this.get('fileUploadServer.uploadFile').perform(_thisFile)).then(function (file) {
                    _this.set('_fileBlock.file', file);
                    _this.sendAction('onCompleted', _this.get('_fileBlock'));
                    resetInput();
                });
            },

            onDeleteFile: function onDeleteFile() {
                this.sendAction('onDeleted', this.get('displayFile'));
                this.set('displayFile', '');
            }
        }
    });
});