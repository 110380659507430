define('my-care-git-ember-portal/services/review-service', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Service.extend({
        _store: _ember['default'].inject.service('store'),
        /**
         * Get person by given id
         * @param {string} personId id of the person
         * @return {Promise} person with given id
         */
        postAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var formData = _ref.formData;
            var review;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        review = this.get('_store').createRecord('review', {
                            compassionate: null,
                            total: null,
                            professional: formData.professional,
                            trusted: formData.trusted,
                            supportive: formData.supportive,
                            friendly: formData.friendly,
                            person: formData.person,
                            description: formData.publicMessage,
                            reviewedBy: formData.reviewedBy,
                            reviewedOn: moment().toISOString()

                        });
                        return context$1$0.abrupt('return', review.save());

                    case 2:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),
        getReviewByIdAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref2) {
            var id = _ref2.id;
            var reload = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        return context$1$0.abrupt('return', this.get('_store').findRecord('review', id, { reload: reload }));

                    case 1:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});