define('my-care-git-ember-portal/components/mc-joblisting-editor/basic/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-component', 'my-care-git-ember-portal/mixins/viewer-premission', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalMixinsViewerPremission, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        store: _ember['default'].inject.service('store'),
        accountPayment: _ember['default'].inject.service('account-payment'),
        locationService: _ember['default'].inject.service('location-service'),
        div_id: 'joblisting-basic',
        rateTypes: [{
            id: 1,
            title: "Per hour"
        }, {
            id: 2,
            title: "Per visit"
        }],
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        appListingUrl: (function () {
            return this.get('env').getApp('APP_HOST') + "/browse/jobs/" + this.get('jobListing.id');
        }).property('jobListing.id'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isFormValid', undefined);
            this.initVar('jobListing', null);
            this.initVar('personId', null);
            this.initVar('errors', {});
            this.initVar('formData', {
                customTitle: null,
                suggestedName: null,
                tempLocation: null,
                isUsingSuggestedName: false,
                hourlyRateAmount: null,
                hoursPerWeek: null,
                closesOn: null,
                isShareable: false,
                isRequiredMoj: false,
                selectedRateType: null,
                services: [],
                totalCost: 0.00.toFixed(2)
            });
            this.initVar('isSaving', false);
            this.initVar('onSaved', null);
            this.initVar('reload', null);
            this.initVar('totalPriceCoachText', undefined);
            this.initVar('hoursPerWeekOptions', []);
            this.initVar('suggestJobListingNames', ['Caregiver', 'Peer Support', 'Mentor', 'Buddy', 'Companion', 'Support Worker', 'Respite Care', 'Personal Assistant', 'Complex Caregiver']);
        },

        _watchFormValid: (function () {
            return this.set('isFormValid', this._checkIsValidationPassed());
        }).observes('isValidationsPassed', 'isFormDirty', 'validations'),

        isDisabledSaveButton: (function () {
            if (this.get('isFormDirty') === false) {
                return true;
            } else if (this.get('isSaving') === true) {
                return true;
            }
            return false;
        }).property('isSaving', 'isFormDirty'),

        willSetupOnce: function willSetupOnce() {
            this._setup();
        },

        _setup: function _setup() {
            var _this = this;

            var jobListing = this.get('jobListing');
            this.initHourlyRate();
            return _ember['default'].RSVP.all([this._setupServices(), this._setupHoursPerWeekAsync(), this._setupHourlyRateAsync()]).then(function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('formData.title', jobListing.get('title'));
                _this.set('formData.description', jobListing.get('description'));
                _this.set('formData.tempLocation', jobListing.get('location'));
                _this.set('formData.isShareable', jobListing.get('isShareable'));
                _this.set('formData.isRequiredMoj', jobListing.get('isRequiredMoj'));
                if (_ember['default'].isEmpty(jobListing.get('closesOn')) === false) {
                    _this.set('formData.closesOn', moment.utc(jobListing.get('closesOn')).format('DD/MM/YYYY'));
                }
                if (jobListing.get('hourlyRateAmount') === 0) {
                    _this.set('formData.isVolunteer', true);
                }
                _this._setupValidations();
                _this._validate({ keepDirty: false });
                _this.set('isFormDirty', false);
            });
        },

        initHourlyRate: function initHourlyRate() {
            if (this.get('jobListing.isHourlyRate')) {
                this.set('formData.selectedRateType', this.get('rateTypes')[0]);
            } else {
                this.set('formData.selectedRateType', this.get('rateTypes')[1]);
            }
        },

        _setupHourlyRateAsync: function _setupHourlyRateAsync() {
            var _this2 = this;

            var jobListing = this.get('jobListing');
            return jobListing.get('hourlyRate').then(function () {
                if (_this2.isDestroyed) {
                    return;
                }
                if (!_ember['default'].isNone(jobListing.get('hourlyRateAmount'))) {
                    _this2.set('formData.hourlyRateAmount', jobListing.get('hourlyRateAmount'));
                }
            });
        },

        _setPureDss: function _setPureDss() {
            var _this3 = this;

            this.get('store').findRecord('person', this.get('personId')).then(function (person) {
                if (_this3.isDestroyed) {
                    return;
                }
                return _this3.get('accountPayment').isPureDss(person);
            }).then(function (isPureDss) {
                if (_this3.isDestroyed) {
                    return;
                }
                _this3.set('totalPriceCoachText', '\n        Let workers know what you are willing to pay.\n        Total cost includes the Mycare service fee' + (isPureDss ? " when you book and pay with Mycare." : ".") + '\n        ');
            });
        },

        _setupServices: function _setupServices() {
            var _this4 = this;

            this.get('store').findAll('service').then(function (services) {
                if (_this4.isDestroyed) {
                    return;
                }
                _this4.set('serviceOptions', services);
                _this4.set('formData.services', _this4.get('jobListing.services').toArray());
            });
        },

        _setupHoursPerWeekAsync: function _setupHoursPerWeekAsync() {
            var _this5 = this;

            return this.get('store').findAll('hours-per-week', { reload: true }).then(function (hoursPerWeek) {
                if (_this5.isDestroyed) {
                    return;
                }
                _this5.set('hoursPerWeekOptions', [_ember['default'].Object.create({
                    value: "-1",
                    label: 'Select hours per week'
                })]);
                var hoursPerWeekOptions = _this5.get('hoursPerWeekOptions');
                hoursPerWeek.forEach(function (rate) {
                    hoursPerWeekOptions.pushObject(_ember['default'].Object.create({
                        value: rate.get('id'),
                        label: rate.get('title'),
                        model: rate
                    }));
                });

                var selectedHoursPerWeek = _this5.get('hoursPerWeekOptions').filter(function (rate) {
                    return rate.get('value') === _this5.get('jobListing.hoursPerWeek.id');
                }).objectAt(0);
                _this5.set('formData.hoursPerWeek', selectedHoursPerWeek);
            });
        },

        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('formData.title', 'title', 'Title', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
            this.addValidation('formData.description', 'description', 'Description', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
            this.addValidation('formData.tempLocation', 'location', 'Location', [this.validateLocation]);
            this.addValidation('formData.hourlyRateAmount', 'hourlyRateAmount', 'Hourly rate', [this.validateHourlyRateAmount]);
            this.addValidation('formData.selectedRateType', 'selectedRateType', 'Hourly rate type', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
            this.addValidation('formData.hoursPerWeek', 'hoursPerWeek', 'Hourly per week', [this.validateHoursPerWeek]);
            this.addValidation('formData.services.[]', 'services', 'Services', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
        },

        _watchTotalCost: (function () {
            var _this6 = this;

            this.get('store').findRecord('person', this.get('personId')).then(function (person) {
                return person.get('accounts', { reload: true });
            }).then(function (accounts) {
                return accounts.objectAt(0);
            }).then(function (account) {
                return account.get('accountType.clientServiceFee');
            }).then(function (serviceRate) {
                if (_this6.isDestroyed) {
                    return;
                }
                var hourlyRate = parseFloat(_this6.get('formData.hourlyRateAmount') || 0.00);
                var mycareFee = hourlyRate * parseFloat(serviceRate);
                var totalCost = hourlyRate + mycareFee;
                _this6.set('formData.totalCost', totalCost.toFixed(2));
            });
        }).observes('formData.hourlyRateAmount'),

        validateLocation: function validateLocation(name) {
            var isValid = this.get('formData.tempLocation') || this.$('#location').find('input').attr('data-valid') !== 'false';
            return {
                result: isValid,
                message: isValid ? null : name + ' is required'
            };
        },

        validateHourlyRateAmount: function validateHourlyRateAmount() {
            var isValid = !_ember['default'].isEmpty(this.get('formData.hourlyRateAmount')) && (this.get('formData.hourlyRateAmount') == 0 || this.get('formData.hourlyRateAmount') >= 23);
            return {
                result: isValid,
                message: isValid ? null : 'Rate must be $23 or more'
            };
        },

        validateClosesOn: function validateClosesOn(name) {
            var isValid = !_ember['default'].isEmpty(this.get('formData.closesOn')) && moment.utc(this.get('formData.closesOn'), 'DD/MM/YYYY', true).isValid();
            return {
                result: isValid,
                message: isValid ? null : name + ' is required'
            };
        },

        validateHoursPerWeek: function validateHoursPerWeek(name) {
            var isValid = !_ember['default'].isEmpty(this.get('formData.hoursPerWeek')) && this.get('formData.hoursPerWeek').get('value') !== '-1';
            return {
                result: isValid,
                message: isValid ? null : name + ' is required'
            };
        },

        watchFormDirty: (function () {
            var _this7 = this;

            _ember['default'].RSVP.hash({
                services: this.get('jobListing.services')
            }).then(function (_ref) {
                var services = _ref.services;

                if (_this7.isDestroyed) {
                    return;
                }
                var getIds = function getIds(list) {
                    return (list || _ember['default'].A()).map(function (it) {
                        return it.id;
                    }).sort();
                };
                var isDirty = !_ember['default'].isEqual(_this7.get('formData.title'), _this7.get('jobListing.title')) || !_ember['default'].isEqual(_this7.get('formData.description'), _this7.get('jobListing.description')) || !_ember['default'].isEqual(_this7.get('formData.tempLocation'), _this7.get('jobListing.location')) || !_.isEqual(getIds(services), getIds(_this7.get('formData.services'))) || !_ember['default'].isEqual(_this7.get('formData.closesOn'), moment.utc(_this7.get('jobListing.closesOn')).format('DD/MM/YYYY')) || !_ember['default'].isEqual(_this7.get('formData.hourlyRateAmount'), _this7.get('jobListing.hourlyRateAmount')) || !_ember['default'].isEqual(_this7.get('formData.hoursPerWeek.value'), _this7.get('jobListing.hoursPerWeek.id'));
                _this7.set('isFormDirty', isDirty);
            });
        }).observes('formData.{title,closesOn,hourlyRateAmount,description}', 'formData.hoursPerWeek.value', 'formData.services.[]', 'formData.tempLocation'),

        updateModel: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var hoursPerWeek, latLng, jobListing;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        hoursPerWeek = this.get('formData.hoursPerWeek.model');

                        if (!this.isDestroyed) {
                            context$1$0.next = 3;
                            break;
                        }

                        return context$1$0.abrupt('return');

                    case 3:
                        context$1$0.next = 5;
                        return this.get('locationService.decodeAsync').perform({ location: this.get('formData.tempLocation') });

                    case 5:
                        latLng = context$1$0.sent;
                        jobListing = this.get('jobListing');

                        jobListing.set('latitude', latLng.lat);
                        jobListing.set('longitude', latLng.lng);
                        jobListing.set('title', this.get('formData.title'));
                        jobListing.set('description', this.get('formData.description'));
                        jobListing.set('services', this.get('formData.services'));
                        jobListing.set('isShareable', this.get('formData.isShareable'));
                        jobListing.set('isRequiredMoj', this.get('formData.isRequiredMoj'));
                        jobListing.set('isHourlyRate', false);
                        if (this.get('formData.selectedRateType.id') === 1) {
                            jobListing.set('isHourlyRate', true);
                        }
                        jobListing.set('hourlyRateAmount', this.get('formData.hourlyRateAmount'));
                        jobListing.set('hoursPerWeek', hoursPerWeek);
                        jobListing.set('closesOn', this.get('formData.closesOn') ? moment.utc(this.get('formData.closesOn'), 'DD/MM/YYYY').toISOString() : null);
                        jobListing.set('location', this.get('formData.tempLocation'));
                        return context$1$0.abrupt('return', jobListing);

                    case 21:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        handle502: function handle502(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('jobListing').reload();
                self.redirectTo('index.job-listings.index');
                toastr.error('Please check is job listing saved, and contact Mycare development team.');
            }
        },

        watchIsVolunteer: (function () {
            if (this.get('formData.isVolunteer') === false) {
                this.set('formData.hourlyRateAmount', '');
            } else {
                this.set('formData.hourlyRateAmount', 0);
            }
        }).observes('formData.isVolunteer'),

        actions: {
            onServicesSelected: function onServicesSelected(category, services) {
                this.set('formData.' + category, services);
            },

            save: function save() {
                var _this8 = this;

                var self = this;
                if (self._validate()) {
                    self.set('isSaving', true);
                    self.get('updateModel').perform().then(function (jobListing) {
                        if (_this8.isDestroyed) {
                            return;
                        }
                        jobListing.save().then(function (jobListing) {
                            if (_this8.isDestroyed) {
                                return;
                            }
                            self.set('jobListing', jobListing);
                            if (self.get('jobListing.hourlyRateAmount') === 0) {
                                _this8.set('formData.isVolunteer', true);
                            }
                            self.set('isSaving', false);
                            self.set('isFormDirty', false);
                            self.sendAction('onSaved', jobListing);
                        }, function (error) {
                            self.handle502(error);
                        });
                    });
                }
            }
        }
    });
});