define('my-care-git-ember-portal/login/route', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            var authController = this.controllerFor('auth');
            if (authController.get('isAuthenticated') === true) {
                this.transitionTo('index.dashboard');
            } else {
                localStorage.removeItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY);
                localStorage.removeItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_ADMIN);
                localStorage.removeItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_VIEWER);
            }
        }
    });
});