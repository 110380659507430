define('my-care-git-ember-portal/index/unsubmitted-timesheet/index/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        beforeModel: function beforeModel() {
            if (this.get('isViewer') === true) {
                this.transitionTo('index.dashboard');
            }
        },

        model: function model() {
            _ember['default'].$('#overlay').removeClass('open');
            _ember['default'].$(this).removeClass('active');
            return _ember['default'].RSVP.hash({
                needReviewVisits: this.store.query('timesheet', {
                    statuses: 'review'
                }, { reload: true }),
                unsubmittedVisits: this.store.query('timesheet', {
                    statuses: 'unsubmitted'
                }, { reload: true })
            }).then(function (result) {
                return result;
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('unsubmittedVisits', model.unsubmittedVisits);
            controller.set('needReviewVisits', model.needReviewVisits);
        }
    });
});