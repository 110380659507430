define("my-care-git-ember-portal/components/mc-experience/what-will-provide/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-component", "my-care-git-ember-portal/utils/common", "my-care-git-ember-portal/utils/function-helpers", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon, _myCareGitEmberPortalUtilsFunctionHelpers, _emberConcurrency) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({

        _wizardService: _ember["default"].inject.service('utils/wizard-experience-create'),
        _provideItemService: _ember["default"].inject.service('experience-provide-item'),
        _provideService: _ember["default"].inject.service('experience-provide'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_avatarUploaderChannelId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
            this.initVar('doesProvideAnything', false);
            this.initVar('selectedProvides', _ember["default"].A([]));
            this.initVar('formData', {
                title: null,
                description: null,
                thingsProvided: null
            });

            this.initVar('form_data', {});
            this.initVar('onCompleted', null);
            this.get('_loadDataAsync').perform();
        },

        _loadDataAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var options;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_provideItemService.findAll').perform();

                    case 2:
                        options = context$1$0.sent;

                        this.set('options', options);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            onToggleDoesProvide: function onToggleDoesProvide() {
                this.set('doesProvideAnything', !this.get('doesProvideAnything'));
            },

            onSelected: function onSelected(option) {
                this.get('addItemAsync').perform(option);
            },

            onRemove: function onRemove(index) {
                this.get('selectedProvides').removeAt(index);
                if (_ember["default"].isEmpty(this.get('selectedProvides'))) {
                    _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onCompleted')(true));
                }
            }
        },

        addItemAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(option) {
            var provide;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_provideService.createAsync').perform({
                            experienceEventProvideItem: option,
                            description: option.get('description'),
                            experienceEventTemplate: this.get('_template')
                        });

                    case 2:
                        provide = context$1$0.sent;

                        this.get('selectedProvides').pushObject(provide);
                        _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onCompleted')(false));
                        this.set('selectedOption', null);

                    case 6:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});