define('my-care-git-ember-portal/utils/common', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = {
        createGuid: function createGuid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },

        isNumber: function isNumber(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },

        parseAddressFromMap: function parseAddressFromMap(location) {
            if (_ember['default'].isNone(location)) {
                return null;
            }
            var locations = location.split(', ');
            return {
                suburb: locations[0],
                city: locations[locations.length === 1 ? 0 : 1]
            };
        },

        closeBrowserTab: function closeBrowserTab(env) {
            if (env.isMobile()) {
                window.history.back();
            } else {
                window.close();
            }
        },

        scrollToFirstErrorInput: function scrollToFirstErrorInput() {
            var selector = arguments.length <= 0 || arguments[0] === undefined ? '.has-error' : arguments[0];
            var offset = arguments.length <= 1 || arguments[1] === undefined ? 40 : arguments[1];
            //jshint ignore:line
            _ember['default'].run.later(function () {
                var firstErrorElem = _ember['default'].$(selector)[0];
                firstErrorElem.scrollIntoView(true);
                var scrolledY = window.scrollY;
                if (scrolledY) {
                    window.scroll(0, scrolledY - offset);
                }
            }, 100);
        },

        decodeLocation: function decodeLocation(location) {
            var defer = _ember['default'].RSVP.defer();
            GMaps.geocode({
                address: location,
                callback: function callback(results, status) {
                    if (status === 'OK') {
                        var latlng = results[0].geometry.location;
                        defer.resolve({
                            lat: latlng.lat(),
                            lng: latlng.lng()
                        });
                    } else {
                        defer.reject(status);
                    }
                }
            });
            return defer.promise;
        },

        /**
         * Generate options list for mc-select component
         * @param models list of orginal data
         * @param modelName name of the type of model to use in prompt message
         * @param labelKey property path for label in each model
         * @param valueKey property path for value in each model
         * @param prompt custom prompt message that override the default one with modelName
         * @param isIncludeNew include option for creating new mode
         * @returns list of options that can be passed to mc-select component
         */
        createSelectOptions: function createSelectOptions(_ref) {
            var models = _ref.models;
            var modelName = _ref.modelName;
            var labelKey = _ref.labelKey;
            var _ref$valueKey = _ref.valueKey;
            var valueKey = _ref$valueKey === undefined ? "id" : _ref$valueKey;
            var prompt = _ref.prompt;
            var _ref$isIncludeNew = _ref.isIncludeNew;
            var isIncludeNew = _ref$isIncludeNew === undefined ? true : _ref$isIncludeNew;
            //jshint ignore:line
            _ember['default'].Logger.assert(!_ember['default'].isNone(models), 'models is required');
            _ember['default'].Logger.assert(!_ember['default'].isNone(modelName), 'modelName is required');
            _ember['default'].Logger.assert(!_ember['default'].isNone(labelKey), 'labelKey is required');
            _ember['default'].Logger.assert(!_ember['default'].isNone(valueKey), 'valueKey is required');
            var selectOptions = _ember['default'].A([_ember['default'].Object.create({
                value: -1,
                label: prompt ? prompt : 'Select an ' + modelName,
                record: null
            })]);
            if (isIncludeNew === true) {
                selectOptions.pushObject(_ember['default'].Object.create({
                    value: 0,
                    label: 'Create a new ' + modelName,
                    record: null
                }));
            }
            models.forEach(function (model) {
                return selectOptions.pushObject(_ember['default'].Object.create({
                    value: model.get(valueKey),
                    label: model.get(labelKey),
                    record: model
                }));
            });
            return selectOptions;
        }
    };
});