define('my-care-git-ember-portal/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        person: _emberData['default'].belongsTo('person', { async: true }),
        bankAccounts: _emberData['default'].hasMany('bank-account', { async: true }),
        preferredBankAccount: _emberData['default'].attr('string'),
        accountBalance: _emberData['default'].attr('number'),
        pendingBalance: _emberData['default'].attr('number'),
        lifetimeEarnings: _emberData['default'].attr('number'),
        hoursPurchased: _emberData['default'].attr('number'),
        accountType: _emberData['default'].belongsTo('account-type', { async: true }),
        accountTypeName: _emberData['default'].attr('string'),
        transactionItems: _emberData['default'].attr('string'),
        creditCards: _emberData['default'].hasMany('credit-card', { async: true }),
        preferredCreditCard: _emberData['default'].attr('string'),
        isGstRegistered: _emberData['default'].attr('boolean'),
        irdNumber: _emberData['default'].attr('string'),
        topupAmount: _emberData['default'].attr('string'),
        updateBalance: _emberData['default'].attr('number'),
        allowNegativeBalance: _emberData['default'].attr('boolean'),
        negativeBalanceLimit: _emberData['default'].attr('number'),

        isWorkerAccount: (function () {
            return this.get('accountType.name').toLowerCase() === 'worker';
        }).property('accountType.name'),

        availableBalance: (function () {
            return this.get('accountBalance') - this.get('pendingBalance');
        }).property('accountBalance', 'pendingBalance'),

        isPositiveBalance: (function () {
            if (this.get('accountBalance') < 0) {
                return false;
            }
            return true;
        }).property('accountBalance'),

        allowNegativeBalanceStatus: (function () {
            return this.get('allowNegativeBalance') ? 'Y' : 'N';
        }).property('allowNegativeBalance'),

        isIFAllowedToBook: (function () {
            if (parseFloat(this.get('availableBalance')) >= 0) {
                return parseFloat(this.get('availableBalance')) <= parseFloat(this.get('negativeBalanceLimit')) && this.get('allowNegativeBalance') ? 'Yes' : 'No';
            } else {
                return parseFloat(this.get('availableBalance')) > parseFloat(this.get('negativeBalanceLimit')) * -1 && this.get('allowNegativeBalance') ? 'Yes' : 'No';
            }
        }).property('availableBalance', 'negativeBalanceLimit', 'allowNegativeBalance'),

        isIFClient: (function () {
            return this.get('accountType.name').toLowerCase() === 'if' || this.get('accountType.name').toLowerCase() === 'if family carers';
        }).property('accountType.name')

    });
});