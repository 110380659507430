define('my-care-git-ember-portal/index/users/threads/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        selectedValues: [],
        threads: [],
        messageSortingDesc: ['message.sentOn:desc'],
        sortedThread: _ember['default'].computed.sort('threads', 'messageSortingDesc'),
        relatedWith: [],
        pageSize: 10,
        offset: 0,
        isThreadLoading: false,
        isMore: true,

        getThreads: (function () {
            var self = this;
            self.set('currentProfile', this.get('model'));
            self.set('isThreadLoading', true);
            self.store.query('thread', { personId: self.get('model.id'), pageSize: self.get('pageSize'), offSet: self.get('offset') }).then(function (threads) {
                for (var i = 0; i < threads.get('length'); i++) {
                    var thread = threads.objectAt(i);
                    if (!self.get('threads').contains(thread)) {
                        self.get('threads').pushObject(thread);
                    }
                }
                if (threads.get('length') < self.get('pageSize')) {
                    self.set('isMore', false);
                } else {
                    self.set('offset', self.get('offset') + self.get('pageSize'));
                }
                self.set('isThreadLoading', false);
            }, function () {
                self.set('isThreadLoading', false);
            });
        }).observes('model'),
        actions: {
            loadMoreMessage: function loadMoreMessage() {
                this.getThreads();
            },
            viewThreads: function viewThreads(thread) {
                this.set("isDisplayMessages", true);
                this.transitionToRoute('index.users.threads.messages', thread);
            },
            backtouser: function backtouser() {
                window.history.go(-1);
            }
        }
    });
});