define("my-care-git-ember-portal/components/mc-custom-activity-picker/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 6
              },
              "end": {
                "line": 6,
                "column": 6
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "item.name", ["loc", [null, [5, 12], [5, 25]]], 0, 0, 0, 0]],
          locals: ["item"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 2
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "selectedItems", ["loc", [null, [4, 14], [4, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 6], [6, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "btn btn--primary");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-times");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element7);
            morphs[1] = dom.createMorphAt(element7, 2, 2);
            return morphs;
          },
          statements: [["element", "action", ["removeItem", ["get", "item.id", ["loc", [null, [10, 31], [10, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 9], [10, 40]]], 0, 0], ["content", "item.name", ["loc", [null, [10, 99], [10, 112]]], 0, 0, 0, 0]],
          locals: ["item"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "selectedItems", ["loc", [null, [9, 12], [9, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 4], [11, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-fw fa-pencil");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Edit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [["element", "action", ["toggleSelectionModal"], [], ["loc", [null, [16, 7], [16, 40]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "class", "btn");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-fw fa-plus");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Add");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["element", "action", ["toggleSelectionModal"], [], ["loc", [null, [18, 12], [18, 45]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 8
                },
                "end": {
                  "line": 29,
                  "column": 8
                }
              },
              "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h2");
              dom.setAttribute(el1, "class", "h3 title-highlight");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "title", ["loc", [null, [28, 41], [28, 50]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 10
                },
                "end": {
                  "line": 37,
                  "column": 10
                }
              },
              "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "checkbox");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element1, 1, 1);
              morphs[1] = dom.createMorphAt(element1, 2, 2);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "item.isChecked", ["loc", [null, [34, 48], [34, 62]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [34, 16], [34, 65]]], 0, 0], ["content", "item.name", ["loc", [null, [34, 65], [34, 78]]], 0, 0, 0, 0]],
            locals: ["item"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 2
              },
              "end": {
                "line": 42,
                "column": 2
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-body");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "close");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "aria-hidden", "true");
            var el4 = dom.createTextNode("×");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "inner");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "form-horizontal col-2");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(element4, 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
            morphs[3] = dom.createMorphAt(element4, 5, 5);
            return morphs;
          },
          statements: [["element", "action", [["get", "closeAction", ["loc", [null, [25, 51], [25, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 42], [25, 64]]], 0, 0], ["block", "if", [["get", "title", ["loc", [null, [27, 14], [27, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 8], [29, 15]]]], ["block", "each", [["get", "componentList", ["loc", [null, [31, 18], [31, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [31, 10], [37, 19]]]], ["inline", "mc-custom-activity-editor", [], ["personId", ["subexpr", "@mut", [["get", "personId", ["loc", [null, [39, 45], [39, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "selectedItems", ["loc", [null, [39, 60], [39, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "onAdded", ["subexpr", "action", ["onNewActivityTypeAddedEvent"], [], ["loc", [null, [39, 82], [39, 120]]], 0, 0]], ["loc", [null, [39, 8], [39, 122]]], 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 2
              },
              "end": {
                "line": 47,
                "column": 2
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-footer");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "btn");
            var el3 = dom.createTextNode("Done");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", [["get", "closeAction", ["loc", [null, [45, 49], [45, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 40], [45, 62]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "section.body", ["loc", [null, [23, 8], [23, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 2], [42, 9]]]], ["block", "if", [["get", "section.footer", ["loc", [null, [43, 8], [43, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [43, 2], [47, 9]]]]],
        locals: ["section", "closeAction"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 13
          }
        },
        "moduleName": "my-care-git-ember-portal/components/mc-custom-activity-picker/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(fragment, [2]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element8, 'class');
        morphs[1] = dom.createMorphAt(element8, 1, 1);
        morphs[2] = dom.createAttrMorph(element9, 'class');
        morphs[3] = dom.createMorphAt(element9, 1, 1);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["multi-select ", ["get", "_modalChannelId", ["loc", [null, [1, 27], [1, 42]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isEditMode", ["loc", [null, [2, 8], [2, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [12, 9]]]], ["attribute", "class", ["concat", ["space-1 ", ["subexpr", "if", [["get", "isEditMode", ["loc", [null, [14, 25], [14, 35]]], 0, 0, 0, 0], "space-top"], [], ["loc", [null, [14, 20], [14, 49]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isEditMode", ["loc", [null, [15, 8], [15, 18]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [15, 2], [19, 9]]]], ["block", "mc-modal", [], ["channelId", ["subexpr", "@mut", [["get", "_modalChannelId", ["loc", [null, [22, 22], [22, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], 4, null, ["loc", [null, [22, 0], [48, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});