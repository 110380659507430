define('my-care-git-ember-portal/index/experience/detail/statuses/controller', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend({

        statusService: _ember['default'].inject.service('data-service/experience-event-template-status'),
        setup: function setup() {
            this.get('loadData').perform();
        },
        loadData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var statuses;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('statusService.getByTemplateId').perform(this.get('model.id'), true);

                    case 2:
                        statuses = context$1$0.sent;

                        this.set('statuses', statuses.toArray().reverse());

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {}

    });
});