define("my-care-git-ember-portal/index/search/profiles/controller", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Controller.extend({
        defaultLocation: 'Auckland, New Zealand',
        sortProps: ['numberOfVerifications:desc', 'loginOn:desc', 'modifiedOn:desc'],
        sortedResultList: _ember["default"].computed.sort('model', 'sortProps'),
        locationService: _ember["default"].inject.service('location-service'),
        searchService: _ember["default"].inject.service('search'),
        markerService: _ember["default"].inject.service('marker'),
        isEnableOnCoordinateChangedEvent: false,
        lat: null,
        lng: null,
        zoom: 13,
        setup: function setup() {
            var self = this;
            if (!this.get('location')) {
                this.set('location', this.get('defaultLocation'));
            }
            self.set('mapBound', _ember["default"].Object.create({
                northLat: null,
                southLat: null,
                eastLng: null,
                westLng: null
            }));
            this.set('isEnableOnCoordinateChangedEvent', false);
        },

        markers: (function () {
            var _this = this;

            return this.get('sortedResultList').map(function (item) {
                return _this.get('markerService').createProfileMarker(item);
            });
        }).property('sortedResultList.[]'),

        _resize: function _resize() {
            var resultContainer = _ember["default"].$(".job-card-container");
            if (window.innerHeight === window.outerHeight) {
                resultContainer.css({
                    height: '100%',
                    maxHeight: '100%'
                });
            } else {
                var bodyHeight = window.innerHeight - 68;
                resultContainer.css({
                    height: bodyHeight,
                    maxHeight: bodyHeight
                });
            }
        },

        runSearch: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var self, thisLatlng, parameter;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;
                        context$1$0.next = 3;
                        return this.get('locationService.decodeAsync').perform({ location: this.get('location') });

                    case 3:
                        thisLatlng = context$1$0.sent;

                        self.set('lat', thisLatlng.lat);
                        self.set('lng', thisLatlng.lng);
                        self.set('mapBound.northLat', thisLatlng.lat);
                        self.set('mapBound.eastLng', thisLatlng.lng);

                        parameter = this.get('searchService').joinBoundsTpSearchParameter(this.get('mapBound'), this.get('location'));
                        context$1$0.next = 11;
                        return this.get('fetchData').perform(parameter);

                    case 11:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        fetchData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(parameter) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this2 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('model', []);
                        context$1$0.next = 3;
                        return this.get('searchService').queryProfiles(parameter).then(function (serviceProfiles) {
                            _this2.set('model', serviceProfiles.toArray());
                            _this2.set('isEnableOnCoordinateChangedEvent', true);
                        });

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            changeSearchType: function changeSearchType(type) {
                if (type === "profile") {
                    this.transitionToRoute('index.search.profiles', { queryParams: { location: this.get('location') } });
                } else if (type === "job") {
                    this.transitionToRoute('index.search.jobs', { queryParams: { location: this.get('location') } });
                }
            },

            search: function search(location, searchParameter) {
                if (location) {
                    this.set('location', location);
                    this.get('searchService').set('searchParameter', searchParameter);
                    this.get('runSearch').perform();
                }
            },

            onCoordinateChangedEvent: function onCoordinateChangedEvent(latLng, bounds) {
                var self = this;

                if (this.get('isEnableOnCoordinateChangedEvent') === true) {
                    self.set('lat', latLng.lat);
                    self.set('lng', latLng.lng);

                    var parameter = this.get('searchService').joinBoundsTpSearchParameter(bounds);
                    this.get('fetchData').perform(parameter);
                }
            },

            onMapResizedEvent: function onMapResizedEvent() {
                var self = this;
                _ember["default"].$('.panel-card-carer').hover(function () {
                    var _this3 = this;

                    var aM = self.get('markers').find(function (m) {
                        return String(m.get('id')) === String($(_this3).data('id'));
                    });
                    self.set('activeMarker', aM);
                });
                this._resize();
            }
        }
    });
});