define("my-care-git-ember-portal/services/experience-provide-item", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _store: _ember["default"].inject.service('store'),

        getItemById: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findRecord('experience-event-provide-item', id);

                    case 2:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        findAll: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        return context$1$0.abrupt("return", this.get('_store').findAll('experience-event-provide-item'));

                    case 1:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});