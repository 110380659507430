define('my-care-git-ember-portal/index/job-listings/messages/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        messageSorting: ['sentOn:desc'],
        sortedMessages: _ember['default'].computed.sort('messages', 'messageSorting'),
        actions: {
            backToUpperLevel: function backToUpperLevel() {
                window.history.back();
            }
        }
    });
});