define('my-care-git-ember-portal/components/mc-experience/basic/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/fedback-handler'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsFedbackHandler) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], {
        tagName: '',
        _store: _ember['default'].inject.service('store'),
        _gMaps: _ember['default'].inject.service('location-service'),
        organisers: [],
        selectedOrganiser: null,
        tempClient: null,
        /**
         * Available services to select
         */
        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.get('organisers').clear();
            var promiseHash = {
                services: self.get('_store').findAll('service'),
                languages: self.get('_store').findAll('language'),
                requirements: self.get('_store').findAll('requirement'),
                employmentTypes: self.get('_store').findAll('employmentType'),
                equipment: self.get('_store').findAll('equipment'),
                hourlyRates: self.get('_store').findAll('hourlyRate')

            };
            _ember['default'].RSVP.hash(promiseHash).then(function (_ref) {
                var services = _ref.services;
                var languages = _ref.languages;
                var requirements = _ref.requirements;
                var employmentTypes = _ref.employmentTypes;
                var equipment = _ref.equipment;
                var hourlyRates = _ref.hourlyRates;

                self.set('services', services);
                self.set('languages', languages);
                self.set('requirements', requirements);
                self.set('employmentTypes', employmentTypes);
                self.set('equipment', equipment);
                self.set('hourlyRates', hourlyRates);
            });

            self.set('hoursPerWeekID', self.get('model.hoursPerWeek.id'));
            if (self.get('model.isNew')) {
                self.setNewJobListingOrganisers();
            }
            self.modelTimesetup();
            self.emptyErrorMessages();
            self.resetDropdownComponentAttributes();
        },

        setNewJobListingOrganisers: function setNewJobListingOrganisers() {
            var _this = this;

            this.get('model.person.relatedWith').forEach(function (relationship) {
                _ember['default'].RSVP.hash({
                    person: relationship.get('person')
                }).then(function (_ref2) {
                    var person = _ref2.person;

                    _this.get('organisers').pushObject(person);
                });
            });
        },

        watchOrganisers: (function () {
            var self = this;
            if (self.get('organisers.length') > 1) {
                self.set('isMoreOrganisers', true);
            } else {
                self.set('selectedOrganiser', self.get('organisers').objectAt(0));
                self.set('isMoreOrganisers', false);
            }
        }).observes('organisers.[]'),

        modelTimesetup: function modelTimesetup() {
            //job start time and end time
            this.set('rawStartsOn', this.get('model.getStartsOn'));
            this.set('rawEndsOn', this.get('model.getEndsOn'));
            //application create time and end time
            this.set('rawCreatedOn', moment(moment(this.get('model.getCreatedOn'), "DD/MM/YYYY")).format("DD/M/YYYY"));
            this.set('rawClosedOn', this.get('model.getClosedOn'));
        },

        hourlyRateValue: (function () {
            var self = this;
            var rateId = self.get('model.hourlyRate.id');
            if (rateId == '1') {
                return 'Negotiable';
            } else if (rateId == '2') {
                return '$16 - $20';
            } else if (rateId == '3') {
                return '$20 - $25';
            } else if (rateId == '4') {
                return '$25 - $30';
            } else if (rateId == '5') {
                return '$30 - $35';
            } else if (rateId == '6') {
                return '$35 - $40';
            } else if (rateId == '7') {
                return 'Volunteer';
            }
        }).property('model.hourlyRate.id'),

        actions: {
            save: function save() {
                var self = this;
                if (self.formCheck()) {
                    self.set('isSaving', true);
                    var taskPromises = [];
                    taskPromises.push(self.get('_store').findRecord('hoursPerWeek', self.get('hoursPerWeekID')));
                    if (self.get('model.isNew') === true) {
                        taskPromises.push(self.get('_gMaps').getLatlng(self.get('model.location')));
                        self.set('model.organiser', self.get('selectedOrganiser'));
                    }
                    _ember['default'].RSVP.all(taskPromises).then(function (values) {
                        self.setModelTimes();
                        self.set('model.hoursPerWeek', values[0]);
                        if (self.get('model.isNew') === true) {
                            var latlng = values[1];
                            self.set('model.latitude', latlng.lat);
                            self.set('model.longitude', latlng.lng);
                        }
                        self.get('model').save().then(function () {
                            self.set('isSaving', false);
                            toastr.info('Listing Saved.');
                            self.set('model', null);
                            window.history.go(-1);
                        });
                    }, function () {
                        self.set('isSaving', false);
                        toastr.error('Listing is not Saved, please contact Mycare development team.');
                    });
                }
            },
            transitionToLocation: function transitionToLocation() {
                this.transitionToRoute('index.job-listings.threads', { queryParams: { personId: 11 } });
            },

            back: function back() {
                window.history.go(-1);
            }
        },

        formCheck: function formCheck() {
            //Clear out all previous errors
            this.emptyErrorMessages();
            this.resetDropdownComponentAttributes();
            this.set('servicesError', false);
            var isValid = true;
            _ember['default'].$('.has-error').removeClass('has-error');

            if (_ember['default'].isEmpty(this.get('model.title')) === true) {
                this.get('errors').pushObject('A title for your job is required');
                _ember['default'].$('#Title').find('input').addClass('has-error');
                isValid = false;
            }

            if (_ember['default'].isEmpty(this.get('model.hourlyRateAmount')) === true) {
                this.get('errors').pushObject('Hourly rate is required');
                _ember['default'].$('#hourlyRate').addClass('has-error');
                isValid = false;
            }

            if (!this.get('model.description')) {
                this.get('errors').pushObject('A description for your job is required, let workers know what they can expect.');
                _ember['default'].$('#description').addClass('has-error');
                isValid = false;
            }

            if (!this.get('model.location') || _ember['default'].$('#location').find('input').attr('data-valid') == 'false') {
                this.get('errors').pushObject('A location for your job is required');
                _ember['default'].$('#location').find('input').addClass('has-error');
                isValid = false;
            }

            if (this.get('selectedHourlyRate.length') === 0) {
                this.get('errors').pushObject('An indicative hourly rate is required');
                _ember['default'].$('#hourlyRate').find('span').addClass('has-error');
                isValid = false;
            }

            if (_ember['default'].isEmpty(this.get('hoursPerWeekID')) === true) {
                this.get('errors').pushObject('Please select hours per week');
                _ember['default'].$('#hoursPerWeek').addClass('has-error');
                isValid = false;
            }

            if (this.get('model.services.length') < 1) {
                this.get('errors').pushObject('At least one service type is required');
                this.set('servicesError', true);
                isValid = false;
            }

            if (!isValid) {
                window.scrollTo(0, 0);
            }

            return isValid;
        },

        setModelTimes: function setModelTimes() {

            if (this.get('rawStartsOn')) {
                this.set('model.startsOn', moment(this.toDate(this.get('rawStartsOn'))).add(1, 'days').toISOString());
            } else {
                this.set('model.startsOn', moment().toISOString());
            }

            if (this.get('rawEndsOn')) {
                this.set('model.endsOn', moment(this.toDate(this.get('rawEndsOn'))).add(1, 'days').toISOString());
            } else {
                this.set('model.endsOn', '');
            }

            if (this.get('rawClosedOn')) {
                this.set('model.closesOn', moment(this.toDate(this.get('rawClosedOn'))).add(1, 'days').toISOString());
            } else {
                this.set('model.closesOn', '');
            }
        },

        resetDropdownComponentAttributes: function resetDropdownComponentAttributes() {
            this.set('employmentTypesError', false);
            this.set('servicesError', false);
            this.set('languagesError', false);
        },

        toDate: function toDate(dateStr) {
            var parts = dateStr.split("/");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        },

        toggle: function toggle() {
            _ember['default'].$('.panel-collapse .collapse .in').collapse('hide');
            _ember['default'].$('.panel-collapse .collapse').collapse('show');
            _ember['default'].run.later(function () {
                _ember['default'].$('#details').removeClass('in');
                _ember['default'].$('#applicants').addClass('in');
            }, 500);
        }

    });
});