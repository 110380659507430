define('my-care-git-ember-portal/mixins/defer', ['exports', 'ember'], function (exports, _ember) {
    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({

        getDeferWithToken: function getDeferWithToken() {
            var access_token = localStorage.getItem('tokenKey');
            _ember['default'].$.ajaxPrefilter(function (options) {
                options.headers = {
                    Authorization: 'bearer ' + access_token,
                    "content-Type": "application/json"
                };
            });
            var defer = _ember['default'].RSVP.defer();

            return defer;
        },

        getDeferNoToken: function getDeferNoToken() {
            _ember['default'].$.ajaxPrefilter(function (options) {
                options.headers = {
                    "content-Type": "application/json"
                };
            });
            var defer = _ember['default'].RSVP.defer();

            return defer;
        }, getDeferWithStandardHeader: function getDeferWithStandardHeader() {
            _ember['default'].$.ajaxPrefilter(function (options) {
                options.headers = {};
            });
            var defer = _ember['default'].RSVP.defer();
            return defer;
        }
    });
});