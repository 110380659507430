define('my-care-git-ember-portal/components/mc-user-detail/worker-qualification/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/viewer-premission'], function (exports, _ember, _myCareGitEmberPortalMixinsViewerPremission) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsViewerPremission['default'], {
        tagName: '',
        sortProps: ['startedOn:desc'],
        sortedQualifications: _ember['default'].computed.sort('model.qualifications', 'sortProps'),
        div_id: "qualifications",
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },
        actions: {
            addNewQualification: function addNewQualification() {
                this.set('qualificationItem', _ember['default'].Object.create());
                this.set('qualificationType', null);
                this.set('qualificationEditingItem', null);
                this.set('isQualificationEditMode', false);
                this.set('qualificationTitle', 'Add new qualification');
                var modal = _ember['default'].$('#modal-profile-manage-qualification');
                modal.modal('hide');
                modal.modal('show');
            },

            editQualification: function editQualification(item) {
                var self = this;
                item.get('qualificationType').then(function (qualificationType) {
                    self.set('qualificationType', qualificationType);
                    var startedOn = item.get('startedOn'),
                        endedOn = item.get('endedOn');
                    self.set('qualificationItem', _ember['default'].Object.create({
                        title: item.get('title'),
                        organisation: item.get('organisation'),
                        description: item.get('description'),
                        startedOn: startedOn,
                        endedOn: endedOn
                    }));
                    self.set('qualificationEditingItem', item);
                    self.set('isQualificationEditMode', true);
                    self.set('qualificationTitle', 'Edit qualification');
                    var modal = _ember['default'].$('#modal-profile-manage-qualification');
                    modal.modal('hide');
                    modal.modal('show');
                });
            },
            deleteQualification: function deleteQualification(item) {
                bootbox.confirm('Are you sure you want to delete?', function (result) {
                    if (result) {
                        _ember['default'].$('.modal').modal('hide');
                        item.deleteRecord();
                        item.save().then(function () {
                            toastr.info(item.get('title') + ' is deleted.');
                        });
                    }
                });
            }
        }
    });
});