define('my-care-git-ember-portal/services/message', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Service.extend({
        _store: _ember['default'].inject.service('store'),
        getMessageByThreadIdAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(threadId, pageSize, offSet) {
            var reload = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').query('message', { threadId: threadId, pageSize: pageSize, offSet: offSet }, { reload: reload });

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});