define("my-care-git-ember-portal/models/credit-card", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        account: _emberData["default"].belongsTo('account', { async: true }),
        cardNumber: _emberData["default"].attr('string'),
        cardName: _emberData["default"].attr('string'),
        cardExpiresOn: _emberData["default"].attr('string'),
        cardHolderName: _emberData["default"].attr('string'),
        url: _emberData["default"].attr('string'),
        isPreferredCreditCard: _emberData["default"].attr('boolean'),
        isVisa: (function () {
            return this.get('cardName').toLowerCase() === 'visa';
        }).property('cardName'),
        isMastercard: (function () {
            return this.get('cardName').toLowerCase() === 'mastercard';
        }).property('cardName'),
        isSwathe: (function () {
            return _ember["default"].isEmpty(this.get('cardName'));
        }).property('cardName')
    });
});