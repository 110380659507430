define('my-care-git-ember-portal/index/users/detail/account-detail/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {

        model: function model(params) {
            return this.store.findRecord('account', params.account_id);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('account_id', model.get('id'));
            controller.get('transactions').clear();
            controller.getSearchResults(50, 0).then(function (transactions) {
                controller.get('transactions').pushObjects(transactions.toArray());
            });
        },

        renderTemplate: function renderTemplate() {
            var controller = this.controllerFor('index.users.detail.account-detail');
            controller.set('isMore', true);
            this.render('index.users.detail.account-detail', {
                into: 'index/users',
                controller: controller
            });
        }
    });
});