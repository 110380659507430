define('my-care-git-ember-portal/index/board-reports/paying/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        platformServiceRevenues: ['platformServiceDssRevenues', 'platformServicePrivateRevenues', 'platformServiceRevenues'],
        platformServiceRevenuesTime: moment().add(-12, 'month'),
        //
        averageClientHourlyCosts: ['averageDssClientHourlyCost', 'averagePrivateClientHourlyCost', 'averageClientHourlyCost'],
        averageClientHourlyCostsTime: moment().add(-12, 'month'),
        //
        averageMycareHourlyFees: ['averageDssMycareHourlyFee', 'averagePrivateMycareHourlyFee', 'averageMycareHourlyFee'],
        averageMycareHourlyFeesTime: moment().add(-12, 'month'),
        //
        averageWorkerIncome: ['averageWorkerIncome'],
        averageWorkerIncomeTime: moment().add(-12, 'month'),
        //
        averageWorkerNegotiatedRate: ['averageWorkerNegotiatedRate'],
        averageWorkerNegotiatedRateTime: moment().add(-12, 'month'),

        averageVisitFees: ['averageDssMycareVisitFee', 'averagePrivateMycareVisitFee', 'averageMycareVisitFee'],
        averageTime: moment().add(-12, 'month')
    });
});