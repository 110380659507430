define('my-care-git-ember-portal/components/mc-select/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    id: null,
    selectedOption: null,
    options: [],
    valuePath: 'value',
    labelPath: 'label',
    onSelected: null,
    isDisabled: false,
    hasError: false,
    classNames: null,
    prompt: null,

    _options: _ember['default'].computed('options.[]', function () {
      var _this = this;

      var options = this.get('options');
      var result = _ember['default'].A(options).map(function (opt) {
        if (typeof opt === 'string' || typeof opt === 'number') {
          var _Ember$Object$create;

          return _ember['default'].Object.create((_Ember$Object$create = {}, _defineProperty(_Ember$Object$create, _this.get('valuePath'), opt), _defineProperty(_Ember$Object$create, _this.get('labelPath'), opt), _Ember$Object$create));
        }
        return opt;
      });
      return this._addPromptOrReturn(result);
    }),

    _addPromptOrReturn: function _addPromptOrReturn(items) {
      var prompt = this.get('prompt');
      if (prompt) {
        var _Ember$Object$create2;

        return [_ember['default'].Object.create((_Ember$Object$create2 = {}, _defineProperty(_Ember$Object$create2, this.get('valuePath'), null), _defineProperty(_Ember$Object$create2, this.get('labelPath'), typeof prompt === 'string' ? prompt : 'Please select item'), _Ember$Object$create2))].concat(_toConsumableArray(items));
      }
      return items;
    },

    _selectedOption: _ember['default'].computed('selectedOption', function () {
      var _this2 = this;

      var selectedOption = this.get('selectedOption');
      if (typeof selectedOption === 'string') {
        return _ember['default'].Object.create({
          value: selectedOption,
          label: selectedOption
        });
      } else {
        if (!selectedOption) {
          var $select = this.$('select');
          if ($select) {
            $select.val(null);
          }
          return this.get('_options').find(function (opt) {
            return opt.get(_this2.get('valuePath')) === null;
          });
        }
        return selectedOption;
      }
    }),

    actions: {
      onSelectedEvent: function onSelectedEvent(selectedOption) {
        var _this3 = this;

        var options = this.get('_options');
        var option = options.filter(function (opt) {
          return String(opt[_this3.get('valuePath')]) === String(selectedOption);
        }).objectAt(0);
        if (this.get('options').any(function (opt) {
          return typeof opt === 'string' || typeof opt === 'number';
        })) {
          this.set('selectedOption', option.get(this.get('valuePath')));
          this.sendAction('onSelected', option.get(this.get('valuePath')));
        } else {
          this.set('selectedOption', option);
          this.sendAction('onSelected', option);
        }
      }
    }
  });
});