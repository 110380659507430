define('my-care-git-ember-portal/models/message', ['exports', 'ember-data'], function (exports, _emberData) {
    //import ENV from 'mycare-ember/config/environment';

    exports['default'] = _emberData['default'].Model.extend({
        avatar: _emberData['default'].belongsTo('file', { async: true }),
        thread: _emberData['default'].belongsTo('thread', { async: true }),
        text: _emberData['default'].attr('string'),
        sentOn: _emberData['default'].attr('string'),
        isIncludeProfile: _emberData['default'].attr('boolean'),
        sender: _emberData['default'].belongsTo('person', { async: true })
    });
});
//    isSentByMe: function() {
//        var  currentPersonId= localStorage.getItem(ENV.APP.AUTH_USER_KEY);
//        if(currentPersonId==this.get('sender.id')){
//          	return true;
//        }
//        else{
//          	return false;
//        }
//    }.property('sender'),