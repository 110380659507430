define('my-care-git-ember-portal/index/users/detail/worker-account/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/mixins/fedback-handler', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalMixinsFedbackHandler, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {

        _workerAccount: {},

        setup: function setup() {
            var self = this;
            self._getWorkerAccount();
        },

        _getWorkerAccount: function _getWorkerAccount() {
            var _this = this;

            this.get('model.accounts').then(function (accounts) {
                var workerAccounts = accounts.filter(function (account) {
                    return account.get('accountType.name').toLowerCase() === 'worker';
                });
                if (workerAccounts.get('length') === 1) {
                    _this.set('_workerAccount', workerAccounts.objectAt(0));
                }
            });
        },

        watchClientAccount: (function () {
            this._getWorkerAccount();
        }).observes('model')

    });
});