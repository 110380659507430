define("my-care-git-ember-portal/components/mc-search-bar/check-boxes/component", ["exports", "ember", "my-care-git-ember-portal/utils/function-helpers", "my-care-git-ember-portal/utils/mc-component"], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _myCareGitEmberPortalUtilsMcComponent) {
  exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({

    init: function init() {
      this._super.apply(this, arguments);
      this.initVar('options', null);
      this.initVar('onSelected', null);
      this.initVar('actionParam', null);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      if (!_ember["default"].isEmpty(this.get('options'))) {
        // this._setupFormData();
        this.toggleProperty('_isInitialised');
      }
    },

    // _setupFormData: function(){
    //   var initialValues = this.get('initialValues') || Ember.A();
    //   var hasInitialValue = (item) => !Ember.isNone(initialValues.find(initItem => initItem.get('id') === item.get('id')));
    //   // this.get('options').forEach(item => {
    //   //   item.set('isChecked', hasInitialValue(item));
    //   // });
    // }.observes('initialValues.[]'),

    actions: {
      onSelected: function onSelected(item) {
        item.toggleProperty('isChecked');
        var selectedItems = this.get('options').filter(function (item) {
          return item.get('isChecked') === true;
        });
        _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onSelected'), this.get('actionParam'), selectedItems);
      }
    }
  });
});