define("my-care-git-ember-portal/components/mc-popover-input/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-component", "my-care-git-ember-portal/utils/common"], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({
        tagName: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this.set('parentId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
            this.set('buttonId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
        },

        didRender: function didRender() {
            _ember["default"].$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
        },

        actions: {
            open: function open() {
                this.set('_shouldRender', true);
                _ember["default"].$('.dropdown.open').removeClass('open');
                _ember["default"].$('#' + this.get('parentId')).addClass('open');
            },

            close: function close() {
                _ember["default"].$('.dropdown.open').removeClass('open');
            },

            reset: function reset() {
                this.set('inputValue', null);
                this.set('value', this.get('inputValue'));
                this.send('close');
            },

            apply: function apply() {
                this.set('value', this.get('inputValue'));
                _ember["default"].$('.dropdown.open').removeClass('open');
            }
        }
    });
});