define('my-care-git-ember-portal/components/mc-credit-card-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      clickRemove: function clickRemove() {
        this.sendAction('remove', this.get('creditCard'));
      },
      clickSetDefault: function clickSetDefault() {
        this.sendAction('setDefault', this.get('creditCard'));
      }
    }
  });
});