define('my-care-git-ember-portal/index/timesheet-incident/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.query('timesheet-note', { pageSize: 10, offset: 0 });
        },

        setupController: function setupController(controller, model) {
            controller.set('_notes', model);
            controller.set('_totalAmount', model.get('meta.total'));
            controller.setup();
        }
    });
});