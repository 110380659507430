define('my-care-git-ember-portal/components/mc-contract/modal/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/validator', 'my-care-git-ember-portal/utils/Validators', 'my-care-git-ember-portal/utils/mc-modal-component', 'my-care-git-ember-portal/config/environment', 'my-care-git-ember-portal/mixins/defer', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsValidator, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalUtilsMcModalComponent, _myCareGitEmberPortalConfigEnvironment, _myCareGitEmberPortalMixinsDefer, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcModalComponent['default'].extend(_myCareGitEmberPortalMixinsValidator['default'], _myCareGitEmberPortalMixinsDefer['default'], {

        _modalHelper: _ember['default'].inject.service('utils/modal-helper'),

        init: function init() {
            this.initVar('_componentId', 'mc-update-contract-modal');
            this.initVar('channelId', 'mc-contract-modal');
            this._super.apply(this, arguments);

            this.initVar('formData', {});
        },

        willSetupOnce: function willSetupOnce() {
            this._super.apply(this, arguments);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var contract = _ref.contract;

            this.set('_contract', contract);
            this.set('contractStartsOn', moment(this.get('_contract.contractStartsOn')).format('DD/MM/YYYY'));
            this.addValidation('contractStartsOn', 'contractStartsOn', 'Starts on', [_myCareGitEmberPortalUtilsValidators['default'].isRequired]);
            this.set('isShowConfirmationForm', false);
            this.set('isSaving', false);
        },

        actions: {
            showConfirmForm: function showConfirmForm() {
                if (this._validate()) {
                    this.set('isShowConfirmationForm', true);
                }
            }

        },

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var contractStartsOn;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!this._validate()) {
                            context$1$0.next = 17;
                            break;
                        }

                        context$1$0.prev = 1;

                        this.set('isSaving', true);
                        contractStartsOn = moment(this.get('contractStartsOn'), 'DD/MM/YYYY').toISOString();

                        this.get('_contract').set('contractStartsOn', contractStartsOn);
                        context$1$0.next = 7;
                        return this.get('_contract').save();

                    case 7:
                        toastr.info('Contract is updated');
                        this._close();

                        context$1$0.next = 14;
                        break;

                    case 11:
                        context$1$0.prev = 11;
                        context$1$0.t0 = context$1$0['catch'](1);

                        console.log('error', context$1$0.t0);

                    case 14:
                        context$1$0.prev = 14;

                        this.set('isSaving', false);
                        return context$1$0.finish(14);

                    case 17:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[1, 11, 14, 17]]);
        }))
    });
});