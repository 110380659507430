define('my-care-git-ember-portal/index/registrations/detail/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        isWorkAndIncoming: (function () {
            return this.get('model.registrationType.name') === 'WI';
        }).property().volatile(),

        isDSS: (function () {
            return this.get('model.registrationType.name') === 'DSS';
        }).property().volatile(),

        isNDHB: (function () {
            return this.get('model.registrationType.name') === 'NDHB';
        }).property().volatile(),

        isMetlifecare: (function () {
            return this.get('model.registrationType.name') === 'Metlifecare';
        }).property().volatile(),

        actions: {
            redirectTo: function redirectTo(route) {
                this.transitionToRoute(route);
            }
        }
    });
});