define('my-care-git-ember-portal/index/registrations/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        errors: [],
        _registrations: [],
        registrations: [],
        _pageSize: 10,
        _totalAmount: 0,
        _currentAmount: 0,
        radioItems: [{ id: 'dss', 'name': 'Dss', isSelected: false }, { id: 'wi', 'name': 'Working income', isSelected: false }, { id: 'all', 'name': 'All', isSelected: true }],
        isMoreUser: true,

        filter: (function () {
            var searchString = this.get('searchString');
            var content = this.get('_registrations');
            var filterResult = content.filter(function (item) {
                if (item.get('getFirstName').toLowerCase().indexOf(searchString.toLowerCase()) != -1 || item.get('getLastName').toLowerCase().indexOf(searchString.toLowerCase()) != -1 || item.get('getEmail').toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    return true;
                }
            });
            this.set('registrations', filterResult);
        }).observes('searchString'),

        actions: {

            onClickPage: function onClickPage(page) {
                var _this = this;

                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.getSearchResults(this.get('pageSize'), offSet, this.get('searchString'), this.get('type')).then(function (registrations) {
                    var _currentAmount = offSet + registrations.get('length');
                    _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                    _this.set('_registrations', registrations);
                    _this.set('registrations', registrations);
                });
            },

            createRegistrations: function createRegistrations() {
                this.transitionToRoute('index.registrations.new');
            },

            doSearch: function doSearch() {
                var _this2 = this;

                var self = this;
                this.set('offset', 0);
                if (_ember['default'].isNone(this.get('searchString'))) {
                    self.getSearchResults(this.get('pageSize'), this.get('offset')).then(function (registrations) {
                        var _currentAmount = registrations.get('length');
                        _this2.set('_currentAmount', _currentAmount >= _this2.get('_totalAmount') ? _this2.get('_totalAmount') : _currentAmount);
                        _this2.set('_totalAmount', registrations.get('meta.total'));
                        _this2.set('_registrations', registrations);
                        _this2.set('registrations', registrations);
                    });
                } else {
                    self.getSearchResults(this.get('pageSize'), this.get('offset'), this.get('searchString'), this.get('type')).then(function (registrations) {
                        var _currentAmount = registrations.get('length');
                        _this2.set('_currentAmount', _currentAmount >= _this2.get('_totalAmount') ? _this2.get('_totalAmount') : _currentAmount);
                        _this2.set('_totalAmount', registrations.get('meta.total'));
                        _this2.set('_registrations', registrations);
                        _this2.set('registrations', registrations);
                    });
                }
            },
            getMoreUsers: function getMoreUsers() {
                var self = this;
                self.set('isLoading', true);
                this.set('offset', this.get('people.length'));
                self.getSearchResults(this.get('pageSize'), this.get('offset'), this.get('searchString'), this.get('type')).then(function (people) {
                    var bool = false;
                    for (var i = 0; i < people.get('length'); i++) {
                        var person = people.objectAt(i);
                        if (!self.get('people').contains(person)) {
                            bool = true;
                            self.get('people').pushObject(person);
                        }
                    }
                    self.set('isMoreUser', bool);
                    self.set('isLoading', false);
                });
            },
            searchByType: function searchByType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.transitionToRoute('index.registrations.' + type);
                }
            }
        },
        getSearchResults: function getSearchResults(pagesize, offset, searchString, type) {
            var self = this;
            self.set('isLoading', true);
            return self.store.query('registration', { pagesize: pagesize, offset: offset, s: searchString, registrationType: type }, { reload: true }).then(function (people) {
                self.set('isLoading', false);
                return people;
            });
        }

    });
});