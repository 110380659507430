define('my-care-git-ember-portal/index/funder/payments/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        fetchDataService: _ember['default'].inject.service('fetch-data'),
        isMore: true,
        _pageSize: 50,
        transactions: [],

        safeComma: (function () {
            return new _ember['default'].Handlebars.SafeString(',');
        }).property(),

        getSearchResults: function getSearchResults(pageSize, offset) {
            var self = this;
            self.set('pageSize', pageSize);
            self.set('offset', offset);
            self.set('isLoading', true);
            return self.store.query('bank-transaction', { pageSize: pageSize, offset: offset }).then(function (transactions) {
                self.set('isLoading', false);
                return transactions;
            });
        },

        actions: {
            onClickPage: function onClickPage(page) {
                var _this = this;

                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.getSearchResults(this.get('_pageSize'), offSet).then(function (transactions) {
                    var _currentAmount = offSet + transactions.get('length');
                    _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                    _this.set('transactions', transactions);
                });
            },

            downloadCSV: function downloadCSV(transaction) {
                var _this2 = this;

                transaction.set('isDownloading', true);
                var filename = 'PaymentFees' + moment(new Date(transaction.get('periodEndsOn'))).format('_DD_MM_YYYY') + '.csv';
                if (transaction.get('description') === 'Service Fee Payout') {
                    _ember['default'].RSVP.resolve(this.fetchCSVServer('/api/Accounts/GenerateMycareFeesFile/' + transaction.get('id'))).then(function (data) {
                        _this2.downLoadCSV(data, filename);
                        transaction.set('isDownloading', false);
                    });
                } else if (transaction.get('description') === 'IRD Payout') {
                    _ember['default'].RSVP.resolve(this.fetchCSVServer('/api/Accounts/GenerateIrdPaymentFile?id=' + transaction.get('id') + '&requiredCsvHeader=false')).then(function (data) {
                        _this2.downLoadCSV(data, filename);
                        transaction.set('isDownloading', false);
                    });
                } else if (transaction.get('description') === 'Worker Payout') {
                    _ember['default'].RSVP.resolve(this.fetchCSVServer('/api/Accounts/GeneratePaymentFile?id=' + transaction.get('id') + '&requiredCsvHeader=false')).then(function (data) {
                        _this2.downLoadCSV(data, filename);
                        transaction.set('isDownloading', false);
                    });
                }
            },
            downloadMycareFeeCSV: function downloadMycareFeeCSV(transaction) {
                var _this3 = this;

                transaction.set('isDownloading', true);
                _ember['default'].RSVP.resolve(this.fetchCSVServer('/api/Accounts/GeneratePaymentFile?id=' + transaction.get('id') + '&requiredCsvHeader=false')).then(function (data) {
                    var filename = '';
                    if (transaction.get('description') === 'Service Fee Payout') {
                        filename = 'MycarePaymentFile';
                    } else if (transaction.get('description') === 'IRD Payout') {
                        filename = 'IRDPaymentFile';
                    } else if (transaction.get('description') === 'Worker Payout') {
                        filename = 'WorkerPaymentFile';
                    }
                    filename = filename + moment(new Date(transaction.get('periodEndsOn'))).format('_DD_MM_YYYY') + '.csv';
                    _this3.downLoadCSV(data, filename);
                    transaction.set('isDownloading', false);
                });
            },
            downloadIRDPayout: function downloadIRDPayout(transaction) {
                var _this4 = this;

                transaction.set('isDownloading', true);
                var filename = 'IRDPaymentDetail' + moment(new Date(transaction.get('periodEndsOn'))).format('_DD_MM_YYYY') + '.csv';
                _ember['default'].RSVP.resolve(this.fetchCSVServer('/api/Accounts/GenerateIrdPaymentFile/' + transaction.get('id'))).then(function (data) {
                    var csvData = _this4.convertToCSV(JSON.stringify(data.irdAccountsPaid));
                    _this4.downLoadCSV(csvData, filename);
                    transaction.set('isDownloading', false);
                });
            },

            downloadIRDFile: function downloadIRDFile(transaction) {
                var _this5 = this;

                transaction.set('isDownloading', true);
                var filename = 'IRDFile' + moment(new Date(transaction.get('periodEndsOn'))).format('_DD_MM_YYYY') + '.csv';
                _ember['default'].RSVP.resolve(this.fetchCSVServer('/api/Accounts/GenerateIrdPaymentFile?id=' + transaction.get('id') + '&requiredCsvHeader=false')).then(function (data) {
                    var csvData = _this5.convertToCSV(JSON.stringify(data));
                    _this5.downLoadCSV(data, filename);
                    transaction.set('isDownloading', false);
                });
            }
        },
        fetchCSVServer: function fetchCSVServer(url) {
            var defer = _ember['default'].RSVP.defer();
            _ember['default'].$.ajax({
                type: 'GET',
                url: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + url,
                contentType: "text/csv",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
                },
                success: function success(data) {
                    defer.resolve(data);
                },
                error: function error(jqXhr, textStatus) {
                    defer.reject(textStatus);
                }
            });
            return defer.promise;
        },

        downLoadCSV: function downLoadCSV(data, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([data]);
                window.navigator.msSaveOrOpenBlob(blob, 'myFile.csv');
            } else {
                var a = document.createElement('a');
                a.href = 'data:attachment/csv,' + encodeURIComponent(data);
                a.target = '_blank';
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            }
        },

        convertToCSV: function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';

            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += ',';

                    line += array[i][index];
                }

                str += line + '\r\n';
            }

            return str;
        }
    });
});