define('my-care-git-ember-portal/components/mc-charts/mc-total-line-chart/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/lineCharts/baseLineChart'], function (exports, _ember, _myCareGitEmberPortalMixinsLineChartsBaseLineChart) {
    // ****chart.js component****
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsLineChartsBaseLineChart['default'], {
        isLoading: false,

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.set('chartParentId', self.get('chartId') + '_parent');
            self.set('pickerStartingTime', self.get('startingTime'));
            self.set('localTime', self.get('startingTime'));
            self.drawDataChart();
        },

        actions: {
            onUpdate: function onUpdate(date) {
                this.set('localTime', date);
                this.drawDataChart();
            }
        },

        drawDataChart: function drawDataChart() {
            var self = this;
            self.set('isLoading', true);
            var reportTypes = self.get('reportTypes');
            var dataArray = [];
            var promiseList = [];
            self.setMonthAndYear();
            reportTypes.forEach(function (reportType) {
                promiseList.push(self.getDataQuery(reportType));
            });
            _ember['default'].RSVP.all(promiseList).then(function (responses) {
                responses.forEach(function (response) {
                    var rawData = response.objectAt(0);
                    dataArray.push({ label: rawData.get('label'), data: rawData.get('values').split(','), total: rawData.get('total'),
                        last30days: rawData.get('last30days'), offset: rawData.get('offset')
                    });
                });
                var dataset = self.getProcessedChartData(dataArray);
                self.buildChart(dataset);
                self.set('isLoading', false);
            });
        },

        getProcessedChartData: function getProcessedChartData(dataArray) {
            var self = this;
            var byOffset = dataArray.slice(0);
            var monthlyData = byOffset.filterBy('label', 'Total');
            monthlyData[0].type = "line";
            self.set('total', monthlyData[0].total);
            self.set('last30days', monthlyData[0].last30days);

            return [self._getTotalDataSet(monthlyData[0])];
        },

        _getTotalDataSet: function _getTotalDataSet(monthlyData) {
            var dataSet = monthlyData.data.slice();
            var offset = monthlyData.offset;
            var totalDataSet = {};
            var data = [];
            dataSet[0] = parseInt(dataSet[0]) + parseInt(offset);
            dataSet.forEach(function (dataObject, index) {
                data[index] = parseInt(dataObject) + parseInt(data[index - 1] === undefined ? 0 : data[index - 1]);
            });
            totalDataSet.type = 'line';
            totalDataSet.label = "Total";
            totalDataSet.data = data;
            totalDataSet.offset = offset;
            totalDataSet.hidden = false;
            return totalDataSet;
        }
    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */