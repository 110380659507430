define('my-care-git-ember-portal/mixins/timesheet-with-issue', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        visits: [],
        entriesSorting: ['actualStartsOn:asc'],
        sortedContent: _ember['default'].computed.sort('visits', 'entriesSorting'),

        didReceiveAttrs: function didReceiveAttrs() {
            this.set('filterResult', this.get('sortedContent'));
        },

        filter: (function () {
            var self = this;
            var searchString = self.get('searchString');
            var content = self.get('sortedContent');
            var filterResult = content.filter(function (item) {
                if (item.get('workerName').toLowerCase().indexOf(searchString.toLowerCase()) != -1 || item.get('clientName').toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    return true;
                }
            });
            self.set('filterResult', filterResult);
        }).observes('searchString')
    });
});