define("my-care-git-ember-portal/components/mc-image-data-cropper/step-two/component", ["exports", "ember", "my-care-git-ember-portal/utils/function-helpers", "my-care-git-ember-portal/utils/mc-component"], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _myCareGitEmberPortalUtilsMcComponent) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('onEnter', undefined);
            this.initVar('onCompleted', undefined);
            this.initVar('onChangeStep', undefined);
            this.initVar('file', undefined);
            this.initVar('cropBoxData', { width: 150, height: 150 });
            this.initVar('ratio', undefined);

            _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onEnter'));
        },

        didInsertElement: function didInsertElement() {
            this._setup();
        },

        _setup: function _setup() {
            var _this = this;

            var $container = this.$('.crop-space');
            var $image = $container.find('img');
            var cropBoxData = this.get('cropBoxData');
            // set center position'
            var left = $container.width() / 2 - cropBoxData.width / 2;
            var top = $container.height() / 2 - cropBoxData.height / 2;
            _ember["default"].set(cropBoxData, 'top', top);
            _ember["default"].set(cropBoxData, 'left', left);
            //init cropper
            var ratio = this.get('ratio') || 1;
            $($image[0]).on('ready', function () {
                _this.cropper.setCropBoxData(cropBoxData);
                // make background transparent
                _this.$('.cropper-container').css('backgroundColor', 'transparent');
                _this.$('.cropper-wrap-box').css('backgroundColor', 'transparent');
                _this.$('.cropper-drag-box').css('backgroundColor', 'transparent');
            });

            this.cropper = new Cropper($image[0], {
                aspectRatio: this.get('cropBoxData.width') / this.get('cropBoxData.height'),
                responsive: true,
                viewMode: 2,
                strict: false,
                guides: false,
                highlight: false,
                dragCrop: false,
                zoomable: false,
                cropBoxMovable: true,
                cropBoxResizable: true,
                minCropBoxWidth: this.get('cropBoxData.width'),
                minCropBoxHeight: this.get('cropBoxData.height'),
                background: false,
                restore: true
            });
        },

        watchImage: (function () {
            this.cropper.replace(this.get('file'));
        }).observes('file'),

        actions: {
            rotateRight: function rotateRight() {
                if (this.cropper) {
                    this.cropper.rotate(90);
                }
            },

            crop: function crop() {
                var canvas = this.cropper.getCroppedCanvas(this.get('cropBoxData'));
                var imageData = canvas.toDataURL();
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onCompleted'), imageData);
            },

            back: function back() {
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(this.get('onChangeStep'), 1);
            }
        }
    });
});