define('my-care-git-ember-portal/index/registrations/new/route', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {
            return this.get('modelAsync').perform();
            // return this.store.createRecord('registration');
        },

        modelAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var registrationTypes, registration;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.store.findAll('registration-type');

                    case 2:
                        registrationTypes = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.store.createRecord('registration');

                    case 5:
                        registration = context$1$0.sent;
                        return context$1$0.abrupt('return', { registrationTypes: registrationTypes, registration: registration });

                    case 7:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            willTransition: function willTransition() {
                var controller = this.controllerFor('index.registrations.new');
                var model = controller.get('registration');
                if (model.get('isNew') === true) {
                    model.deleteRecord();
                }
            }
        },

        setupController: function setupController(controller, model) {
            controller.set('registrationTypes', model.registrationTypes);
            controller.set('registration', model.registration);
            controller.setup();
        }
    });
});