define('my-care-git-ember-portal/mixins/viewer-premission', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({

        isViewer: (function () {
            return localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_VIEWER) === true || localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_VIEWER) === 'true';
        }).property(),

        setViewerPermission: function setViewerPermission(componentId) {
            var self = this;
            if (self.get('isViewer')) {
                var mainDiv = {};
                if (componentId) {
                    mainDiv = _ember['default'].$('#' + componentId);
                } else {
                    mainDiv = self.$('div');
                }
                if (mainDiv) {
                    mainDiv.addClass("no-cursor");
                }
            } else {
                return;
            }
        }
    });
});