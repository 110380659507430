define('my-care-git-ember-portal/models/report-row', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        month: _emberData['default'].attr('string'),
        year: _emberData['default'].attr('string'),
        organisation: _emberData['default'].attr('string'),
        funding: _emberData['default'].attr('string'),
        values: _emberData['default'].attr('string'),
        total: _emberData['default'].attr('number'),
        type: _emberData['default'].attr('string'),
        reportTable: _emberData['default'].belongsTo('report-table'),
        getValuesAsArr: (function () {
            return this.get('values').split(",").map(Number);
        }).property('values'),
        hasValue: (function () {
            return this.get('total') > 0;
        }).property('total')
    });
});