define('my-care-git-ember-portal/login/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        loginServices: _ember['default'].inject.service('login'),
        authController: _ember['default'].inject.controller('auth'),
        login: {},
        actions: {
            login: function login() {
                var self = this;
                var login = {
                    grant_type: 'password',
                    username: self.get('login.email'),
                    password: self.get('login.password')
                };
                if (self.validateForm()) {
                    self.set('isLoading', true);
                    self.get('loginServices').doAuthenticate(login).then(function () {
                        // Transition to previous page or homepage
                        self.set('isLoading', false);
                        self.set('authController.isLoggedIn', true);
                        self.transitionToRoute('index.dashboard');
                    })['catch'](function (error) {
                        self.set('isLoading', false);
                        if (error.status == 400) {
                            if (error.responseJSON.error_description) {
                                self.set('error', error.responseJSON.error_description);
                            } else {
                                self.set('error', 'The user name or password is incorrect.');
                            }
                        } else if (error.status == 302 || error.status == 0 && self.isIEOrEdge()) {
                            self.transitionToRoute('fa-code');
                        }
                    });
                }
            }
        },

        validateForm: function validateForm() {
            var self = this;
            var isValid = true;
            self.set('error', "");
            self.set('passwordError', "");
            _ember['default'].$('.has-error').removeClass('has-error');
            if (!self.get("login.email")) {
                self.set('error', "Email is required.");
                _ember['default'].$('#email').addClass('has-error');
                isValid = false;
            }
            if (!self.get("login.password")) {
                self.set('passwordError', "Password is required.");
                _ember['default'].$('#password').addClass('has-error');
                isValid = false;
            }
            return isValid;
        },

        isIEOrEdge: function isIEOrEdge() {
            var uA = window.navigator.userAgent,
                onlyIEorEdge = /msie\s|trident\/|edge\//i.test(uA) && !!(document.uniqueID || window.MSInputMethodContext);
            // checkVersion = (onlyIEorEdge && +(/(edge\/|rv:|msie\s)([\d.]+)/i.exec(uA)[2])) || NaN;
            return onlyIEorEdge;
        }
    });
});