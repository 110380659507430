define('my-care-git-ember-portal/mixins/verify-phone-number', ['exports', 'ember', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Mixin.create(_myCareGitEmberPortalMixinsDefer['default'], {

        getChangeMobileNumberProcess: function getChangeMobileNumberProcess(mobileNumber) {
            var self = this;
            self.set('isUpdatingPassword', true);
            var changePhoneNumber = {
                "changePhoneNumber": {
                    "email": localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_EMAIL),
                    "newPhoneNumber": mobileNumber
                }
            };
            changePhoneNumber = JSON.stringify(changePhoneNumber);
            var defer = this.getDeferWithToken();
            _ember['default'].$.post(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/logins/ChangePhoneNumber', changePhoneNumber).then(function (loginResponse) {
                defer.resolve(loginResponse);
            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        },

        getConfirmCodeProcess: function getConfirmCodeProcess(mobileNumber, code) {
            var self = this;
            self.set('isEnableWatch', true);
            self.set('isUpdatingPassword', true);
            var changePhoneNumber = {
                "changePhoneNumber": {
                    "email": localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_EMAIL),
                    "newPhoneNumber": mobileNumber,
                    "code": code
                }
            };
            changePhoneNumber = JSON.stringify(changePhoneNumber);
            var defer = self.getDeferWithToken();
            _ember['default'].$.post(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/logins/VerifyPhoneNumber', changePhoneNumber).then(function (loginResponse) {
                defer.resolve(loginResponse);
            }, function (error) {
                defer.reject(error);
            });
            return defer.promise;
        }
    });
});