define("my-care-git-ember-portal/index/weekly-visits-report/worker-report/controller", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        timesheetSummaries: [],
        startsOn: "",
        endsOn: "",
        actions: {
            sortBy: function sortBy(variable) {
                var temp = this.get('timesheetSummaries').sortBy(variable);
                if (!this.get('isReverse')) {
                    temp.reverse();
                }
                this.set('isReverse', !this.get('isReverse'));
                this.set('timesheetSummaries', temp);
            }
        }
    });
});