define('my-care-git-ember-portal/index/visits/detail/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            back: function back() {
                this.get('timesheet').rollbackAttributes();
                window.history.go(-1);
            }
        }
    });
});