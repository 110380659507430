define('my-care-git-ember-portal/services/event-bus', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    _availableEvents: ['global:on:changeRoute', 'global:event:isAuthenticated', 'global:event:logout', 'global:event:profileSwitched', 'global:event:loggedInUserUpdated', 'global:event:currentPersonUpdated', 'mc-navbar:on:hideBackButton', 'mc-navbar:on:showBackButton', 'threads:event:receivedNew', 'worker-stats-counter:event:onProfileStatsChanged', 'mc-feedback-form:on:open', 'mc-feedback-form:on:hide', 'mc-feedback-form:on:send'],

    lastStates: {},

    publish: function publish(name) {
      var matchEvent = this.get('_availableEvents').find(function (e) {
        return e === name;
      });
      var isWithChannel = name.split(':').length === 4;
      if (matchEvent || isWithChannel) {
        this.set('lastStates.' + name.replace(':', '_'), arguments);
        return this.trigger.apply(this, arguments);
      } else {
        throw new Error('Unkown event type (' + name + ')');
      }
    },

    subscribe: function subscribe(name, callable) {
      var executeLastRun = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

      var matchEvent = this.get('_availableEvents').find(function (e) {
        return e === name;
      });
      var isWithChannel = name.split(':').length === 4;
      if (matchEvent || isWithChannel) {
        if (isWithChannel) {
          this.get('_availableEvents').pushObject(name);
        }
        var laststate = this.get('lastStates.' + name.replace(':', '_'));
        var subscription = this.on.apply(this, arguments);
        if (laststate && executeLastRun === true) {
          this.publish(laststate[0], laststate[1]);
        }
        return subscription;
      } else {
        throw new Error('Unkown event type (' + name + ')');
      }
    },

    unsubscribe: function unsubscribe() {
      return this.off.apply(this, arguments);
    }
  });
});