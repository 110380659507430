define('my-care-git-ember-portal/index/users/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/mixins/search_settings_mixin', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalMixinsSearch_settings_mixin, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {
        errors: [],
        _people: [],
        people: [],
        _pageSize: 10,
        _totalAmount: 0,
        _currentAmount: 0,
        isMoreUser: true,
        isWorker: false,
        radioItems: [{ id: 'all', 'name': 'All', isSelected: true }, { id: 'worker', 'name': 'Worker', isSelected: false }, { id: 'seeker', 'name': 'Organiser', isSelected: false }, { id: 'archived', 'name': 'Archived', isSelected: false }, { id: 'owner', 'name': 'Client', isSelected: false }],
        clientTypeItems: [{ id: 'dssowner', 'name': 'DSS client', isSelected: false }, { id: 'privateowner', 'name': 'Private client', isSelected: false }, { id: 'owner', 'name': 'All', isSelected: true }],
        seekerClientTypeItems: [{ id: 'dssseeker', 'name': 'DSS client', isSelected: false }, { id: 'privateseeker', 'name': 'Private client', isSelected: false }, { id: 'seeker', 'name': 'All', isSelected: true }],
        isShowClientType: true,
        isShowSeekerClientType: true,
        firstNameSortingDesc: ['person.firstName:desc'],
        firstNameSortingAsc: ['person.firstName:asc'],

        reset: function reset() {
            this.set('isShowClientType', false);
            this.set('isShowSeekerClientType', false);
            this.set('clientTypeItems', [{ id: 'dssowner', 'name': 'DSS client', isSelected: false }, { id: 'privateowner', 'name': 'Private client', isSelected: false }, { id: 'owner', 'name': 'All', isSelected: true }]);
            this.set('seekerClientTypeItems', [{ id: 'dssseeker', 'name': 'DSS client', isSelected: false }, { id: 'privateseeker', 'name': 'Private client', isSelected: false }, { id: 'seeker', 'name': 'All', isSelected: true }]);
        },
        filter: (function () {
            this.doFilter();
        }).observes('searchString'),

        doFilter: function doFilter() {
            var searchString = this.get('searchString');
            var content = this.get('_people');
            var filterResult = content.filter(function (item) {
                var firstName = item.get('firstName') ? item.get('firstName') : "";
                var lastName = item.get('lastName') ? item.get('lastName') : "";
                var email = item.get('email') ? item.get('email') : "";
                if (firstName.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || lastName.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || item.get('id').toLowerCase().indexOf(searchString.toLowerCase()) != -1 || email.toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    return true;
                }
            });
            this.set('filterResult', filterResult);
        },

        actions: {
            doSearch: function doSearch() {
                var self = this;
                self.set('offset', 0);
                self.set('selectedPage', '');
                this.transitionToRoute('index.users.search', { queryParams: { searchString: this.get('searchString') } });
            },

            onNewBusinessUser: function onNewBusinessUser() {
                this.transitionToRoute('index.users.new-business-user');
            },

            onClickPage: function onClickPage(page) {
                var _this = this;

                var self = this;
                var offSet = (page.get('id') - 1) * this.get('_pageSize');
                this.set('selectedPage', page);
                self.getSearchResults(self.get('pageSize'), offSet, self.get('searchString'), self.get('type')).then(function (people) {
                    var _currentAmount = offSet + people.get('length');
                    _this.set('_currentAmount', _currentAmount >= _this.get('_totalAmount') ? _this.get('_totalAmount') : _currentAmount);
                    _this.set('_people', people);
                    _this.set('filterResult', people);
                });
            },

            searchByType: function searchByType(type) {
                var self = this;
                if (typeof type === 'string') {
                    self.set('_people', []);
                    this.set('selectedPage', '');
                    self.set('pageSize', 10);
                    self.set('isWorker', false);
                    self.transitionToRoute('index.users.' + type);
                }
            },

            onClientFilterClick: function onClientFilterClick(type) {
                var _this2 = this;

                this.set('type', type);
                this.set('offset', 0);
                this.getSearchResults().then(function (people) {
                    _this2.set('_people', people);
                    _this2.set('filterResult', people);
                    _this2.set('_currentAmount', people.get('length') > _this2.get('_pageSize') ? _this2.get('_pageSize') : people.get('length'));
                    _this2.set('_totalAmount', people.get('meta.total'));
                });
            },

            UpdateCRM: function UpdateCRM() {
                var self = this;
                self.set('isCRMUpdating', true);
                var defer = this.getDeferWithToken();
                _ember['default'].$.post(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/AdminProcess/UpdateCrmContacts').then(function (response) {
                    defer.resolve(response);
                }, function (error) {
                    defer.reject(error);
                });
                defer.promise.then(function () {
                    self.set('isCRMUpdating', false);
                    toastr.info('Insightly has been updated');
                }, function (error) {
                    self.set('isCRMUpdating', false);
                    console.log(error);
                    toastr.error('Server error please contact the dev team.');
                });
            },
            routeTo: function routeTo(route, model) {
                this.transitionToRoute(route, model);
            }
        },

        getSearchResults: function getSearchResults() {
            var pagesize = arguments.length <= 0 || arguments[0] === undefined ? this.get('pageSize') : arguments[0];
            var offset = arguments.length <= 1 || arguments[1] === undefined ? this.get('offset') : arguments[1];
            var searchString = arguments.length <= 2 || arguments[2] === undefined ? this.get('searchString') : arguments[2];
            var type = arguments.length <= 3 || arguments[3] === undefined ? this.get('type') : arguments[3];

            var self = this;
            self.set('isLoading', true);
            self.set('type', type);
            return self.store.query('list-person', { pagesize: pagesize, offset: offset, s: searchString, type: type }, { reload: true }).then(function (people) {
                self.set('isLoading', false);
                return people;
            });
        }
    });
});