define('my-care-git-ember-portal/components/mc-tick-box-with-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        value: null,
        originalValue: null,
        hasValue: false,
        setup: (function () {
            var self = this;
            self.set('tickBoxId', self.get('id') + "_tickBox");
            self.set('dropdownId', self.get('id') + "_dropdown");
        }).on('init'),

        watchSetValue: (function () {
            var self = this;
            if (_ember['default'].isEmpty(self.get('value')) === false) {
                self.set('hasValue', true);
                self.set('originalValue', self.get('value'));
            }
        }).observes('value'),

        watchHasValue: (function () {
            var self = this;
            if (self.get('hasValue') === false) {
                self.set('value', null);
            } else {
                if (_ember['default'].isEmpty(self.get('originalValue')) === false) {
                    self.set('value', self.get('originalValue'));
                }
            }
        }).observes('hasValue')
    });
});