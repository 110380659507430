define("my-care-git-ember-portal/services/experience-provide", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _store: _ember["default"].inject.service('store'),

        getProvideById: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findRecord('experience-event-provide', id);

                    case 2:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        updateAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(experienceProvide) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return experienceProvide.save();

                    case 2:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        createAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var formData = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
            var experienceProvide;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        experienceProvide = this.get('_store').createRecord('experience-event-provide', {
                            description: formData.description,
                            experienceEventProvideItem: formData.experienceEventProvideItem,
                            experienceEventTemplate: formData.experienceEventTemplate
                        });
                        context$1$0.next = 3;
                        return experienceProvide.save();

                    case 3:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        deleteAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(provide) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        provide.deleteRecord();
                        context$1$0.next = 3;
                        return provide.save();

                    case 3:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});