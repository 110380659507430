define('my-care-git-ember-portal/index/market-dynamics/controller', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        listingReportTypes: ['jobListings'],
        seekersTypes: ['clients'],
        workers: ['workers'],
        workersStartingTime: moment().add(-12, 'month'),
        clientsStartingTime: moment().add(-12, 'month'),
        listingsStartingTime: moment().add(-12, 'month'),

        updateDashboard: (function () {
            var self = this;
            self.fetchFromServer('/api/Reports/dashboard').then(function (data) {
                // first row //consumer
                //     self.set('publishedProfiles', data.publishedProfiles);
                //     self.set('clientProfiles', data.clientProfiles);
                //     self.set('openJobListings', data.openJobListings);
                //     self.set('hoursAdvertised', data.hoursAdvertised);
                //     self.set('hoursAdvertisedYear', data.hoursAdvertisedYear);
                //     // second row
                //     self.set('consumerContracts', data.consumerContracts - 5);
                //     self.set('consumerDeposits', data.consumerDeposits);
                //     self.set('consumerHoursScheduled', data.consumerHoursScheduled);
                //     self.set('consumerSpend', data.consumerSpend);
                //     self.set('consumerHoursWorked', data.consumerHoursWorked);
                // third row
                self.set('consumerMycareFees', data.consumerMycareFees);
                self.set('consumerHourlyFee', data.consumerHourlyFee);
                self.set('consumerHourlyRate', (data.consumerCostClient - data.consumerHourlyFee) / 0.95);
                self.set('consumerCostClient', data.consumerCostClient);
                self.set('consumerRunRate', data.consumerRunRate);

                //business
                //row one
                // self.set('businessContracts', data.businessContracts);
                // self.set('businessHoursUnsubmit', data.businessHoursUnsubmit);
                // self.set('businessHoursScheduled', data.businessHoursScheduled);
                // self.set('businessHoursWorked', data.businessHoursWorked);
                // self.set('businessRunRate', data.businessRunRate);
            });
        }).on('init'),

        fetchFromServer: function fetchFromServer(url) {
            var defer = _ember['default'].RSVP.defer();
            _ember['default'].$.ajax({
                type: 'GET',
                url: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + url,
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
                },
                success: function success(data) {
                    defer.resolve(data);
                },
                error: function error(jqXhr, textStatus) {
                    defer.reject(textStatus);
                }
            });
            return defer.promise;
        }

    });
});