define('my-care-git-ember-portal/index/weekly-visits-report-detail/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        totalCost: 0,
        totalHours: 0,
        totalVisits: 0,
        queryParams: ['startsOn', 'endsOn', 'personId', 'isWorker'],
        startsOn: null,
        endsOn: null,
        setup: function setup() {
            var _this = this;

            this.set('isLoading', true);
            var queryObject = {
                startsOn: this.get('startsOn'),
                endsOn: this.get('endsOn'),
                isAllStatus: true
            };

            if (this.get('isWorker') === 'true') {
                queryObject.contractorPersonId = this.get('personId');
            } else {
                queryObject.clientPersonId = this.get('personId');
            }

            this.store.query('timesheet', queryObject).then(function (timesheets) {
                _this.set('timesheets', timesheets);
                _this.setupSummary(timesheets);
                _this.set('isLoading', false);
            });
        },
        setupSummary: function setupSummary(timesheets) {
            var cost = 0;
            var hours = 0;
            var totalVisits = 0;
            timesheets.forEach(function (timesheet) {
                cost += parseFloat(timesheet.get('estimatePaymentForWorker'));
                hours += parseFloat(timesheet.get('actualDuration'));
                totalVisits += 1;
            });
            this.set('totalCost', cost);
            this.set('totalHours', hours);
            this.set('totalVisits', totalVisits);
        }

    });
});