define('my-care-git-ember-portal/services/marker', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    trimText: function trimText(description) {
      if (description !== undefined && description !== null) {
        var strLength = 75;
        if (description.length > strLength) {
          description = description.substring(0, strLength - 1) + "... ";
        }
        return description;
      } else {
        return '';
      }
    },

    createProfileMarker: function createProfileMarker(item) {
      var color = arguments.length <= 1 || arguments[1] === undefined ? '#81368B' : arguments[1];

      var services = '';
      if (item.get('services')) {
        services = item.get('services').join(', ');
      }
      return _ember['default'].Object.create({
        id: item.get('id'),
        lat: item.get('latitude'),
        lng: item.get('longitude'),
        title: item.get('title'),
        content: '\n          <div class="profile-info">\n            <div class="panel-header">\n              <h3>\n                <a \n                  href="/#/browse/profile/' + item.get('id') + '?backTo=true" \n                  target="_blank">' + item.get('firstName') + '\n                </a>\n              </h3>\n              <p>\n                <small><i class="fa fa-map-marker"></i>&nbsp;' + item.get('location') + '</small>\n              </p>\n            </div>\n            <div class="panel-body">\n              <p>\n                ' + this.trimText(item.get('description')) + '\n                <a href="/#/browse/profile/' + item.get('id') + '?backTo=true" target="_blank">more</a>\n              </p>\n              <p>\n                <strong>Services:&nbsp;</strong> ' + services + '\n              </p>\n            </div>\n          </div>\n        ',
        icon: {
          path: "M0-165c-27.618 0-50 21.966-50 49.054C-50-88.849 0 0 0 0s50-88.849 50-115.946C50-143.034 27.605-165 0-165z",
          fillColor: '#ccaed0',
          fillOpacity: 1,
          strokeColor: color,
          strokeWeight: 1.5,
          scale: 1 / 5
        }
      });
    },

    createJobMarker: function createJobMarker(item) {
      var color = arguments.length <= 1 || arguments[1] === undefined ? '#009BDE' : arguments[1];

      var services = '';
      if (item.get('services')) {
        services = item.get('services').join(', ');
      }
      return _ember['default'].Object.create({
        id: item.get('id'),
        lat: item.get('latitude'),
        lng: item.get('longitude'),
        title: item.get('title'),
        content: '\n        <div class="job-listing-wrapper-small" style="max-width:300px">\n           <div class="job-listing-wrapper pink">\n            <div class="profile-info">\n              <div class="panel-header">\n                <h3><a href="#/browse/job/' + item.get('id') + '?backTo=true" target="_blank">' + item.get('title') + '</a></h3>\n                <p>\n                  <small><i class="fa fa-map-marker"></i>&nbsp;' + item.get('location') + '</small>\n                </p>\n              </div>\n              <div class="panel-body">\n                <p>\n                  ' + this.trimText(item.get('description')) + '\n                  <a href="#/browse/job/' + item.get('id') + '?backTo=true" target="_blank">more</a>\n                </p>\n                <p>\n                  <strong>Services:&nbsp;</strong> ' + services + '\n                </p>\n              </div>\n            </div>\n           </div>\n        </div>\n        ',
        icon: {
          path: "M0-165c-27.618 0-50 21.966-50 49.054C-50-88.849 0 0 0 0s50-88.849 50-115.946C50-143.034 27.605-165 0-165z",
          fillColor: '#99d7f1',
          fillOpacity: 1,
          strokeColor: color,
          strokeWeight: 1.5,
          scale: 1 / 5
        }
      });
    }
  });
});