define('my-care-git-ember-portal/index/threads/detail/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/fedback-handler', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/config/environment', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsFedbackHandler, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalConfigEnvironment, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsDefer['default'], {

        index: _ember['default'].inject.controller('index'),
        messageService: _ember['default'].inject.service('message'),
        /**
         * Available services to select
         */
        _pageSize: 20,
        selectedPage: null,
        offSet: 0,
        messages: [],
        showButton: true,

        setup: function setup() {
            this.get('messages').clear();
            this.set('showButton', true);
            this.get('initData').perform();
        },
        initData: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var messages;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('messageService.getMessageByThreadIdAsync').perform(this.get('model.id'), this.get('_pageSize'), this.get('offSet'));

                    case 2:
                        messages = context$1$0.sent;

                        this.set('offSet', this.get("offSet") + messages.get('length'));
                        this.get('messages').pushObjects(messages.toArray());
                        if (messages.get('length') < this.get('_pageSize')) {
                            this.set('showButton', false);
                        }

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            loadMore: function loadMore() {
                this.get('initData').perform();
            },

            backAction: function backAction() {
                if (window.history.length > 2) {
                    window.history.go(-1);
                } else {
                    this.transitionToRoute('index.threads');
                }
            }
        }

    });
});