define('my-care-git-ember-portal/components/mc-agreement/row/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        typeStyle: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.updateData();
        },

        updateData: (function () {
            var typeStyle = '';
            if (!_ember['default'].isEmpty(this.get('_agreement.contractorSignedOn')) && !_ember['default'].isEmpty(this.get('_agreement.contractEndsOn')) && moment().diff(this.get('_agreement.contractEndsOn')) > 0) {
                typeStyle = 'danger';
            } else if (!_ember['default'].isEmpty(this.get('_agreement.contractorSignedOn'))) {
                typeStyle = 'success';
            } else {
                typeStyle = 'warning';
            }
            this.set('typeStyle', typeStyle);
        }).observes('_agreement.contractorSignedOn', '_agreement.contractEndsOn'),

        isShowUpdateContractButton: (function () {
            return !_ember['default'].isEmpty(this.get('_agreement.contractorSignedOn')) && (_ember['default'].isEmpty(this.get('_agreement.contractEndsOn')) || moment().diff(this.get('_agreement.contractEndsOn')) <= 0);
        }).property('_agreement.contractorSignedOn', '_agreement.contractEndsOn'),

        actions: {
            onReview: function onReview() {
                _myCareGitEmberPortalUtilsFunctionHelpers['default'].callOrIgnore(this.get('onReview'), this.get('_agreement'));
            },

            showRepaymentModal: function showRepaymentModal() {
                this.get('eventbus').publish('mc-update-contract-modal:on:open:mc-contract-modal', { contract: this.get('_agreement') });
            }
        }
    });
});