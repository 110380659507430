define('my-care-git-ember-portal/components/mc-agreement-timesheet/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        didReceiveAttrs: function didReceiveAttrs() {
            this.setIsUnsubmitted();
        },
        setIsUnsubmitted: function setIsUnsubmitted() {
            var _this = this;

            var self = this;
            return _ember['default'].RSVP.resolve(this.get('timesheet.timesheetStatuses')).then(function (statuses) {
                if (moment().isAfter(_this.get('timesheet.actualEndsOn')) && statuses.get('firstObject.timesheetStatusTypeName').toLowerCase() == 'scheduled') {
                    self.set('isUnsubmitted', true);
                }
                self.set('isUnsubmitted', false);
            });
        },

        TypeStyle: (function () {
            if (this.get('timesheet.timesheetStatuses.firstObject.timesheetStatusTypeName')) {
                if (this.get('timesheet.timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() == 'paid') {
                    return "success";
                } else if (this.get('timesheet.timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() == 'pending') {
                    return "success";
                } else if (this.get('timesheet.timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() == 'scheduled') {
                    return "warning";
                } else if (this.get('timesheet.timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() == 'review') {
                    return "danger";
                }
            }
            return "";
        }).property(),

        totalCost: (function () {
            var statusName = this.get('timesheet.timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase();
            if (statusName == 'scheduled' || statusName == 'accepted') {
                var estimatePayment = this.get('timesheet.estimatePaymentForWorker') ? parseFloat(this.get('timesheet.estimatePaymentForWorker')) : 0;
                var totalMaxMileages = this.get('timesheet.totalMaxMileages') ? parseFloat(this.get('timesheet.totalMaxMileages')) : 0;
                var maxExpenseAmount = this.get('timesheet.maxExpenseAmount') ? parseFloat(this.get('timesheet.maxExpenseAmount')) : 0;

                return estimatePayment + totalMaxMileages + maxExpenseAmount;
            } else {
                var estimatePaymentForWorker = this.get('timesheet.estimatePaymentForWorker') ? parseFloat(this.get('timesheet.estimatePaymentForWorker')) : 0;
                var totalMileagesDollarValue = this.get('timesheet.totalMileagesDollarValue') ? parseFloat(this.get('timesheet.totalMileagesDollarValue')) : 0;
                var totalExpenses = this.get('timesheet.totalExpenses') ? parseFloat(this.get('timesheet.totalExpenses')) : 0;
                return estimatePaymentForWorker + totalMileagesDollarValue + totalExpenses;
            }
        }).property('timesheet.{totalMileages,totalExpenses}')

    });
});