define("my-care-git-ember-portal/models/timesheet", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
    exports["default"] = _emberData["default"].Model.extend({
        hourlyRate: _emberData["default"].attr('string'),
        nightlyRate: _emberData["default"].attr('string'),
        createdOn: _emberData["default"].attr('string'),
        modifiedOn: _emberData["default"].attr('string'),
        periodType: _emberData["default"].attr('string'),
        scheduledStartsOn: _emberData["default"].attr('string'),
        scheduledEndsOn: _emberData["default"].attr('string'),
        actualStartsOn: _emberData["default"].attr('string'),
        actualEndsOn: _emberData["default"].attr('string'),
        isOvernightActual: _emberData["default"].attr('boolean'),
        isOvernightScheduled: _emberData["default"].attr('boolean'),
        isPublished: _emberData["default"].attr('boolean'),
        workerName: _emberData["default"].attr('string'),
        clientName: _emberData["default"].attr('string'),
        clientServiceFee: _emberData["default"].attr('number'),
        workerServiceFee: _emberData["default"].attr('number'),
        gstRate: _emberData["default"].attr('number'),
        taxRate: _emberData["default"].attr('number'),
        activities: _emberData["default"].hasMany('activity', { async: true }),
        expenses: _emberData["default"].hasMany('expense', { async: true }),
        mileages: _emberData["default"].hasMany('mileage', { async: true }),
        notes: _emberData["default"].hasMany('timesheet-note', { async: true }),
        contract: _emberData["default"].belongsTo('contract', { async: true }),
        timesheetStatuses: _emberData["default"].hasMany('timesheet-status', { async: true }),
        totalCostClient: _emberData["default"].attr('number'),
        totalCostWorker: _emberData["default"].attr('number'),
        visitTotal: _emberData["default"].attr('number'),
        clientFee: _emberData["default"].attr('number'),
        workerFee: _emberData["default"].attr('number'),
        workerTax: _emberData["default"].attr('number'),
        totalTaxWorker: _emberData["default"].attr('number'),
        totalBeforeTaxWorker: _emberData["default"].attr('number'),
        isReschedule: _emberData["default"].attr('boolean'),
        hasIncident: _emberData["default"].attr('boolean'),
        isVisitOkayClient: _emberData["default"].attr('boolean'),
        isVisitOkayWorker: _emberData["default"].attr('boolean'),
        maxMileageDistance: _emberData["default"].attr('number', { defaultValue: 0 }),
        maxExpenseAmount: _emberData["default"].attr('number', { defaultValue: 0 }),

        init: function init() {
            this._super.apply(this, arguments);
            this.setEstimateTotal();
            this.setFees();
            this.setTotalExpenses();
            this.setTotalMileages();
        },

        isRemoveAtualTime: (function () {
            return !!(_ember["default"].isEmpty(this.get('actualStartsOn')) && _ember["default"].isEmpty(this.get('actualEndsOn')));
        }).property('actualStartsOn', 'actualEndsOn'),

        timesheetSeekerName: (function () {
            var self = this;
            return self.get('contract.clientPerson.firstName') + " " + moment(self.get('scheduledStartsOn')).utc().format('h: mm a') + ' - ' + moment(self.get('scheduledEndsOn')).utc().format('h: mm a');
        }).property('contract', 'scheduledStartsOn', 'scheduledEndsOn'),

        timesheetWorkerName: (function () {
            var self = this;
            return self.get('contract.contractorPerson.firstName') + ' ' + moment(self.get('scheduledStartsOn')).utc().format('h: mm a') + ' - ' + moment(self.get('scheduledEndsOn')).utc().format('h: mm a');
        }).property('contract', 'scheduledStartsOn', 'scheduledEndsOn'),

        scheduledDurationInMinutes: (function () {
            return moment(this.get('scheduledEndsOn')).diff(moment(this.get('scheduledStartsOn')), 'minutes');
        }).property('scheduledStartsOn', 'scheduledEndsOn'),

        actualDurationInMinutes: (function () {
            return moment(this.get('actualEndsOn')).diff(moment(this.get('actualStartsOn')), 'minutes');
        }).property('actualStartsOn', 'actualEndsOn'),

        scheduledDuration: (function () {
            var ms = moment(this.get('scheduledEndsOn')).diff(moment(this.get('scheduledStartsOn')));
            return moment.duration(ms).days() * 24 + moment.duration(ms).hours() + moment.duration(ms).minutes() / 60;
        }).property('scheduledStartsOn', 'scheduledEndsOn'),

        actualDuration: (function () {
            var ms = moment(this.get('actualEndsOn')).diff(moment(this.get('actualStartsOn')));
            return moment.duration(ms).days() * 24 + moment.duration(ms).hours() + moment.duration(ms).minutes() / 60;
        }).property('actualStartsOn', 'actualEndsOn'),

        getServiceFee: (function () {
            if (_ember["default"].isEmpty(this.get('clientServiceFee'))) {
                return 0;
            }
            return this.get('clientServiceFee');
        }).property('clientServiceFee'),

        getWorkerFee: (function () {
            if (_ember["default"].isEmpty(this.get('workerServiceFee'))) {
                return 0;
            }
            return this.get('workerServiceFee');
        }).property('workerServiceFee'),

        getTaxRate: (function () {
            if (_ember["default"].isEmpty(this.get('taxRate'))) {
                return 0;
            }
            return this.get('taxRate');
        }).property('taxRate'),

        totalMaxMileages: (function () {
            return Number(this.get('maxMileageDistance') || 0) * 0.73;
        }).property('maxMileageDistance'),

        setEstimateTotal: (function () {
            var total = Number(this.get('estimatePaymentForWorker')) + Number(this.get('estimateFee') || 0) + Number(this.get('totalMileages') || 0) + Number(this.get('totalExpenses') || 0);
            this.set('estimateTotal', total);
            this.set('estimateTotalMaxClaim', Number(this.get('estimatePaymentForWorker')) + Number(this.get('estimateMaxClaimFee') || 0) + Number(this.get('totalMaxMileages') || 0) + Number(this.get('maxExpenseAmount') || 0));
        }).observes('isOvernightScheduled', 'isOvernightActual', 'scheduledDuration', 'actualDuration', 'hourlyRate', 'nightlyRate', 'clientServiceFee', 'totalMileages', 'totalExpenses', 'maxMileageDistance', 'maxExpenseAmount'),

        estimatePaymentForWorker: (function () {
            if (this.get('isOvernightActual') === true) {
                return this.get('nightlyRate');
            } else if (this.get('isOvernightScheduled') === true) {
                return this.get('nightlyRate');
            } else if (_ember["default"].isEmpty(this.get('actualDuration')) === false) {
                return this.get('hourlyRate') * this.get('actualDuration');
            } else if (_ember["default"].isEmpty(this.get('scheduledDuration')) === false) {
                return this.get('hourlyRate') * this.get('scheduledDuration');
            }
        }).property('isOvernightScheduled', 'isOvernightActual', 'scheduledDuration', 'actualDuration', 'hourlyRate', 'nightlyRate', 'clientServiceFee'),

        setFees: (function () {
            var visitFee = Number(this.get('estimatePaymentForWorker') || 0) * Number(this.get('getServiceFee') || 0);
            var mileageFee = Number(this.get('totalMileages') || 0) * 0.05;
            var mileageMaxClaimFee = Number(this.get('maxMileageDistance') || 0) * 0.73 * 0.05;
            var expensesFee = Number(this.get('totalExpenses') || 0) * 0.05;
            var expensesMaxClaimFee = Number(this.get('maxExpenseAmount') || 0) * 0.05;
            var totalFee = visitFee + mileageFee + expensesFee;
            var totalMaxClaimsFee = visitFee + mileageMaxClaimFee + expensesMaxClaimFee;
            this.set('visitFee', visitFee);
            this.set('mileageFee', mileageFee);
            this.set('mileageMaxClaimFee', mileageMaxClaimFee);
            this.set('expensesFee', expensesFee);
            this.set('expensesMaxClaimFee', expensesMaxClaimFee);
            this.set('estimateFee', totalFee);
            this.set('estimateMaxClaimFee', totalMaxClaimsFee);
        }).observes('totalExpenses', 'estimatePaymentForWorker', 'isOvernightScheduled', 'isOvernightActual', 'scheduledDuration', 'actualDuration', 'hourlyRate', 'nightlyRate', 'clientServiceFee', 'totalMileages', 'maxMileageDistance', 'maxExpenseAmount'),

        getRound: function getRound(fee) {
            var number = String(parseFloat(fee));
            var partsOfNumber = number.split(".");
            if (partsOfNumber.length > 1) {
                var firstNumber = parseInt(partsOfNumber[1].charAt(0));
                var secondNumber = parseInt(partsOfNumber[1].charAt(1));
                var thirdNumber = parseInt(partsOfNumber[1].charAt(2));
                if (secondNumber % 2 === 0) {
                    if (thirdNumber > 5) {
                        return parseFloat(String(partsOfNumber[0]) + "." + String(firstNumber) + String(secondNumber)) + 0.01;
                    }
                    return parseFloat(String(partsOfNumber[0]) + "." + String(firstNumber) + String(secondNumber));
                } else {
                    if (thirdNumber > 4) {
                        return parseFloat(String(partsOfNumber[0]) + "." + String(firstNumber) + String(secondNumber)) + 0.01;
                    }
                    return parseFloat(String(partsOfNumber[0]) + "." + String(firstNumber) + String(secondNumber));
                }
            } else {
                return parseFloat(fee).toFixed(2);
            }
        },

        setTotalExpenses: (function () {
            var _this = this;

            _ember["default"].RSVP.resolve(this.get('expenses')).then(function (expenses) {
                var total = expenses.reduce(function (acc, current) {
                    return acc + Number(current.get('amount'));
                }, 0);
                _this.set('totalExpenses', total);
            });
        }).observes('expenses.@each.amount'),

        hasExpenseRate: (function () {
            return this.get('totalMileages') > 0 || this.get('maxExpenseAmount') > 0;
        }).property('totalExpenses', 'maxExpenseAmount'),

        setTotalMileages: (function () {
            var _this2 = this;

            _ember["default"].RSVP.resolve(this.get('mileages')).then(function (expenses) {
                var total = expenses.reduce(function (acc, current) {
                    return acc + Number(current.get('distance'));
                }, 0);
                _this2.set('totalMileages', total);
                _this2.set('totalMileagesDollarValue', total * 0.73);
            });
        }).observes('mileages.@each.distance'),

        hasMileageRate: (function () {
            return this.get('totalMileages') > 0 || this.get('maxMileageDistance') > 0;
        }).property('totalMileages', 'maxMileageDistance')
    });
});