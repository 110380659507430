define('my-care-git-ember-portal/index/controller', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        authController: _ember['default'].inject.controller('auth'),
        loginServer: _ember['default'].inject.service('login'),

        appVersion: (function () {
            return 'Version: ' + _myCareGitEmberPortalConfigEnvironment['default'].APP.VERSION;
        }).property().volatile(),

        isAdministrator: (function () {
            return this.get('loginServer.isAdministrator');
        }).property('loginServer.isAdministrator'),

        isReportViewer: (function () {
            return this.get('loginServer.isReportViewer');
        }).property('loginServer.isReportViewer'),

        actions: {
            logout: function logout() {
                this.get('authController').invalidate();
            },

            toggleOverlay: function toggleOverlay() {
                _ember['default'].$(this).toggleClass('active');
                _ember['default'].$('#overlay').toggleClass('open');
                // Close button below
                _ember['default'].$(".bar1").toggleClass("active");
                _ember['default'].$(".bar2").toggleClass("active");
                _ember['default'].$(".bar3").toggleClass("active");
            },

            linkTo: function linkTo(url) {
                _ember['default'].$('#overlay').removeClass('open');
                _ember['default'].$(this).removeClass('active');
                // Close button below
                _ember['default'].$(".bar1").removeClass("active");
                _ember['default'].$(".bar2").removeClass("active");
                _ember['default'].$(".bar3").removeClass("active");
                this.transitionToRoute(url);
            }
        }
    });
});