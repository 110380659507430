define('my-care-git-ember-portal/index/experience/detail/description/controller', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend({

        templateService: _ember['default'].inject.service('experience-event-template'),

        formData: _ember['default'].Object.create({}),
        setup: function setup() {

            this.set('formData.describeYourSelf', this.get('model.describeYourSelf'));
            this.set('formData.whatWeDoDescription', this.get('model.whatWeDoDescription'));
            this.set('formData.whereWeGoDescription', this.get('model.whereWeGoDescription'));
            this.set('formData.guestRequirementDescription', this.get('model.guestRequirementDescription'));
            this.set('formData.guestArrangeDescription', this.get('model.guestArrangeDescription'));
            this.set('formData.note', this.get('model.note'));
        },

        actions: {
            save: function save() {
                this.get('templateService.saveDescriptionsAsync').perform({ template: this.get('model'), formData: this.get('formData') }).then(function () {
                    toastr.info('Job listing updated.');
                });
            }
        }

    });
});