define('my-care-git-ember-portal/index/route', ['exports', 'ember', 'my-care-git-ember-portal/config/environment', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment, _emberConcurrency) {
    exports['default'] = _ember['default'].Route.extend({

        loginServer: _ember['default'].inject.service('login'),

        beforeModel: function beforeModel() {
            var self = this;
            var authController = this.controllerFor('auth');
            if (authController.get('isAuthenticated') === false) {
                localStorage.setItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.REDIRECT_URL, self.get('router.url'));
                self.transitionTo('login');
            }
        },

        model: function model() {
            return this.get('modelAsync').perform();
        },

        modelAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var promiseHash;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.store.findAll('role');

                    case 2:
                        context$1$0.t0 = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.store.findAll('service');

                    case 5:
                        context$1$0.t1 = context$1$0.sent;
                        context$1$0.next = 8;
                        return this.store.findAll('account-type');

                    case 8:
                        context$1$0.t2 = context$1$0.sent;
                        context$1$0.next = 11;
                        return this.store.findAll('language');

                    case 11:
                        context$1$0.t3 = context$1$0.sent;
                        context$1$0.t4 = this.store.findAll('employment-type');
                        context$1$0.t5 = this.store.findAll('requirement');
                        context$1$0.t6 = this.store.findAll('job-application-status-type');
                        context$1$0.t7 = this.store.findAll('hourly-rate');
                        context$1$0.t8 = this.store.findAll('equipment');
                        context$1$0.t9 = this.store.findAll('disability');
                        context$1$0.t10 = this.store.findAll('ethnicity');
                        context$1$0.t11 = this.store.findAll('organisation');
                        context$1$0.t12 = this.store.findAll('subscription-type');
                        context$1$0.t13 = this.store.findAll('funding-type');
                        context$1$0.t14 = this.store.findAll('funding-host');
                        context$1$0.t15 = this.store.findAll('support-type');
                        context$1$0.t16 = this.store.findAll('account-type');
                        context$1$0.t17 = this.store.findAll('ethnicity');
                        context$1$0.t18 = this.store.findAll('qualification-type');
                        context$1$0.t19 = this.store.findAll('registration-type');
                        context$1$0.t20 = this.store.findAll('timesheet-note-category');
                        promiseHash = {
                            roles: context$1$0.t0,
                            services: context$1$0.t1,
                            accountTypes: context$1$0.t2,
                            languages: context$1$0.t3,
                            employmentTypes: context$1$0.t4,
                            requirements: context$1$0.t5,
                            jobApplicationStatus: context$1$0.t6,
                            hourlyRates: context$1$0.t7,
                            equipment: context$1$0.t8,
                            disability: context$1$0.t9,
                            ethnicity: context$1$0.t10,
                            organisations: context$1$0.t11,
                            subscriptionTypes: context$1$0.t12,
                            fundingTypes: context$1$0.t13,
                            fundingHosts: context$1$0.t14,
                            supportTypes: context$1$0.t15,
                            accountTypes: context$1$0.t16,
                            ethnicities: context$1$0.t17,
                            qualificationTypes: context$1$0.t18,
                            registrationTypes: context$1$0.t19,
                            timesheetNoteCategory: context$1$0.t20
                        };
                        context$1$0.next = 32;
                        return _ember['default'].RSVP.hash(promiseHash).then(function (response) {
                            return response;
                        });

                    case 32:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 33:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        setupController: function setupController(controller, model) {

            var isAdministrator = false;
            var isReportViewer = false;
            var isFinanceAdmin = false;
            if (localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_ADMIN).toLowerCase() === 'true') {
                isAdministrator = true;
            }
            if (localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_VIEWER).toLowerCase() === 'true') {
                isReportViewer = true;
            }
            if (localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_FINANCE).toLowerCase() === 'true') {
                isFinanceAdmin = true;
            }
            this.set('loginServer.isAdministrator', isAdministrator);
            this.set('loginServer.isReportViewer', isReportViewer);
            this.set('loginServer.isFinanceAdmin', isFinanceAdmin);
        },

        actions: {
            error: function error(_error) {
                var self = this;
                if (_error.errors && _error && _error.errors[0].status === '401') {
                    self.controllerFor('auth').invalidate();
                    return self.transitionTo('login');
                } else {
                    return self.transitionTo('login');
                }
            }
        }
    });
});