define('my-care-git-ember-portal/mixins/lineCharts/single-line-chart-data', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        drawDataChart: function drawDataChart(isShowRawData) {
            var self = this;
            self.set('isLoading', true);
            self.set('doesShowReloadButton', false);
            self.setMonthAndYear();
            self.getDataQuery(self.get('reportType')).then(function (dssMatches) {
                self.set('isLoading', false);
                self.setLocalData(dssMatches.objectAt(0));
                self.drawChart(dssMatches.objectAt(0), isShowRawData);
            }, function () {
                self.set('isLoading', false);
                self.set('doesShowReloadButton', true);
            });
        },

        getSumData: function getSumData(dataArray) {
            var suMDataArray = [];
            var initialNumber = 0;
            dataArray.forEach(function (dataNumber) {
                initialNumber = parseInt(initialNumber) + parseInt(dataNumber);
                suMDataArray.push(initialNumber);
            });
            return suMDataArray;
        },

        drawChart: function drawChart(data, isShowRawData) {

            var self = this;
            self.set('doesShowReloadButton', false);
            var dataArray = [];
            var myChart = self.setChart();
            var offset = _ember['default'].isEmpty(data.get('offset')) ? 0 : data.get('offset');
            var numbers = data.get('values').split(',');
            var rawNumbers = data.get('values').split(',');
            if (_ember['default'].isEmpty(numbers[0])) {
                numbers[0] = offset;
            } else {
                numbers[0] = parseInt(numbers[0]) + offset;
            }

            if (isShowRawData === true) {
                dataArray.push({ label: data.get('label'), data: rawNumbers });
            }
            dataArray.push({ label: 'Total', data: self.getSumData(numbers), hidden: this.get('isHideTotal') });
            //color scale
            var colorScale = d3.scale.linear().domain([0, dataArray.length]).range([{ color: "#5c068b", opacity: 0.6 }, { color: "#22a0c8", opacity: 0.6 }]);
            dataArray.forEach(function (dataObject, index) {
                myChart.data.datasets.push({
                    label: dataObject.label,
                    data: dataObject.data,
                    fill: false,
                    border: 1,
                    type: "line",
                    borderColor: self.hexToRgb(colorScale(index).color, colorScale(index).opacity),
                    backgroundColor: self.hexToRgb(colorScale(index).color, colorScale(index).opacity),
                    hidden: dataObject.hidden
                });
            });
            myChart.update();
        },
        setLocalData: function setLocalData(data) {
            var self = this;
            self.set('total', data.get('total'));
            self.set('last30days', data.get('last30days'));
            var obj = _ember['default'].Object.create({
                values: data.get('values'),
                offset: data.get('offset')
            });
            self.set('localData', obj);
        }
    });
});