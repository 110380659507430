define("my-care-git-ember-portal/components/mc-joblisting-editor/details/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 24,
              "column": 4
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-joblisting-editor/details/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-right");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "mc-spinner-button", [], ["isButtonLoading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [19, 44], [19, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "isDisabled", ["subexpr", "@mut", [["get", "isDisabledSaveButton", ["loc", [null, [20, 39], [20, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "buttonText", "Save", "action", "save", "classes", "btn btn-primary"], ["loc", [null, [19, 8], [22, 56]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 7
          }
        },
        "moduleName": "my-care-git-ember-portal/components/mc-joblisting-editor/details/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "when");
        dom.setAttribute(el2, "class", "control-label");
        var el3 = dom.createTextNode("Let workers know what they need to do");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "space-top-3");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-group");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "for", "helpRequired");
        dom.setAttribute(el3, "class", "control-label");
        var el4 = dom.createTextNode("Tell the workers anything else about these activities");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 3, 3);
        morphs[3] = dom.createMorphAt(element0, 7, 7);
        return morphs;
      },
      statements: [["attribute", "id", ["concat", [["get", "div_id", ["loc", [null, [1, 12], [1, 18]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "mc-activities-picker", [], ["personId", ["subexpr", "@mut", [["get", "personId", ["loc", [null, [4, 40], [4, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "initialValues", ["subexpr", "readonly", [["get", "formData.activityTypes", ["loc", [null, [5, 55], [5, 77]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 45], [5, 78]]], 0, 0], "onChanged", ["subexpr", "action", ["onSelectedActivities"], [], ["loc", [null, [6, 41], [6, 72]]], 0, 0], "error", ["subexpr", "@mut", [["get", "validations.activityTypes", ["loc", [null, [7, 37], [7, 62]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 8], [7, 64]]], 0, 0], ["inline", "textarea", [], ["classNames", "form-control", "placeholder", "I’m looking for someone to help with…", "value", ["subexpr", "@mut", [["get", "formData.helpRequired", ["loc", [null, [14, 25], [14, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "rows", "8", "id", "helpRequired"], ["loc", [null, [12, 8], [15, 47]]], 0, 0], ["block", "unless", [["get", "isViewer", ["loc", [null, [17, 14], [17, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 4], [24, 15]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});