define('my-care-git-ember-portal/index/board-reports/working/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        approvedVisits: ['dssApprovedVisits', 'privateApprovedVisits', 'approvedVisits'],
        approvedVisitsTime: moment().add(-12, 'month'),
        activePlatformClients: ['activeDssPlatformClients', 'activePrivatePlatformClients', 'activePlatformClients'],
        activePlatformClientsTime: moment().add(-12, 'month'),
        workers: ['activePlatformWorkers'],
        workersStartingTime: moment().add(-12, 'month')

    });
});