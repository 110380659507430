define('my-care-git-ember-portal/index/users/detail/client-agreement/controller', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/mixins/fedback-handler', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalMixinsFedbackHandler, _emberConcurrency) {
    exports['default'] = _ember['default'].Controller.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {
        isClientOrganiser: (function () {
            return this.get('model.isClient') && this.get('model.isOrganiser');
        }).property('model.isClient', 'model.isOrganiser'),

        setup: function setup() {
            var self = this;
            self.get('_getContractsAndListings').perform();
        },

        _getContractsAndListings: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var clientJoblistings, clientAgreements, relationships, relatedPersons, joblistingsArray, agreementsArray;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        clientJoblistings = [];
                        clientAgreements = [];
                        context$1$0.next = 4;
                        return this.get('model.relationships');

                    case 4:
                        relationships = context$1$0.sent;
                        context$1$0.next = 7;
                        return _ember['default'].RSVP.all(relationships.map(function (rel) {
                            return rel.get('relatedPerson');
                        }));

                    case 7:
                        relatedPersons = context$1$0.sent;
                        context$1$0.next = 10;
                        return _ember['default'].RSVP.all(relatedPersons.map(function (p) {
                            return p.get('jobListings');
                        }));

                    case 10:
                        joblistingsArray = context$1$0.sent;
                        context$1$0.next = 13;
                        return _ember['default'].RSVP.all(relatedPersons.map(function (p) {
                            return p.get('clientContracts');
                        }));

                    case 13:
                        agreementsArray = context$1$0.sent;

                        joblistingsArray.forEach(function (listings) {
                            clientJoblistings = clientJoblistings.concat(listings.toArray());
                        });
                        agreementsArray.forEach(function (agreements) {
                            clientAgreements = clientAgreements.concat(agreements.toArray());
                        });

                        this.set('clientJoblistings', clientJoblistings);
                        this.set('clientAgreements', clientAgreements);

                    case 18:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});