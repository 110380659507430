define("my-care-git-ember-portal/index/experience/detail/controller", ["exports", "ember", "ember-concurrency", "my-care-git-ember-portal/utils/Validators", "my-care-git-ember-portal/mixins/validator"], function (exports, _ember, _emberConcurrency, _myCareGitEmberPortalUtilsValidators, _myCareGitEmberPortalMixinsValidator) {
    exports["default"] = _ember["default"].Controller.extend(_myCareGitEmberPortalMixinsValidator["default"], {

        isLoadingCategory: true,
        templateService: _ember["default"].inject.service('experience-event-template'),
        statusService: _ember["default"].inject.service('data-service/experience-event-template-status'),
        statusTypeService: _ember["default"].inject.service('data-service/experience-event-template-status-type'),
        categories: [],
        initData: {},
        isPublished: false,

        formData: _ember["default"].Object.create({}),

        setup: function setup() {
            var status = this.get('model.statuses');
            this.set('currentStatus', _ember["default"].A(status).objectAt(status.get('length') - 1));
            if (this.get('currentStatus.experienceEventTemplateStatusType.id') == 3) {
                this.set('isPublished', true);
            }
        },

        actions: {
            setPublish: function setPublish(bool) {
                if (bool === true) {
                    this.get('setPublishAsync').perform(3, bool, 'Published by admin');
                } else {
                    this.get('setPublishAsync').perform(2, bool, 'Unpublished by admin');
                }
            }
        },
        setPublishAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id, bool, comment) {
            var statusType, status;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('statusTypeService.getById').perform(id);

                    case 2:
                        statusType = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.get('statusService.saveAsync').perform({
                            template: this.get('model.template'),
                            type: statusType,
                            comment: comment
                        });

                    case 5:
                        status = context$1$0.sent;

                        this.set('currentStatus', status);
                        this.set('isPublished', bool);

                    case 8:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});