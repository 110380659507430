define('my-care-git-ember-portal/components/mc-location-picker/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * location-picker component
     * Options <br>
     *   location: the property to assign selected location
     */
    exports['default'] = _ember['default'].Component.extend({
        /**
         * Initialise component at startup
         */
        didInsertElement: function didInsertElement() {
            var self = this;
            var locationPicker = _ember['default'].$('.cp-locationPicker-location');
            locationPicker.attr('data-valid', 'true');
            locationPicker.geocomplete({
                types: ['(regions)'],
                componentRestrictions: { country: "nz" }
            }).bind("geocode:result", function () {
                locationPicker.attr('data-valid', 'true');
                self.set('location', locationPicker.val());
            });
            locationPicker.val(self.get('location'));
        },

        keyDown: function keyDown() {
            var locationPicker = _ember['default'].$('.cp-locationPicker-location');
            locationPicker.attr('data-valid', 'false');
        },
        focusIn: function focusIn() {
            var locationPicker = _ember['default'].$('.cp-locationPicker-location');
            this.set('tempString', locationPicker.val());
            locationPicker.val('');
        },
        focusOut: function focusOut() {
            var locationPicker = _ember['default'].$('.cp-locationPicker-location');
            if (!locationPicker.val()) {
                locationPicker.val(this.get('tempString'));
            }
        },

        countryChange: (function () {
            var self = this;
            var locationPicker = _ember['default'].$('.cp-locationPicker-location');
            if (self.get('selectedCountry.name') === 'New Zealand' || self.get('selectedCountry.name') === '') {
                locationPicker.geocomplete('autocomplete').setComponentRestrictions({ country: 'nz' });
            } else {
                locationPicker.geocomplete('autocomplete').setComponentRestrictions();
            }
        }).observes('selectedCountry'),

        setLocation: (function () {
            var self = this;
            self.set('value', self.get('location'));
        }).observes('location'),

        actions: {
            doSearch: function doSearch() {
                this.sendAction('action', this.get('location'));
            }
        }

    });
});