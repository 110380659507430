define("my-care-git-ember-portal/components/mc-experience/what-will-provide/item/component", ["exports", "ember", "my-care-git-ember-portal/utils/mc-component", "my-care-git-ember-portal/utils/common", "ember-concurrency"], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _myCareGitEmberPortalUtilsCommon, _emberConcurrency) {
    exports["default"] = _myCareGitEmberPortalUtilsMcComponent["default"].extend({
        _provideItemService: _ember["default"].inject.service('experience-provide-item'),
        _provideService: _ember["default"].inject.service('experience-provide'),
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('componentId', _myCareGitEmberPortalUtilsCommon["default"].createGuid());
            this.initVar('doesProvideAnything', false);
            this.initVar('doesShowDescription', false);
            this.initVar('formData', {
                title: null,
                description: null,
                thingsProvided: null
            });

            this.initVar('onCompleted', null);
            this.get('_loadDataAsync').perform();
        },

        _loadDataAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var options;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_provideItemService.findAll').perform();

                    case 2:
                        options = context$1$0.sent;

                        this.set('options', options);

                    case 4:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        currentIndex: (function () {
            if (!_ember["default"].isEmpty(this.get('index'))) {
                return this.get('index') + 1;
            }
            return null;
        }).property('index'),

        actions: {
            onSelected: function onSelected(option) {
                this.set('_provide.experienceEventProvideItem', option);
                this.get('saveAsync').perform();
            },

            onRemove: function onRemove() {
                this.get('_provideService.deleteAsync').perform(this.get('_provide'));
            },

            saveDescription: function saveDescription() {
                this.set('tempDescription', this.get('_provide.description'));
                this.get('_provideService.updateAsync').perform(this.get('_provide'));
                this.set('doesShowDescription', false);
            },

            openAddDescription: function openAddDescription() {
                this.set('tempDescription', this.get('_provide.description'));
                this.set('doesShowDescription', true);
            },

            cancelDescription: function cancelDescription() {
                this.set('_provide.description', this.get('tempDescription'));
                this.set('doesShowDescription', false);
            }
        },

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_provide').save();

                    case 2:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});