define('my-care-git-ember-portal/mixins/datetime-formater', ['exports', 'ember'], function (exports, _ember) {

    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({

        getDateFormat: function getDateFormat(date) {

            var dd = date.getDate();
            var mm = date.getMonth() + 1; //January is 0!
            var yyyy = date.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }

            if (mm < 10) {
                mm = '0' + mm;
            }

            var newDate = dd + '/' + mm + '/' + yyyy;
            return newDate;
        },

        epochToDate: function epochToDate(t) {
            function pad2(n) {
                return n > 9 ? n : '0' + n;
            }

            var d = new Date(t);
            var year = d.getUTCFullYear();
            var month = d.getUTCMonth() + 1; // months start at zero
            var day = d.getUTCDate();

            return pad2(day) + '/' + pad2(month) + '/' + year;
        }

    });
});