define('my-care-git-ember-portal/services/login', ['exports', 'ember', 'my-care-git-ember-portal/config/environment', 'my-care-git-ember-portal/mixins/defer'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment, _myCareGitEmberPortalMixinsDefer) {
    exports['default'] = _ember['default'].Service.extend(_myCareGitEmberPortalConfigEnvironment['default'], _myCareGitEmberPortalMixinsDefer['default'], {
        /**
         * Do authentication
         * @returns promise after login. We can use this to chain callback depending on the requirement.
         *
         */
        isAdministrator: '',
        isReportViewer: '',

        doAuthenticate: function doAuthenticate(loginData) {
            var defer = this.getDeferWithStandardHeader();
            _ember['default'].$.post(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/Token', loginData).then(function (loginResponse) {
                defer.resolve(loginResponse);
            }, function (error) {
                defer.reject(error);
            });
            var promise = defer.promise.then(function (loginResponse) {
                localStorage.setItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY, loginResponse.access_token);
                var isAdministrator = false;
                var isReportViewer = false;
                var isFinanceAdmin = false;

                if (loginResponse.isAdministrator.toLowerCase() == 'true') {
                    isAdministrator = true;
                }
                if (loginResponse.isReportViewer.toLowerCase() == 'true') {
                    isReportViewer = true;
                }
                if (loginResponse.isFinanceAdmin.toLowerCase() == 'true') {
                    isFinanceAdmin = true;
                }

                localStorage.setItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_ADMIN, isAdministrator);
                localStorage.setItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_VIEWER, isReportViewer);
                localStorage.setItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_IS_FINANCE, isFinanceAdmin);

                //Set authorization header to next ajax request.
                return _ember['default'].$.ajaxPrefilter(function (options) {
                    options.headers = {
                        Authorization: 'bearer ' + loginResponse.access_token
                    };
                });
            });
            return promise;
        }

    });
});