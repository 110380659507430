define("my-care-git-ember-portal/components/crop-image-upload", ["exports", "ember", "ember-uploader", "my-care-git-ember-portal/utils/function-helpers"], function (exports, _ember, _emberUploader, _myCareGitEmberPortalUtilsFunctionHelpers) {
    /**
     * File Upload component <br>
     * This component requries Authorization header to be included explicitly since it is not
     * hooked into ember-data adapter.
     * Options <br>
     *   url: the url to upload files to
     *   uploadedUrl: the url for uploaded file after uploading is successful
     */
    exports["default"] = _emberUploader["default"].FileField.extend({

        init: function init() {
            // this.set('onSelected', undefined);
            this._super.apply(this, arguments);
        },

        //Callback for when file is selected.
        filesDidChange: (function () {
            var self = this;
            var files = self.get('files'); //FileList object

            var onReload = function onReload(event) {
                var picFile = event.target;
                var allImgComponents = _ember["default"].$('#cropper-example-2').find('img');
                self.set('imagedata', picFile.result);
                allImgComponents.attr('src', picFile.result);
                _myCareGitEmberPortalUtilsFunctionHelpers["default"].callOrIgnore(self.get('onSelected'), picFile.result);
            };
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                //Only pics
                if (file.type.match('image')) {
                    var picReader = new FileReader();
                    picReader.onload = onReload;
                    picReader.readAsDataURL(file);
                }
            }
        }).observes("files"),

        removeFiles: (function () {
            var input = _ember["default"].$('#cropImageUploader');
            input.val('');
            this.$().val('');
        }).observes('isRemoveFile')

    });
});