define('my-care-git-ember-portal/index/agreement/detail/controller', ['exports', 'ember', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _ember['default'].Controller.extend({
        entriesSorting: ['actualStartsOn:desc'],
        sortedContent: _ember['default'].computed.sort('model.timesheets', 'entriesSorting'),
        _channelId: _myCareGitEmberPortalUtilsCommon['default'].createGuid(),
        isShow: true,
        radioItems: [{ id: 'draft', name: 'Draft', isSelected: false }, { id: 'scheduled', name: 'Scheduled', isSelected: false }, { id: 'submitted', name: 'Submitted', isSelected: false }, { id: 'unsubmitted', name: 'Unsubmitted', isSelected: false }, { id: 'needapproval', name: 'Need approval', isSelected: false }, { id: 'approved', name: 'Approved', isSelected: false }, { id: 'paid', name: 'Paid', isSelected: false }, { id: 'remove', name: 'Removed', isSelected: false }, { id: 'all', name: 'All', isSelected: true }],

        setup: function setup() {
            this.set('isShow', true);
            this.get('radioItems').forEach(function (item) {
                return item.isSelected = false;
            });
            var allItems = this.get('radioItems').filter(function (item) {
                return item.id === 'all';
            });
            allItems[0].isSelected = true;
            this.setIsShowPaid();
        },

        isShowUpdateContractButton: (function () {
            return !_ember['default'].isEmpty(this.get('model.contract.contractorSignedOn')) && (_ember['default'].isEmpty(this.get('model.contract.contractEndsOn')) || moment().diff(this.get('model.contract.contractEndsOn')) <= 0);
        }).property('model.contract.contractorSignedOn', 'model.contract.contractEndsOn'),

        setIsShowPaid: function setIsShowPaid() {
            var self = this;
            var content = this.get('sortedContent');
            if (self.get('isShow') === false) {
                var filterResult = content.filter(function (item) {
                    if (item.get('timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() !== "paid") {
                        return true;
                    }
                });
                self.set('filterResult', filterResult);
            } else {
                self.set('filterResult', content);
            }
        },
        actions: {
            goBack: function goBack() {
                window.history.back();
            },

            clickIsShow: function clickIsShow() {
                this.set('isShow', !this.get('isShow'));
                this.setIsShowPaid();
            },

            showRepaymentModal: function showRepaymentModal() {
                this.get('eventbus').publish('mc-update-contract-modal:on:open:mc-contract-modal', { contract: this.get('model.contract') });
            },

            onDone: function onDone() {
                //            console.log('onDone');
            },

            searchByType: function searchByType(type) {

                var self = this;
                var content = this.get('sortedContent');

                if (type !== 'all') {
                    var filterResult = [];
                    if (type === 'scheduled') {
                        filterResult = content.filter(function (item) {
                            if (moment().isBefore(item.get('actualEndsOn')) && item.get('timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() == 'scheduled') {
                                return true;
                            }
                        });
                    } else if (type === 'unsubmitted') {
                        filterResult = content.filter(function (item) {
                            if (moment().isAfter(item.get('actualEndsOn')) && item.get('timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() == 'scheduled') {
                                return true;
                            }
                        });
                    } else {
                        filterResult = content.filter(function (item) {
                            if (item.get('timesheetStatuses.firstObject.timesheetStatusTypeName').toLowerCase() === type) {
                                return true;
                            }
                        });
                    }

                    self.set('filterResult', filterResult);
                } else {
                    self.set('filterResult', content);
                }
            }
        }

    });
});