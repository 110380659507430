define("my-care-git-ember-portal/models/experience-event-template", ["exports", "ember", "ember-data"], function (exports, _ember, _emberData) {
    exports["default"] = _emberData["default"].Model.extend({
        title: _emberData["default"].attr('string'),
        createdPerson: _emberData["default"].belongsTo('person', { async: true }),
        description: _emberData["default"].attr('string'),
        describeYourSelf: _emberData["default"].attr('string'),
        whatWeDoDescription: _emberData["default"].attr('string'),
        whereWeGoDescription: _emberData["default"].attr('string'),
        directions: _emberData["default"].attr('string'),
        meetingLocation: _emberData["default"].belongsTo('address', { async: true }),
        latitude: _emberData["default"].attr('number'),
        longitude: _emberData["default"].attr('number'),
        guestRequirementDescription: _emberData["default"].attr('string'),
        guestArrangeDescription: _emberData["default"].attr('string'),
        note: _emberData["default"].attr('string'),
        aggreedWithMyCareTermsAndConditions: _emberData["default"].attr('boolean'),
        isAnyItemProvideOnTheEvent: _emberData["default"].attr('boolean'),
        isExperienceIncludeAlcohol: _emberData["default"].attr('boolean'),
        readMyCareTermsAndConditions: _emberData["default"].attr('boolean'),
        preparationTime: _emberData["default"].attr('number', { defaultValue: 0 }),
        maximumNumberOfPeople: _emberData["default"].attr('number', { defaultValue: 0 }),
        images: _emberData["default"].hasMany('image', { async: true }),
        categories: _emberData["default"].hasMany('experience-event-category', { async: true }),
        experienceEvents: _emberData["default"].hasMany('experience-event', { async: true }),
        pricePerPerson: _emberData["default"].attr('number', { defaultValue: 0 }),
        isPublished: _emberData["default"].attr('boolean'),
        publishedOn: _emberData["default"].attr('string'),
        experienceEventProvides: _emberData["default"].hasMany('experience-event-provide', { async: true }),
        originalImage: _emberData["default"].belongsTo('image', { async: true }),
        coverImage: _emberData["default"].belongsTo('image', { async: true }),
        thumbImage: _emberData["default"].belongsTo('image', { async: true }),
        createdOn: _emberData["default"].attr('string'),
        modifiedOn: _emberData["default"].attr('string'),
        cancellation: _emberData["default"].attr('boolean'),
        cancelationType: _emberData["default"].belongsTo('experience-event-template-cancelation-type', { async: true }),

        orderedEvents: (function () {
            return this.get('experienceEvents').sortBy('startsOn');
        }).property('experienceEvents'),

        hasTitleAndDescription: (function () {
            return !_ember["default"].isEmpty(this.get('title')) && !_ember["default"].isEmpty(this.get('description'));
        }).property('title', 'description'),

        hasGallery: (function () {
            return !_ember["default"].isEmpty(this.get('originalImage.url')) || this.get('images.length') > 0;
        }).property('originalImage', 'images.[]'),

        hasCategory: (function () {
            return !_ember["default"].isEmpty(this.get('categories'));
        }).property('categories'),

        hasEvent: (function () {
            return this.get('experienceEvents.length') > 0;
        }).property('experienceEvents.[]'),

        hasWhatWeDoDescription: (function () {
            return !_ember["default"].isEmpty(this.get('whatWeDoDescription'));
        }).property('whatWeDoDescription'),

        hasWhereWeGoDescription: (function () {
            return !_ember["default"].isEmpty(this.get('whereWeGoDescription'));
        }).property('whereWeGoDescription'),

        hasLocation: (function () {
            return !_ember["default"].isEmpty(this.get('meetingLocation.number')) && !_ember["default"].isEmpty(this.get('meetingLocation.street')) && !_ember["default"].isEmpty(this.get('latitude')) && !_ember["default"].isEmpty(this.get('longitude'));
        }).property('meetingLocation.{number,street},latitude,longitude'),

        hasProvides: (function () {
            return this.get('experienceEventProvides.length') > 0;
        }).property('experienceEventProvides'),

        hasNote: (function () {
            return !_ember["default"].isEmpty(this.get('note'));
        }).property('note'),

        hasDescribeYourSelf: (function () {
            return !_ember["default"].isEmpty(this.get('describeYourSelf'));
        }).property('describeYourSelf'),

        hasGuestRequirement: (function () {
            return !_ember["default"].isEmpty(this.get('guestRequirementDescription')) || this.get('isExperienceIncludeAlcohol');
        }).property('guestRequirementDescription', 'isExperienceIncludeAlcohol'),

        hasPricePerPerson: (function () {
            return this.get('pricePerPerson') > 0;
        }).property('pricePerPerson'),

        hasNumberOfGuest: (function () {
            return this.get('maximumNumberOfPeople') > 0;
        }).property('maximumNumberOfPeople'),

        hasPreparation: (function () {
            return this.get('preparationTime') > 0;
        }).property('preparationTime')

    });
});