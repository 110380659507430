define('my-care-git-ember-portal/models/recommendation', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        firstName: _emberData['default'].attr('string'),
        createdOn: _emberData['default'].attr('string'),
        responseOn: _emberData['default'].attr('string'),
        response: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        recommendedBy: _emberData['default'].belongsTo('person', { async: true }),
        person: _emberData['default'].belongsTo('person', { async: true })
    });
});