define('my-care-git-ember-portal/index/account-balance/pending/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.query('account', { type: 'pending-balance' });
        },

        setupController: function setupController(controller, model) {
            controller.set('accounts', model);
        }
    });
});