define('my-care-git-ember-portal/index/experience/index/detail/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        setupController: function setupController(controller) {
            var detailController = this.controllerFor('index.job-listings.detail');
            if (_ember['default'].isEmpty(detailController.get('model.id'))) {
                this.transitionTo('index.job-listings');
            }
            controller.set('model', detailController.get('model'));
            controller.emptyErrorMessages();
            controller.setup();
            controller.resetDropdownComponentAttributes();
        }
    });
});