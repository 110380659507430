define('my-care-git-ember-portal/components/mc-covid-vaccination-settings-form/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/mc-component', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalUtilsMcComponent, _emberConcurrency) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({
        tagName: "",
        div_id: 'covid-vaccination-settings',
        _store: _ember['default'].inject.service('store'),

        init: function init() {
            //public attributes
            this._super.apply(this, arguments);
            //console.log("this.get('loginServer.isAdministrator')",this.isAdministrator);     
            this.get('loadSettings').perform({ reload: true });
            this.initVar('loadingFlag', false);
        },

        isAdministrator: (function () {
            return this.get('loginServer.isAdministrator');
        }).property('loginServer.isAdministrator'),

        didReceiveAttrs: function didReceiveAttrs() {
            this.get('loadSettings').perform({ reload: true });
        },

        loadSettings: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var self, appConfigurationSettingObj;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;

                        this.set('loadingFlag', true);
                        context$1$0.next = 4;
                        return self.get('_store').findAll('covid-vaccination-dose-type').then(function (vaccinationDoses) {
                            self.set('vaccinationDoses', vaccinationDoses);
                        });

                    case 4:
                        context$1$0.next = 6;
                        return self.get('_store').findRecord('app-configuration-setting', '1', { reload: true }).then(function (appConfigurationSettings) {
                            return appConfigurationSettings;
                        });

                    case 6:
                        appConfigurationSettingObj = context$1$0.sent;

                        self.set('doseType', appConfigurationSettingObj.get('settingValue'));

                        this.set('loadingFlag', false);

                    case 9:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveSettings: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(appConfigurationSetting) {
            var self;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;
                        appConfigurationSetting = this.get('_store').createRecord('app-configuration-setting', {
                            minimumCovidVaccinationLevel: self.get('doseType')
                        });
                        context$1$0.next = 4;
                        return appConfigurationSetting.save().then(function () {
                            toastr.info('App settings saved');
                        }, function (error) {
                            toastr.info('App settings not saved');
                            self._addValidationError('api', 'App settings not saved');
                        });

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});