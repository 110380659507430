define('my-care-git-ember-portal/index/board-reports/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            // to trigger index error method, in case to protect tokenKey is not correct
            return this.store.findAll('employment-type', { reload: true });
        },
        setupController: function setupController(controller) {
            controller.updateDashboard();
        }
    });
});