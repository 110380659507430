define('my-care-git-ember-portal/models/bank-transaction', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        amount: _emberData['default'].attr('number'),
        description: _emberData['default'].attr('string'),
        periodStartsOn: _emberData['default'].attr('string'),
        periodEndsOn: _emberData['default'].attr('string'),
        isMycareFee: (function () {
            return this.get('description') === 'Service Fee Payout';
        }).property('description'),

        isIRDPayout: (function () {
            return this.get('description') === 'IRD Payout';
        }).property('description')
    });
});