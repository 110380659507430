define('my-care-git-ember-portal/components/mc-spinner-button/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        // customIcon is an attribute to create font awesome icon
        tagName: '',
        isButtonLoading: false,
        init: function init() {
            this._super();
            if (this.get('customIcon')) {
                this.set('spinnerClass', this.get('customIcon'));
            }
        },

        actions: {
            doAction: function doAction() {
                if (!this.get('isButtonLoading')) {
                    this.sendAction('action', this.get('param'));
                }
            }
        }
    });
});