define('my-care-git-ember-portal/components/mc-account-fee/row/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/function-helpers', 'ember-concurrency'], function (exports, _ember, _myCareGitEmberPortalUtilsFunctionHelpers, _emberConcurrency) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        typeStyle: '',
        _accountFeeService: _ember['default'].inject.service('account-fee-service'),
        showFutureFees: false,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
        },

        currentAccountFee: (function () {
            return this.get('accountFeeGroup.accountFees').objectAt(0);
        }).property('accountFeeGroup.accountFees.[]'),

        futureAccountFees: (function () {
            return this.get('accountFeeGroup.accountFees').slice(1);
        }).property('accountFeeGroup.accountFees.[]'),

        hasFutureFees: (function () {

            return this.get('accountFeeGroup.accountFees.length') > 1;
        }).property('accountFeeGroup.accountFees.[]'),

        deleteAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_accountFeeService.deleteAccountFeeAsync').perform({ accountTypeId: this.get('currentAccountFee.accountTypeId'), accountFeeTypeId: this.get('currentAccountFee.accountFeeTypeId') }).then(function () {
                            _this.sendAction('_onDone');
                        });

                    case 2:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            showEditAccountFeeModal: function showEditAccountFeeModal(accountFeeDto, isNew) {
                this.get('eventbus').publish('mc-update-account-fee-modal:on:open:' + this.get('_channelId'), { accountFeeDto: accountFeeDto, isNew: isNew });
            },

            toggleFutureFees: function toggleFutureFees() {
                this.set('showFutureFees', !this.get('showFutureFees'));
            }
        }
    });
});