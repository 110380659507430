define('my-care-git-ember-portal/helpers/date-format', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (params) {
        var date = params[0];
        var format = params[1];

        if (_ember['default'].isBlank(date)) {
            return '';
        }

        if (_ember['default'].isBlank(format)) {
            format = 'LL';
        }

        return moment(new Date(date)).format(format);
    });
});