define('my-care-git-ember-portal/models/mileage', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        createdOn: _emberData['default'].attr('string'),
        startLocation: _emberData['default'].attr('string'),
        startLatitude: _emberData['default'].attr('number'),
        startLongitude: _emberData['default'].attr('number'),
        endLocation: _emberData['default'].attr('string'),
        endLatitude: _emberData['default'].attr('number'),
        endLongitude: _emberData['default'].attr('number'),
        distance: _emberData['default'].attr('number'),
        rate: _emberData['default'].attr('number'),
        timesheet: _emberData['default'].belongsTo('timesheet', { async: true }),
        createdPerson: _emberData['default'].belongsTo('person', { async: true }),

        value: (function () {
            return Number(this.get('distance')) * 0.73;
        }).property('distance'),

        fee: (function () {
            return Number(this.get('value')) * 0.05;
        }).property('value'),

        total: (function () {
            return Number(this.get('value')) * 1.05;
        }).property('value')
    });
});