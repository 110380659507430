define("my-care-git-ember-portal/services/experience-event-template", ["exports", "ember", "ember-concurrency"], function (exports, _ember, _emberConcurrency) {
    exports["default"] = _ember["default"].Service.extend({
        _store: _ember["default"].inject.service('store'),
        cancellationService: _ember["default"].inject.service('data-service/experience-event-template-cancelation-type'),

        getProvideById: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(id) {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('_store').findRecord('experience-event-provide', id);

                    case 2:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 3:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveDescriptionsAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref) {
            var template = _ref.template;
            var formData = _ref.formData;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        template.set('describeYourSelf', formData.describeYourSelf);
                        template.set('whatWeDoDescription', formData.whatWeDoDescription);
                        template.set('whereWeGoDescription', formData.whereWeGoDescription);
                        template.set('guestRequirementDescription', formData.guestRequirementDescription);
                        template.set('guestArrangeDescription', formData.guestArrangeDescription);
                        template.set('note', formData.note);

                        context$1$0.next = 8;
                        return template.save();

                    case 8:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 9:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveBasicAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref2) {
            var template = _ref2.template;
            var formData = _ref2.formData;
            var cancellation;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        template.set('title', formData.title);
                        template.set('description', formData.description);
                        template.set('categories', formData.categories);
                        template.set('preparationTime', formData.preparationTime);
                        template.set('maximumNumberOfPeople', formData.maximumNumberOfPeople);
                        template.set('pricePerPerson', formData.pricePerPerson);
                        template.set('experienceEventProvides', formData.selectedProvides);
                        template.set('maximumNumberOfPeople', formData.maximumNumberOfPeople);
                        template.set('aggreedWithMyCareTermsAndConditions', formData.aggreedWithMyCareTermsAndConditions);
                        template.set('isPublished', formData.isPublished);
                        template.set('isAnyItemProvideOnTheEvent', formData.isAnyItemProvideOnTheEvent);
                        template.set('isExperienceIncludeAlcohol', formData.isExperienceIncludeAlcohol);
                        template.set('readMyCareTermsAndConditions', formData.readMyCareTermsAndConditions);

                        context$1$0.next = 15;
                        return this.get('cancellationService.getById').perform(formData.cancellationId);

                    case 15:
                        cancellation = context$1$0.sent;

                        template.set('cancelationType', cancellation);
                        context$1$0.next = 19;
                        return template.save();

                    case 19:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 20:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveAddressAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref3) {
            var template = _ref3.template;
            var formData = _ref3.formData;
            var latlng = _ref3.latlng;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        template.set('latitude', latlng.lat);
                        template.set('longitude', latlng.lng);
                        template.set('directions', formData.directions);
                        context$1$0.next = 5;
                        return template.save();

                    case 5:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 6:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveImageAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref4) {
            var template = _ref4.template;
            var formData = _ref4.formData;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (formData.oImage) {
                            template.set('originalImage', formData.oImage);
                        }

                        template.get('images').clear();
                        template.get('images').pushObjects(formData.images);
                        context$1$0.next = 5;
                        return template.save();

                    case 5:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 6:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))
    });
});