define("my-care-git-ember-portal/models/search-job-listing", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    rawId: _emberData["default"].attr("string"),
    title: _emberData["default"].attr("string"),
    description: _emberData["default"].attr("string"),
    hourlyRate: _emberData["default"].attr("string"),
    listedOn: _emberData["default"].attr("string"),
    location: _emberData["default"].attr("string"),
    latitude: _emberData["default"].attr("string"),
    longitude: _emberData["default"].attr("string"),
    hoursPerWeek: _emberData["default"].attr("string"),
    services: _emberData["default"].attr()
  });
});