define('my-care-git-ember-portal/services/fetch-data', ['exports', 'ember', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({
        fetchFromServer: function fetchFromServer(url) {
            var defer = _ember['default'].RSVP.defer();
            _ember['default'].$.ajax({
                type: 'GET',
                url: _myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + url,
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
                },
                success: function success(data) {
                    defer.resolve(data);
                },
                error: function error(jqXhr, textStatus) {
                    defer.reject(textStatus);
                }
            });
            return defer.promise;
        }

    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */