define('my-care-git-ember-portal/components/mc-date-picker/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalUtilsCommon) {

    /**
     * date-picker component
     * Options: <br>
     *   selection: bind variable to update when value is selected.
     */
    exports['default'] = _ember['default'].Component.extend({
        componentId: _myCareGitEmberPortalUtilsCommon['default'].createGuid(),
        disabled: false,
        // tagName: '',
        /**
         * Setup component on startup
         */
        didRender: function didRender() {
            var self = this;
            //Initialise date picker
            this.set('disabled', this.get('inputDisabled') ? this.get('inputDisabled') : false);
            var dateToday = new Date();
            var input = this.$('#' + this.get('componentId')).datepicker({
                //          picker.date = moment.utc();
                date: moment.utc(),
                autoclose: true,
                //Do not change this date format if you can. If you have to do, please check all date related code with momentjs.
                format: 'dd/mm/yyyy',
                todayHighlight: true,
                orientation: "top",
                startDate: self.get('startDate') ? self.get('startDate') : null,
                endDate: self.get('endDate') ? self.get('endDate') : null
            });

            //Call back when date selection is changed
            input.on("changeDate", function (event) {
                //update the property bind with 'selection'
                self.set("selection", event.format());
            });
            this.set('tempSelection', this.get('selection'));

            this._super();
            _ember['default'].run.scheduleOnce('afterRender', this, this.afterRenderEvent);
        },

        afterRenderEvent: function afterRenderEvent() {
            _ember['default'].$('#' + this.get('componentId')).datepicker('setDate', this.get('selection'));
            if (!this.get('selection')) {
                this.set('isEnable', true);
            }
        },

        actions: {
            click: function click() {
                var self = this;
                var input = _ember['default'].$('#' + self.get('componentId'));
                input.show();
                input.datepicker('show');

                if (_ember['default'].isEmpty(self.get('showInput')) || self.get('showInput') === false) {
                    input.hide();
                }
            }
        },

        /**
         * Reset date picker with selected values when selection was maded.
         */
        reset: (function () {
            this.$('.cp-datePicker').datepicker('setDate', this.get('selection'));
        }).observes('selection'),

        startdate: (function () {
            if (this.get('isEnable')) {
                this.set('selection', '');
            } else {
                this.set('selection', this.get('tempSelection'));
            }
        }).observes('isEnable')
    });
});