define('my-care-git-ember-portal/index/threads/detail/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return this.store.findRecord('thread', params.thread_id);
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setup();
        }
    });
});