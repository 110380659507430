define('my-care-git-ember-portal/components/mc-joblisting-card/browse/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mycareEmberUtilsMcComponent) {
  exports['default'] = _mycareEmberUtilsMcComponent['default'].extend({
    init: function init() {
      this.set('jobListing', undefined);
      this.set('onClick', undefined);
      this.set('favouritePersonId', undefined);
      this.set('_isFavourite', false);
      this._super.apply(this, arguments);
    }
  });
});