define('my-care-git-ember-portal/components/mc-registration-form/metlifecare/component', ['exports', 'ember', 'my-care-git-ember-portal/mixins/form-checker', 'my-care-git-ember-portal/mixins/fedback-handler', 'my-care-git-ember-portal/mixins/defer', 'my-care-git-ember-portal/mixins/viewer-premission', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _myCareGitEmberPortalMixinsFormChecker, _myCareGitEmberPortalMixinsFedbackHandler, _myCareGitEmberPortalMixinsDefer, _myCareGitEmberPortalMixinsViewerPremission, _myCareGitEmberPortalConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend(_myCareGitEmberPortalMixinsFormChecker['default'], _myCareGitEmberPortalMixinsFedbackHandler['default'], _myCareGitEmberPortalMixinsDefer['default'], _myCareGitEmberPortalMixinsViewerPremission['default'], {

        _store: _ember['default'].inject.service('store'),
        formData: {},

        div_id: 'dss-form',
        didRender: function didRender() {
            var self = this;
            self.setViewerPermission(this.get('div_id'));
        },

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            self.emptyErrorMessages();
            _ember['default'].RSVP.hash({
                organisations: self.get('_store').findAll('organisation')
            }).then(function (_ref) {
                var disabilities = _ref.disabilities;
                var ethnicities = _ref.ethnicities;
                var fundingTypes = _ref.fundingTypes;
                var organisations = _ref.organisations;
                var benefitTypes = _ref.benefitTypes;

                self.set('organisations', organisations);

                self.newModelSetup();
            });
        },

        selectedOrganisations: (function () {
            var self = this;
            return [self.get('model.organisation')];
        }).property('model.id'),

        newModelSetup: function newModelSetup() {
            var self = this;
            this.set('formData.isUsed', this.get('model.isUsed'));
            var prePaidSubscriber = self.get('model');
            if (prePaidSubscriber.get('isNew') === true) {
                var Metlifecare = this.get('organisations').filter(function (organisation) {
                    return organisation.get('name') === 'Metlifecare';
                });
                prePaidSubscriber.set('organisation', Metlifecare.objectAt(0));
                prePaidSubscriber.set('isUsed', true);
            }
        },

        actions: {
            sendReminderEmail: function sendReminderEmail() {
                var self = this;
                self.set('isSending', true);
                var access_token = localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY);
                $.ajax({
                    url: encodeURI(_myCareGitEmberPortalConfigEnvironment['default'].APP.API_HOST + '/api/AdminProcess/ReSendDssEmail?id=' + self.get('model.id')),
                    method: 'GET',
                    dataType: 'Json',
                    data: {},
                    contentType: 'application/x-www-form-urlencoded',
                    beforeSend: function beforeSend(request) {
                        request.setRequestHeader("Authorization", 'bearer ' + access_token);
                    },
                    success: function success() {
                        self.set('isSending', false);
                        toastr.info('Email sent.');
                    },
                    error: function error(_error) {
                        console.log(_error);
                    }
                });
            },
            save: function save() {
                var self = this;
                if (self.personalDetailCheck()) {
                    self.set('isSaving', true);
                    self.set('model.isUsed', this.get('formData.isUsed'));
                    self.get('model').save().then(function () {
                        self.set('isSaving', false);
                        self.redirectTo('index.registrations.all');
                        toastr.info('Registration saved.');
                    }, function (error) {
                        self.handle502(error);
                    });
                }
            },
            deleteSubscriber: function deleteSubscriber() {
                var self = this;
                bootbox.confirm('Are you sure you want to delete?', function (result) {
                    if (result) {
                        self.set('isDeleting', true);
                        self.get('model').deleteRecord();
                        self.get('model').save().then(function () {
                            self.set('isDeleting', false);
                            self.redirectTo('index.registrations.all');
                        });
                    }
                });
            },

            setOrganisation: function setOrganisation(type) {
                this.set('model.organisation', type);
            },

            onUpdateDateOfBirth: function onUpdateDateOfBirth(date) {
                this.set('model.dateOfBirth', date);
            },

            setReferrer: function setReferrer(value) {
                this.set('model.referrer', value);
            },

            back: function back() {
                this.get('model').rollbackAttributes();
                window.history.go(-1);
            }
        },

        handle502: function handle502(error) {
            var self = this;
            self.set('isSaving', false);
            if (error.errors && error && error.errors[0].status === '0') {
                self.get('model').reload();
                // self.redirectTo('index.registrations.index');
                toastr.error('Please reload this page, check whether the data is saved, and contact Mycare development team.');
            }
        },

        personalDetailCheck: function personalDetailCheck() {
            //Clear out all previous errors
            this.emptyErrorMessages();
            _ember['default'].$('.has-error').removeClass('has-error');
            var isValid = true;
            if (_ember['default'].isEmpty(this.get('model.firstName'))) {
                this.addErrorMessage('First name is required.');
                _ember['default'].$('#firstName').addClass('has-error');
                isValid = false;
            }
            if (_ember['default'].isEmpty(this.get('model.lastName'))) {
                this.addErrorMessage('Last name is required.');
                _ember['default'].$('#lastName').addClass('has-error');
                isValid = false;
            }
            if (_ember['default'].isEmpty(this.get('model.person'))) {
                this.addErrorMessage('Person id is required.');
                _ember['default'].$('#personId').addClass('has-error');
                isValid = false;
            }
            if (_ember['default'].isEmpty(this.get('model.businessName'))) {
                this.addErrorMessage('Business name is required.');
                _ember['default'].$('#businessName').addClass('has-error');
                isValid = false;
            }
            if (_ember['default'].isEmpty(this.get('model.contactPerson'))) {
                this.addErrorMessage('Contact person is required.');
                _ember['default'].$('#contactPerson').addClass('has-error');
                isValid = false;
            }
            if (_ember['default'].isEmpty(this.get('model.costCentre'))) {
                this.addErrorMessage('Cost centre is required.');
                _ember['default'].$('#costCentre').addClass('has-error');
                isValid = false;
            }

            return isValid;
        }

    });
});