define("my-care-git-ember-portal/index/experience/detail/images/controller", ["exports", "ember", "ember-concurrency", "my-care-git-ember-portal/utils/common", "my-care-git-ember-portal/mixins/validator"], function (exports, _ember, _emberConcurrency, _myCareGitEmberPortalUtilsCommon, _myCareGitEmberPortalMixinsValidator) {
    exports["default"] = _ember["default"].Controller.extend(_myCareGitEmberPortalMixinsValidator["default"], {

        formData: _ember["default"].Object.create({}),
        templateService: _ember["default"].inject.service('experience-event-template'),
        _smartCropperService: _ember["default"].inject.service('smart-cropper'),
        _dataUploadService: _ember["default"].inject.service('image-data-uploader'),
        _fileUploadServer: _ember["default"].inject.service('file-upload'),
        _imageService: _ember["default"].inject.service('image'),
        _longImageSize: { width: 750, height: 1000 },
        _wideImageSize: { width: 750, height: 420 },
        _longImageChannelId: _myCareGitEmberPortalUtilsCommon["default"].createGuid(),
        _wideImageChannelId: _myCareGitEmberPortalUtilsCommon["default"].createGuid(),
        _fileBlocks: [],

        setup: function setup() {
            var _this = this;

            _ember["default"].RSVP.all([this.get('model.originalImage'), this.get('model.coverImage'), this.get('model.thumbImage')]).then(function (result) {
                if (!_ember["default"].isEmpty(result[0])) {
                    _this.set('formData._originalImageSource', result[0].get('url'));
                }
                if (!_ember["default"].isEmpty(result[1])) {
                    _this.set('formData._originalImageSource', result[1].get('url'));
                }
                if (!_ember["default"].isEmpty(result[2])) {
                    _this.set('formData._originalImageSource', result[2].get('url'));
                }
                _this.set('formData.images', []);
                if (!_ember["default"].isEmpty(_this.get('model.images'))) {
                    _this.get('_fileBlocks').clear();
                    _this.get('model.images').forEach(function (image, index) {
                        _this.get('_fileBlocks').pushObject(_ember["default"].Object.create({
                            id: index,
                            file: image
                        }));
                    });
                }

                for (var i = _this.get('model.images.length'); i < 5; i++) {
                    _this.get('_fileBlocks').pushObject(_ember["default"].Object.create({
                        id: i,
                        file: null
                    }));
                }
            });
            this._setupValidations();
        },

        _setupValidations: function _setupValidations() {
            this.addValidation('formData.isCheckResult', 'image', 'Image', [this.hasUploadError]);
        },

        hasUploadError: function hasUploadError() {
            return {
                result: !this.get('formData.isCheckResult'),
                message: this.get('imageUploadMessage')
            };
        },

        actions: {
            onCompleted: function onCompleted(images) {
                this.set('formData.images', images);
            },
            onSelectedFile: function onSelectedFile(files, resetInput) {
                var _thisFile = files[0];
                var checkResult = this.get('_fileUploadServer').fileCheck(_thisFile, true);
                var checkFilesize = this.get('_fileUploadServer').isFileTooLarge(_thisFile.size);
                this.set('formData.isCheckResult', false);
                if (checkResult !== true) {
                    this.set('imageUploadMessage', checkResult);
                    this.set('formData.isCheckResult', true);
                    return;
                }

                if (checkFilesize !== true) {
                    this.set('imageUploadMessage', checkFilesize);
                    this.set('formData.isCheckResult', true);

                    return;
                }

                this.get('cropImage').perform(_thisFile, resetInput);
            },

            cropLongImage: function cropLongImage() {
                this.get('eventbus').publish("mc-image-cropper:on:open:" + this.get('_longImageChannelId'));
            },
            cropWideImage: function cropWideImage() {
                this.get('eventbus').publish("mc-image-cropper:on:open:" + this.get('_wideImageChannelId'));
            },

            onLongImageSave: function onLongImageSave(source) {
                this.set('formData._longImageSource', source);
            },

            onWideImageSave: function onWideImageSave(source) {
                this.set('formData._wideImageSource', source);
            }
        },

        saveAsync: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var oImage, lImage, wImage;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (_ember["default"].isEmpty(this.get('formData._originalImageSource'))) {
                            context$1$0.next = 5;
                            break;
                        }

                        context$1$0.next = 3;
                        return this.get('saveImage').perform(this.get('formData._originalImageSource'));

                    case 3:
                        oImage = context$1$0.sent;

                        this.set('model.originalImage', oImage);

                    case 5:
                        if (_ember["default"].isEmpty(this.get('formData._longImageSource'))) {
                            context$1$0.next = 10;
                            break;
                        }

                        context$1$0.next = 8;
                        return this.get('saveImage').perform(this.get('formData._longImageSource'));

                    case 8:
                        lImage = context$1$0.sent;

                        this.set('model.coverImage', lImage);

                    case 10:
                        if (_ember["default"].isEmpty(this.get('formData._wideImageSource'))) {
                            context$1$0.next = 15;
                            break;
                        }

                        context$1$0.next = 13;
                        return this.get('saveImage').perform(this.get('formData._wideImageSource'));

                    case 13:
                        wImage = context$1$0.sent;

                        this.set('model.coverImage', wImage);

                    case 15:

                        this.get('templateService.saveImageAsync').perform({ template: this.get('model'), formData: this.get('formData') });

                    case 16:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        cropImage: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(file, resetInput) {
            var reader;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this2 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        reader = new FileReader();

                        reader.onload = function (evt) {
                            var source = evt.target.result;
                            _this2.set('formData._originalImageSource', source);
                        };
                        reader.readAsDataURL(file);

                        context$1$0.next = 5;
                        return this.get('_smartCropperService.cropImage').perform(file, this.get('_longImageSize'), function (canvas) {
                            var source = canvas.toDataURL("image/png");
                            _this2.set('formData._longImageSource', source);
                        });

                    case 5:
                        context$1$0.next = 7;
                        return this.get('_smartCropperService.cropImage').perform(file, this.get('_wideImageSize'), function (canvas) {
                            var source = canvas.toDataURL("image/png");
                            _this2.set('formData._wideImageSource', source);
                        });

                    case 7:

                        resetInput();

                    case 8:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveImage: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(source) {
            var data, fileUrl, file;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        data = this.get('_fileUploadServer').formatData(source);
                        context$1$0.next = 3;
                        return this.get('_dataUploadService.uploadData').perform(data);

                    case 3:
                        fileUrl = context$1$0.sent;
                        file = _ember["default"].Object.create({
                            url: fileUrl.media.url,
                            fileName: ''
                        });
                        context$1$0.next = 7;
                        return this.get('_imageService.saveAsync').perform(file);

                    case 7:
                        return context$1$0.abrupt("return", context$1$0.sent);

                    case 8:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});