define('my-care-git-ember-portal/components/mc-search-bar/slider/component', ['exports', 'ember', 'my-care-git-ember-portal/utils/common'], function (exports, _ember, _myCareGitEmberPortalUtilsCommon) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',
        isVolunteer: false,
        min: 23,
        max: 40,
        selectMin: 23,
        selectMax: 40,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('parentId', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
            this.set('buttonId', _myCareGitEmberPortalUtilsCommon['default'].createGuid());
        },
        didRender: function didRender() {
            _ember['default'].$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
            this.set('isComponentReady', true);
        },

        actions: {
            open: function open() {
                _ember['default'].$('.dropdown.open').removeClass('open');
                _ember['default'].$('#' + this.get('parentId')).addClass('open');
            },

            onUpdateMin: function onUpdateMin(value) {
                if (this.get('isComponentReady') === true) {
                    this.set('selectMin', value);
                }
            },

            onUpdateMax: function onUpdateMax(value) {
                if (this.get('isComponentReady') === true) {
                    this.set('selectMax', value);
                }
            },

            applyHourlyRate: function applyHourlyRate() {
                this.sendAction('setRate', { max: this.get('selectMax'), min: this.get('selectMin'), isVolunteer: this.get('isVolunteer')
                });
                this.set('isUpdated', true);
                _ember['default'].$('.dropdown.open').removeClass('open');
            },

            cancelHourlyRate: function cancelHourlyRate() {
                this.set('selectMin', this.get('min'));
                this.set('selectMax', this.get('max'));
                this.set('isVolunteer', false);
                this.set('isUpdated', false);
                _ember['default'].$('.dropdown.open').removeClass('open');
            },

            toggleVolunteer: function toggleVolunteer() {
                this.toggleProperty('isVolunteer');
            }
        }
    });
});
/**
 * Loadable mixin
 * This mixin is used to store loading state in controller.
 */