define('my-care-git-ember-portal/index/job-listings/detail/applicants/threads', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        messageSortingDesc: ['message.sentOn:desc'],
        sortedThread: _ember['default'].computed.sort('threads', 'messageSortingDesc'),

        actions: {
            viewThreads: function viewThreads(thread) {
                this.set("isDisplayMessages", true);
                this.transitionToRoute('index.job-listings.detail.applicants.threads.messages', thread.get('id'));
            },
            backToUpperLevel: function backToUpperLevel() {
                this.transitionToRoute('index.job-listings.detail.applicants');
            }
        }

    });
});