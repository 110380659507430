define('my-care-git-ember-portal/index/weekly-visits-report-detail/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        queryParams: {
            startsOn: {
                refreshModel: true
            },
            endsOn: {
                refreshModel: true
            },
            personId: {
                refreshModel: true
            },
            isWorker: {
                refreshModel: true
            }
        },
        setupController: function setupController(controller) {
            controller.setup();
        }
    });
});