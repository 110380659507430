define('my-care-git-ember-portal/components/mc-profile-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    rel: null,
    currentPersonId: null,
    currentProfileRole: null,
    redirectService: _ember['default'].inject.service('redirect'),

    redirectKey: (function () {
      var redirectKey = null;
      var beforeLoginKey = this.get('env').getApp('REDIRECT_BEFORELOGIN_KEY');
      var redirectService = this.get('redirectService');
      if (redirectService.hasKey(beforeLoginKey)) {
        redirectKey = beforeLoginKey;
      } else {
        var profileKey = this.get('env').getApp('REDIRECT_PROFILE_KEY');
        if (redirectService.hasKey(profileKey)) {
          redirectKey = profileKey;
        }
      }
      return redirectKey;
    }).property(),

    isSelected: (function () {
      return this.get('rel.person.id') === this.get('currentPersonId') && this.get('currentProfileRole') === this.get('rel.role.name').toLowerCase();
    }).property('currentPersonId', 'rel.person.id'),

    profileDefaultImg: (function () {
      return this.get('env').getApp('PROFILE_DEFAULT_IMAGE');
    }).property(),

    actions: {
      handleSwitchProfile: function handleSwitchProfile() {
        this.sendAction('action', this.get('rel.role.name'), this.get('rel.person.id'), this.get('rel.person.firstName'), this.get('redirectKey'));
      }
    }
  });
});