define('my-care-git-ember-portal/components/mc-list-experience-event-template/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            sortBy: function sortBy(variable) {
                // var temp= this.get('joblistings').sortBy(variable);
                // if(!this.get('isReverse')){
                //     temp.reverse();
                // }
                // this.set('isReverse',!this.get('isReverse'));
                // this.set('joblistings',temp);
            }
        }
    });
});