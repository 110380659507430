define('my-care-git-ember-portal/index/users/detail/client-report/route', ['exports', 'ember', 'my-care-git-ember-portal/mixins/search_settings_mixin'], function (exports, _ember, _myCareGitEmberPortalMixinsSearch_settings_mixin) {
    exports['default'] = _ember['default'].Route.extend(_myCareGitEmberPortalMixinsSearch_settings_mixin['default'], {

        model: function model(params) {
            return this.store.findRecord('account', params.account_id);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        },

        renderTemplate: function renderTemplate() {
            var controller = this.controllerFor('index.users.detail.client-report');
            this.render('index.users.detail.client-report', {
                into: 'index/users',
                controller: controller
            });
            controller.setup();
        }
    });
});