define('my-care-git-ember-portal/components/mc-joblisting-card/search-detail/component', ['exports', 'ember', 'mycare-ember/utils/mc-component'], function (exports, _ember, _mycareEmberUtilsMcComponent) {
  exports['default'] = _mycareEmberUtilsMcComponent['default'].extend({
    _jobListingService: _ember['default'].inject.service('job-listing'),
    _store: _ember['default'].inject.service('store'),

    init: function init() {
      this.set('jobListing', undefined);
      this.set('onClick', undefined);
      this.set('favouritePersonId', undefined);
      this.set('_isFavourite', false);
      this._super.apply(this, arguments);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.get('_jobListingService').isFavourite(this.get('favouritePersonId'), this.get('jobListing.id')).then(function (result) {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('_isFavourite', result);
      });
    },

    noFixedHours: (function () {
      return this.get('_jobListingService').hasFixedHours(this.get('jobListing'));
    }).property('jobListing.hoursPerWeek'),

    actions: {
      viewJobListing: function viewJobListing(job) {
        var self = this;
        var auth = self.get('auth');
        localStorage.setItem('triggerNextJobSearch', false);
        localStorage.setItem('browseJobScrollId', job.get('id'));
        if (!self.get('auth.isAuthenticated')) {
          _ember['default'].$('#modal-user-not-login').modal('show');
          return;
        } else if ((auth.get('isSeeker') || auth.get('isWorkerOrganiser')) && auth.get('currentProfileRole') === self.get('env').get('ORGANISER_PROFILE_TYPE')) {
          //will change, when person id add the jobsearchlisting object
          _ember['default'].$('#modal-user-is-seeker').modal('show');
          return;
        }
        self.transitionToRoute('browse.jobs.detail', job.get('id'));
      },

      toggleFavourite: function toggleFavourite() {
        var _this2 = this;

        // Eargely highlight the icon
        this.toggleProperty('_isFavourite');
        return this.get('_jobListingService').addOrRemoveFavouritesAsync(this.get('favouritePersonId'), this.get('jobListing.id')).then(function (result) {
          if (_this2.isDestroyed) {
            return;
          }
          _this2.set('_isFavourite', result);
        });
      }
    }

  });
});