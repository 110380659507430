define('my-care-git-ember-portal/index/visits/index/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        radioItems: [{ id: 'draft', name: 'Draft', isSelected: false }, { id: 'scheduled', name: 'Scheduled', isSelected: false }, { id: 'submitted', name: 'Submitted', isSelected: false }, { id: 'unsubmitted', name: 'Unsubmitted', isSelected: false }, { id: 'needapproval', name: 'Need approval', isSelected: false }, { id: 'approved', name: 'Approved', isSelected: false }, { id: 'paid', name: 'Paid', isSelected: false }, { id: 'remove', name: 'Removed', isSelected: false }, { id: 'all', name: 'All', isSelected: true }],

        actions: {
            searchByType: function searchByType(searchType) {
                var _this = this;

                this.set('isLoading', true);
                this.store.query('timesheet', { statuses: searchType }).then(function (timesheets) {
                    _this.set('isLoading', false);
                    _this.set('timesheets', timesheets);
                });
            }
        }
    });
});