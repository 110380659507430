define('my-care-git-ember-portal/components/mc-pagination/component', ['exports', 'my-care-git-ember-portal/utils/mc-component'], function (exports, _myCareGitEmberPortalUtilsMcComponent) {
    exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({
        tagName: '',
        // paginationSize
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('pages', []);
            this.initVar('selectPages', []);
            this.initVar('paginationSize', 5);
            this.initVar('isMorePage', false);
            this.initVar('doesShowPrevious', false);
            this.initVar('doesShowNext', false);
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this.setPages();
            this.setupPages();
        },

        setPages: (function () {
            var pageNumber = Math.ceil(this.get('total') / parseInt(this.get('pageSize')));
            var pages = [];
            for (var i = 1; i <= pageNumber; i++) {
                var page = Ember.Object.create({
                    id: i,
                    name: i,
                    active: i === (Ember.isEmpty(this.get('_selectedPage.id')) ? 1 : this.get('_selectedPage.id'))
                });
                pages.pushObject(page);
            }
            this.set('currentPageSize', this.get('selectedPageSize.size') > this.get('total') ? this.get('total') : this.get('selectedPageSize.size'));
            this.set('pages', pages);
            this.set('doesShowNext', false);
            if (pages.get('length') > 1) {
                this.set('doesShowNext', true);
            }
        }).observes('pageSize', 'total'),

        setupPages: function setupPages() {
            if (this.get('pages.length') > this.get('paginationSize')) {
                this.set('isMorePage', true);
                this.set('doesShowNext', true);
                this.setSelectPages(0, this.get('paginationSize'));
            } else {
                this.set('selectPages', this.get('pages'));
            }
        },

        processSelectedPageSize: function processSelectedPageSize(page) {
            var id = page.get('id');
            var isGreaterThan3 = id > 3;
            var isLessThanPageSize = id < this.get('pages.length') - 1;
            if (isGreaterThan3 || isLessThanPageSize) {
                this.centerSelectedPage(page);
            }
        },

        setShowPreviousAndNextButton: function setShowPreviousAndNextButton(page) {
            var id = page.get('id');
            if (id < this.get('pages.length')) {
                this.set('doesShowNext', true);
            } else {
                this.set('doesShowNext', false);
            }

            if (id > 1) {
                this.set('doesShowPrevious', true);
            } else {
                this.set('doesShowPrevious', false);
            }
        },

        centerSelectedPage: function centerSelectedPage(page) {
            var lastPageId = this.get('pages.length');
            var floor = Math.floor(this.get('paginationSize') / 2);
            var ceil = Math.ceil(this.get('paginationSize') / 2);
            var pageId = page.get('id');
            if (pageId == lastPageId) {
                this.setSelectPages(lastPageId - this.get('paginationSize'), pageId + floor);
            } else if (pageId > lastPageId - floor) {
                var gapTomid = pageId - (lastPageId - floor);
                this.setSelectPages(pageId - ceil - gapTomid, pageId + floor);
            } else if (pageId > floor) {
                this.setSelectPages(pageId - ceil, pageId + floor);
            } else if (pageId <= floor) {
                this.setSelectPages(0, this.get('paginationSize'));
            }
        },

        setSelectPages: function setSelectPages(start, end) {
            this.set('selectPages', this.get('pages').slice(start, end));
        },

        getCurrentPage: function getCurrentPage() {
            var pages = this.get('pages').filterBy('active', true);
            return pages.objectAt(0);
        },
        actions: {
            goPrevious: function goPrevious() {
                var cp = this.getCurrentPage();
                if (cp.get('id') > 0) {
                    cp.set('active', false);
                    var tPages = this.get('pages').filterBy('id', cp.get('id') - 1);
                    var selectPage = tPages.objectAt(0);
                    selectPage.set('active', true);
                    this.processSelectedPageSize(selectPage);
                    this.setShowPreviousAndNextButton(selectPage);
                    this.sendAction('onSelectPage', selectPage);
                }
            },
            goNext: function goNext() {
                var cp = this.getCurrentPage();
                if (cp.get('id') < this.get('pages.length')) {
                    cp.set('active', false);
                    var tPages = this.get('pages').filterBy('id', cp.get('id') + 1);
                    var selectPage = tPages.objectAt(0);
                    selectPage.set('active', true);
                    this.processSelectedPageSize(selectPage);
                    this.setShowPreviousAndNextButton(selectPage);
                    this.sendAction('onSelectPage', selectPage);
                }
            },

            clickPage: function clickPage(page) {
                if (page.get('active') === false) {
                    this.get('pages').forEach(function (page) {
                        page.set('active', false);
                    });
                    page.set('active', true);
                    if (this.get('isMorePage') === true) {
                        this.processSelectedPageSize(page);
                    }
                    this.setShowPreviousAndNextButton(page);
                    this.sendAction('onSelectPage', page);
                }
            }
        }

    });
});