/* jshint ignore:start */

define('my-care-git-ember-portal/config/environment', ['ember'], function(Ember) {
  var prefix = 'my-care-git-ember-portal';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (!runningTests) {
  require("my-care-git-ember-portal/app")["default"].create({"AUTH_PROFILE_ID":"profileId","AUTH_USER_KEY":"userKey","AUTH_IS_ADMIN":"isAdministrator","AUTH_IS_VIEWER":"isReportViewer","AUTH_IS_FINANCE":"isReportFinance","AUTH_TOKEN_KEY":"tokenKey","SEEKER_SIGN_UP":"signUpFor","AUTH_EMAIL":"email","PROFILE_DEFAULT_IMAGE":"/img/profile-default-image.jpg","CIRCLE_DEFAULT_IMAGE":"/img/circle-default-image.jpg","DEFAULT_SEARCH_LOCATION":"Auckland, New Zealand","DEFAULT_SEARCH_LOCATION_LAT":-36.8484597,"DEFAULT_SEARCH_LOCATION_LNG":174.76333150000005,"GUID":"guid","REDIRECT_URL":"redirectUrl","LOG_TRANSITIONS":true,"ENV_NAME":"preprod","API_HOST":"https://mycare-portal-api-preprod.azurewebsites.net","APP_HOST":"https://test.mycare.co.nz","VERSION":"File-size-increase-20240909.1","name":"my-care-git-ember-portal","version":"0.0.0+67841654"});
}

/* jshint ignore:end */
