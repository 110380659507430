define('my-care-git-ember-portal/components/mc-threads-infinity-scroll/component', ['exports', 'ember'], function (exports, _ember) {

    /**
     * File Upload component <br>
     * This component requries Authorization header to be included explicitly since it is not
     * hooked into ember-data adapter.
     * Options <br>
     *   url: the url to upload files to
     *   uploadedUrl: the url for uploaded file after uploading is successful
     */
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            loadMoreMessage: function loadMoreMessage() {
                this.sendAction(this.get('loadMoreMessage'));
            }
        }
    });
});