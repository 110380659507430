define('my-care-git-ember-portal/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        label: _emberData['default'].attr('string'),
        month: _emberData['default'].attr('string'),
        year: _emberData['default'].attr('string'),
        reportType: _emberData['default'].attr('string'),
        values: _emberData['default'].attr('string'),
        total: _emberData['default'].attr('string'),
        last30days: _emberData['default'].attr('string'),
        offset: _emberData['default'].attr('number')
    });
});