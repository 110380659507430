define('my-care-git-ember-portal/models/account-report', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    endsOn: _emberData['default'].attr('string'),
    startsOn: _emberData['default'].attr('string'),
    totalHours: _emberData['default'].attr('number'),
    totalCostClient: _emberData['default'].attr('number'),
    totalCostWorker: _emberData['default'].attr('number'),
    totalBeforeTaxWorker: _emberData['default'].attr('number'),
    totalTaxWorker: _emberData['default'].attr('number')
  });
});