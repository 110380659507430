define('my-care-git-ember-portal/mixins/creditcard-add-process', ['exports', 'ember'], function (exports, _ember) {
    /**
     * Loadable mixin
     * This mixin is used to store loading state in controller.
     */
    exports['default'] = _ember['default'].Mixin.create({
        env: _ember['default'].inject.service('env'),
        customTitle: '',
        creditCardError: '',
        isAddingCreditCard: false,

        addCreditCardProcess: function addCreditCardProcess(account, afterFunction, errorHandle) {
            var self = this;
            _ember['default'].$('#modal-credit-card-add').modal('show');
            self.set('isAddingCreditCard', true);
            _ember['default'].$('#modal-credit-card-add').on('hidden.bs.modal', function () {
                self.set('isAllowGetCreditCard', false);
                self.set('isAddingCreditCard', false);
                self.set('selectedContract', '');
                self.set('creditCardError', '');
            });
            self.store.createRecord('credit-card', { account: account }).save().then(function (creditCard) {
                self.set('creditCardCreateUrl', creditCard.get('url'));
                // reload iframe
                _ember['default'].$('#payment-content').attr('src', function (i, val) {
                    return val;
                });
                self.set('isAllowGetCreditCard', true);
                self.processCreditCardRegister(creditCard.get('id'), afterFunction, errorHandle);
                self.set('isAddingCreditCard', false);
            });
        },

        processCreditCardRegister: function processCreditCardRegister(creditCardId, afterFunction, errorHandle) {
            var self = this;
            if (self.get('isAllowGetCreditCard') === true) {
                _ember['default'].$.ajaxPrefilter(function (options) {
                    options.headers = {
                        Authorization: 'bearer ' + localStorage.getItem(self.get('env').getApp('AUTH_TOKEN_KEY'))
                    };
                });
                _ember['default'].$.ajax({
                    url: self.get('env').getApp('API_HOST') + '/api/creditCards/' + creditCardId,
                    success: function success(data) {
                        var creditCard = data.creditCards;
                        if (_ember['default'].isEmpty(creditCard.cardNumber) === false) {
                            self.store.findRecord('credit-card', creditCard.id, { reload: true }).then(function (creditCard) {
                                afterFunction(creditCard);
                            });
                        } else {
                            var AFunction = function AFunction() {
                                self.processCreditCardRegister(creditCard.id, afterFunction, errorHandle);
                            };
                            _ember['default'].run.later(AFunction, 1000);
                        }
                    },
                    error: function error(response) {
                        self.set('isAllowGetCreditCard', false);
                        self.set('creditCardError', response.responseJSON.HttpError.Message);
                        errorHandle(response.responseJSON.HttpError.Message);
                    }
                });
            }
        },

        stopGetCreditCard: function stopGetCreditCard() {
            this.set('isAllowGetCreditCard', false);
        }

    });
});