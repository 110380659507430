define("my-care-git-ember-portal/components/mc-spinner-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 4,
              "column": 4
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-spinner-button/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-spinner fa-spin");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["content", "buttonText", ["loc", [null, [3, 51], [3, 67]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-spinner-button/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createUnsafeMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "spinnerClass", ["loc", [null, [5, 20], [5, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "buttonText", ["loc", [null, [5, 46], [5, 62]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "my-care-git-ember-portal/components/mc-spinner-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element1, 'id');
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createAttrMorph(element1, 'disabled');
        morphs[3] = dom.createElementMorph(element1);
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "id", ["get", "id", ["loc", [null, [1, 13], [1, 15]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "classes", ["loc", [null, [1, 26], [1, 33]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "isEnable", ["loc", [null, [1, 47], [1, 55]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["doAction"], [], ["loc", [null, [1, 58], [1, 79]]], 0, 0], ["block", "if", [["get", "isButtonLoading", ["loc", [null, [2, 10], [2, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 4], [6, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});