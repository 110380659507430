define('my-care-git-ember-portal/components/mc-validation/component', ['exports', 'my-care-git-ember-portal/utils/mc-component'], function (exports, _myCareGitEmberPortalUtilsMcComponent) {
  exports['default'] = _myCareGitEmberPortalUtilsMcComponent['default'].extend({

    init: function init() {
      this._super.apply(this, arguments);
      this.initVar('validation', undefined);
      this.initVar('id', undefined);
      this.initVar('noFormGroupClass', false);
    }
  });
});