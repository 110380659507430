define("my-care-git-ember-portal/components/mc-image-cropper/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 8
                },
                "end": {
                  "line": 14,
                  "column": 8
                }
              },
              "moduleName": "my-care-git-ember-portal/components/mc-image-cropper/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mc-image-cropper/step-one", [], ["onCompleted", ["subexpr", "action", ["onSetupOneCompleted"], [], ["loc", [null, [13, 50], [13, 80]]], 0, 0]], ["loc", [null, [13, 10], [13, 82]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 8
                },
                "end": {
                  "line": 23,
                  "column": 8
                }
              },
              "moduleName": "my-care-git-ember-portal/components/mc-image-cropper/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mc-image-cropper/step-two", [], ["file", ["subexpr", "@mut", [["get", "_selectedFileData", ["loc", [null, [18, 19], [18, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "cropBoxData", ["subexpr", "@mut", [["get", "cropBoxData", ["loc", [null, [19, 26], [19, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "ratio", ["subexpr", "@mut", [["get", "ratio", ["loc", [null, [20, 20], [20, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onChangeStep", ["subexpr", "action", ["onChangeStep"], [], ["loc", [null, [21, 27], [21, 50]]], 0, 0], "onCompleted", ["subexpr", "action", ["onSetupTwoCompleted"], [], ["loc", [null, [22, 26], [22, 56]]], 0, 0]], ["loc", [null, [17, 10], [22, 58]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 8
                },
                "end": {
                  "line": 30,
                  "column": 8
                }
              },
              "moduleName": "my-care-git-ember-portal/components/mc-image-cropper/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mc-image-cropper/step-three", [], ["file", ["subexpr", "@mut", [["get", "_cropFileDataUrl", ["loc", [null, [27, 19], [27, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "onChangeStep", ["subexpr", "action", ["onChangeStep"], [], ["loc", [null, [28, 27], [28, 50]]], 0, 0], "onCompleted", ["subexpr", "action", ["onSetupThreeCompleted"], [], ["loc", [null, [29, 26], [29, 58]]], 0, 0]], ["loc", [null, [26, 10], [29, 60]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 33,
                "column": 2
              }
            },
            "moduleName": "my-care-git-ember-portal/components/mc-image-cropper/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-body");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "close");
            dom.setAttribute(el2, "aria-label", "Cancel");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "aria-hidden", "true");
            var el4 = dom.createTextNode("×");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "sr-only");
            var el4 = dom.createTextNode("Cancel");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "inner");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "h3 title-highlight");
            var el4 = dom.createTextNode("\n          Update profile picture\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element2, 3, 3);
            morphs[2] = dom.createMorphAt(element2, 5, 5);
            morphs[3] = dom.createMorphAt(element2, 7, 7);
            return morphs;
          },
          statements: [["element", "action", ["cancel"], [], ["loc", [null, [4, 42], [4, 61]]], 0, 0], ["block", "if", [["get", "_stepRegistry.one", ["loc", [null, [12, 14], [12, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 8], [14, 15]]]], ["block", "if", [["get", "_stepRegistry.two", ["loc", [null, [16, 14], [16, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 8], [23, 15]]]], ["block", "if", [["get", "_stepRegistry.three", ["loc", [null, [25, 14], [25, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [25, 8], [30, 15]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "my-care-git-ember-portal/components/mc-image-cropper/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "section.body", ["loc", [null, [2, 8], [2, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [33, 9]]]]],
        locals: ["section"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 13
          }
        },
        "moduleName": "my-care-git-ember-portal/components/mc-image-cropper/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "mc-modal", [], ["channelId", ["subexpr", "@mut", [["get", "_modalChannelId", ["loc", [null, [1, 22], [1, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [34, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});