define('my-care-git-ember-portal/helpers/dollar-format', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (params) {
        var number = params[0];
        var places = params[1] ? params[1] : 2;
        if (_ember['default'].isEmpty(number)) {
            return '$0.00';
        }

        var fixedTwoDigital = null,
            twoPartOfNumber = null,
            numberBeforeDoc = null;
        if (number >= 0) {
            fixedTwoDigital = parseFloat(number).toFixed(places);
            twoPartOfNumber = fixedTwoDigital.split('.');
            numberBeforeDoc = null;
            if (twoPartOfNumber.length === 1) {
                numberBeforeDoc = twoPartOfNumber[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return '$' + numberBeforeDoc;
            } else {
                numberBeforeDoc = twoPartOfNumber[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return '$' + numberBeforeDoc + '.' + twoPartOfNumber[1];
            }
        } else {
            fixedTwoDigital = (-parseFloat(number)).toFixed(places);
            twoPartOfNumber = fixedTwoDigital.split('.');
            numberBeforeDoc = null;
            if (twoPartOfNumber.length === 1) {
                numberBeforeDoc = twoPartOfNumber[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return '-$' + numberBeforeDoc;
            } else {
                numberBeforeDoc = twoPartOfNumber[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return '-$' + numberBeforeDoc + '.' + twoPartOfNumber[1];
            }
        }
    });
});