define('my-care-git-ember-portal/helpers/get-joblisting-status-css-class', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Helper.helper(function (param) {
        var isPublished = param[0].get('isPublished');
        var isCloseTimeValid = param[0].get('closesOn') ? moment().utc().isBefore(param[0].get('closesOn')) : true;

        if (isPublished === true && isCloseTimeValid === true) {
            return "success";
        } else if (isPublished === false) {
            return "warning";
        } else if (isCloseTimeValid === false) {
            return "danger";
        }
    });
});